import React, { Component } from 'react';
import axios from 'axios';
import {injectIntl, FormattedMessage as FM} from 'react-intl';
import * as appConst from './../../common/const/Const';
import * as utils from './../../common/utils/Funcs';

class AreaInteres extends Component {

    constructor (props){
        super(props);
        this.state = {
            id: this.props.id,
            nombre: this.props.nombre
        }
    }

    componentDidMount(){

        // Inicialización de los tooltip de este componente
        utils.iniTooltip();
    }
    
    editar(id){
        document.querySelector("#capaLabelTag_"+id).className = "hideElement";
        document.querySelector("#capaTextoTag_"+id).className = "";
        document.querySelector("#txtTag_"+id).value = document.querySelector("#labelTag_"+id).innerText;
        document.querySelector("#txtTag_"+id).focus();
    }

    cancelar(id){
        document.querySelector("#capaLabelTag_"+id).className = "";
        document.querySelector("#capaTextoTag_"+id).className = "hideElement";
    }

    async actualizar(id){

        if (document.querySelector("#txtTag_"+id).value){
        
            const data = {
                "nombre": document.querySelector("#txtTag_"+id).value,
                "idAutor": utils.getTokenValue("idUsuario", appConst.PERFIL_PADRE)
            }

            try{
                const response = await axios.put(appConst.PUT_AREA_INTERES.replace("#idAreaInteres", id), data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)} )
                
                if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
                    if (utils.getLog()) console.log("status PUT_AREA_INTERES: "+response.status);
                    document.querySelector("#capaLabelTag_"+id).className = "";
                    document.querySelector("#capaTextoTag_"+id).className = "hideElement";
                    document.querySelector("#labelTag_"+id).innerText = document.querySelector("#txtTag_"+id).value;
                    this.props.lanzarMensaje(this.props.intl.formatMessage({id:"app.padre.areaInteres.mensajes.editar"}), appConst.ALERT_TIPO_SUCCESS, appConst.ALERT_CLOSE)
                }

            }catch(error){
                if (utils.getLog()) console.log("Error PUT_AREA_INTERES: "+error);
                this.props.lanzarMensaje(utils.getTextError(error, this.props.intl, this.props.history, null, null, this.actualizar.bind(this), [id], appConst.PERFIL_PADRE), utils.getTipoAlert(error), appConst.ALERT_OPEN);
            }
        
        }else this.props.lanzarMensaje(this.props.intl.formatMessage({id:"app.padre.areaInteres.validar.area-interes-no-vacia"}), appConst.ALERT_TIPO_WARNING, appConst.ALERT_CLOSE);
    }

    async eliminar(id){
        
        try{
            const response = await axios.delete(appConst.DELETE_AREA_INTERES.replace("#idAreaInteres", id), {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)} )

            if (response && response.status === appConst.HTTPSTATUS_OK){
                if (utils.getLog()) console.log("status DELETE_AREA_INTERES: "+response.status);
                // Recargar todas las áreas de interés
                this.props.recargarLista();
                this.props.lanzarMensaje(this.props.intl.formatMessage({id:"app.padre.areaInteres.mensajes.eliminar"}), appConst.ALERT_TIPO_SUCCESS, appConst.ALERT_CLOSE)
            }

        }catch(error){
            if (utils.getLog()) console.log("Error DELETE_AREA_INTERES: "+error);
            this.props.lanzarMensaje(utils.getTextError(error, this.props.intl, this.props.history, null, null, this.eliminar.bind(this), [id], appConst.PERFIL_PADRE), utils.getTipoAlert(error), appConst.ALERT_OPEN);
        }
    }
    
    render(){ 
        return (
            <div className="separadorEtiqueta p-2" id={this.state.id}>
                <div className="row">
                    <div className="col-sm-10">
                        <div className="hideElement" id={"capaTextoTag_"+this.state.id}>
                            <span className="icon-tag"></span>
                            <input className="input-css" type="text" id={"txtTag_"+this.state.id} name={"txtTag_"+this.state.id} size="45" maxLength="100" />&nbsp;&nbsp;
                            <span className="icon-check hand text-secondary me-2" id={"guardarTag_"+this.state.id} data-bs-toggle="tooltip" title={this.props.intl.formatMessage({id:"app.padre.areaInteres.botonera.guardar"})} onClick={this.actualizar.bind(this, this.state.id)}></span>
                            <span className="icon-cancel hand text-secondary" id={"cancelarTag_"+this.state.id} data-bs-toggle="tooltip" title={this.props.intl.formatMessage({id:"app.padre.areaInteres.botonera.cancelar"})} onClick={this.cancelar.bind(this, this.state.id)}></span>
                        </div>
                        <div id={"capaLabelTag_"+this.state.id}>
                            <span className="icon-tag"></span>
                            <span className="labelEtiqueta" id={"labelTag_"+this.state.id} name={"labelTag_"+this.state.id}>{this.state.nombre}</span>
                        </div>
                    </div>
                    <div className="col-sm-2 text-end">
                        <span className="icon-edit icono-muy-peke hand link" data-bs-toggle="tooltip" title={this.props.intl.formatMessage({id:"app.padre.areaInteres.botonera.editar"})} id={"btnEditar_"+this.state.id} onClick={this.editar.bind(this, this.state.id)}></span>&nbsp;&nbsp;
                        <span className="icon-delete icono-muy-peke hand link" data-bs-toggle="tooltip" title={this.props.intl.formatMessage({id:"app.padre.areaInteres.botonera.eliminar"})} id={"btnEliminar_"+this.state.id} onClick={this.eliminar.bind(this, this.state.id)}></span>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(AreaInteres);