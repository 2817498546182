import React, { Component } from 'react';
import * as utils from './Funcs';
import { withRouter} from 'react-router-dom';

class Callback extends Component {

    constructor(props){
        super(props);
        
        if (utils.getLog()) console.log("callback --> " + process.env.REACT_APP_WEB_BASE_URL+"/"+this.props.urlCallback);
            
        // Se redirige a la dirección urlCallback una vez se haya hecho login con éxito
        this.props.history.push(process.env.REACT_APP_WEB_BASE_URL + "/" + this.props.urlCallback);
    }

    render(){ 
        return (
            <div></div>
        )
    }
}

export default withRouter(Callback);