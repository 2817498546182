import React, { Component } from 'react';
import HeaderGuess from './../../common/header/HeaderGuess';
import Footer from './../footer/Footer';
import FormRegistro from './FormRegistro';
import * as appConst from './../../common/const/Const'

class Registro extends Component {

  constructor(props){
    super(props);
    this.state = {
      logoPadre: ''
    }
  }

  setLogoPadreHeader(logo){

    this.setState({logoPadre: logo});
  }

  render(){ 
    return (
      <div>
        {/* CABECERA */}
        <HeaderGuess logo={this.state.logoPadre}/>

        {/* FORM REGISTRO */}
        <FormRegistro _setLogoPadreHeader={this.setLogoPadreHeader.bind(this)}/>
        
        {/* PIE DE PAGINA */}
        <Footer patrocinadores={appConst.OCULTAR_PATROCINADORES} />
      </div>
    )
  }
}

export default Registro;