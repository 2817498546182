import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import * as appConst from '../const/Const';
import * as utils from '../utils/Funcs';

/*
 * PROPIEDADES DE ENTRADA:
 *
 *      - endpoint          : nombre del servicio web de la API
 *      - paramsEndpoint    : parámetros del servicio web
 *      - perfil            : id del perfil
 *      - textField1        : campo a mostrar de la entidad de hibernate
 *      - func1             : función que se ejecuta con el valor del parámetro textField1
 *      - textSize1         : indica los caracteres a mostrar del parámetro textField1, truncando su valor
 *      - textField2        : segundo campo a mostrar de la entidad de hibernate separado del primer campo por un "-"
 *      - func2             : función que se ejecuta con el valor del parámetro textField2
 *      - textSize2         : indica los caracteres a mostrar del parámetro textField2, truncando su valor
 *      - valueField:       : campo value del option
 *      - preSelected:      : precarga el option cuyo value coincida con el valor de preSelected
 *      - reload:           : un cambio en el valor de esta prop refrescará el combo
 *      - sinHTML:          : [true/false]: muestra el valor de cada option (text) con o sin html dentro del valor que se pinta al usuario. En su lugar, el valor completo se almacenará en el atributo data-textobd
 *      - data:             : valor a asignar al atributo textobd de cada opción del combo como un valor adicional no visible al usuario 
 *      - funcData:         : función que se ejecuta con el parámetro de entrada <data>
 * 
 * ATRIBUTO PERSONALIZADO EN CADA OPTION PARA ALMACENAR EL CÓDIGO HTML QUE LLEGUE COMO VALOR Y QUE NO SERÁ MOSTRADO COMO TEXT DEL COMBO:
 *      - data-textobd: por defecto se asigna el valor completo leído de BBDD de cada option, pero se puede asignar al valor que llegue en la prop 'data'
 */

function CargarListaDatos(props) {

    const [valores, setValores] = useState([]);
    let text1, text2, valorData = "";
   
    // Carga los datos cada vez que llegue un endpoint diferente o parámetros del endpoint diferentes o un reload de datos forzado
    useEffect(() => {

        async function cargarDatos() {
            try{
                if (utils.getLog()) console.log("Params de CargarListaDatos: "+JSON.stringify(props.paramsEndpoint));
                const response = await axios.get(props.endpoint, {params: props.paramsEndpoint, headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, props.perfil)})
                if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
                    if (utils.getLog()) console.log(response.status+": "+props.endpoint);
                    setValores(response.data);
                
                } else if (response && response.status === appConst.HTTPSTATUS_NO_CONTENT){
                    setValores([]);
                }
                
            }catch(error){
                if (utils.getLog()) console.log("Error GET_DATOS_COMBO ("+props.endpoint+"): "+error);
                utils.getTextError(error, props.intl, props.history, null, null, cargarDatos, [], props.perfil);
            }
        }
        cargarDatos();

    }, [props.endpoint, JSON.stringify(props.paramsEndpoint), props.reload])
    
    return(
        <React.Fragment>

            {valores.map( e => {
                
                /********* Valor data (atributo no visible) *********/
                valorData = (eval("e."+props.data)) ? eval("e."+props.data)  : "";
                if (props.funcData)
                    valorData = eval("props.funcData("+valorData+")");

                /********* Valor texto 1 *********/
                text1 = (eval("e."+props.textField1)) ? eval("e."+props.textField1)  : "";
                // Si llega func1 se ejecuta su valor como una función con parámetro text1
                if (props.func1 && text1)
                    text1 = eval("props.func1("+text1+")");
                
                // Si llega textSize1 se ejecuta un truncamiento a text1 
                if (props.textSize1 && text1 && text1.length > props.textSize1)
                    text1 = text1.substring(0, props.textSize1) + "...";
                
                /********* Valor texto 2 *********/
                text2 = (eval("e."+props.textField2)) ? eval("e."+props.textField2)  : "";

                // Si llega func2 se ejecuta su valor como una función con parámetro text2
                if (props.func2 && text2)
                    text2 = eval("props.func2("+text2+")");

                // Si llega textSize2 se ejecuta un truncamiento a text2
                if (props.textSize2 && text2 && text2.length > props.textSize2)
                    text2 = text2.substring(0, props.textSize2) + "...";

                /********* Se pinta el componente *********/
                return <option 
                    key={eval("e."+props.valueField)}
                    value={eval("e."+props.valueField)}
                    selected={ (props.preSelected === eval("e."+props.valueField)) ? true : false }
                    data-textobd={(props.data) ? valorData : text1 + ((text2)?" - ":"") + text2}
                >
                    {!props.sinHTML && text1 + ((text2)?" - ":"") + text2}
                    {props.sinHTML && utils.withoutHtml(text1 + ((text2)?" - ":"") + text2)}

                </option>
            })}
        </React.Fragment>
    )
}

export default injectIntl(withRouter(CargarListaDatos));