import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import * as appConst from './../common/const/Const';
import * as utils from './../common/utils/Funcs';
import { injectIntl, FormattedMessage as FM } from 'react-intl';

class FormLogin extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      campo: {},
      error: {}
    };
  }

  validarFormulario() {
    let campo = this.state.campo;
    let error = {};
    let formularioValido = true;
  
    // Usuario
    if (!campo['user']) {
        formularioValido = false;
        error["user"] = <FM id='app.general.requerido'/>;
    
    // Se valida si el formato del usuario es un email válido
    }else if (!utils.isValidEmail(campo["user"])){
      formularioValido = false;
      error["user"] = <FM id='app.general.format.email'/>;
    }

    // Contraseña
    if (!campo['pass']) {
      formularioValido = false;
      error["pass"] = <FM id='app.general.requerido'/>;
    }
  
    // Seteo el estado de error 
    if (!formularioValido){
      this.setState({ error });
      this.props._showMensaje(this.props.intl.formatMessage( {id:"app.general.form.revisarCampos"} ), appConst.ALERT_TIPO_WARNING, appConst.ALERT_OPEN);
    
    // Se limpian las validaciones por cada campo
    }else this.setState({ error: {} });
  
    return formularioValido;
  }

  // Se detecta cuando un campo del formulario es rellenado y por ende cambia de estado 
  detectarCambio(field, e) {
      
      let campo = this.state.campo;
      campo[field] = e.target.value;

      // Cambio de estado de campo 
      this.setState({ 
        campo,
        error: {}
      });
      //this.props._showMensaje("", "");
  }
  
  goHome(prefix, callback){

    if (utils.getLog()) console.log("Redirección a home ("+prefix+")");
    this.props.history.push({
      pathname: process.env.REACT_APP_WEB_BASE_URL+"/"+prefix+"/home",
      state: {
        callback
      }
    });
  }

  onSubmit = e => {
    e.preventDefault();

    if ( this.validarFormulario() ){
      this.doLogin();
    }
  }

  async doLogin(){

    const data = {
      "username": this.state.campo["user"],
      "password": this.state.campo["pass"]
    }

    try{
      const response = await axios.post(appConst.POST_LOGIN, data, {headers: utils.getHeaders(appConst.HEADER_LOGIN, null)})
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
        if (utils.getLog()) console.log("Status POST_LOGIN: "+response.status);
        
        // Se almacena en local el accessToken y el refreshToken
        utils.setJwtToken(response.data.token);
        utils.setJwtRefreshToken(response.data.token, response.data.refreshToken);

        // Se lee el perfil directamente del token recibido
        this.goHome(utils.getPrefixURL(utils.getTokenValue1(response.data.token, "tipo")), this.props.callback);
      }
    }catch(error){
      if (utils.getLog()) console.log("Error POST_LOGIN: "+error);
      this.props._showMensaje(utils.getTextError(error, this.props.intl, this.props.history, null, null, null, [], null), utils.getTipoAlert(error), appConst.ALERT_OPEN);
    }
  }

  render(){ 
    return(
      <form onSubmit={this.onSubmit}>
        
        <div className='row'>
          
          <div className='col-sm-12 pt-3'>
            <span className="label-form icon-user"> <FM id='app.login.label.username'/></span>
            <input type="text" className="input-css full-width" name="user" id="user" placeholder={this.props.intl.formatMessage({id: 'app.login.input.placeholder.email'})} onChange={this.detectarCambio.bind(this, "user")} value={this.state.campo["user"]} />
            <span className="form-error">{this.state.error["user"]}</span>
          </div>

          <div className='col-sm-12 pt-3'>
            <span className="label-form icon-key"> <FM id='app.login.label.contrasena'/></span>
            <input type="password" className="input-css full-width" name="pass" id="pass" placeholder={this.props.intl.formatMessage({id: 'app.login.input.placeholder.contrasena'})} onChange={this.detectarCambio.bind(this, "pass")} value={this.state.campo["pass"]} />
            <span className="form-error">{this.state.error["pass"]}</span>
          </div>

          <div className='col-sm-12 pt-2'>
            <button type="submmit" className="full-width btn btn-md btn-primary mt-4"><span className="label-button"><FM id='app.login.button.acceder'/></span></button>
          </div>

        </div>
      </form>
    )
  }
}

/*
* Se envuelve a la clase con:
*    injectIntl: para poder pasar la prop this.props.intl en traducciones para javascript puro (no JSX)
*    withRouter: para poder pasar la prop this.props.history para redireccionar con push("/miUrl")
*/
export default injectIntl(withRouter(FormLogin));