import React, { Component } from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import * as appConst from './components/common/const/Const';
import PrivateRoute from './components/common/utils/PrivateRoute';

// Estilos
import './assets/css/layout.css';
import 'bootstrap/dist/css/bootstrap.min.css';//5.1
import './assets/css/finder-icons.css';
import './assets/css/finder-icons-embedded.css';

// Componentes
import Login from './components/login/Login';
import ChangePass from './components/pass/ChangePass';
import NuevaPass from './components/pass/NuevaPass';
import AceptacionMatch from './components/common/web/AceptacionMatch';
import ValidacionPostLogin from './components/common/utils/ValidacionPostLogin';
import ConfirmacionCuenta from './components/common/web/ConfirmacionCuenta';

// Componentes de ADMIN
import Principal from './components/admin/principal/Principal';
import RegistroPadre from './components/admin/registro/Registro';

// Componentes de PADRES
import MiMundoPadre from './components/padre/miMundo/MiMundoPadre';
import ListaActividadesPadre from './components/padre/miMundo/ListaActividades';
import MisMatchesPadre from './components/padre/misMatches/MisMatchesPadre';
import DetalleMatch from './components/padre/misMatches/DetalleMatch';
import MiPerfilPadre from './components/padre/perfil/MiPerfil';
//import ManualUsuarioPadre from './components/padre/help/ManualUsuario';
import InvitacionUsuario from './components/padre/invitacion/InvitacionUsuario';
import MisAreasInteres from './components/padre/misAreasInteres/MisAreasInteres';
import MisEtiquetas from './components/padre/misEtiquetas/MisEtiquetas';
import MisTextos from './components/padre/misTextos/MisTextos';
import MisEnvios from './components/padre/misEnvios/MisEnvios';

// Componentes de HIJOS
import Registro from './components/hijo/registro/Registro';
import MiPerfilHijo from './components/hijo/perfil/MiPerfil';
import ManualUsuarioHijo from './components/hijo/help/ManualUsuario';
import MiMundoHijo from './components/hijo/miMundo/MiMundoHijo';
import ListaActividadesHijo from './components/hijo/miActividad/ListaActividades';
import DetalleActividad from './components/hijo/miActividad/DetalleActividad';
import Patrocinadores from './components/hijo/patrocinadores/Patrocinadores';

// Componentes de error
import ErrorPage from './components/common/error/ErrorPage';

class Layout extends Component {
  
  render(){ 
    return (
      <div className='contenedorGlobal'>
        <Router>
          <Switch>
            
            {/* LOGIN */}
            {/* Si hay un único token local (padre o hijo) se accede sin identificación, en caso contrario (no existe ningún token local o existen los dos (padre e hijo), va al login */}
            <Route exact path={process.env.REACT_APP_WEB_BASE_URL+"/"} component={Login}></Route>
            {/* Va siempre al login, sí o sí, con independencia de si existen token(s) local(es) */}
            <Route exact path={process.env.REACT_APP_WEB_BASE_URL+"/login"} component={Login}></Route>

            {/* ADMIN, PADRES E HJIOS */}
            <Route exact path={process.env.REACT_APP_WEB_BASE_URL+"/pass"} component={ChangePass}></Route>
            <Route exact path={process.env.REACT_APP_WEB_BASE_URL+"/newPassword"} component={NuevaPass}></Route>

            {/* PADRES E HJIOS */}
            <Route exact path={process.env.REACT_APP_WEB_BASE_URL+"/confirmacion-cuenta"} component={ConfirmacionCuenta}></Route>
            <Route exact path={process.env.REACT_APP_WEB_BASE_URL+"/aceptacion-match"} component={AceptacionMatch}></Route>
            
            {/* ADMIN */}
            <PrivateRoute acceso={appConst.PERFIL_ADMIN} lang={this.props.intl} exact path={process.env.REACT_APP_WEB_BASE_URL+"/a/home"} component={Principal}></PrivateRoute>
            <PrivateRoute acceso={appConst.PERFIL_ADMIN} lang={this.props.intl} exact path={process.env.REACT_APP_WEB_BASE_URL+"/a/registroPadre"} component={RegistroPadre}></PrivateRoute>
            
            {/* PADRE */}
            <PrivateRoute acceso={appConst.PERFIL_PADRE} lang={this.props.intl} exact path={process.env.REACT_APP_WEB_BASE_URL+"/p/home"} component={ValidacionPostLogin}></PrivateRoute>
            <PrivateRoute acceso={appConst.PERFIL_PADRE} lang={this.props.intl} exact path={process.env.REACT_APP_WEB_BASE_URL+"/p/mi-mundo"} component={MiMundoPadre}></PrivateRoute>
            <PrivateRoute acceso={appConst.PERFIL_PADRE} lang={this.props.intl} exact path={process.env.REACT_APP_WEB_BASE_URL+"/p/lista-actividades"} component={ListaActividadesPadre} ></PrivateRoute>
            <PrivateRoute acceso={appConst.PERFIL_PADRE} lang={this.props.intl} exact path={process.env.REACT_APP_WEB_BASE_URL+"/p/mis-matches"} component={MisMatchesPadre}></PrivateRoute>
            <PrivateRoute acceso={appConst.PERFIL_PADRE} lang={this.props.intl} exact path={process.env.REACT_APP_WEB_BASE_URL+"/p/mis-envios"} component={MisEnvios}></PrivateRoute>
            <PrivateRoute acceso={appConst.PERFIL_PADRE} lang={this.props.intl} exact path={process.env.REACT_APP_WEB_BASE_URL+"/p/nuevo-match"} component={DetalleMatch} operacion={appConst.NUEVO_MATCH}></PrivateRoute>
            <PrivateRoute acceso={appConst.PERFIL_PADRE} lang={this.props.intl} exact path={process.env.REACT_APP_WEB_BASE_URL+"/p/editar-match"} component={DetalleMatch} operacion={appConst.EDITAR_MATCH}></PrivateRoute>
            <PrivateRoute acceso={appConst.PERFIL_PADRE} lang={this.props.intl} exact path={process.env.REACT_APP_WEB_BASE_URL+"/p/ver-match"} component={DetalleMatch} operacion={appConst.VER_MATCH}></PrivateRoute>
            <PrivateRoute acceso={appConst.PERFIL_PADRE} lang={this.props.intl} exact path={process.env.REACT_APP_WEB_BASE_URL+"/p/mi-perfil"} component={MiPerfilPadre}></PrivateRoute>
            <PrivateRoute acceso={appConst.PERFIL_PADRE} lang={this.props.intl} exact path={process.env.REACT_APP_WEB_BASE_URL+"/p/mis-etiquetas"} component={MisEtiquetas}></PrivateRoute>
            <PrivateRoute acceso={appConst.PERFIL_PADRE} lang={this.props.intl} exact path={process.env.REACT_APP_WEB_BASE_URL+"/p/mis-areas-interes"} component={MisAreasInteres}></PrivateRoute>
            <PrivateRoute acceso={appConst.PERFIL_PADRE} lang={this.props.intl} exact path={process.env.REACT_APP_WEB_BASE_URL+"/p/mis-textos"} component={MisTextos}></PrivateRoute>
            <PrivateRoute acceso={appConst.PERFIL_PADRE} lang={this.props.intl} exact path={process.env.REACT_APP_WEB_BASE_URL+"/p/invitacion-usuario"} component={InvitacionUsuario}></PrivateRoute>
            
            {/* HIJO */}
            <Route exact path={process.env.REACT_APP_WEB_BASE_URL+"/h/registro"} component={Registro}></Route>
            <PrivateRoute acceso={appConst.PERFIL_HIJO} lang={this.props.intl} exact path={process.env.REACT_APP_WEB_BASE_URL+"/h/home"} component={ValidacionPostLogin}></PrivateRoute>
            <PrivateRoute acceso={appConst.PERFIL_HIJO} lang={this.props.intl} exact path={process.env.REACT_APP_WEB_BASE_URL+"/h/mi-perfil"} component={MiPerfilHijo}></PrivateRoute>
            <PrivateRoute acceso={appConst.PERFIL_HIJO} lang={this.props.intl} exact path={process.env.REACT_APP_WEB_BASE_URL+"/h/manual-usuario"} component={ManualUsuarioHijo}></PrivateRoute>
            <PrivateRoute acceso={appConst.PERFIL_HIJO} lang={this.props.intl} exact path={process.env.REACT_APP_WEB_BASE_URL+"/h/mi-mundo"} component={MiMundoHijo}></PrivateRoute>
            <PrivateRoute acceso={appConst.PERFIL_HIJO} lang={this.props.intl} exact path={process.env.REACT_APP_WEB_BASE_URL+"/h/lista-actividades"} component={ListaActividadesHijo}></PrivateRoute>
            <PrivateRoute acceso={appConst.PERFIL_HIJO} lang={this.props.intl} exact path={process.env.REACT_APP_WEB_BASE_URL+"/h/crearActividad"} component={DetalleActividad} operacion={appConst.OPERACION_NUEVO}></PrivateRoute>
            <PrivateRoute acceso={appConst.PERFIL_HIJO} lang={this.props.intl} exact path={process.env.REACT_APP_WEB_BASE_URL+"/h/editarActividad"} component={DetalleActividad} operacion={appConst.OPERACION_EDITAR}></PrivateRoute>
            <PrivateRoute acceso={appConst.PERFIL_HIJO} lang={this.props.intl} exact path={process.env.REACT_APP_WEB_BASE_URL+"/h/patrocinadores"} component={Patrocinadores}></PrivateRoute>
            
            {/* PATH NOT FOUND */}
            {/*<Route exact path="/solicitudes/:id/:otroId?" componet={Solicitudes}></Route>*/}
            <Route render={() => <ErrorPage textoError={this.props.intl.formatMessage({id:'app.errorpage.404'})} /> }></Route>
            
          </Switch>

        </Router>
      </div>
    )
  }
}

export default injectIntl(Layout);