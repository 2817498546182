import React, { Component } from 'react';
import axios from 'axios';
import {injectIntl, FormattedMessage as FM} from 'react-intl';
import Alert from './../../common/utils/Alert';
import * as utils from './../../common/utils/Funcs';
import * as appConst from './../../common/const/Const';
import Header from './../header/Header';
import Footer from './../footer/Footer';
import Etiqueta from './Etiqueta';
import NuevaEtiqueta from './NuevaEtiqueta';

class MisEtiquetas extends Component {

    constructor (props){

        super(props);
        this.state = {
            data: [],
            esNuevaEtiqueta: false,
            textoAlert: "",
            tipoAlert: "",
            closeAlert: appConst.ALERT_OPEN
        }
        this.crearEtiqueta = this.crearEtiqueta.bind(this);
        this.cargarEtiquetas = this.cargarEtiquetas.bind(this);
        this.cerrarComponenteNuevaEtiqueta = this.cerrarComponenteNuevaEtiqueta.bind(this);
        this.postGuardarNuevaEtiqueta = this.postGuardarNuevaEtiqueta.bind(this);
        this.postCancelarNuevaEtiqueta = this.postCancelarNuevaEtiqueta.bind(this);
    }

    componentDidMount(){

        // Carga todas las etiquetas del padre logeado
        this.cargarEtiquetas();
    }

    crearEtiqueta(){

        // Se oculta el botón 'Crear etiqueta'
        document.querySelector("#btnCrear").className = "hideElement";

        // Se muestra el componente NuevaEtiqueta
        this.setState({esNuevaEtiqueta: true})
    }

    postGuardarNuevaEtiqueta(){

        // Se muestra de nuevo el botón 'Crear etiqueta'
        document.querySelector("#btnCrear").className = "btn btn-sm btn-primary";
    }

    postCancelarNuevaEtiqueta(){

        // Se muestra de nuevo el botón 'Crear etiqueta'
        document.querySelector("#btnCrear").className = "btn btn-sm btn-primary";
    }

    async cargarEtiquetas(){

        try{
            const response = await axios.get(appConst.GET_ETIQUETAS, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE), params: {idPadre: utils.getTokenValue("idPadre", appConst.PERFIL_PADRE)}} )
            
            if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
                if (utils.getLog()) console.log("status GET_ETIQUETAS: "+response.status);

                this.setState({ 
                    data: response.data,
                    textoAlert: "",
                    tipoAlert: ""
                });

            }else if (response && response.status === appConst.HTTPSTATUS_NO_CONTENT){

                this.setState({ 
                    data: [],
                    textoAlert: <FM id="app.padre.etiquetas.listado.sinDatos" />,
                    tipoAlert: appConst.ALERT_TIPO_WARNING,
                    closeAlert: appConst.ALERT_OPEN
                });
            }

        }catch(error){
            if (utils.getLog()) console.log("Error GET_ETIQUETAS: "+error);
            this.setState({
                textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.cargarEtiquetas.bind(this), [], appConst.PERFIL_PADRE),
                tipoAlert: utils.getTipoAlert(error),
                closeAlert: appConst.ALERT_OPEN
            });
        }
    }

    lanzarMensaje (texto, tipo, close){
        this.setState({
            textoAlert: texto,
            tipoAlert: tipo,
            closeAlert: close
        });
    }

    recargarLista(){
        this.cargarEtiquetas();
    }

    cerrarComponenteNuevaEtiqueta(){
        this.setState({esNuevaEtiqueta: false})
    }

    render(){ 
        return (
            <div>
                <Header />

                <div className="contenedorHijo">

                    {/* MIGAS DE PAN */}
                    <ol className="migaPan">
                        <li className="enLineaFin icon-tags"> <FM id="app.padre.etiquetas.title" /></li>
                    </ol>
                    
                    <p className="prosa mx-5 mb-5"> <FM id="app.padre.etiquetas.subtitle" /></p>

                    {/* INI CONTENEDOR 75% */}
                    <div className="contenedor75">
                        
                        <Alert texto={this.state.textoAlert} tipo={this.state.tipoAlert} close={this.state.closeAlert}/>
                        
                        <div className="row mb-2">

                            <div className="col-sm-12 text-end" id="capaNuevo">
                            
                                <button className="btn btn-sm btn-primary" id="btnCrear" onClick={this.crearEtiqueta}><span className="label-button"><FM id='app.padre.etiquetas.botonera.nuevo'/></span></button>
                                
                                {/* COMPONENTE 'NUEVA ETIQUETA' INCRUSTADO */}
                                {this.state.esNuevaEtiqueta &&
                                    <NuevaEtiqueta 
                                        _cerrarComponente={this.cerrarComponenteNuevaEtiqueta}
                                        _postGuardar={this.postGuardarNuevaEtiqueta}
                                        _postCancelar={this.postCancelarNuevaEtiqueta}
                                        _recargarDatos={this.cargarEtiquetas}
                                        _lanzarMensaje={this.lanzarMensaje.bind(this)}
                                        focus={true}
                                />}
                                
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-sm-12">
                                { this.state.data.map( e => 
                                    <Etiqueta
                                        key={e.id}
                                        id={e.id}
                                        nombre={e.nombre}
                                        _lanzarMensaje={this.lanzarMensaje.bind(this)}
                                        _recargarLista={this.recargarLista.bind(this)}
                                    />
                                )}
                            </div>
                        </div>
                    
                    </div>
                    {/* FIN CONTENEDOR 50% */}

                </div>

                <Footer />
            </div>
        )
    }
}

export default injectIntl(MisEtiquetas);