import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage as FM} from 'react-intl';
import FooterPatrocinadores from './FooterPatrocinadores';
import * as appConst from './../../common/const/Const'
import * as utils from './../../common/utils/Funcs'

class Footer extends Component{

    constructor (props){
        super(props);
        this.goAyudaHijo = this.goAyudaHijo.bind(this);
    }

    goAyudaHijo(){

        this.props.history.push(appConst.LINK_RELATIVO_AYUDA_HIJO);
    }

    render(){
        return(
            <footer className="fixed-bottom">
                {(this.props.patrocinadores === appConst.MOSTRAR_PATROCINADORES) && <FooterPatrocinadores />}
                <div className='row pie'>
                    <div className='col-sm-8'>
                        <span className="ps-5 icon-copyright"><FM id='app.general.pie.copyright' /></span>
                        <span className="ps-4 hand" onClick={utils.goAvisoLegal}><FM id='app.general.pie.condiciones' /></span>
                        <span className="ps-4 hand" onClick={utils.goPrivacidad}><FM id='app.general.pie.privacidad' /></span>
                        <span className="ps-4 hand" onClick={this.goAyudaHijo}><FM id='app.general.pie.ayudaHijo' /></span>
                    </div>
                    <div className='col-sm-4 pe-5 text-end'>
                        <img src={appConst.URL_LOGO_FINDER_SOLO_IMG} className='imgEmpresaPie'/>
                    </div>
                </div>
            </footer>
        )
    }
}

export default injectIntl(withRouter(Footer));