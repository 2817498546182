import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Header from './../header/Header';
import Footer from './../footer/Footer';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import * as appConst from './../../common/const/Const'
import * as utils from './../../common/utils/Funcs';
import Alert from './../../common/utils/Alert';
import CargarMaestroDatos from './../../common/datos/CargarMaestroDatos';
import FichaEmpresa from './FichaEmpresa';

class MiMundoHijo extends Component {

  constructor(props){
    super(props);
    this.state = {
      datos: [],
      textoAlert: '',
      tipoAlert: '',
      closeAlert: appConst.ALERT_OPEN
    };
    this.listarMundo = this.listarMundo.bind(this);
  }

  componentDidMount(){
    
    // Inicialización de los tooltip de este componente
    utils.iniTooltip();

    // Carga las empresas del mundo actual
    this.listarMundo();
  }

  async listarMundo(){

    try{
      const response = await axios.get(appConst.GET_MUNDOS, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_HIJO), params: {
        idPadre: utils.getTokenValue("idPadre", appConst.PERFIL_HIJO),
        idNecesidad: document.querySelector("#cmbNecesidades").value,
        idSector: document.querySelector("#cmbSectores").value,
        razonSocial: document.querySelector("#txtRazonSocial").value.trim(),
        descripcion: document.querySelector("#txtDescripcion").value.trim()
      }} )
      
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){

        if (utils.getLog()) console.log("status GET_MUNDOS: "+response.status);
        this.setState({ 
          datos: response.data,
          textoAlert: "",
          tipoAlert: ""
        });
          
      }else if (response && response.status === appConst.HTTPSTATUS_NO_CONTENT){
        
        this.setState({ 
          datos: [],
          textoAlert: this.props.intl.formatMessage({id:"app.hijo.mi-mundo.listado.sin-datos"}),
          tipoAlert: appConst.ALERT_TIPO_WARNING,
          closeAlert: appConst.ALERT_CLOSE
        });
      }

    }catch(error){
        if (utils.getLog()) console.log("Error GET_MUNDOS: "+error);
        this.setState({
            textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.listarMundo, [], appConst.PERFIL_HIJO),
            tipoAlert: utils.getTipoAlert(error),
            closeAlert: appConst.ALERT_OPEN
        });
    }
  }

  showMensaje(texto, tipo, close){
    this.setState ({
      textoAlert: texto,
      tipoAlert: tipo,
      closeAlert: close
    });
  }
  
  render(){ 
    return (
      <div>
        <Header itemMenu={appConst.MENU_HIJO_MI_MUNDO}/>
        
        <div className="contenedorHijo">

          {/* MIGAS DE PAN */}
          <ol className="migaPan">
              <li className="enLineaFin icon-globe"> <FM id="app.hijo.mi-mundo.title" /> <span className="prosa">{"(empresas del mundo "+utils.getTokenValue("nombrePadre", appConst.PERFIL_HIJO)+")"}</span></li>
          </ol>
          
          {/* INI CONTENEDOR */}
          <div className="contenedor">
            
            <Alert texto={this.state.textoAlert} tipo={this.state.tipoAlert} close={this.state.closeAlert}/>
            
            {/* INI FILTROS */}
            <div>

              {/* CABECERA FILTROS (Totales, Botón criterios) */}
              <div className="row mb-2">
                <div className="col-sm-6">
                  <span><FM id="app.hijo.mi-mundo.totales" /> <strong id="contador">{this.state.datos.length}</strong> <FM id="app.hijo.mi-mundo.totales-empresas" /></span>
                </div>
                <div className="col-sm-6 text-end">
                  <a href="#criterios" className="icon-arrow-combo link" data-bs-toggle="collapse" aria-expanded="false" aria-controls="criterios">
                    <span data-bs-toggle="tooltip" title={this.props.intl.formatMessage( {id:"app.hijo.mi-mundo.buscar.criterios.tooltip"} )}><FM id="app.hijo.mi-mundo.buscar.criterios" /></span>
                  </a>
                </div>
              </div>

              {/* CUERPO FILTROS (Citerios de búsqueda) */}
              <div className="row cajaBlanca collapse" id="criterios">
                <div className="col-sm-10">
                  <div className="row pb-3">
                    <div className="col-sm-12">

                      <div className="block-field">
                        <div>
                          <div><span className="label-form" title={this.props.intl.formatMessage( {id: 'app.hijo.mi-mundo.criterio.necesidades'} )}><FM id='app.hijo.mi-mundo.criterio.necesidades'/></span></div>
                          <select id="cmbNecesidades" name="cmbNecesidades" className="select-css selectSizeCriteria">
                            <option value="">{this.props.intl.formatMessage( {id: 'app.general.combos.todas'} )}</option>
                            <CargarMaestroDatos
                                tabla={appConst.MAESTRO_NECESIDADES}
                                preSelected={""}
                                perfil={appConst.PERFIL_HIJO}
                            />
                          </select>
                        </div>
                      </div>
                    
                      <div className="block-field">
                        <div>
                          <div><span className="label-form" title={this.props.intl.formatMessage( {id: 'app.hijo.mi-mundo.criterio.sector'} )}><FM id='app.hijo.mi-mundo.criterio.sector'/></span></div>
                          <select id="cmbSectores" name="cmbSectores" className="select-css selectSizeCriteria">
                            <option value="">{this.props.intl.formatMessage( {id: 'app.general.combos.todos'} )}</option>
                            <CargarMaestroDatos
                                tabla={appConst.MAESTRO_SECTORES}
                                preSelected={""}
                                perfil={appConst.PERFIL_HIJO}
                            />
                          </select>
                        </div>
                      </div>

                      {/*<div className="block-field">
                        <div>
                          <div><span className="label-form" title={this.props.intl.formatMessage( {id: 'app.hijo.mi-mundo.criterio.estadosMatches'} )}><FM id='app.hijo.mi-mundo.criterio.estadosMatches'/></span></div>
                          <select id="cmbEstadosMatches" name="cmbEstadosMatches" className="select-css selectSizeCriteria">
                            <option value="">{this.props.intl.formatMessage( {id: 'app.general.combos.todos'} )}</option>
                            <CargarMaestroDatos
                                tabla={appConst.MAESTRO_ESTADOS_TRANSACCION}
                                preSelected={""}
                                perfil={appConst.PERFIL_HIJO}
                            />
                          </select>
                        </div>
                      </div>*/}
                      
                      <div className="block-field">
                        <div>
                          <div><span className="label-form" title={this.props.intl.formatMessage( {id: 'app.hijo.mi-mundo.criterio.razonSocial'} )}><FM id='app.hijo.mi-mundo.criterio.razonSocial'/></span></div>
                          <input className="input-css" id="txtRazonSocial" name="txtRazonSocial" style={{width: "150px"}} placeholder={this.props.intl.formatMessage( {id: 'app.hijo.mi-mundo.criterio.razonSocial'} ).toLowerCase()}/>
                        </div>
                      </div>

                      <div className="block-field">
                        <div>
                          <div><span className="label-form" title={this.props.intl.formatMessage( {id: 'app.hijo.mi-mundo.criterio.descripcion'} )}><FM id='app.hijo.mi-mundo.criterio.descripcion'/></span></div>
                          <input className="input-css" id="txtDescripcion" name="txtDescripcion" style={{width: "150px"}} placeholder="texto libre"/>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="col-sm-2 pt-3 text-center">
                  <button className="btn btn-sm btn-primary icon-search" onClick={this.listarMundo}><FM id="app.hijo.mi-mundo.botonera.buscar" /></button>
                </div>
              </div>
            </div>
            {/* FIN FILTROS */}
                                
            {/* DETALLE */}
            <div className="mx-0 mb-2">
              
              <div className="row pb-4">
                <div className="col-sm-12">
                  
                  {this.state.datos.map(m => (utils.getTokenValue("idHijo", appConst.PERFIL_HIJO) === m.hijo.id) &&
                      <FichaEmpresa 
                        key={m.id}
                        id={m.id}
                        empresaLogeada={true}
                        idHijo={m.hijo.id}
                        idEmpresa={m.hijo.empresa.id}
                        razonSocial={m.hijo.empresa.razonSocial}
                        nombre={m.hijo.empresa.nombre}
                        descripcion={m.hijo.empresa.descripcion}
                        logo={m.hijo.empresa.logo}
                        email={m.hijo.empresa.email}
                        telefono={m.hijo.empresa.telefono}
                        direccion={m.hijo.empresa.direccion}
                        cp={m.hijo.empresa.cp}
                        localidad={m.hijo.empresa.localidad}
                        provincia={m.hijo.empresa.provincia}
                        urlWeb={m.hijo.empresa.urlweb}
                        urlVideo={m.hijo.empresa.urlvideo}
                        urlNoticia={m.hijo.empresa.urlnoticia}
                        sector={(m.hijo.empresa.sector) ? m.hijo.empresa.sector.nombre : <i><FM id="app.hijo.mi-mundo.sin-sector" /></i>}
                        areasInteres={m.areasInteresHijo}
                        necesidades={m.hijo.necesidadesHijo}
                        _showMensaje={this.showMensaje.bind(this)}
                        _listarMundo={this.listarMundo.bind(this)}
                      />
                    )}

                    {/* OJO PQ NO ES CORRECTO SI HAY BUSQUEDAS <h4 className='p-1 m-0'>{(this.state.datos.length > 1 ? this.state.datos.length - 1 : "0") + " empresa(s) restante(s)"}</h4><hr className='pt-0 mt-0'/>*/}

                    {this.state.datos.map(m => (utils.getTokenValue("idHijo", appConst.PERFIL_HIJO) != m.hijo.id) &&
                        <FichaEmpresa 
                          key={m.id}
                          id={m.id}
                          empresaLogeada={false}
                          idHijo={m.hijo.id}
                          idEmpresa={m.hijo.empresa.id}
                          razonSocial={m.hijo.empresa.razonSocial}
                          nombre={m.hijo.empresa.nombre}
                          descripcion={m.hijo.empresa.descripcion}
                          logo={m.hijo.empresa.logo}
                          email={m.hijo.empresa.email}
                          telefono={m.hijo.empresa.telefono}
                          direccion={m.hijo.empresa.direccion}
                          cp={m.hijo.empresa.cp}
                          localidad={m.hijo.empresa.localidad}
                          provincia={m.hijo.empresa.provincia}
                          urlWeb={m.hijo.empresa.urlweb}
                          urlVideo={m.hijo.empresa.urlvideo}
                          urlNoticia={m.hijo.empresa.urlnoticia}
                          sector={(m.hijo.empresa.sector) ? m.hijo.empresa.sector.nombre : <i><FM id="app.hijo.mi-mundo.sin-sector" /></i>}
                          areasInteres={m.areasInteresHijo}
                          necesidades={m.hijo.necesidadesHijo}
                          _showMensaje={this.showMensaje.bind(this)}
                          _listarMundo={this.listarMundo.bind(this)}
                        />
                    )}

                </div>
              </div>
            </div>
            {/* FIN DETALLE */}

          </div>
          {/* FIN CONTENEDOR */}

        </div>

        <Footer patrocinadores={appConst.MOSTRAR_PATROCINADORES} />
      </div>
    )
  }
}

export default injectIntl(withRouter(MiMundoHijo));