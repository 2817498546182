//import 'babel-polyfill';
import React, { Component } from 'react';
import {
  ContentState,
  Editor,
  EditorState,
  convertFromHTML,
  convertToRaw,
  RichUtils
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { stateToHTML } from 'draft-js-export-html';
import * as utils from './../../common/utils/Funcs';
import convertFromHTMLToContentBlocks from 'draft-js/lib/convertFromHTMLToContentBlocks';
import { onAddLink } from "./Link";

class EditorHTML extends Component {

  /* PARAMETROS DE ENTRADA
   * =====================
   *
   *  id            String - id del DOM
   *  value         String - valor actual del editor
   *  init          boolean - precarga inicial con value
   *  placeholder   String - cadena para placeholder
   *  readonly      boolean - lectura / escritura
   *  toolbar       boolean - mostrar / ocultar botonera
   *  height        number - altura del editor
   *  _onChange     function - función onchange del componente origen. Params: id del componente DOM, contenido del editor
   *  _onBlur       function - función onBlur del componente origen. Params: id del componente DOM, contenido del editor
  */
 
  constructor(props) {

    super(props);
    this.state = {
      editorState: EditorState.createEmpty()
    };
    this.handleBlur = this.handleBlur.bind(this);
    this.miEditor = React.createRef();
    this.onChange = editorState => {
      
      // Se actualiza el estado del editor. Con esto se visualiza y pinta en tiempo real cada caracter escrito en el propio editor
      this.setState({ editorState });

      // Se actualiza el estado de la variable que almacena el texto en html en el componente que llamó al editor.
      // Para ello, antes se extrae el texto plano del HTML porque podría estar vacío pero con valor ("<p><br></p>")
      this.cambiarEstadoComponenteOrigen(editorState);
    }

    /** BOTONERA DEL EDITOR **/
    // Formatos
    this._onButtonClick = style => {
      this.miEditor.current.focus();
      this.setState({editorState: RichUtils.toggleInlineStyle(this.state.editorState, style)});
      utils.hideTooltip("btn1");
      utils.hideTooltip("btn2");
      utils.hideTooltip("btn3");
    }
    // Añadir enlace
    this._onButtonAddLink = (editor, actualizarEditor, textoSeleccionado) => {
      // Se vuelve a poner el foco dentro del editor para asegurar la ejecución del onBlur, en caso necesario
      this.miEditor.current.focus();
      onAddLink(editor, actualizarEditor, textoSeleccionado);
      utils.hideTooltip("btn4");
    }
    //console.log(convertToRaw(this.state.editorState.getCurrentContent()));
  }

  // onChange
  cambiarEstadoComponenteOrigen(editorState){

    if (this.props._onChange){
      const contenidoPlano = editorState.getCurrentContent().getPlainText();
      this.props._onChange(this.props.id, (contenidoPlano !== "") ? stateToHTML(editorState.getCurrentContent()) : "");
    }
  }

  // onBlur
  handleBlur(){
    // Para los casos en los que renderizar la pantalla completa, donde se encuentra el componente editor, tenga una falta de rendimiento con el _onChange
    // Se actualiza el estado de la pantalla origen con el valor del editor solo cuando se haga onBlur
    // Es como ejecutar la función cambiarEstadoComponenteOrigen pero con onBlur en lugar de onChange
    if (this.props._onBlur){
      const _editorState = this.state.editorState;
      console.log("onBlur: " + stateToHTML(_editorState.getCurrentContent()));
      const contenidoPlano = _editorState.getCurrentContent().getPlainText();
      this.props._onBlur(this.props.id, (contenidoPlano !== "") ? stateToHTML(_editorState.getCurrentContent()) : "");
    }
  }

  componentDidMount(){

    // Inicialización de los tooltip de este componente
    utils.iniTooltip();
  }

  componentWillReceiveProps(nextProps){
    
    // Cambiar el estado de init en el componente que usa el editor: init = true (precarga editor con value) | false (ignora precarga)
    // Nota: en la función detectarCambio del componente que usa el editor se suele poner a false el estado de init
    if (nextProps.init)
      this.cargarDatosEditor(nextProps.value);
  }

  cargarDatosEditor(value){

    // Precarga HTML en el editor
    if (value){

      const blocksFromHTML = convertFromHTML(value);
      const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
      this.setState({ editorState: EditorState.createWithContent(state) });
    
    }else this.setState({ editorState: EditorState.createEmpty() });
  }

  /*myBlockStyleFn(contentBlock) {

    // No funciona
    const type = contentBlock.getType();
    if (type === 'blockquote')
      return 'superFancyBlockquote';
    if (type === 'p')
      return 'red';
  }*/

  _updateEditorState(newEditorState){
    
    this.setState({
      editorState: newEditorState
    });

    // Actualiza el estado del componente origen para los casos en que éste ejecute el onChange
    this.cambiarEstadoComponenteOrigen(this.state.editorState);
    
    // Actualiza el estado del componente origen para los casos en que éste ejecute el onBlur
    this.handleBlur(this.state.editorState);
  }

  _getCurrentTextSelection = () => {
    const selectionState = this.state.editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    const selectedText = currentContentBlock.getText().slice(start, end);
    return selectedText;
  };

  render() {
    return (
      <div className='pt-2'>

        {/* BOTONERA */}
        {this.props.toolbar && <div>
          <button id="btn1" className='icon-bold icono-extra-peke btn btn-sm btn-primary me-2' data-bs-toggle="tooltip" title="Negrita" onClick={this._onButtonClick.bind(this, 'BOLD')} />
          <button id="btn2" className='icon-italic icono-extra-peke btn btn-sm btn-primary me-2' data-bs-toggle="tooltip" title="Cursiva" onClick={this._onButtonClick.bind(this, 'ITALIC')} />
          <button id="btn3" className='icon-underline icono-extra-peke btn btn-sm btn-primary me-2' data-bs-toggle="tooltip" title="Subrayado" onClick={this._onButtonClick.bind(this, 'UNDERLINE')} />
          <button id="btn4" className='icon-link icono-extra-peke btn btn-sm btn-primary me-2' data-bs-toggle="tooltip" title="Añadir enlace" onClick={this._onButtonAddLink.bind(this, this.state.editorState, this._updateEditorState.bind(this), this._getCurrentTextSelection.bind(this))} />

        </div>}
        
        {/* EDITOR */}
        <div className='textArea-css pb-3' style={{minHeight: (this.props.minHeight) ? this.props.minHeight : 100}}>
          <Editor
            editorState={this.state.editorState}
            onChange={this.onChange}
            readOnly={this.props.readonly}
            ref={this.miEditor}
            textAlignment="left"
            placeholder={this.props.placeholder}
            //blockStyleFn={this.myBlockStyleFn}
            onBlur={this.handleBlur}
          />
        </div>

      </div>
    );
  }
}
  
export default EditorHTML;