import React, { Component } from 'react';
import axios from 'axios';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Alert from './../../common/utils/Alert';
import * as appConst from './../../common/const/Const';
import * as utils from './../../common/utils/Funcs';
import Header from './../header/Header';
import Footer from './../footer/Footer';
import FichaEnvio from './FichaEnvio';

class MisEnvios extends Component {

  constructor(props){
    super(props);
    this.state = {
      datos: [],
      textoAlert: '',
      tipoAlert: '',
      closeAlert: appConst.ALERT_OPEN
    };
  }

  componentDidMount(){
    
    // Inicialización de los tooltip de este componente
    utils.iniTooltip();

    // Carga los envíos del padre
    this.listarEnvios();
  }

  async listarEnvios(){

    try{
      const response = await axios.get(appConst.GET_TRANSACCIONES, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE), params: {
        listaTiposTransaccion: appConst.TIPO_TRAN_FORMACION +","+ appConst.TIPO_TRAN_WEBINAR +","+ appConst.TIPO_TRAN_REUNION +","+ appConst.TIPO_TRAN_NOTICIA +","+ appConst.TIPO_TRAN_EL_DIA_DE +","+ appConst.TIPO_TRAN_MESA_REDONDA,
        idEmpresa: utils.getTokenValue("idEmpresa", appConst.PERFIL_PADRE)
      }} );
      
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
          if (utils.getLog()) console.log("status GET_TRANSACCIONES: "+response.status);

        this.setState({ datos: response.data });

      }else if (response && response.status === appConst.HTTPSTATUS_NO_CONTENT){

        this.setState({ 
            textoAlert: <FM id="app.padre.mis-envios.sin-contenido"/>,
            tipoAlert: appConst.ALERT_TIPO_WARNING,
            closeAlert: appConst.ALERT_OPEN
         });
      }

    }catch(error){
        if (utils.getLog()) console.log("Error GET_TRANSACCIONES: "+error);
        this.setState({
            textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.listarEnvios, [], appConst.PERFIL_PADRE),
            tipoAlert: utils.getTipoAlert(error),
            closeAlert: appConst.ALERT_OPEN
        });
    }
  }

  render(){ 
    return (
      <div>
        <Header itemMenu={appConst.MENU_PADRE_MIS_ENVIOS}/>
        
        <div className="contenedorPadre">

          {/* MIGAS DE PAN */}
          <ol className="migaPan">
              <li className="enLineaFin icon-send"> <FM id="app.padre.mis-envios.title" /></li>
          </ol>
          
          {/* INI CONTENEDOR */}
          <div className="contenedor">

            <Alert texto={this.state.textoAlert} tipo={this.state.tipoAlert} close={this.state.closeAlert} />

            <div className="row">
              <div className="col-sm-12">
                { this.state.datos.map(tran => 
                  <FichaEnvio
                    key={tran.id}
                    idEnvio={tran.id}
                    tipo={tran.tipoTransaccion.nombre}
                    nombre={tran.nombre}
                    asunto={tran.asunto}
                    cuerpo={tran.cuerpo}
                    fecha={tran.fecha}
                    participantes={tran.participantes}
                  />
                )}
              </div>
            </div>
          
          </div>
          {/* FIN CONTENEDOR */}

        </div>
        <Footer />
      </div>
    )
  }
}

export default injectIntl(withRouter(MisEnvios));