import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import Alert from './../../common/utils/Alert';
import * as utils from './../../common/utils/Funcs';
import * as appConst from './../../common/const/Const'
import CargarMaestroDatos from './../../common/datos/CargarMaestroDatos';

class FormRegistro extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      campo: {},
      error: {},
      idPadre: '',
      nombrePadre: '',
      textoAlert: '',
      tipoAlert: '',
      closeAlert: appConst.ALERT_OPEN
    }
  }

  componentDidMount(){
    
    // Inicialización de los tooltip de este componente
    utils.iniTooltip();

    // Se extrae y comprueba de la URL el valor del id del Padre del usuario que se registra
    this.comprobarURL();
  }

  comprobarURL(){
    
    // Se compueba que exista el idPadre como parámetro de la URL y que su longitud sea de 36
    if (this.props.location.search.split("?")[1] && this.props.location.search.split("?")[1].length === 36)
      this.checkIdPadre(this.props.location.search.split("?")[1]);
    else {
      this.setState({
        textoAlert: <FM id='app.hijo.registro.faltaParametroIdPadre'/>,
        tipoAlert: appConst.ALERT_TIPO_WARNING,
        closeAlert: appConst.ALERT_OPEN
      });
    }
  }

  async checkIdPadre(idPadre){
    try{
      const response = await axios.get(appConst.GET_CHECK_PADRE.replace("#idPadre", idPadre), {headers: utils.getHeaders(appConst.HEADER_SIN_TOKEN, appConst.PERFIL_HIJO)})
      if (response && response.status === appConst.HTTPSTATUS_OK){
          if (utils.getLog()) console.log("Status GET_CHECK_PADRE: "+response.status+". Id del padre existe");
          // Solo se puede proseguir con el registro, y por tanto mostrar el form, si se encuentra en la URL el valor del idPadre del usuario que se registra
          document.querySelector("#formRegistro").className = "";
          this.setState({
            idPadre,
            nombrePadre: response.data.empresa.nombre
          });
          // Pinta el logo del padre en la cabecera de Guess
          this.props._setLogoPadreHeader(response.data.empresa.logo);
          return idPadre;
      }
    }catch(error){
      if (utils.getLog()) console.log("Error GET_CHECK_PADRE: "+error);
      this.setState({
        textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, null, [], appConst.PERFIL_HIJO),
        tipoAlert: utils.getTipoAlert(error),
        closeAlert: appConst.ALERT_OPEN
      });
    }
  }

  validarFormulario() {
    let campo = this.state.campo;
    let error = {};
    let formularioValido = true;
  
    // Nombre
    if (!campo['txtNombre']) {
        formularioValido = false;
        error["txtNombre"] = <FM id='app.general.requerido'/>;
    }

    // Cargo
    if (!campo['cmbCargo']) {
      formularioValido = false;
      error["cmbCargo"] = <FM id='app.general.requerido'/>;
    }

    // Nombre empresa
    if (!campo['txtNombreEmpresa']) {
      formularioValido = false;
      error["txtNombreEmpresa"] = <FM id='app.general.requerido'/>;
    }

    // Email
    if (!campo['txtEmail']) {
      formularioValido = false;
      error["txtEmail"] = <FM id='app.general.requerido'/>;
    }

    // Se valida si el formato del email es válido
    if (typeof campo["txtEmail"] !== "undefined" && !utils.isValidEmail(campo["txtEmail"])) {
      formularioValido = false;
      error["txtEmail"] = <FM id='app.general.format.email'/>;
    }

    // Contraseña
    if (!campo['txtPass']) {
      formularioValido = false;
      error["txtPass"] = <FM id='app.general.requerido'/>;

    }else if (campo['txtPass'].length < appConst.PASSWORD_SIZE) {
      // Contraseña válida
      formularioValido = false;
      error["txtPass"] = <FM id='app.hijo.registro.val.format.contrasena' values={{ valor: appConst.PASSWORD_SIZE }}/>;
    }

    // Contraseña2
    if (!campo['txtPass2']) {
      formularioValido = false;
      error["txtPass2"] = <FM id='app.general.requerido'/>;

    // Contraseñas no iguales
    }else if (campo['txtPass'] !== campo['txtPass2']) {
      formularioValido = false;
      error["txtPass2"] = <FM id='app.hijo.registro.val.contrasenas_desiguales'/>;
    }

    if (!campo['chkCondiciones']){
      formularioValido = false;
      error["chkCondiciones"] = <FM id='app.hijo.registro.val.condiciones'/>;
    }
  
    // Seteo el estado de error 
    if (!formularioValido)
      this.setState({
          error,
          textoAlert: <FM id="app.general.form.revisarCampos" />,
          tipoAlert: appConst.ALERT_TIPO_WARNING,
          closeAlert: appConst.ALERT_OPEN
      });
    
    // Se limpian las validaciones por cada campo
    else this.setState({ error: {} });
  
    return formularioValido;
  }

  // Se detecta cuando un campo del formulario es rellenado y por ende cambia de estado 
  detectarCambio(field, e) {
      
      let campo = this.state.campo;
      if (e.target.type === 'checkbox'){
        campo[field] = e.target.checked;
      }else{
        campo[field] = e.target.value;
      }

      // Cambio de estado de campo 
      this.setState({
        campo,
        textoAlert: '',
        tipoAlert: ''
      });
  }
  
  onSubmit = e => {
    e.preventDefault();
    
    if ( this.validarFormulario() ){
      this.guardarUsuarioMundo();
    }
  }

  async guardarUsuarioMundo(){
      
    // Este pojo no está mapeado a ninguna entidad de datos de la API porque solo sirve para aglutinar los datos para las entidades:
    // - Usuario
    // - Empresa
    // - Hijo
    // - Mundo
    const data = {
      "nombre": this.state.campo["txtNombre"],
      "apellidos": this.state.campo["txtApellidos"],
      "email": this.state.campo["txtEmail"],
      "password": this.state.campo["txtPass"],
      "tipo": appConst.PERFIL_HIJO,
      "idCargo": this.state.campo["cmbCargo"],
      "consentTerms": utils.getBooleanNumber(this.state.campo["chkCondiciones"]),
      "consentPubli": utils.getBooleanNumber(this.state.campo["chkPublicidad"]),
      "nombreEmpresa": this.state.campo["txtNombreEmpresa"],
      "idPadre": this.state.idPadre
    }

    try{
      // Llamada al endpoint sin token (porque es el registro de usuario)
      const response = await axios.post(appConst.POST_USUARIO_MUNDO, data, {headers: utils.getHeaders(appConst.HEADER_SIN_TOKEN, appConst.PERFIL_HIJO)})
      if (response && response.data && response.status === appConst.HTTPSTATUS_CREATED){
          if (utils.getLog()) console.log("Status POST_USUARIO_MUNDO: "+response.status+". UsuarioMundo creado con éxito");
          
          this.setState({
            textoAlert: <FM id="app.hijo.registro.usuarioCreado" />,
            tipoAlert: appConst.ALERT_TIPO_SUCCESS
          });
      }
    }catch(error){
      if (utils.getLog()) console.log("Error POST_USUARIO_MUNDO: "+error);
      this.setState({
        textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, null, [], appConst.PERFIL_HIJO),
        tipoAlert: utils.getTipoAlert(error)
      });
    }
  }

  render(){ 
    return(
      <div className='contenedorHijo'>

        {/* NO HAY MIGAS DE PAN */}
        {/*<ol className="migaPan">
          // Do nothing
        </ol>*/}
        
        {/* INI CONTENEDOR */}
        <div className="contenedor">

          {/* INI FORM USUARIO */}
          <form onSubmit={this.onSubmit} id="formRegistro" className="hideElement">
          
            <div className="mx-auto" style={{width: "60%"}}>

              <Alert texto={this.state.textoAlert} tipo={this.state.tipoAlert} close={this.state.closeAlert}/>

              {/* MIGA DE PAN */}
              <li className="migaPan icon-user pb-2"> <FM id="app.hijo.registro.title" values={{nombrePadre: <strong>{this.state.nombrePadre}</strong>}}/></li>
              
              <div className="row cajaBlanca">

                <div className="row pt-2 ps-4">
                  <div className="col-md-4">
                    
                    <div><span className="label-form"><FM id='app.hijo.registro.label.nombre'/></span></div>
                    <input type="text" className="input-css full-width" name="txtNombre" id="txtNombre" maxLength="50" onChange={this.detectarCambio.bind(this, "txtNombre")} value={this.state.campo["txtNombre"]} />
                    <span className="form-error">{this.state.error["txtNombre"]}</span>

                  </div>
                  <div className="col-md-4">
                    
                    <div><span className="label-form"><FM id='app.hijo.registro.label.apellidos'/></span></div>
                    <input type="text" className="input-css full-width" name="txtApellidos" id="txtApellidos" maxLength="100" onChange={this.detectarCambio.bind(this, "txtApellidos")} value={this.state.campo["txtApellidos"]} />
                    <span className="form-error">{this.state.error["txtApellidos"]}</span>

                  </div>
                  <div className="col-md-4">
                    
                    <div><span className="label-form"><FM id='app.hijo.mi-perfil.form.label.cargo'/></span></div>
                    <select id="cmbCargo" name="cmbCargo" className="select-css full-width" onChange={this.detectarCambio.bind(this, "cmbCargo")}>
                      <option value="">{this.props.intl.formatMessage( {id: 'app.general.combos.selecciono'} )}</option>
                      <CargarMaestroDatos
                          tabla={appConst.MAESTRO_CARGOS}
                          preSelected={this.state.campo['cmbCargo']}
                          perfil={appConst.PERFIL_HIJO}
                          tipoToken={appConst.HEADER_SIN_TOKEN}
                      />
                    </select>
                    <span className="form-error">{this.state.error["cmbCargo"]}</span>

                  </div>
                </div>

                <div className="row pt-2 ps-4">
                  <div className="col-md-4">
                    
                    <div><span className="label-form"><FM id='app.hijo.registro.label.email'/></span></div>
                    <input type="text" className="input-css full-width" name="txtEmail" id="txtEmail" maxLength="100" onChange={this.detectarCambio.bind(this, "txtEmail")} value={this.state.campo["txtEmail"]} />
                    <span className="form-error">{this.state.error["txtEmail"]}</span><br />                    

                  </div>
                  <div className="col-md-4">
                    
                    <div><span className="label-form"><FM id='app.hijo.registro.label.contrasena'/></span></div>
                    <input type="password" className="input-css full-width" name="txtPass" id="txtPass" maxLength="100" onChange={this.detectarCambio.bind(this, "txtPass")} value={this.state.campo["txtPass"]} />
                    <span className="form-error">{this.state.error["txtPass"]}</span>

                  </div>
                  <div className="col-md-4">
                    
                    <div><span className="label-form"><FM id='app.hijo.registro.label.contrasena2'/></span></div>
                    <input type="password" className="input-css full-width" name="txtPass2" id="txtPass2" maxLength="100" onChange={this.detectarCambio.bind(this, "txtPass2")} value={this.state.campo["txtPass2"]} />
                    <span className="form-error">{this.state.error["txtPass2"]}</span>

                  </div>
                </div>

                <div className="row pt-2 ps-4">
                  <div className="col-md-12">
                    
                    <div><span className="label-form"><FM id='app.hijo.registro.label.nombreEmpresa'/><span className="icon-error prosa" data-bs-toggle="tooltip" title={this.props.intl.formatMessage( {id: 'app.general.asociarUsuarioaEmpresa'} )}/></span></div>
                    <input type="text" className="input-css full-width" name="txtNombreEmpresa" id="txtNombreEmpresa" maxLength="50" onChange={this.detectarCambio.bind(this, "txtNombreEmpresa")} value={this.state.campo["txtNombreEmpresa"]} />
                    <span className="form-error">{this.state.error["txtNombreEmpresa"]}</span>

                  </div>
                </div>

                <div className="row py-3 ps-4">
                  <div className="col-md-12">
                    
                    <div><input type="checkbox" className="check" id="chkCondiciones" name="chkCondiciones" onChange={this.detectarCambio.bind(this, "chkCondiciones")} checked={this.state.campo["chkCondiciones"]}/><label onClick={utils.goAvisoLegal} className="label-check"> <FM id='app.hijo.registro.condiciones' values={{cond: <span className='link'>aviso legal y política de privacidad</span>}}/></label></div>
                    <span className="form-error">{this.state.error["chkCondiciones"]}</span>

                    <div><input type="checkbox" className="check" id="chkPublicidad" name="chkPublicidad" onChange={this.detectarCambio.bind(this, "chkPublicidad")} checked={this.state.campo["chkPublicidad"]}/><label htmlFor="chkPublicidad" className="label-check"> <FM id='app.padre.mi-perfil.form.label.publicidad'/></label></div>
                    <span className="form-error">{this.state.error["chkPublicidad"]}</span>          

                  </div>
                </div>
                
                <div className="separador mb-3"></div>

                <div className="row pt-2 ps-4">
                  <div className="col-md-6 pb-3">
                    
                    <button type="submmit" className="btn btn-md btn-primary"><span className="label-button"><FM id='app.hijo.registro.button.registrar'/></span></button>&nbsp;&nbsp;&nbsp;
                    
                  </div>
                  <div className="col-md-6 pb-3 text-end">
                    
                    <Link to={process.env.REACT_APP_WEB_BASE_URL+"/h/home"} className="link"><FM id='app.hijo.registro.button.tengoCuenta'/></Link>

                  </div>
                </div>

              </div>
            </div>
          </form>
          {/* FIN FORM USUARIO */}

        </div>
        {/* FIN CONTENEDOR */}

      </div>
    )
  }
}

/*
* Se envuelve a la clase con:
*    injectIntl: para poder pasar la prop this.props.intl en traducciones para javascript puro (no JSX)
*    withRouter: para poder pasar la prop this.props.history para redireccionar con push("/miUrl")
*/
export default injectIntl(withRouter(FormRegistro));