import React, { Component } from 'react';
import axios from 'axios';
import {injectIntl, FormattedMessage as FM} from 'react-intl';
import * as appConst from './../../common/const/Const';
import * as utils from './../../common/utils/Funcs';

class NuevaEtiqueta extends Component {

    constructor (props){
        super(props);
        this.guardar = this.guardar.bind(this);
        this.cancelar = this.cancelar.bind(this);
    }

    componentDidMount(){

        // Inicialización de los tooltip y popover de este componente
        utils.iniTooltip();

        if (this.props.fondoComponente)
            document.querySelector("#capaTextoTag").className = "align-middle capaNuevaEtiqueta puntaRendonda px-3 "+this.props.fondoComponente;
            
        if (this.props.focus)
            document.querySelector("#txtTag").focus();
    }

    async guardar(){
        
        if (document.querySelector("#txtTag").value){

            const data = {
                "nombre": document.querySelector("#txtTag").value,
                "idPadre": utils.getTokenValue("idPadre", appConst.PERFIL_PADRE),
                "baja": appConst.NO_BAJA_LOGICA,
                "idAutor": utils.getTokenValue("idUsuario", appConst.PERFIL_PADRE)
            }

            try{
                const response = await axios.post(appConst.POST_ETIQUETA, data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)} )
                
                if (response && response.data && response.status === appConst.HTTPSTATUS_CREATED){
                    if (utils.getLog()) console.log("status POST_ETIQUETA: "+response.status);
                    
                    // Se refresca la lista con la nueva etiqueta, se ejecuta la tarea postGuardar si existe y se cierra este componente
                    if (this.props._recargarDatos)
                        this.props._recargarDatos();

                    if (this.props._postGuardar)
                        this.props._postGuardar();

                    utils.hideTooltip("guardarTag");
                    this.props._cerrarComponente();

                    if (this.props._lanzarMensaje)
                        this.props._lanzarMensaje(this.props.intl.formatMessage({id:"app.padre.etiquetas.mensajes.crear"}), appConst.ALERT_TIPO_SUCCESS, appConst.ALERT_CLOSE);
                }

            }catch(error){
                if (utils.getLog()) console.log("Error POST_ETIQUETA: "+error);
                if (this.props._lanzarMensaje)
                    this.props._lanzarMensaje(utils.getTextError(error, this.props.intl, this.props.history, null, null, this.guardar, [], appConst.PERFIL_PADRE), utils.getTipoAlert(error), appConst.ALERT_OPEN);
            }
        }else{
            if (this.props._lanzarMensaje)
                this.props._lanzarMensaje(this.props.intl.formatMessage({id:"app.padre.etiquetas.validar.etiqueta-no-vacia"}), appConst.ALERT_TIPO_WARNING, appConst.ALERT_CLOSE);
        }
    }

    cancelar(){
        // Se limpia los posibles mensajes anteriores
        if (this.props._lanzarMensaje)
            this.props._lanzarMensaje('', '');
        if (this.props._postCancelar)
            this.props._postCancelar();
        utils.hideTooltip("cancelarTag")
        this.props._cerrarComponente();
    }
    
    render(){ 
        return (
            <div className="align-middle capaNuevaEtiqueta px-3" id="capaTextoTag">
                <span className="icon-tag me-2"></span>
                <input className="input-css" type="text" id="txtTag" name="txtTag" placeholder={this.props.intl.formatMessage({id:"app.padre.etiquetas.nueva-etiqueta.tooltip"})} size="35" maxLength="100" />&nbsp;&nbsp;
                <span className="icon-check hand text-secondary me-2" id="guardarTag" data-bs-toggle="tooltip" title={this.props.intl.formatMessage({id:"app.padre.etiquetas.botonera.guardar"})} onClick={this.guardar}></span>
                <span className="icon-cancel hand text-secondary" id="cancelarTag" data-bs-toggle="tooltip" title={this.props.intl.formatMessage({id:"app.padre.etiquetas.botonera.cancelar"})} onClick={this.cancelar}></span>
            </div>
        )
    }
}

export default injectIntl(NuevaEtiqueta);