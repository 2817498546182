import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {injectIntl, FormattedMessage as FM} from 'react-intl';
import Alert from './../common/utils/Alert';
import { getPerfilAuth, getPrefixURL } from './../common/utils/Funcs';
import * as appConst from './../common/const/Const';
import FormLogin from './FormLogin';

class Login extends Component {

  constructor(props){
    super(props);
    this.state = {
      login: (this.props.location.state) ? this.props.location.state.login : false,
      callback: (this.props.location.state) ? this.props.location.state.callback : "",
      textoAlert: '',
      tipoAlert: '',
      closeAlert: appConst.ALERT_OPEN
    };
  }

  componentDidMount(){

    /*
     * Si el usuario ya se encuentra logeado (único token local), se le permite pasar sin identificarse de nuevo
     * Se redirige al login cuando se cumplen alguno de los siguientes casos:
     *    - Existen los dos logeados (padre e hijo) o no existe ninguno
     *    - Si llega /login en la url se ignora el token local (si lo hubiera) --> Sirve para el usuario para logearse como padre e hijo a la vez
     *    - Si llega this.props.location.state.login = true en la url se ignora el token local (si lo hubiera) --> Sirve para la programación interna de código
     *    - Si existe una callback a ejecutar tras el login
     */
    const perfil = getPerfilAuth();
    if (perfil && window.location.href.indexOf("/login") < 0 && !this.state.login && !this.state.callback)
      this.props.history.push(process.env.REACT_APP_WEB_BASE_URL+"/"+getPrefixURL(perfil)+"/home");
  }

  showMensaje(texto, tipo, close){
    this.setState ({
      textoAlert: texto,
      tipoAlert: tipo,
      closeAlert: close
    });
  }

  render(){ 
    return (
      <div className="login-signin mx-auto">

        <div className='pb-3'>
          <Alert texto={this.state.textoAlert} tipo={this.state.tipoAlert} close={this.state.closeAlert}/>
        </div>

        <div className='row login-fondo p-3'>
          <div className='col-sm-12'>

            {/* LOGO */}
            <div className='row text-center pb-3'>
              <div className='col-sm-12'>
                <img src={appConst.URL_LOGO_FINDER} className="imgEmpresaMedio" alt={this.props.intl.formatMessage( {id: 'app.login.logo.alt'} )}/>
              </div>
            </div>

            {/* FORM */}
            <div className="row">
              <div className="col-sm-12">
                <FormLogin 
                  _showMensaje={this.showMensaje.bind(this)}
                  callback={this.state.callback}
                />
              </div>
            </div>
            
            {/* CAMBIO CONTRASEÑA */}
            <div className="row pt-3">
              <div className="col-sm-12 text-center">
                <span className="pass2"> <Link to={process.env.REACT_APP_WEB_BASE_URL+"/pass"} target={"_blank"} className="link"><FM id="app.general.login.olvidoContrasena"/></Link></span>
              </div>
            </div>
            
          </div>
        </div>

      </div>
    )
  }
}

export default injectIntl(Login);