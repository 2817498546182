import React, { Component } from 'react';
import Callback from './../../common/utils/Callback';
import Bienvenida from './../../common/utils/Bienvenida';

  // ***************************************************************************************************************************
  // 1º Este es el lugar donde se ejecutan las callbacks una vez se haga login. Si se ejecuta la callback no se ejecutan los siguientes casos de este componente
  // 2º Si no hay callbacks, entonces se carga la página inicial de entrada a la aplicación: "bienvenida"
  // ***************************************************************************************************************************

class ValidacionPostLogin extends Component {

    constructor (props){

        super(props);
        this.state = {
          callback: (this.props.location.state && this.props.location.state.callback) ? this.props.location.state.callback : "",
        };
    }

    render(){ 
        return (
            <div>
                {/* CALLBACKS */}
                {this.state.callback && <Callback urlCallback={this.state.callback}/>}

                {/* Primera pantalla de entrada a la aplicación */}
                <Bienvenida />
            </div>
        )
    }
}

export default ValidacionPostLogin;