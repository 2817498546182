import React, { Component } from 'react';
import { injectIntl,  FormattedMessage as FM } from 'react-intl';
import DOMPurify from 'dompurify';

class Confirmacion extends Component{
    
    constructor(props){
        super(props);
    }

    /* PARAMETROS:
     *
     *      REQUERIDO
     *      ---------
     *      id              String: identifica unívocamente una instancia distinta en la misma página
     *      titulo          String: título de la modal
     *      texto           String: texto de la modal que admite html
     *      accion          String: función a ejecutar tras pinchar en Aceptar
     *           
     *      OPCIONAL
     *      ---------
     *      estiloHeader    String: nombres de clases separadas por espacios (default: "icon-warning icono-peke")
     *      imagen          String: imagen de la modal (default: "")
     *      boton           String: nombre del botón Aceptar (default: "Aceptar")
     */
    
    render(){
        return (
            
            <div id={"confirm_"+this.props.id} className="modal fade" tabindex="-1" aria-labelledby="modalConfirmLabel" aria-hidden="true" data-bs-backdrop="true">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                    <div className="modal-content px-3">
                        
                        {/* HEADER */}
                        <div className="modal-header">
                            <span className={(this.props.estiloHeader) ? this.props.estiloHeader : "icon-warning icono-peke"}></span>
                            <h5 className="modal-title" id="modalConfirmLabel"> {this.props.titulo}</h5>
                            <button id="btnCerrarModal" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        
                        {/* BODY (se evita el XSS, cross-site scripting con la utilidad DOMPurify.sanitize), ya que es peligrosa la inyección de HTML de esta forma */}
                        <div className="modal-body">
                            <div className="prosa text-justify py-2" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.texto)}}></div>

                            {/* Imagen (opcional) */}
                            {this.props.imagen &&
                                <div className="text-center">
                                    <img src={this.props.imagen} className='imgConfirm' />
                                </div>
                            }
                        </div>

                        {/* FOOTER */}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-secondary me-3" data-bs-dismiss="modal">Cerrar</button>
                            <button type="button" className="btn btn-sm btn-primary me-3" data-bs-dismiss="modal" onClick={this.props.accion}>{(this.props.boton) ? this.props.boton : "Aceptar"}</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(Confirmacion);