import zIndex from '@material-ui/core/styles/zIndex';
import { Component } from 'react';
import {FormattedMessage as FM} from 'react-intl';

class FooterPatrocinadores extends Component{

    render(){
        return(
            <div>
            {/* DEJAR footer COMO RAIZ DEL DOCUMENTO Y QUITAR LOS DIVS QUE ENGLOBAN A footer */}
            {/*<footer className="fixed-bottom border pt-2 mb-3" style={{background: "#FFF", zIndex: "-100"}}>
                <div className="row text-center">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-2">
                        <img src="https://www.seedcapitalbizkaia.eus/wp-content/uploads/2020/05/seedcapitalbizkaia.png" className="imagenPatrocinador" />
                    </div>
                    <div className="col-sm-2">
                        <img src="https://finbox.es/prototipo/finderProd/images/mimundohijo/u1635.png" className="imagenPatrocinador" />
                    </div>
                    <div className="col-sm-2">
                        <img src="https://es.safecreative.net/wp-content/uploads/2016/09/logo-bilbao-ekintza-maker-faire.jpg" className="imagenPatrocinador" />
                    </div>
                    <div className="col-sm-2">
                        <img src="https://finbox.es/prototipo/finderProd/images/mimundohijo/u1673.png" className="imagenPatrocinador" />
                    </div>
                    <div className="col-sm-2">
                        <img src="https://www.bilbaoport.eus/wp-content/uploads/2014/09/logo_bilbaoport.png" className="imagenPatrocinador" />
                    </div>
                    <div className="col-sm-1"></div>
                </div>
                <br/>
            </footer>*/}
            </div>
        )
    }
}

export default FooterPatrocinadores;