import React, { Component } from 'react';
import HeaderGuess from './../../common/header/HeaderGuess';
import FormRegistro from './FormRegistro';

class Registro extends Component {

  render(){ 
    return (
      <div>
        
        {/* CABECERA */}
        <HeaderGuess />

        {/* FORM REGISTRO */}
        <FormRegistro />
        
      </div>
    )
  }
}

export default Registro;