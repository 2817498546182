import React, { Component } from 'react';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import Alert from './../../common/utils/Alert';
import * as appConst from '../../common/const/Const';
import * as utils from '../../common/utils/Funcs';
import HeaderGuess from '../../common/header/HeaderGuess';

class Principal extends Component {

  constructor(props){
    super(props);
    this.state = {
      textoAlert: '',
      tipoAlert: '',
      closeAlert: appConst.ALERT_OPEN
    };
  }

  render(){ 
    return (
      <div>
        
        <HeaderGuess />

        <div className='contenedorAdmin'>
        
          {/* MIGAS DE PAN */}
          <ol className="migaPan">
              <li className="enLineaFin icon-globe"> <FM id="app.admin.principal.title" /> </li>
          </ol>
          
          {/* INI CONTENEDOR */}
          <div className="contenedor">

            <Alert texto={this.state.textoAlert} tipo={this.state.tipoAlert} close={this.state.closeAlert}/>
            
            {/* Menú de admin */}
            <div>
              <ul>
                <li><span className='icon-user prosa hand' onClick={() => { this.props.history.push(process.env.REACT_APP_WEB_BASE_URL+"/a/registroPadre") }}>Registro de un padre</span></li>
                <li><span className='icon-logout prosa hand' onClick={() => utils.doLogout(this.props.history, appConst.PERFIL_ADMIN)}>Cerrar sesión</span></li>
              </ul>
            </div>

          </div>
          {/* FIN CONTENEDOR */}

        </div>
      </div>
    )
  }
}

export default injectIntl(Principal);