import React, { Component } from 'react';
import * as appConst from './../../common/const/Const';
import * as utils from './../../common/utils/Funcs';
import { FormattedMessage as FM } from 'react-intl';
import Badge from './../../common/utils/Badge';
import DOMPurify from 'dompurify';

class Match extends Component {

  constructor(props){
    super(props);
  }

  render(){ 
    return (
      <div className="cajaNoTanBlanca px-5 py-2">

          {/* FILA 1: header visible */}
          <div className="row">
            <div className="col-sm-9 text-start">
              <a href={"#detalleMatch"+this.props.transaccion.id} className="icon-arrow-combo link prosaResaltada" data-bs-toggle="collapse" aria-expanded="false" aria-controls={"detalleMatch"+this.props.transaccion.id}>&nbsp;&nbsp;{this.props.transaccion.asunto}</a>
            </div>
            <div className="col-sm-3 text-end">
              <span className="prosa" data-bs-toggle="tooltip" title="Fecha de última acción sobre el match">{(this.props.transaccion.fecha) ? utils.formatearFechaCastellano(this.props.transaccion.fecha) : <i><FM id="app.padre.mis-matches.match.sin-fecha" /></i>}</span>&nbsp;
              <span className={utils.getIconoMatchById(this.props.transaccion.idEstadoTransaccion)} data-bs-toggle="tooltip" title={this.props.transaccion.estadoTransaccion.nombre}></span>
            </div>
          </div>

          {/* EL RESTO OCULTO INICIALMENTE */}
          <div className="collapse" id={"detalleMatch"+this.props.transaccion.id}>

            <div className="separador my-3"></div>
            
            {/* FILA 2: logos empresas */}
            <div className="row prosa">
              <div className="col-md-1"></div>
              <div className="col-md-2 text-center">
                <span className="position-relative">
                  <img className="imgMatch" src={utils.getLogoParticipante(this.props.transaccion.participantes, appConst.TIPO_PART_ORIGEN)} />
                  <Badge 
                    id={appConst.TIPO_PART_ORIGEN}
                    tooltip={utils.getEstadoLargoParticipante(this.props.transaccion.participantes, appConst.TIPO_PART_ORIGEN)}
                    icono={(utils.getIdEstadoParticipante(this.props.transaccion.participantes, appConst.TIPO_PART_ORIGEN) === appConst.ESTADO_PART_CONFIRMADO)?"icon-check":"icon-help"}
                    fondo={(utils.getIdEstadoParticipante(this.props.transaccion.participantes, appConst.TIPO_PART_ORIGEN) === appConst.ESTADO_PART_CONFIRMADO)?"bg-success":"bg-danger"}
                  />
                </span>
              </div>
              <div className="col-md-2 pt-5 text-center">
                ---------------------------
              </div>
              <div className="col-md-2 pt-3 text-center">
                <span className={utils.getIconoMatchById(this.props.transaccion.idEstadoTransaccion) + " icono-medio"}></span><br/>
                <span className="prosa">{this.props.transaccion.estadoTransaccion.nombre}</span>
              </div>
              <div className="col-md-2 pt-5 text-center">
                ---------------------------
              </div>
              <div className="col-md-2 text-center">
                <span className="position-relative">
                  <img className="imgMatch" src={utils.getLogoParticipante(this.props.transaccion.participantes, appConst.TIPO_PART_DESTINO)} />
                  <Badge 
                    id={appConst.TIPO_PART_DESTINO}
                    tooltip={utils.getEstadoLargoParticipante(this.props.transaccion.participantes, appConst.TIPO_PART_DESTINO)}
                    icono={(utils.getIdEstadoParticipante(this.props.transaccion.participantes, appConst.TIPO_PART_DESTINO) === appConst.ESTADO_PART_CONFIRMADO)?"icon-check":"icon-help"}
                    fondo={(utils.getIdEstadoParticipante(this.props.transaccion.participantes, appConst.TIPO_PART_DESTINO) === appConst.ESTADO_PART_CONFIRMADO)?"bg-success":"bg-danger"}
                  />
                </span>
              </div>
              <div className="col-md-1"></div>
            </div>

            {/* FILA 3: nombres empresas */}
            <div className="row prosa">
              <div className="col-md-4 text-center">
                <p className='fw-lighter fs-6'>{utils.getNombreEmpresaParticipante(this.props.transaccion.participantes, appConst.TIPO_PART_ORIGEN)}</p>
              </div>
              <div className="col-md-4"></div>
              <div className="col-md-4 text-center">
                  <p className='fw-lighter fs-6'>{utils.getNombreEmpresaParticipante(this.props.transaccion.participantes, appConst.TIPO_PART_DESTINO)}</p>
              </div>
            </div>

            {/* FILA 4: cuerpo */}
            <div className="row prosa pb-3">
              <div className="col-sm-12">
                <span className='prosa' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.transaccion.cuerpo)}}></span>
              </div>
            </div>
          </div>

      </div>
    )
  }
}

export default Match;