import React, { Component } from 'react';
import axios from 'axios';
import {injectIntl, FormattedMessage as FM} from 'react-intl';
import Alert from './../../common/utils/Alert';
import * as utils from './../../common/utils/Funcs';
import * as appConst from './../../common/const/Const';
import Header from './../header/Header';
import Footer from './../footer/Footer';
import AreaInteres from './AreaInteres';

class MisAreasInteres extends Component {

    constructor (props){

        super(props);
        this.state = {
            data: [],
            textoAlert: "",
            tipoAlert: "",
            closeAlert: appConst.ALERT_OPEN
        }
        this.crearAreaInteres = this.crearAreaInteres.bind(this);
        this.lanzarEmail = this.lanzarEmail.bind(this);
        this.guardar = this.guardar.bind(this);
        this.cancelar = this.cancelar.bind(this);
    }

    componentDidMount(){

        // Inicialización de los tooltip de este componente
        utils.iniTooltip();

        // Carga todas las AreaInteres del padre logeado
        this.cargarAreaInteres();
    }

    crearAreaInteres(){

        document.querySelector("#btnCrear").className = "hideElement";
        document.querySelector("#capaTextoTag").className = "";
        document.querySelector("#txtTag").focus();
    }

    async lanzarEmail(){

        try{
            let listaFormateada = (this.state.data.map(a => "<li>"+a.nombre+"</li>")).toString();
            
            const response = await axios.get(appConst.GET_AREA_INTERES_ENVIAR_EMAIL.replace("#idPadre", utils.getTokenValue("idPadre", appConst.PERFIL_PADRE)), {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE), params: {
                listaAreasInteres: utils.replaceAll(listaFormateada, ",", ""),
                linkAreasInteres: appConst.LINK_ENVIO_AREAS_INTERES
            }} )

            if (response && response.status === appConst.HTTPSTATUS_OK){
                if (utils.getLog()) console.log("status GET_AREA_INTERES_ENVIAR_EMAIL: "+response.status);
                
                this.setState({
                    textoAlert: <FM id="app.padre.areaInteres.mensajes.enviarEmail" />,
                    tipoAlert: appConst.ALERT_TIPO_SUCCESS,
                    closeAlert: appConst.ALERT_OPEN
                });
            
            }else if (response && response.status === appConst.HTTPSTATUS_NO_CONTENT){

                this.setState({
                    textoAlert: <FM id="app.padre.areaInteres.mensajes.enviarEmail.sinEmpresasOSinEmails" />,
                    tipoAlert: appConst.ALERT_TIPO_WARNING,
                    closeAlert: appConst.ALERT_OPEN
                });
            }

        }catch(error){
            if (utils.getLog()) console.log("Error GET_AREA_INTERES_ENVIAR_EMAIL: "+error);
            this.setState({
                textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.lanzarEmail, [], appConst.PERFIL_PADRE),
                tipoAlert: utils.getTipoAlert(error),
                closeAlert: appConst.ALERT_OPEN
            });
        }
    }

    async guardar(){
        
        if (document.querySelector("#txtTag").value){

            const data = {
                "nombre": document.querySelector("#txtTag").value,
                "idPadre": utils.getTokenValue("idPadre", appConst.PERFIL_PADRE),
                "baja": appConst.NO_BAJA_LOGICA,
                "idAutor": utils.getTokenValue("idUsuario", appConst.PERFIL_PADRE)
            }

            try{
                const response = await axios.post(appConst.POST_AREA_INTERES, data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)} )
                
                if (response && response.data && response.status === appConst.HTTPSTATUS_CREATED){
                    if (utils.getLog()) console.log("status POST_AREA_INTERES: "+response.status);
                    
                    // Se refresca la lista con la nueva área de interés
                    this.cargarAreaInteres();

                    // Se muestra de nuevo el botón 'Crear área de interés'
                    document.querySelector("#btnCrear").className = "btn btn-sm btn-primary";
                    document.querySelector("#capaTextoTag").className = "hideElement";

                    // Se limpia el valor del campo de la nueva área de interés
                    document.querySelector("#txtTag").value = "";

                    this.setState({
                        textoAlert: <FM id="app.padre.areaInteres.mensajes.crear" />,
                        tipoAlert: appConst.ALERT_TIPO_SUCCESS,
                        closeAlert: appConst.ALERT_CLOSE
                    });
                }

            }catch(error){
                if (utils.getLog()) console.log("Error POST_AREA_INTERES: "+error);
                this.setState({
                    textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.guardar, [], appConst.PERFIL_PADRE),
                    tipoAlert: utils.getTipoAlert(error),
                    closeAlert: appConst.ALERT_OPEN
                });
            }
        }else{
            this.setState({
                textoAlert: <FM id="app.padre.areaInteres.validar.area-interes-no-vacia" />,
                tipoAlert: appConst.ALERT_TIPO_WARNING,
                closeAlert: appConst.ALERT_CLOSE
            });
        }
    }

    cancelar(){

        // Se muestra de nuevo el botón 'Crear área de interés'
        document.querySelector("#btnCrear").className = "btn btn-sm btn-primary";
        document.querySelector("#capaTextoTag").className = "hideElement";

        // Se limpia el valor del campo de la nueva área de interés
        document.querySelector("#txtTag").value = "";
    }

    async cargarAreaInteres(){

        try{
            const response = await axios.get(appConst.GET_AREAS_INTERES, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE), params: {idPadre: utils.getTokenValue("idPadre", appConst.PERFIL_PADRE)}} )
            
            if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
                if (utils.getLog()) console.log("status GET_AREAS_INTERES: "+response.status);

                // Se muestra el botón EnviarEmail si hay áreas de interés
                document.querySelector("#btnLanzarEmail").className = "btn btn-sm btn-primary";
                // Se oculta la línea por debajo del botón 'Crear área de interés' porque hay áreas de interés
                //document.querySelector("#capaNuevo").className = "col-sm-9 text-end";
                this.setState({ 
                    data: response.data,
                    textoAlert: "",
                    tipoAlert: "",
                });

            }else if (response && response.status === appConst.HTTPSTATUS_NO_CONTENT){

                // Se oculta el botón EnviarEmail si no hay áreas de interés
                document.querySelector("#btnLanzarEmail").className = "hideElement";
                // Se pinta una línea por debajo del botón 'Crear área de interés' porque la lista está vacía
                //document.querySelector("#capaNuevo").className = "col-sm-9 text-end separadorEtiqueta p-2";
                this.setState({ 
                    data: [],
                    textoAlert: <FM id="app.padre.areaInteres.listado.sinDatos" />,
                    tipoAlert: appConst.ALERT_TIPO_WARNING,
                    closeAlert: appConst.ALERT_OPEN
                });
            }

        }catch(error){
            if (utils.getLog()) console.log("Error GET_AREAS_INTERES: "+error);
            this.setState({
                textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.cargarAreaInteres.bind(this), [], appConst.PERFIL_PADRE),
                tipoAlert: utils.getTipoAlert(error),
                closeAlert: appConst.ALERT_OPEN
            });
        }
    }

    _lanzarMensaje (texto, tipo, close){
        this.setState({
            textoAlert: texto,
            tipoAlert: tipo,
            closeAlert: close
        });
    }

    _recargarLista(){
        this.cargarAreaInteres();
    }

    render(){ 
        return (
            <div>
                <Header />

                <div className="contenedorHijo">

                    {/* MIGAS DE PAN */}
                    <ol className="migaPan">
                        <li className="enLineaFin icon-star-full"> <FM id="app.padre.areaInteres.title" /></li>
                    </ol>
                    
                    <p className="prosa mx-5 mb-5"> <FM id="app.padre.areaInteres.subtitle" /></p>

                    <div className="contenedor75">
                        
                        <Alert texto={this.state.textoAlert} tipo={this.state.tipoAlert} close={this.state.closeAlert}/>
                        
                        <div className="row mb-2">

                            <div className="col-sm-3" id="capaLanzarEmail">
                                <button className="hideElement" id="btnLanzarEmail" onClick={this.lanzarEmail} data-bs-toggle="tooltip" title="Envía un email a todas las empresas de tu mundo sugiriendo todas las áreas de interés que has creado"><FM id='app.padre.areaInteres.botonera.lanzarEmail'/></button>
                            </div>

                            <div className="col-sm-9 text-end p-2" id="capaNuevo">
                            
                                <button className="btn btn-sm btn-primary" id="btnCrear" onClick={this.crearAreaInteres}><FM id='app.padre.areaInteres.botonera.nuevo'/></button>
                                
                                <div className="hideElement" id="capaTextoTag">
                                    <span className="icon-tag"></span>
                                    <input type="hidden" id="txtIdTag" name="txtIdTag"/>
                                    <input className="input-css" type="text" id="txtTag" name="txtTag" placeholder={this.props.intl.formatMessage({id:"app.padre.areaInteres.nueva-area-interes.tooltip"})} size="45" maxLength="100" />&nbsp;&nbsp;
                                    <span className="icon-check hand text-secondary me-2" id="guardarTag" data-bs-toggle="tooltip" title={this.props.intl.formatMessage({id:"app.padre.areaInteres.botonera.guardar"})} onClick={this.guardar}></span>
                                    <span className="icon-cancel hand text-secondary" id="cancelarTag" data-bs-toggle="tooltip" title={this.props.intl.formatMessage({id:"app.padre.areaInteres.botonera.cancelar"})} onClick={this.cancelar}></span>
                                </div>
                                
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-sm-12">
                                { this.state.data.map( e => <AreaInteres key={e.id} id={e.id} nombre={e.nombre} lanzarMensaje={this._lanzarMensaje.bind(this)} recargarLista={this._recargarLista.bind(this)} /> ) }
                            </div>
                        </div>
                    
                    </div>
                </div>

                <Footer />
            </div>
        )
    }
}

export default injectIntl(MisAreasInteres);