import React, { Component } from 'react';
import * as appConst from './../const/Const';
import DOMPurify from 'dompurify';

class Alert extends Component{
    
    constructor(props){
        super(props);
        this.state = {
            texto: this.props.texto,
            tipo: this.props.tipo,
            close: (this.props.close) ? this.props.close : appConst.ALERT_OPEN,
            waitNextMessage: false
        }
    }

    componentWillReceiveProps(next_props){

        // Se detecta un cambio en la nueva prop texto y se actualiza el mensaje.
        // A la vez que el mensaje sale por pantalla, se setea el estado de close (si llega) para cerrar el Alert desde el método pintarMensaje
        // Nota: una actualización del estado aquí, no hace que vuelva a ejecutarse este método, pero sí renderiza el Alert de nuevo
        if (this.state.texto != next_props.texto && !this.state.waitNextMessage){
            this.actualizarProps(next_props.texto, next_props.tipo, next_props.close);
            window.scrollTo(0, 0);
        
        // Esta rama sirve para paliar el sobreescribir un mensaje cuando llegan 2 seguidos, el primero con close y el segundo con open (debido al delay), y se fuerza un delay igual al primer close + 1 para cargar el segundo justo 1 ms después
        }else if (this.state.texto != next_props.texto && this.state.waitNextMessage){
            window.setTimeout(this.actualizarProps.bind(this, next_props.texto, next_props.tipo, next_props.close), this.state.close + 1);
            window.scrollTo(0, 0);
        }
    }

    actualizarProps(newText, newType, newClose){

        this.setState({
            texto: newText,
            tipo: newType,
            close: newClose,
            waitNextMessage: (newClose > 0) ? true : false
        });
    }
    
    pintarMensaje(mensaje, tipo){
        
        // Si llega el estado close con valor != 0, el componente Alert se resetea con los valores de estado iniciales, y cerrándolo.
        if (this.state.close != appConst.ALERT_OPEN)
            window.setTimeout(this.actualizarProps.bind(this, "", "", appConst.ALERT_OPEN), this.state.close);

        if (mensaje && tipo){

            let estilo, icono, ariaLabel = "";
            if (tipo === appConst.ALERT_TIPO_SUCCESS){
                estilo = appConst.ALERT_ESTILO_SUCCESS;
                icono = appConst.ALERT_ICONO_SUCCESS;
                ariaLabel = appConst.ALERT_ARIALABEL_SUCCESS;
                
            }else if (tipo === appConst.ALERT_TIPO_WARNING){
                estilo = appConst.ALERT_ESTILO_WARNING;
                icono = appConst.ALERT_ICONO_WARNING;
                ariaLabel = appConst.ALERT_ARIALABEL_WARNING;

            }else if (tipo === appConst.ALERT_TIPO_DANGER){
                estilo = appConst.ALERT_ESTILO_DANGER;
                icono = appConst.ALERT_ICONO_DANGER;
                ariaLabel = appConst.ALERT_ARIALABEL_DANGER;
            }

            return this.getHTML(mensaje, estilo, icono, ariaLabel);
            
        }else
            return <void></void>;
    }

    getHTML(mensaje, estilo, icono, ariaLabel){

        return(
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" className="hideElement">
                    <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </symbol>
                    <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                    </symbol>
                    <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                    </symbol>
                </svg>
                <div className={"alert " + estilo + " d-flex align-items-center py-2"} role="alert">
                    <svg className="bi flex-shrink-0 me-3" width="18" height="18" role="img" aria-label={ariaLabel}><use xlinkHref={icono}/></svg>
                    <div>{mensaje}</div>
                    {/*<div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(mensaje)}}></div>*/}
                </div>
            </div>
        )
    }

    render(){
        
        return( this.pintarMensaje(this.state.texto, this.state.tipo) )
    }
}

export default Alert;