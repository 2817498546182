import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/index.css';

// ENRUTAMIENTO
import Layout from './Layout';

// IDIOMA
import {IntlProvider} from "react-intl";
import messages_es from "./lang/es.json";
import messages_en from "./lang/en.json";

const messages = {
    'es': messages_es,
    'en': messages_en
};
const language = navigator.language.split(/[-_]/)[0];  // Extrae el language (es) sin el código de región (-ES)

// Se envuelve la aplicación completa (Layout.js) con IntlProvider para aplicar el idioma a todos los componentes
ReactDOM.render(
  <IntlProvider locale={language} messages={messages[language]}>
    <Layout />
  </IntlProvider>,
  document.getElementById('root')
);