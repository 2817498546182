import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Alert from './../utils/Alert';
import * as appConst from './../const/Const';
import * as utils from './../utils/Funcs';
import Callback from '../utils/Callback';

class ErrorPage extends Component{

    constructor(props){
        super(props);
    }

    componentDidMount(){
        
        window.setTimeout(() => utils.doLogout(this.props.history, "", this.props.callback), 2000);
    }

    render(){
        return(
            <div className="m-5">
                <div>
                    <Alert texto={this.props.textoError} tipo={appConst.ALERT_TIPO_WARNING} close={appConst.ALERT_OPEN}/>
                </div>
            </div>
        )
    }
}

export default injectIntl(withRouter(ErrorPage));