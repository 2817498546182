import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import * as appConst from '../const/Const';
import * as utils from '../utils/Funcs';

function CargarMaestroDatos(props) {

    const [valores, setValores] = useState([]);

    useEffect(() => {
        
        async function cargarDatos() {
            try{
                if (utils.getLog()) console.log("TipoToken de CargarMaestroDatos: "+JSON.stringify(props.tipoToken));
                // En el caso de los siguientes maestros se podrán recuperar los datos con token o sin token:
                //  - Cargos
                //  - Tipos de padre
                const token = (props.tipoToken === appConst.HEADER_SIN_TOKEN && (props.tabla === appConst.MAESTRO_CARGOS || props.tabla === appConst.MAESTRO_TIPOS_PADRE)) ? appConst.HEADER_SIN_TOKEN : appConst.HEADER_CON_TOKEN;
                const response = await axios.get(appConst.GET_MAESTRO.replace("#tabla", props.tabla), {headers: utils.getHeaders(token, props.perfil)})
                if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
                    if (utils.getLog()) console.log(response.status+": Maestro "+props.tabla);
                    setValores(response.data);
                }
            }catch(error){
                if (utils.getLog()) console.log("Error GET_MAESTRO: "+error);
                utils.getTextError(error, props.intl, props.history, null, null, cargarDatos, [], props.perfil);
            }
        }
        cargarDatos();
    }, [])

    return(
        <React.Fragment>
            {/*{valores.map( (e) => <option key={e.id} value={e.id} selected={ (props.preSelected == e.id) ? true : false }>
                {(props.descripcion) ? e.descripcion : e.nombre}
            </option>)}*/}
            {valores.map( (e) => {
                if (!props.excluirId || props.excluirId != e.id)
                    return (
                        <option key={e.id} value={e.id} selected={ (props.preSelected == e.id) ? true : false }>
                            {(props.descripcion) ? e.descripcion : e.nombre}
                        </option>
                    )
            })}
        </React.Fragment>
    )
}

export default injectIntl(withRouter(CargarMaestroDatos));