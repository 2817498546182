/*********************************************************************/
/*********      UTILIDADES GLOBALES A TODA LA APLICACION     *********/
/*********************************************************************/
import * as appConst from './../const/Const';
import jwt_decode from "jwt-decode";
import axios from 'axios';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import S3 from 'react-aws-s3';
//window.Buffer = window.Buffer || require("buffer").Buffer;
//import { unstable_createMuiStrictModeTheme } from '@material-ui/core';

// Setea en local Storage el token JWT
export const setJwtToken = token => localStorage.setItem(appConst.TOKEN_KEY + "_" + getTokenValue1(token, 'tipo'), token);

// Extrae de local Storage el token JWT del perfil solicitado
export const getJwtToken = perfil => localStorage.getItem(appConst.TOKEN_KEY + "_" + perfil);

// Elimina de local Storage el token JWT del perfil solicitado
export const removeJwtToken = perfil => localStorage.removeItem(appConst.TOKEN_KEY + "_" + perfil);

// Setea en local Storage el refreshToken JWT
export const setJwtRefreshToken = (token, refreshToken) => localStorage.setItem(appConst.REFRESH_TOKEN_KEY + "_" + getTokenValue1(token, 'tipo'), refreshToken);

// Extrae de local Storage el refreshToken JWT del perfil solicitado
export const getJwtRefreshToken = perfil => localStorage.getItem(appConst.REFRESH_TOKEN_KEY + "_" + perfil);

// Elimina de local Storage el refreshToken JWT
export const removeJwtRefreshToken = perfil => localStorage.removeItem(appConst.REFRESH_TOKEN_KEY + "_" + perfil);

// Extrae de local Storage el valor de un claim del token JWT del perfil solicitado
export const getTokenValue = (claim, perfil) => (getJwtToken(perfil)) ? jwt_decode(getJwtToken(perfil))[claim] : '';

// Extrae del parámetro token el valor de un claim del token JWT
export const getTokenValue1 = (token, claim) => jwt_decode(token)[claim];

// Extrae el locale del navegador (por ejemplo: es-ES)
export const getLocale = () => navigator.language;

// Convierte un valor booleano a su número equitativo
export const getBooleanNumber = val => (val) ? 1 : 0;

// Convierte un valor string a booleano
export const getStringToBoolean = val => (val === "1") ? true : false;

// Config de escritura en la consola del navegador de la ejecución del FRONT-END
export const getLog = () => (process.env.REACT_APP_LOG === 'true');

// Setea el total de registros de un componente tabulator
export const setRecordCount = (objHTML, value) => document.querySelector("#"+objHTML).innerHTML = value;

// Retorna el día del mes actual
export const getDiaActual = () => (new Date().getDate() < 10) ? "0"+(new Date().getDate()) : new Date().getDate();

// Retorna el mes actual
export const getMesActual = ceros => {
  var addCeros = false;
  if (ceros) addCeros = true;
  if (addCeros){
      return ((new Date().getMonth()+1) < 10) ? "0"+(new Date().getMonth()+1) : new Date().getMonth()+1;
  }else{
      return new Date().getMonth()+1;
  }
}

// Retorna el año actual
export const getAnoActual = () => new Date().getFullYear();

// Retorna la hora actual
export const getHoraActual = () => (new Date().getHours() < 10) ? "0"+(new Date().getHours()) : new Date().getHours();

// Retorna los minutos actuales
export const getMinutosActual = () => (new Date().getMinutes() < 10) ? "0"+(new Date().getMinutes()) : new Date().getMinutes();

// Retorna los segundos actuales
export const getSegundosActual = () => (new Date().getSeconds() < 10) ? "0"+(new Date().getSeconds()) : new Date().getSeconds();

// Retorna la fecha solicitada lista para almacenarse en BD con hora
export const formatearFechaUTC = (fecha, horas, minutos) => {
	var date = new Date(fecha.split("/")[2], fecha.split("/")[1]-1, fecha.split("/")[0], horas, minutos, "00");
	return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
}

// Retorna la fecha solicitada lista para almacenarse en BD sin hora
export const formatearFechaUTCSinHora = (fecha) => {
	var date = new Date(fecha.split("/")[2], fecha.split("/")[1]-1, fecha.split("/")[0]);
	return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
}

// Retorna la fecha del sistema en el idioma solicitado
export const getSystemDate = lang => (lang === appConst.LOCALE_ES) ? getDiaActual()+"/"+getMesActual(true)+"/"+getAnoActual() : getAnoActual()+"-"+getMesActual(true)+"-"+getDiaActual();

// Retorna la fecha informada por parámetro formateada al castellano
export const formatearFechaCastellano = (p_f, incluirHora) => {
	
  let f = new Date(p_f);
	let fecha = ((f.getDate() < 10) ? "0"+f.getDate() : f.getDate()) + "/" + (((f.getMonth()+1) < 10) ? "0"+(f.getMonth() + 1) : f.getMonth() + 1) + "/" + f.getFullYear();
  let hora = "";
	if (incluirHora){
		hora = " - " + ((f.getHours() < 10) ? "0"+f.getHours() : f.getHours()) + ":" + ((f.getMinutes() < 10) ? "0"+f.getMinutes() : f.getMinutes());
	}
	return fecha+" "+hora;
}

// Retorna la fecha informada por parámetro formateada al inglés
export const formatearFechaIngles = p_f => {
  let f = new Date(p_f);
	return f.getFullYear() + "-" + (((f.getMonth()+1) < 10) ? "0"+(f.getMonth() + 1) : f.getMonth() + 1) + "-" + ((f.getDate() < 10) ? "0"+f.getDate() : f.getDate());
}

// Extrae la cabecera para realizar llamadas a la API en base al valor de tipoEndpoint (0: login, 1: con token, 2: sin token) y al perfil
export const getHeaders = (tipoEndpoint, perfil) => {
  let retorno = null;

  // LOGIN
  if (tipoEndpoint === appConst.HEADER_LOGIN)
    retorno = {
      'X-Requested-With': 'XMLHttpRequest',
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': getLocale()
    }

  // Con TOKEN
  else if (tipoEndpoint === appConst.HEADER_CON_TOKEN)
    retorno = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': getLocale(),
      'Authorization': "Bearer "+getJwtToken(perfil)
    }

  // Sin TOKEN
  else if (tipoEndpoint === appConst.HEADER_SIN_TOKEN)
    retorno = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': getLocale()
    }
  
  // Con REFRESH_TOKEN
  else if (tipoEndpoint === appConst.HEADER_CON_REFRESH_TOKEN)
    retorno = {
      'Accept-Language': getLocale(),
      'X-Authorization': "Bearer "+getJwtRefreshToken(perfil)
    }
  
  return retorno;
}

// Extrae la cabecera para realizar llamadas a la API desde el componente tabulator en función al perfil solicitado
export const getHeadersTabulator = perfil => {
  return {
    contentType: "application/json; charset=utf-8",
    headers: {
      "Authorization": "Bearer " + getJwtToken(perfil),
      "Accept": "application/json; charset=utf-8",
      "Accept-Language": getLocale(),
    }
  }
}

/* 
 * Tratamiento de errores de retorno de API
 * Params:
 *    error
 *    lang
 *    history
 *    cadenaNotFound
 *    cadenaConflicto
 *    callback
 *    callback_params
 *    perfil
 */
export const getTextError = (error, lang, history, cadenaNotFound, cadenaConflicto, callback, callback_params, perfil) => {

  let mensaje = '';
  if (getLog()) console.log("lang: "+lang);
  if (getLog()) console.log("history: "+history);

  /***** INI: MAX_QUESTIONS: Número de consultas por usuario de BD y por hora configurado en el servidor de BBDD *****/
  if (error && error.response && isMaxQuestions(error.response.status, error.response.data.message))
    return mensaje = lang.formatMessage( {id: 'app.error.500.isMaxQuestions'} );
  /***** FIN: MAX_QUESTIONS: Número de consultas por usuario de BD y por hora configurado en el servidor de BBDD *****/

  if (error && error.response && lang){
    
    /******************* INTERNAL ERROR SERVER: 500 ****************************/
    if (error.response.status === appConst.HTTPSTATUS_INTERNAL_SERVER_ERROR){ //500
      
      if (error.toString().indexOf(appConst.SIN_CONEXION) > 0)
        mensaje = lang.formatMessage( {id: 'app.error.500.sinConexion'} );
      else
        mensaje = lang.formatMessage( {id: 'app.error.500'} );

    /************************** BAD REQUEST: 400 *****************************/
    }else if (error.response.status === appConst.HTTPSTATUS_BAD_REQUEST){ //400
      
      if (error.response.data)
        mensaje = error.response.data.message;
      else
        mensaje = lang.formatMessage( {id: 'app.error.400'} );

    /************************** UNAUTHORIZED: 401 *****************************/
    }else if (error.response.status === appConst.HTTPSTATUS_UNAUTHORIZED){ //401

      if (error.response.data){

        if (getLog()) console.log("API errorCode: "+error.response.data.errorCode);
        
        // EXPIRED ACCESS TOKEN (11)
        if (error.response.data.errorCode === appConst.ERROR_CODE_EXPIRED_ACCESS_TOKEN){
          if (getLog()) console.log("Access Token caducado. Se refresca token");
          // callback es la función que no pudo completarse por expirar el accessToken. Se vuelve a enviar para re-lanzala una vez se refresque el token, pero solo si hay una callback a la que llamar
          refrescarToken(lang, history, callback, callback_params, perfil);
          
        // EXPIRED REFRESH TOKEN (13) redirección al login
        }else if (error.response.data.errorCode === appConst.ERROR_CODE_EXPIRED_REFRESH_TOKEN){
          if (getLog()) console.log("Refresh Token caducado. Se redirige al login");
          mensaje = lang.formatMessage( {id: 'app.error.401.refreshtoken.expired'} );
					//window.setTimeout(() => doLogout(history, getTokenValue("tipo", perfil)), 2000);
          doLogout(history, getTokenValue("tipo", perfil));
  
        }else mensaje = error.response.data.message;
        
      }else mensaje = lang.formatMessage( {id: 'app.error.401'} );

    /************************** FORBIDDEN: 403 *****************************/
    }else if (error.response.status === appConst.HTTPSTATUS_FORBIDDEN){ //403
      
      if (error.response.data)
        mensaje = error.response.data.message;
      else
        mensaje = lang.formatMessage( {id: 'app.error.403'} );

    }else if (error.response.status === appConst.HTTPSTATUS_NOT_FOUND){ //404
    
      // Campo no existe
      if (cadenaNotFound)
        mensaje = lang.formatMessage( {id: 'app.error.404.cadena'}, {valor: cadenaNotFound} );

      else
        mensaje = lang.formatMessage( {id: 'app.error.404'} );

    /************************** CONFLICT: 409 *****************************/
    }else if (error.response.status === appConst.HTTPSTATUS_CONFLICT){ //409
      
      // Campo duplicado
      if (cadenaConflicto)
        mensaje = lang.formatMessage( {id: 'app.error.409.cadena'}, {valor: cadenaConflicto} );
      
      // Conflicto controlado
      else if (error.response.data)
        mensaje = error.response.data.message;
      
      // Conflicto no controlado
      else
        mensaje = lang.formatMessage( {id: 'app.error.409'} );
    }
    
  }else{
    // No llega la variable lang para poder utilizar el mutiidioma, y se hardcodea el mensaje en castellano
    if (error.toString().indexOf(appConst.SIN_CONEXION) > 0)
      mensaje = "No hay conexión a Internet en este equipo.";
    else
      mensaje = "Vaya, parece que algo no ha ido bien.";
  }
  
  return mensaje;
}

// Cuando caduca el accessToken se refresca para generar otro token y llamar a la función callback pendiente de ejectar
export const refrescarToken = (lang, history, callback, callback_params, perfil) => {

  axios.get(appConst.GET_TOKEN_WITH_REFRESH_TOKEN, {headers: getHeaders(appConst.HEADER_CON_REFRESH_TOKEN, perfil)})
    .then(response => {
      if (response.data){
        if (getLog()) console.log("GET_TOKEN_WITH_REFRESH_TOKEN: AccessToken regenerado con éxito. Callback: "+callback.name);
        
        // Se actualiza el accessToken con el nuevo token generado 
        setJwtToken(response.data.token);
        
        // Se llama la función que no se ejecutó por token caducado
        if (callback){
          if (!callback_params || callback_params.length == 0)
            callback();
          else if (callback_params.length == 1)
            callback(callback_params[0]);
          else if (callback_params.length == 2)
            callback(callback_params[0], callback_params[1]);
          else if (callback_params.length == 3)
            callback(callback_params[0], callback_params[1], callback_params[2]);
          else if (callback_params.length == 4)
            callback(callback_params[0], callback_params[1], callback_params[2], callback_params[3]);
          else if (callback_params.length == 5)
            callback(callback_params[0], callback_params[1], callback_params[2], callback_params[3], callback_params[4]);
          else if (callback_params.length == 6)
            callback(callback_params[0], callback_params[1], callback_params[2], callback_params[3], callback_params[4], callback_params[5]);
          else if (callback_params.length == 7)
            callback(callback_params[0], callback_params[1], callback_params[2], callback_params[3], callback_params[4], callback_params[5], callback_params[6]);
        
        }else if (getLog()) console.log("GET_TOKEN_WITH_REFRESH_TOKEN: No existe función callback a llamar");

      } else if (getLog()) console.log("GET_TOKEN_WITH_REFRESH_TOKEN: La API no ha devuelto el token generado");
    })
  .catch(error => {
    if (getLog()) console.log("Error GET_TOKEN_WITH_REFRESH_TOKEN: "+error);
    getTextError(error, lang, history);
  });
}

// Desconecta al usuario de la sesión
export const doLogout = (hist, perfil, callback) => {
  
  // Redirección al login
  hist.push({
    pathname: process.env.REACT_APP_WEB_BASE_URL + "/",
    state: {
        login: true,
        callback
    }
});

  removeJwtToken(perfil);
  removeJwtRefreshToken(perfil);
}

// Si status es 401 ó 500 y el mensaje de error incluye la cadena: "has exceeded the 'max_que", se ha excedido el valor de MAX_QUESTIONS (= MAX_QUERIES)
export const isMaxQuestions = (status, sms) => (status && (status === appConst.HTTPSTATUS_INTERNAL_SERVER_ERROR || status === appConst.HTTPSTATUS_UNAUTHORIZED) && sms && sms.indexOf(appConst.ERROR_MAX_QUESTIONS) >= 0) ? true : false;

// Valida el formato de un email correcto
export const isValidEmail = campo => {
  let valido = true;
  let posicionArroba = campo.lastIndexOf('@');
  let posicionPunto = campo.lastIndexOf('.');

  if (!(posicionArroba < posicionPunto && posicionArroba > 0 && campo.indexOf('@@') === -1 && posicionPunto > 2 && (campo.length - posicionPunto) > 2)) {
    valido = false;
  }
  return valido;
}

// Valida un código postal correcto
export const validarCodigoPostal = cp_ => (cp_.length === 5 && validarNumero(cp_) && parseInt(cp_) >= 1000 && parseInt(cp_) <= 52999) ? true : false;

// Valida un teléfono correcto
export const validarTelefono = tel => {
	if ( tel.length < 9 ){ return false; }
	// Se cogen los 9 últimos caracteres. De +0034654123789, se obtiene: 654123789
	var solo_numero = tel.substr(tel.length-9, tel.length);
	if ( validarNumero(solo_numero)) { return true; }
	else { return false; }
}

// Valida que un dato sea un valor numérico
export const validarNumero = num => (!isNaN(num)) ? true: false;

// Valida que un número decimal tenga una sola coma
export const esPuntoCorrecto = valorDecimal => ( valorDecimal.toString().split(".").length - 1 === 1 ) ? true : false;

// Valida que la tecla pulsada sea solo dígitos o coma
export const soloNumerosYComas = e => {
	var key = window.event ? e.which : e.keyCode;
	if ( (key < 44 || key > 44) && (key < 48 || key > 57) ) {
    	e.preventDefault();
  	}
}

// Valida que un valor sea un número decimal, es decir, que contenga una sola coma y a ambos lados de ésta existan valores numéricos
export const validarNumeroDecimal = valorDecimal => (esPuntoCorrecto(valorDecimal) && validarNumero(valorDecimal.split(".")[0]) && validarNumero(valorDecimal.split(".")[1])) ? true : false;

// Formatea un valor con el separador de punto (si tenía nua coma)
export const formatearDecimalAPunto = valor => {
	
	valor = valor.toString();
	// Comprobar si el número es decimal
	if (valor.indexOf(",") > 0 && valor.split(",").length === 2 ){
		
    // Pasar número decimal a representación con coma
		return valor.split(",")[0] + "." + valor.split(",")[1]
		
  }else return valor;
}

// Abre un enlace externo a un fichero pdf
export const openPdf = (hist, link) => hist.push({
  pathname: process.env.REACT_APP_WEB_BASE_URL+'/t/pdf',
  state: { filenamePDF: link }
})

// Inicializa todos los tooltip de un componente con la disposición de la cajita en el lado que indica el parámetro 'valPlacement'
export const iniTooltip_old = valPlacement => document.querySelectorAll('[data-bs-toggle="tooltip"]').tooltip({
  trigger: 'hover',
  placement: valPlacement,
  animated: 'fade',
  html: true
});

// Retorna la instancia de la modal solicitada por parámetro
export const getModal = obj => {
  const objTriggerEl = document.querySelector("#"+obj);
  return bootstrap.Modal.getInstance(objTriggerEl);
}

// Oculta la modal solicitada por parámetro
export const hideModal = obj => {
  getModal(obj)?.hide();
}

// Muestra la modal solicitada por parámetro
export const openModal = obj => {
  let objModal = new bootstrap.Modal(document.querySelector('#'+obj))
  let DOMModal = document.querySelector('#'+obj)
  objModal.show(DOMModal);
}

// Retorna la instancia del tooltip solicitado por parámetro
export const getTooltip = obj => {
  const objTriggerEl = document.querySelector("#"+obj);
  return bootstrap.Tooltip.getInstance(objTriggerEl);
}

// Oculta el tooltip solicitado por parámetro
export const hideTooltip = obj => {
  if (getTooltip(obj)) getTooltip(obj).hide();
}

// Muestra el tooltip solicitado por parámetro
export const showTooltip = obj => {
  getTooltip(obj).show();
}

// Inicializa todos los tooltip de un componente
export const iniTooltip = () => {
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
}

// Oculta todos los tooltip de un componente
/*export const hideAllTooltips = () => {
  
  for (const tooltip of document.querySelectorAll('[data-bs-toggle="tooltip"]')){
    console.log(bootstrap.Tooltip.getInstance(tooltip).id);
    bootstrap.Tooltip.getInstance(tooltip).hide();
  }
}*/

// Inicializa todos los popovers (tooltips con formato título y cuerpo) de un componente
export const iniPopover = () => {
  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })
}

// Se obtiene el formato del mensaje por pantalla al usuario (icono, color, ...)
export const getTipoAlert = error => (!error.response || error.response.status === appConst.HTTPSTATUS_INTERNAL_SERVER_ERROR) ? appConst.ALERT_TIPO_DANGER : appConst.ALERT_TIPO_WARNING;

// Cambia el favicon: icono de la pestaña del navegador
export const changeFavicon = src => {
  var link = document.createElement('link'),
      oldLink = document.getElementById('dynamic-favicon');
  link.id = 'dynamic-favicon';
  link.rel = 'shortcut icon';
  link.href = src;
  if (oldLink) document.head.removeChild(oldLink);
  document.head.appendChild(link);
 }

// Devuelve las iniciales del usuario del token logeado con perfil: perfil
export const getAvatar = perfil => (getTokenValue('nombre', perfil).charAt() + getCleanValue(getTokenValue('apellidos', perfil)).charAt()).toUpperCase()

// Devuelve las iniciales del usuario de la entidad user
export const getLetrasAvatarFromUser = (user) => (user) ? (user.nombre.charAt() + getCleanValue(user.apellidos).charAt()).toUpperCase() : "";

// Devuelve las iniciales del usuario users de la posición i
export const getLetrasAvatar = (users, i) => (users.length > 0 && users[i] != undefined) ? (users[i].nombre.charAt() + getCleanValue(users[i].apellidos).charAt()).toUpperCase() : "";

export const getUsuarioLogeado = (perfil, usuarioCompleto) => {

  const sizeMax = appConst.SIZE_HEADER_USER_LOGGED;
  const nombre = (getTokenValue("nombre", perfil)) ? getTokenValue("nombre", perfil) : "";
  const apellidos = (getTokenValue("apellidos", perfil))  ? getTokenValue("apellidos", perfil) : "";
  const empresa = (getTokenValue("nombreEmpresa", perfil)) ? getTokenValue("nombreEmpresa", perfil) : "sin empresa";
  const user = nombre + " " + apellidos + " (" + empresa + ")";
  if (user.length <= sizeMax || usuarioCompleto) return user;
  else return user.substring(0, sizeMax-3) + "...";
}

// Se devuelve el nombre, apellidos, cargo y si es admin del usuario pasado como parámetro en la posición del índice del array de usuarios
export const getUserNombreCargo = (users, i) => {
  try{
    if(users.length > 0 && users[i] != undefined){
      const nombre = (users[i].nombre) ? users[i].nombre : "";
      const apellidos = (users[i].apellidos) ? users[i].apellidos : "";
      const cargo = (users[i].cargo) ? users[i].cargo : "";
      return nombre + " " + apellidos + " (" + cargo.nombre + ") " + ((users[i].admin == appConst.ES_USER_ADMIN)? " [Administrador]" : "")
    }
  }catch{
    return "--";
  }
};

export const idUserAdmin = (users, i) => {
  if(users.length > 0 && users[i] != undefined)
    return ((users[i].admin == appConst.ES_USER_ADMIN) ? true : false)
};

// Se devuelve el id del usuario pasado como parámetro en la posición del índice del array de usuarios
export const getIdUser = (users, i) => {
    if(users.length > 0 && users[i] != undefined){
      return users[i].id
    }
};

// Se devuelve el id de la empresa del usuario pasado como parámetro en la posición del índice del array de usuarios
export const getIdEmpresaUser = (users, i) => {
  if(users.length > 0 && users[i] != undefined){
    return users[i].idEmpresa
  }
};

// Se devuelve el id que se construye para el check de un usuario pasado como parámetro en la posición del índice del array de usuarios
export const getIdCheckUser = (users, i) => getIdEmpresaUser(users, i) + "_" + getIdUser(users, i);

// Comprueba un NIF válido
export const validarNIF = nif => {
  nif = nif.toUpperCase().replace(/[\s\-]+/g, '');
  if(/^(\d|[XYZ])\d{7}[A-Z]$/.test(nif)) {
      var num = nif.match(/\d+/);
      num = (nif[0]!='Z'? nif[0]!='Y'? 0: 1: 2)+num;
      if(nif[8]=='TRWAGMYFPDXBNJZSQVHLCKE'[num%23]) {
          return /^\d/.test(nif)? 'DNI': 'NIE';
      }
  }
  else if(/^[ABCDEFGHJKLMNPQRSUVW]\d{7}[\dA-J]$/.test(nif)) {
      for(var sum=0,i=1;i<8;++i) {
          var num = nif[i]<<i%2;
          var uni = num%10;
          sum += (num-uni)/10+uni;
      }
      var c = (10-sum%10)%10;
      if(nif[8]==c || nif[8]=='JABCDEFGHI'[c]) {
          return /^[KLM]/.test(nif)? 'ESP': 'CIF';
      }
  }
  return false;
}

export const getPerfilByURL = url => {

  if (url.indexOf("/"+appConst.PREFIX_URL_ADMIN+"/") >= 0)
    return appConst.PERFIL_ADMIN

  else if (url.indexOf("/"+appConst.PREFIX_URL_PADRE+"/") >= 0)
    return appConst.PERFIL_PADRE

  else if (url.indexOf("/"+appConst.PREFIX_URL_HIJO+"/") >= 0)
    return appConst.PERFIL_HIJO

  else return -1;
}

export const getPrefixURL = perfil => {
        
  let retorno = "";
  if (perfil === appConst.PERFIL_ADMIN)
    retorno = appConst.PREFIX_URL_ADMIN;

  else if (perfil === appConst.PERFIL_PADRE)
    retorno = appConst.PREFIX_URL_PADRE;
    
  else if (perfil === appConst.PERFIL_HIJO)
    retorno = appConst.PREFIX_URL_HIJO;
  
  return retorno;
}

// Comprueba si un usuario es administrador de su empresa (puede modificar datos y recibir notificaciones)
export const isAdmin = perfil => (getTokenValue("admin", perfil) === appConst.ES_USER_ADMIN);

// Comprueba si un usuario está logeado en función del perfil solicitado y si tiene suficientes privilegios para acceder
export const isAuth = (acceso) => {
  if (acceso === getTokenValue("tipo", acceso))
    return true;
  else return false;
}

// Recupera el perfil logeado siempre que exista únicamente un token. En caso de estar logeado como padre e hijo,
// se redirige al login para que elija el usuario
export const getPerfilAuth = () => {
  
  const perfilPadre = getTokenValue("tipo", appConst.PERFIL_PADRE)
  const perfilHijo = getTokenValue("tipo", appConst.PERFIL_HIJO)

  // Si existen los dos o no existe ninguno no se devuelve nada
  if ( (perfilPadre && perfilHijo) || (!perfilPadre && !perfilHijo) )
    return;

  else if (perfilPadre) return perfilPadre;
  else if (perfilHijo) return perfilHijo;
}

export const replaceAll = (str, find, replace) => str.replace(new RegExp(find, 'g'), replace);

export const goExternalURL = arg => {

  const a = document.createElement("a");
  a.href = arg;
  a.target = "_blank";
  a.rel = "noopener noreferrer";
  a.click();
}

export const goAvisoLegal = () => goExternalURL(appConst.AVISO_LEGAL);

export const goPrivacidad = () => goExternalURL(appConst.POLITICA_PRIVACIDAD);

export const getFechaReg = (fechaCreaccion, fechaModificacion) => (fechaModificacion != undefined) ? formatearFechaCastellano(fechaModificacion, true) : formatearFechaCastellano(fechaCreaccion, true);

export const getIconoMatchById = idEstadoMatch => {

  let icono = "";
  switch (idEstadoMatch){
    case appConst.ESTADO_TRAN_BORRADOR:
      icono = appConst.ICONO_TRAN_BORRADOR;
      break;
    case appConst.ESTADO_TRAN_ENVIADO:
      icono = appConst.ICONO_TRAN_ENVIADO;
      break;
    case appConst.ESTADO_TRAN_MATCHEADO:
      icono = appConst.ICONO_TRAN_MATCH;
      break;
  }
  return icono;
}

export const cerrarMenuEmergente = (idOffCanvas) => {

  let offCanvas = document.querySelector("#"+idOffCanvas)
  let _offCanvas = bootstrap.Offcanvas.getInstance(offCanvas);
  _offCanvas.hide();
}

export const isSameTarget = target => (window.location.href.indexOf(target) >= 0) ? true : false;

// Método para evitar cargar una página ya abierta que muestre posibles componentes Alerts obsoletos (mensajes anteriores)
// Params:
//    hist:         this.props.history
//    target:       url de destino
export const goTarget = (hist, target) => {

  if (!isSameTarget(target))
      hist.push(target);
  else cerrarMenuEmergente("offcanvasMenu");
}

export const validarURL = url => (url && (url.indexOf("https://") == 0 || url.indexOf("http://") == 0)) ? url : "https://" + url;

export const copy = idBoton => window.setTimeout(() => cargarCopyToClipBoard(idBoton), 2000);

// Acciones después de cargarse el DOM de copiar al portapapeles desde un botón Copiar con id: idBoton
export const cargarCopyToClipBoard = idBoton => {
    
  const copiar = document.querySelector('#'+idBoton);
  if (copiar)
    copiar.addEventListener('click', function (e) {

      // Forma: <tag_padre> texto_a_copiar <tag_icono_copiar /> </tag_padre>
      // <span> mi_texto_a_copiar <span class='icon-copy icono-muy-peke hand' id='btnCopiar' title='Copiar'></span></span>

      if (copyToClipBoard(e.target.parentElement.innerText)){
        const original = e.target.innerText;
        e.target.innerText = original + " Copiado!";
        window.setTimeout(() => {e.target.innerText = original}, 2000);
      }
    });

  else if (getLog()) console.log("No se ha cargado el DOM para el btnCopiar");
}


// Acción de copiar al portapapeles
export const copyToClipBoard = contenido => {

  // Clipboard API no disponible
  if (!navigator.clipboard){
    console.log("Clipboard API no disponible");
    return;
  }

  navigator.clipboard.writeText(contenido)
    .then(() => {
      if (getLog()) console.log("Copiado!");
    })
    .catch(err => {
      if (getLog()) console.log('No ha sido posible copiar el texto', err);
    })
    return true;
}

export const getCleanValue = value => (value) ? value : "";

export const getIdUsuarioEmpresaParticipante = (participantes, tipo) => {

  let participante = participantes.filter(p => (p.tipoParticipante.id == tipo));
  return (participante[0] !== undefined) ? participante[0].usuarioEmpresa.id : "Sin usuario";
}

export const getIdParticipante = (participantes, tipo) => {

  let participante = participantes.filter(p => (p.tipoParticipante.id == tipo));
  return (participante[0] !== undefined) ? participante[0].id : "Sin participante";
}

export const getIdEmpresaParticipante = (participantes, tipo) => {

  let participante = participantes.filter(p => (p.tipoParticipante.id == tipo));
  return (participante[0] !== undefined) ? participante[0].empresa.id : "Sin empresa";
}

export const getNombreEmpresaParticipante = (participantes, tipo) => {

  let participante = participantes.filter(p => (p.tipoParticipante.id == tipo));
  return (participante[0] !== undefined) ? participante[0].empresa.nombre : "Sin empresa";
}

export const getNombreUsuarioEmpresaParticipante = (participantes, tipo) => {

  let participante = participantes.filter(p => (p.tipoParticipante.id == tipo));
  return (participante[0] !== undefined) ? participante[0].usuarioEmpresa.nombre+" "+getCleanValue(participante[0].usuarioEmpresa.apellidos)+" ("+participante[0].usuarioEmpresa.cargo.nombre+")" : "";
}

export const getLogoParticipante = (participantes, tipo) => {

  let participante = participantes.filter(p => (p.tipoParticipante.id == tipo));
  return (participante[0] !== undefined) ? participante[0].empresa.logo : "";
}

export const getIdTipoParticipanteByIdEmpresa = (participantes, idEmpresa) => {

  let participante = participantes.filter(p => (p.idEmpresa == idEmpresa));
  return (participante[0] !== undefined && participante.length == 1) ? participante[0].idTipoParticipante : "";
}

export const getEstadoParticipante = (participantes, tipo) => {

  let participante = participantes.filter(p => (p.tipoParticipante.id == tipo));
  return (participante[0] !== undefined && participante.length == 1) ? participante[0].estadoParticipante.nombre : "";
}

export const getEstadoLargoParticipante = (participantes, tipo) => {

  let participante = participantes.filter(p => (p.tipoParticipante.id == tipo));
  return (participante[0] !== undefined && participante.length == 1) ? participante[0].estadoParticipante.descripcion : "";
}

export const getIdEstadoParticipante = (participantes, tipo) => {

  let participante = participantes.filter(p => (p.tipoParticipante.id == tipo));
  return (participante[0] !== undefined && participante.length == 1) ? participante[0].estadoParticipante.id : "";
}

export const getUsuarioAdmin = (usuarios) => {

  let usuario = usuarios.filter(u => (u.admin == appConst.ES_USER_ADMIN));
  return (usuario[0] !== undefined) ? usuario[0] : null;
}

export const getURLParam = param => {

  if (getLog()) console.log("getURLParam::Param::"+param);
  let valor = "";
  const url = window.location.href;
  const params = url.split("?")[1];

  if (!params) 
    return valor;

  else{

    const parejasClaveValor = params.split("&");
    if (!parejasClaveValor)
      return valor;

    else{
        
      let claveValor = parejasClaveValor.filter(cv => cv.indexOf(param+"=") >= 0);
      
      if (claveValor){
        // Se convierte a String pq filter devuelve un Object
        claveValor = claveValor.toString();
        if (getLog()) console.log("getURLParam::valor::"+claveValor.split("=")[1]);
        return claveValor.split("=")[1];
      }
    }
  }
}

// window.location.href contiene la callback a ejecutar, y se coge su url relativa desde /#/ en adelante
export const getCallback = () => window.location.href.substring(window.location.href.indexOf("/#/") + 3);

export const withoutHtml = html => {
  
  var temporalDivElement = document.createElement("div");
  temporalDivElement.innerHTML = html;
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
}

export const getAtributeCombo = (idObj, atributo) => {

  const obj = document.querySelector('#'+idObj);
  const opcion = obj.options[obj.selectedIndex];
  if (getLog()) console.log("Atributo combo: "+ eval("opcion.dataset."+atributo) );
  return eval("opcion.dataset."+atributo);
}

export const getAtribute = (idObj, atributo) => {

  const obj = document.querySelector('#'+idObj);
  if (getLog()) console.log("Atributo: "+ eval(obj + '.dataset.' + atributo) );
  return eval(obj + '.dataset.' + atributo);
}

// Subir objeto a AWS S3: https://<mi_bucket>.s3-eu-west-3.amazonaws.com/
export const uploadFileToS3 = async (idFileInput) => {

  let file = document.querySelector("#"+idFileInput).files[0];
  let newFileName = document.querySelector("#"+idFileInput).files[0].name;
  let contentType = document.querySelector("#"+idFileInput).files[0].type;
  if (!contentType || appConst.MIMETYPE_IMAGES.indexOf(contentType) < 0) return -1;
  
  const config = {
    bucketName: process.env.REACT_APP_S3_BUCKET,
    dirName: process.env.REACT_APP_S3_DIR_NAME_LOGOS,
    region: process.env.REACT_APP_S3_REGION,
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY
  }

  const ReactS3Client = new S3(config);
  try{
    const response = await ReactS3Client.uploadFile(file, newFileName);
    if (response && response.status === 204){
      if (getLog()) console.log("Success");
      return response.location;

    }else return "";

  }catch(error){
    if (getLog()) console.log("Error AWS S3: "+error);
    return "";
  }
}

export const getStringURL = url => new URL(url).pathname;

export const quitarUltimoCaracter = data => (data.length > 0) ? data.substring(0, data.length-1) : data;
