import React, { Component } from 'react';
import {injectIntl, FormattedMessage as FM} from 'react-intl';
import Header from './../header/Header';
import Footer from './../footer/Footer';
import DetalleTexto from './DetalleTexto';

class MisTextos extends Component {

    constructor (props){
        super(props);
    }

    render(){ 
        return (
            <div>
                <Header />

                <div className="contenedorHijo">

                    {/* MIGAS DE PAN */}
                    <ol className="migaPan">
                        <li className="enLineaFin icon-text"> <FM id="app.padre.textos.title" /></li>
                    </ol>
                    
                    <p className="prosa mx-5"> <FM id="app.padre.textos.subtitle" /></p>

                    <div className="contenedor50">
                        <DetalleTexto />
                    </div>
                </div>

                <Footer />
            </div>
        )
    }
}

export default injectIntl(MisTextos); 