import React, { Component } from 'react';
import axios from 'axios';
import Header from './../header/Header';
import Footer from './../footer/Footer';
import * as appConst from './../../common/const/Const'
import * as utils from './../../common/utils/Funcs';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import Alert from './../../common/utils/Alert';
import Actividad from './../../hijo/miActividad/Actividad';

class ActividadEmpresa extends Component {

  constructor(props){
    super(props);
    this.state = {
      datos: [],
      textoAlert: '',
      tipoAlert: '',
      closeAlert: appConst.ALERT_OPEN
    };
  }

  componentDidMount(){

    this.listarActividad();
  }

  async listarActividad(){

    try{
      const response = await axios.get(appConst.GET_ACTIVIDADES_HIJO.replace("#idHijo", (this.props.location.state)?this.props.location.state.idHijo:"No se ha podido obtener la actividad de esta empresa"), {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE), params: {
        excluirBorrador: true
      }});
      
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
        if (utils.getLog()) console.log("status GET_ACTIVIDADES_HIJO: "+response.status+". Actividades cargadas con éxito");
        this.setState({ 
          datos: response.data
        });
          
      }else if (response && response.status === appConst.HTTPSTATUS_NO_CONTENT){
        if (utils.getLog()) console.log("status GET_ACTIVIDADES_HIJO: "+response.status+". No hay actividades.");
        this.setState({ 
          datos: [],
          textoAlert: <FM id="app.padre.mi-mundo.actividades.sin-datos" />,
          tipoAlert: appConst.ALERT_TIPO_WARNING,
          closeAlert: appConst.ALERT_OPEN
        });
      }

    }catch(error){
        if (utils.getLog()) console.log("Error GET_ACTIVIDADES_HIJO: "+error);
        this.setState({
            textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.listarActividad, [], appConst.PERFIL_PADRE),
            tipoAlert: utils.getTipoAlert(error),
            closeAlert: appConst.ALERT_OPEN
        });
    }
  }

  showMensaje(texto, tipo){

    this.setState({
      textoAlert: texto,
      tipoAlert: tipo
    });
  }
  
  render(){ 
    return (
      <div>
        <Header itemMenu={appConst.MENU_PADRE_MI_MUNDO}/>
        
        <div className="contenedorPadre">

          {/* MIGAS DE PAN */}
          <ol className="migaPan">
              <li className="icon-globe enLinea"> <Link to={process.env.REACT_APP_WEB_BASE_URL+"/p/mi-mundo"} className="link"><FM id="app.padre.mi-mundo.title" /></Link></li>
              <li className="icon-list enLineaFin"> <FM id="app.padre.mi-mundo.actividades.title" values={{ valor: (this.props.location.state) ? <i>{this.props.location.state.nombreEmpresa}</i> : <i className='prosa'> (no se ha podido cargar el nombre de la empresa)</i> }}/></li>
          </ol>
          
          <div className="contenedor mx-auto pt-2">

            <Alert texto={this.state.textoAlert} tipo={this.state.tipoAlert} close={this.state.closeAlert}/>
            
            {/* LISTA DE ACTIVIDAD (todas las actividades excepto las de estado borrador para tipo match) */}
            {this.state.datos.map(a => 
              <Actividad
                id={a.id}
                key={a.id}
                nombreEmpresa={this.nombreEmpresa}
                fecha={a.fecha}
                descripcion={a.descripcion}
                idActividad={a.idActividad}
                nombre={a.actividad.nombre}
                color={a.actividad.color}
                idTransaccion={a.idTransaccion}
                transaccion={a.transaccion}
                idAutor={a.idAutor}
                fechaCreacionReg={a.fechaCreacion}
                fechaModifReg={a.fechaModif}
                perfil={appConst.PERFIL_PADRE}
                _listarActividad={this.listarActividad.bind(this)}
                _showMensaje={this.showMensaje.bind(this)}
              />)
            }

          </div>
        </div>

        <Footer patrocinadores={appConst.OCULTAR_PATROCINADORES} />
      </div>
    )
  }
}

export default injectIntl(ActividadEmpresa);