import { Component } from 'react';
import axios from 'axios';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import * as appConst from './../common/const/Const';
import * as utils from './../common/utils/Funcs';
import Alert from './../common/utils/Alert';
import HeaderGuess from './../common/header/HeaderGuess';

class NuevaPass extends Component {

    constructor(props){
        super(props);
        this.state = {
            campo: {},
            error: {},
            uuid: '',
            textoAlert: '',
            tipoAlert: '',
            closeAlert: appConst.ALERT_OPEN
        }
        this.clickAceptar = this.clickAceptar.bind(this);
    }

    componentDidMount(){
        // Se extrae de la URL el uuid del usuario que solicita la modificación de su contraseña
        const param = this.props.location.search.split("?")[1];
        if (param && param.split("=")[0] === "id" && param.split("=")[1])
            this.setState({ uuid: param.split("=")[1] });
    }

    validarFormulario() {
        let campo = this.state.campo;
        let error = {};
        let formularioValido = true;
        
        // Campo Password
        if (!campo["txtPass"]) {
            formularioValido = false;
            error["txtPass"] = <FM id='app.general.nuevaPass.aviso.pass1Vacia' values={{ valor: appConst.PASSWORD_SIZE }}/>;
        }else if (campo['txtPass'].length < appConst.PASSWORD_SIZE) {
            // Tamaño de contraseña insuficiente
            formularioValido = false;
            error["txtPass"] = <FM id='app.general.nuevaPass.contrasenaMinCaracteres' values={{ valor: appConst.PASSWORD_SIZE }}/>;
        }

        // Campo Password repetida
        if (!campo["txtPassRepe"]) {
            formularioValido = false;
            error["txtPassRepe"] = <FM id='app.general.nuevaPass.aviso.pass2vacia'/>;
        
        }else if (campo['txtPass'] !== campo['txtPassRepe']) {
            // Passwords diferentes
            formularioValido = false;
            error["txtPassRepe"] = <FM id='app.general.nuevaPass.aviso.passDiferentes'/>;
        }
        
        // Seteo el estado de error 
        if (!formularioValido)
            this.setState({
                error,
                textoAlert: <FM id="app.general.form.revisarCampos" />,
                tipoAlert: appConst.ALERT_TIPO_WARNING
            });
    
        // Se limpian las validaciones por cada campo
        else this.setState({ error: {} });
        
        return formularioValido;
    }

    // Se detecta cuando un campo del formulario es rellenado y por ende cambia de estado 
    detectarCambio(field, e) {
        let campo = this.state.campo;
        campo[field] = e.target.value;
        
        // Cambio de estado de campo 
        this.setState({
          campo,
          textoAlert: '',
          tipoAlert: ''
        });
    }

    clickAceptar(){

        if ( this.validarFormulario() ){
            this.actualizarPassword();
        }
    }

    async actualizarPassword(){

        if (this.state.uuid){
            
            const data = {
                "password": this.state.campo["txtPass"]
            }

            try{
                const response = await axios.put(appConst.PUT_PASS_USUARIO.replace("#idUsuario", this.state.uuid), data, {headers: utils.getHeaders(appConst.HEADER_SIN_TOKEN)} )
                if (response && response.status === appConst.HTTPSTATUS_OK){
                    if (utils.getLog()) console.log("Status PUT_PASS_USUARIO: "+response.status);
                    this.setState({ 
                        textoAlert: <FM id="app.general.nuevaPass.aviso.passActualizada" />,
                        tipoAlert: appConst.ALERT_TIPO_SUCCESS,
                        closeAlert: appConst.ALERT_CLOSE
                    });
                }
            }catch(error){
                if (utils.getLog()) console.log("Error PUT_PASS_USUARIO: "+error);
                this.setState({
                    textoAlert: utils.getTextError(error, this.props.intl, this.props.history),
                    tipoAlert: utils.getTipoAlert(error),
                    closeAlert: appConst.ALERT_OPEN
                });
            }
        
        // No llega el uuid del usuario por la url
        }else{
            this.setState({ 
                textoAlert: <FM id="app.general.nuevaPass.aviso.faltanParametros" />,
                tipoAlert: appConst.ALERT_TIPO_WARNING,
                closeAlert: appConst.ALERT_OPEN
            });
        }
    }

    render(){
        return (
            <div>

                <HeaderGuess />

                {/* INI CONTENEDOR */}
                <div className="contenedorHijo">

                    <div className="row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10">
                            <Alert texto={this.state.textoAlert} tipo={this.state.tipoAlert} close={this.state.closeAlert}/>
                        </div>
                        <div className="col-sm-1"></div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 p5 text-center">
                            <h5><FM id="app.general.nuevaPass.intro" /></h5><br />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            
                            <div className="row">
                                <div className="col-md-4"></div>

                                <div className="col-md-4">
                                    <form role="form">

                                        {/* PASSWORD */}
                                        <div className="form-group">
                                            <label className="label-form"><FM id='app.general.nuevaPass.label.contrasena'/></label>
                                            <input type="password" className="input-css form-control" name="txtPass" id="txtPass" maxLength="100" onChange={this.detectarCambio.bind(this, "txtPass")} value={this.state.campo["txtPass"]} />
                                            <label className="form-error">{this.state.error["txtPass"]}</label>
                                        </div>

                                        {/* PASSWORD REPE */}
                                        <div className="form-group">
                                            <label className="label-form"><FM id='app.general.nuevaPass.label.contrasenaRepe'/></label>
                                            <input type="password" className="input-css form-control" name="txtPassRepe" id="txtPassRepe" maxLength="100" onChange={this.detectarCambio.bind(this, "txtPassRepe")} value={this.state.campo["txtPassRepe"]} />
                                            <label className="form-error">{this.state.error["txtPassRepe"]}</label>
                                        </div>

                                        <div className="text-center">
                                            <button type="button" className="btn btn-primary" onClick={this.clickAceptar}><FM id="app.general.nuevaPass.boton.cambiarContrasena" /></button><br /><br />
                                            <Link to={process.env.REACT_APP_WEB_BASE_URL + "/login"} className="link">Acceder a Finder</Link>
                                        </div>
                                    </form>
                                </div>

                                <div className="col-md-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* FIN CONTENEDOR */}

            </div>
        )
    }
}

export default injectIntl(withRouter(NuevaPass));