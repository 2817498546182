import React, { Component } from 'react';
import axios from 'axios';
import * as appConst from './../const/Const'
import * as utils from './../utils/Funcs';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import FooterPadre from './../../padre/footer/Footer';
import FooterHijo from './../../hijo/footer/Footer';

class Bienvenida extends Component {

  constructor(props){
    super(props);
    this.state = {
      perfil: utils.getPerfilByURL(window.location.href),
      prefixURL: utils.getPrefixURL(utils.getPerfilByURL(window.location.href)),
      validacion: appConst.EMPRESA_SIN_VALIDACION,
    }
    this.acceder = this.acceder.bind(this);
  }

  componentDidMount(){

    this.validacion();
  }

  async validacion(){
      
    try{
      const response = await axios.get(appConst.GET_EMPRESA_BY_ID.replace("#idEmpresa", utils.getTokenValue("idEmpresa", this.state.perfil)), {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, this.state.perfil)})
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
          if (utils.getLog()) console.log("Status GET_EMPRESA_BY_ID: "+response.status+". Validación empresa post login: "+response.data.validacion);
          
          this.setState({validacion: response.data.validacion});
      }
    }catch(error){
      if (utils.getLog()) console.log("Error GET_EMPRESA_BY_ID: "+error);
    }
  }

  acceder(){

    if (utils.isAdmin(this.state.perfil) && this.state.validacion === appConst.EMPRESA_VALIDACION_KO)
      this.props.history.push(process.env.REACT_APP_WEB_BASE_URL+'/'+this.state.prefixURL+'/mi-perfil');
    else
      this.props.history.push(process.env.REACT_APP_WEB_BASE_URL+'/'+this.state.prefixURL+'/mi-mundo');
  }

  render(){
    return (
      <div>
        
        <div className="contenedor">
          
          <div className="contenedor mt-4">

            <div className="row text-center fs-4 ps-3 ms-3 pb-5">
              
              <div className='col-sm-12'>

                <div className="row ps-3 ms-3 align-items-center">
                  <div className='col-sm-6 px-4 text-end'>
                    <p><strong>Kaixo {utils.getTokenValue("nombre", this.state.perfil)}!</strong><br/>Qué bien tenerte por aquí.</p>
                  </div>

                  <div className='col-sm-6 px-4 text-start'>
                    {/* SOLO PARA PADRES */}
                    {this.state.perfil === appConst.PERFIL_PADRE &&
                      <img src={utils.getTokenValue("logoEmpresa", this.state.perfil)} className="imgEmpresaGrande"/>
                    }
                    {/* SOLO PARA HIJOS */}
                    {this.state.perfil === appConst.PERFIL_HIJO &&
                      <img src={utils.getTokenValue("logoPadre", this.state.perfil)} className="imgEmpresaGrande"/>
                    }
                  </div>
                </div>
              </div>

            </div>
            
            <div className="row px-3 mx-3 mb-5">
              <div className='col-sm-4'>
                <img src={appConst.IMG_BIENVENIDA} className="imgBienvenida"/>
              </div>
              <div className='col-sm-8'>
                <p className='prosa'><strong>Finder</strong> es una red de crecimiento empresarial que conecta necesidad con oportunidad, con el objetivo de buscar la mejora conjunta de todos sus integrantes. Un <strong>ecosistema de empresas, organismos públicos y privados, banca e inversores que forman una estructura premium</strong>, con el fin de crear futuro empresarial.</p>
                {/* SOLO PARA PADRES */}
                {this.state.perfil === appConst.PERFIL_PADRE &&
                  <p className='prosa'><strong>Tu papel aquí es muy relevante</strong> porque podrás dinamizar las conexiones entre las empresas de tu mundo, actuando como un <strong>perfil moderador</strong>, para generar futuro empresarial en el <strong>territorio de Bizkaia</strong>.</p>
                }
                <p className='prosa'>
                    <ul>
                      <FM id="app.manualUsuario.paraque.descripcion" values={{intro: <br/>}}/>
                    </ul>
                  </p>
                {/* SOLO PARA HIJOS */}
                {this.state.perfil === appConst.PERFIL_HIJO && 
                  <li className="icon-info hand item-menu-user text-end" onClick={() => window.location.href = process.env.REACT_APP_WEB_SERVER + process.env.REACT_APP_WEB_BASE_URL+'#/' + appConst.PREFIX_URL_HIJO + '/manual-usuario'}> <FM id='app.manualUsuario.bienvenida.masinfo' /></li>
                }
              </div>
            </div>

            <div className="row text-center ps-5 ms-5">
              <div className='col-sm-12'>
                <button className='btn btn-secondary icon-heart-full' onClick={this.acceder}> Empezar</button>
              </div>
            </div>

          </div>
        </div>

        {/* SOLO PARA PADRES */}
        {this.state.perfil === appConst.PERFIL_PADRE && <FooterPadre />}
        {/* SOLO PARA HIJOS */}
        {this.state.perfil === appConst.PERFIL_HIJO && <FooterHijo />}
      </div>
    )
  }
}

export default injectIntl(withRouter(Bienvenida));