import React, { Component } from 'react';
import axios from 'axios';
import Header from './../header/Header';
import Footer from './../footer/Footer';
import * as appConst from './../../common/const/Const'
import * as utils from './../../common/utils/Funcs';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import Alert from './../../common/utils/Alert';
import Actividad from './Actividad';

class MiActividad extends Component {

  constructor(props){
    super(props);
    this.state = {
      datos: [],
      textoAlert: '',
      tipoAlert: '',
      closeAlert: appConst.ALERT_OPEN
    };
    this.detalleActividad = this.detalleActividad.bind(this);
  }

  componentDidMount(){

    this.listarActividad();
  }

  async listarActividad(){

    try{
      // Se excluyen los borradores de matches ya que éstos son creados solo por padres y aún no han sido lanzados
      const response = await axios.get(appConst.GET_ACTIVIDADES_HIJO.replace("#idHijo", utils.getTokenValue("idHijo", appConst.PERFIL_HIJO)), {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_HIJO), params: {
        excluirBorrador: true
      }});
      
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
        if (utils.getLog()) console.log("status GET_ACTIVIDADES_HIJO: "+response.status+". Actividades cargadas con éxito");
        this.setState({ 
          datos: response.data,
          textoAlert: "",
          tipoAlert: "",
          closeAlert: appConst.ALERT_CLOSE
        });

      }else if (response && response.status === appConst.HTTPSTATUS_NO_CONTENT){
        if (utils.getLog()) console.log("status GET_ACTIVIDADES_HIJO: "+response.status+". No hay actividades.");
        this.setState({ 
          datos: [],
          textoAlert: this.props.intl.formatMessage({id:"app.hijo.lista-actividades.listado.sin-datos"}),
          tipoAlert: appConst.ALERT_TIPO_WARNING,
          closeAlert: appConst.ALERT_OPEN
        });
      }

    }catch(error){
        if (utils.getLog()) console.log("Error GET_ACTIVIDADES_HIJO: "+error);
        this.setState({
            textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.listarActividad, [], appConst.PERFIL_HIJO),
            tipoAlert: utils.getTipoAlert(error),
            closeAlert: appConst.ALERT_OPEN
        });
    }
  }

  detalleActividad(){

    this.props.history.push(process.env.REACT_APP_WEB_BASE_URL+"/h/crearActividad");
  }

  showMensaje(texto, tipo, close){
    
    this.setState({
      textoAlert: texto,
      tipoAlert: tipo,
      closeAlert: close
    });
  }

  render(){ 
    return (
      <div>
        <Header itemMenu={appConst.MENU_HIJO_MI_ACTIVIDAD}/>
        
        <div className="contenedorHijo">

          {/* MIGAS DE PAN */}
          <ol className="migaPan">
              <li className="enLineaFin icon-list"> <FM id="app.hijo.lista-actividades.title" /></li>
          </ol>
          
          <div className="contenedor">
            
            <Alert texto={this.state.textoAlert} tipo={this.state.tipoAlert} close={this.state.closeAlert} />

            <div className="mx-auto">

              {/* CREAR ACTIVIDAD */}
              <div className="row text-end" id="capaBtnCrear">
                <div className="cols-m-12">
                  <button type="button" className="btn btn-sm btn-primary" onClick={this.detalleActividad}><span className="label-button"><FM id='app.hijo.lista-actividades.botonera.crear'/></span></button>
                </div>
              </div>
              
              {/* LISTA DE ACTIVIDAD (todas las actividades + las actividades de tipo match que no sean borrador. Ojo pq a.transaccion puede ser null para todas las actividades distintas de match) */}
              {/*this.state.datos.map(a => (a.transaccion == null || a.transaccion.idEstadoTransaccion !== appConst.ESTADO_TRAN_BORRADOR) &&*/}
              {this.state.datos.map(a => 
                <Actividad
                  id={a.id}
                  key={a.id}
                  fecha={a.fecha}
                  descripcion={a.descripcion}
                  idActividad={a.idActividad}
                  nombre={a.actividad.nombre}
                  color={a.actividad.color}
                  idTransaccion={a.idTransaccion}
                  transaccion={a.transaccion}
                  idAutor={a.idAutor}
                  fechaCreacionReg={a.fechaCreacion}
                  fechaModifReg={a.fechaModif}
                  perfil={appConst.PERFIL_HIJO}
                  _listarActividad={this.listarActividad.bind(this)}
                  _showMensaje={this.showMensaje.bind(this)}
                />)
              }

            </div>
          </div>
        </div>

        <Footer patrocinadores={appConst.MOSTRAR_PATROCINADORES} />
      </div>
    )
  }
}

export default injectIntl(MiActividad);