import React, { Component } from 'react';
import { injectIntl,  FormattedMessage as FM } from 'react-intl';
import { withRouter } from 'react-router-dom';
import * as utils from './../../common/utils/Funcs';
import DOMPurify from 'dompurify';

class FichaEnvio extends Component{

    constructor(props){
        super(props);
    }

    render(){ 
        return (

            <div className="mb-4">

                {/* CABECERA */}
                <div className="row headerCajaMatch">
                    <div className="col-md-9 text-start">
                        <span className="tamTitle icon-mail"> {this.props.tipo}</span>
                    </div>
                    <div className="col-md-3 text-end pe-4">
                        {utils.formatearFechaCastellano(this.props.fecha, true)}
                    </div>
                </div>
                
                {/* CUERPO */}
                <div className="row bodyCajaMatch">
                    
                    {/* ASUNTO Y CUERPO */}
                    <div className="col-md-10 px-5">
                        <p className="text-justify">
                            <p><span className="prosa tamTitle fw-bold">{this.props.asunto}</span></p>
                            <p><span className="prosa" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.cuerpo)}}></span></p>
                        </p>
                    </div>

                    {/* PARTICIPANTES */}
                    <div className="col-md-2 px-4 bordeIzqAzul text-center">
                        <div className='hand' data-bs-toggle="modal" data-bs-target={"#modalDestinatarios"+this.props.idEnvio}>
                            <span className='icon-user icono-medio'></span><br/>
                            <span className='prosa'> Destinatarios</span>
                        </div>
                    </div>

                </div>

                {/* POPUP DESTINATARIOS */}
                <div className="modal fade" id={"modalDestinatarios"+this.props.idEnvio} tabindex="-1" aria-labelledby="modalDestinatariosLabel" aria-hidden="true" data-bs-backdrop="true">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                    <div className="modal-content px-3">
                    <div className="modal-header">
                        <h5 className="modal-title icon-user" id="modalDestinatariosLabel"> <FM id="app.padre.mis-envios.popup.destinatarios.title" /> {" ("+this.props.participantes.length+")"}</h5>
                        <button id="btnCerrarModal" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    
                    <div className="modal-body text-justify">

                        {/* CUERPO */}
                        <div className='pb-3'>
                            <span className="prosa"><FM id="app.padre.mis-envios.popup.destinatarios.descripcion" />:  </span>
                            <p className='prosa'><strong>{this.props.asunto}</strong></p>
                        </div>

                        {/* Lista de destinatarios */}
                        {this.props.participantes.length == 0 && <span className='prosa'><FM id="app.padre.mis-envios.popup.destinatarios.sin-destinatarios" /></span>}
                        {this.props.participantes?.map(p => {
                            return(
                                <div className='row my-3'>
                                    <div className='col-sm-1'></div>
                                    <div className='col-sm-3 pt-2 text-center'>
                                        <img src={p.empresa.logo} className="imgEmpresaPeke"/>
                                    </div>
                                    <div className='col-sm-7'>
                                        <label className='prosa'><strong>{p.empresa.nombre}</strong></label><br/>
                                        <span className='icon-user prosa'> {p.usuarioEmpresa.nombre + " " +utils.getCleanValue(p.usuarioEmpresa.apellidos) + " (" + p.usuarioEmpresa.cargo.nombre +")"}</span><br/>
                                        <span className='icon-mail prosa'> {p.usuarioEmpresa.email}</span>
                                    </div>
                                    <div className='col-sm-1'></div>
                                </div>
                            );
                        })}

                    </div>
                    
                    <hr className="text-muted"/>
                    <div className="mb-3">
                        
                        <div className="float-end align-middle">
                            <button type="button" className="btn btn-sm btn-secondary me-3" data-bs-dismiss="modal"><FM id="app.padre.mis-envios.popup.destinatarios.botonera.cerrar" /></button>
                        </div>
                    </div>

                    </div>
                </div>
                </div>
                {/* FIN POPUP DESTINATARIOS */}

            </div>
        )
    }
}

export default injectIntl(withRouter(FichaEnvio));