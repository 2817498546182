import { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import * as utils from './../../common/utils/Funcs';
import Badge from './../../common/utils/Badge';
import * as appConst from './../../common/const/Const';
import Notificaciones from './../../common/utils/Notificaciones';

class Header extends Component{

    constructor (props){
        super(props);
        this.miPerfil = this.miPerfil.bind(this);
        this.showManualUsuario = this.showManualUsuario.bind(this);
        this.logout = this.logout.bind(this);
    }

    componentDidMount(){

        // Inicialización de los tooltip de este componente
        utils.iniTooltip();
        
        // Selección del menú cargado por defecto
        this.selMenu(this.props.itemMenu);
    }

    getUserLoged(){
        return utils.getUsuarioLogeado(appConst.PERFIL_HIJO);
    }
    
    getEmailLogged(){
        return utils.getTokenValue("sub", appConst.PERFIL_HIJO);
    }

    selMenu(item){

        const NAME_LINK = "m";
        const NAME_HR = "hr-item";
        if(item){
            document.querySelector("#"+NAME_LINK+item).className = "link menu-item-sel";
            document.querySelector("#"+NAME_HR+item).className = "item-hr-sel item-hr-sel-long";
        }
    }

    miPerfil(){
        
        utils.hideTooltip("btnPerfil");
        utils.goTarget(this.props.history, process.env.REACT_APP_WEB_BASE_URL+"/h/mi-perfil");
    }

    showManualUsuario(){
        
        utils.hideTooltip("btnManualUsuario");
        utils.goTarget(this.props.history, process.env.REACT_APP_WEB_BASE_URL+"/h/manual-usuario");
    }

    logout(){

        utils.hideTooltip("btnDesconectar");
        utils.doLogout(this.props.history, appConst.PERFIL_HIJO);
    }

    render(){
        return(
            <header className="container-fluid">
                <div className="row cabecera py-2">
                    {/* ZONA LOGO EMPRESA */}
                    <div className="col-sm-2 align-self-center ps-5">
                        <span><img src={utils.getTokenValue("logoEmpresa", appConst.PERFIL_HIJO)} width='35' height='35' alt='logo'/></span>
                    </div>

                    {/* ZONA MENU */}
                    <div className="col-sm-6 align-self-center">
                        <div className="row px-4 mx-auto">
                            
                            {/* Quitar la siguiente línea si existe menú Colaboradores */}
                            <div className="col-sm-4"></div>
                            {/************************************************************/}
                            
                            <div className="col-sm-4">
                                <Link id="m1" style={{ textDecoration: 'none' }} to={process.env.REACT_APP_WEB_BASE_URL+"/h/mi-mundo"} className="link menu-item"><span className="icon-globe" id="menu1"> <FM id='app.hijo.cabecera.menu.miMundo' /></span></Link>
                                <div className="item-hr-sel item-hr-sel-long hideElement" id="hr-item1"></div>
                            </div>
                            <div className="col-sm-4">
                                <Link id="m2" style={{ textDecoration: 'none' }} to={process.env.REACT_APP_WEB_BASE_URL+"/h/lista-actividades"} className="link menu-item"><span className="icon-list" id="menu2"> <FM id='app.hijo.cabecera.menu.miActividad' /></span></Link>
                                <div className="item-hr-sel item-hr-sel-long hideElement" id="hr-item2"></div>
                            </div>
                            {/*<div className="col-sm-4">
                                <Link id="m3" style={{ textDecoration: 'none' }} to={process.env.REACT_APP_WEB_BASE_URL+"/h/patrocinadores"} className="link menu-item"><span className="icon-info" id="menu3"> <FM id='app.hijo.cabecera.menu.patrocinadores' /></span></Link>
                                <div className="item-hr-sel item-hr-sel-long hideElement" id="hr-item3"></div>
                            </div>*/}
                        </div>
                    </div>
                    
                    {/* ZONA LOGO PADRE */}
                    <div className="col-sm-1 capa-perfil text-center">
                        <a href={utils.getTokenValue("serviciosPadre", appConst.PERFIL_HIJO)} target="_blank" className="linkFFF">
                            <img src={utils.getTokenValue("logoPadre", appConst.PERFIL_HIJO)} width='35' height='35' alt='logo' data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-html="true" title={"<p>Ver servicios de "+utils.getTokenValue("nombrePadre", appConst.PERFIL_HIJO)+"</p><p>"+utils.getTokenValue("nombreEmpresa", appConst.PERFIL_HIJO)+" empresa miembro de "+utils.getTokenValue("nombrePadre", appConst.PERFIL_HIJO)+"</p>"} />
                        </a>
                    </div>

                    {/* ZONA USUARIO LOGEADO */}
                    <div className="col-sm-3 p-1 capa-perfil">
                        
                        <Notificaciones 
                            perfil={appConst.PERFIL_HIJO}
                            funcs={[
                                { n: "notificacionDatosEmpresa" },
                                { n: "tienesUnMatch" },
                                { n: "notificacionNecesidadesEmpresa" },
                                { n: "notificacionAreasInteresEmpresa" }
                            ]}
                        />

                        <span className="ps-3"><label className="avatar">{utils.getAvatar(appConst.PERFIL_HIJO)}</label></span>
                        
                        {/* MENÚ EMERGENTE */}
                        <button id="btnMenu" className="btn py-0 px-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenu" aria-controls="offcanvasMenu">
                            <span className="hand" id="userLogged">{this.getUserLoged()}</span>
                        </button>
                        <div className="offcanvas offcanvas-end ms-4 px-4" data-bs-scroll="true" tabindex="-1" id="offcanvasMenu" aria-labelledby="offcanvasMenuRightLabel">
                            <div className="offcanvas-header">
                                <h5 id="offcanvasMenuRightLabel">Menú</h5>
                                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div className="separador"></div>
                            <div className="offcanvas-body">
                                
                                {/* TRATAMIENTO DE LOGO DE EMPRESA */}
                                <div className="text-center pb-3">
                                    {/* CON LOGO */}
                                    {(utils.getStringURL(utils.getTokenValue("logoEmpresa", appConst.PERFIL_HIJO)) != utils.getStringURL(appConst.SIN_LOGO_EMPRESA)) && <img src={utils.getTokenValue("logoEmpresa", appConst.PERFIL_HIJO)} className="imgEmpresaMedio" />}
                                    {/* SIN LOGO (siendo admin) */}
                                    {(utils.getStringURL(utils.getTokenValue("logoEmpresa", appConst.PERFIL_HIJO)) === utils.getStringURL(appConst.SIN_LOGO_EMPRESA) && utils.isAdmin(appConst.PERFIL_HIJO)) && 
                                        <span className="position-relative hand" onClick={() => {utils.hideTooltip("logo"); utils.goTarget(this.props.history, process.env.REACT_APP_WEB_BASE_URL+"/h/mi-perfil?2")}}>
                                            <img src={utils.getTokenValue("logoEmpresa", appConst.PERFIL_HIJO)} className="imgEmpresaMedio"/>
                                            <Badge 
                                                id={"logo"}
                                                tooltip={"Añade un logo a tu empresa"}
                                                icono={"icon-plus"}
                                                fondo={"bg-success"}
                                            />
                                        </span>}
                                    {/* SIN LOGO (no siendo admin) */}
                                    {(utils.getStringURL(utils.getTokenValue("logoEmpresa", appConst.PERFIL_HIJO)) === utils.getStringURL(appConst.SIN_LOGO_EMPRESA) && !utils.isAdmin(appConst.PERFIL_HIJO)) && 
                                        <img src={utils.getTokenValue("logoEmpresa", appConst.PERFIL_HIJO)} className="imgEmpresaMedio"/>
                                    }
                                </div>

                                {/* Opciones de menú */}
                                <div className="row">
                                    <div className="col-sm-12">
                                        <li id="btnPerfil" className="icon-user hand item-menu-user" onClick={this.miPerfil} data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="left" title={this.props.intl.formatMessage( {id:'app.hijo.cabecera.menu.emergente.miPerfil.tooltip'} ) + " <br/><i><strong>" + this.getEmailLogged()+"</i></strong>"}> <FM id='app.hijo.cabecera.menu.emergente.miPerfil' /></li>
                                        {/*<li id="btnManualUsuario" className="icon-info hand item-menu-user" onClick={this.showManualUsuario} data-bs-toggle="tooltip" data-bs-placement="left" title={this.props.intl.formatMessage( {id:'app.hijo.cabecera.menu.emergente.manualUsuario.tooltip'} )}> <FM id='app.hijo.cabecera.menu.emergente.manualUsuario' /></li>*/}
                                        <li id="btnDesconectar" className="icon-logout hand item-menu-user" onClick={this.logout} data-bs-toggle="tooltip" data-bs-placement="left" title={this.props.intl.formatMessage( {id:'app.hijo.cabecera.menu.emergente.desconectar.tooltip'} )}> <FM id='app.hijo.cabecera.menu.emergente.desconectar' /></li>
                                    </div>
                                </div>

                                {/* Pie del menú: Ayuda y logo Finder */}
                                <div className='row fijarAbajo'>
                                    <div className='col-sm-8 pb-3'>
                                        <li id="btnManualUsuario" className="icon-info hand item-menu-user" onClick={this.showManualUsuario} data-bs-toggle="tooltip" data-bs-placement="left" title={this.props.intl.formatMessage( {id:'app.hijo.cabecera.menu.emergente.manualUsuario.tooltip'} )}> <FM id='app.hijo.cabecera.menu.emergente.manualUsuario' /></li>
                                    </div>
                                    <div className='col-sm-4 pb-3 text-end'>
                                        <img src={appConst.URL_LOGO_FINDER} className="imgEmpresaMedio"/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default injectIntl(withRouter(Header));