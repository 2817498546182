import React, { Component } from 'react';
import axios from 'axios';
import Header from './../header/Header';
import Footer from './../footer/Footer';
import { Link } from 'react-router-dom';
import * as appConst from './../../common/const/Const'
import * as utils from './../../common/utils/Funcs';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import CargarMaestroDatos from './../../common/datos/CargarMaestroDatos';
import CargarListaDatos from './../../common/datos/CargarListaDatos';
import Alert from './../../common/utils/Alert';
// DatePicker
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
registerLocale("es", es);

class DetalleActividad extends Component {

  constructor(props){
    super(props);
    this.state = {
      campo: [],
      error: [],
      textoAlert: "",
      tipoAlert: "",
      closeAlert: appConst.ALERT_OPEN
    }
    this.guardarActividad = this.guardarActividad.bind(this);
  }

  componentDidMount(){

    // La operación es EDICION si llegan datos de la actividad con su id
    if (this.props.operacion === appConst.OPERACION_EDITAR && this.props.location.state && this.props.location.state.id)
      this.precargarActividad();
  }

  precargarActividad(){

    let campo = this.state.campo;
    campo["txtId"] = this.props.location.state.id;
    campo["txtFecha"] = this.props.location.state.fecha;
    campo["txtDescripcion"] = this.props.location.state.descripcion;
    campo["cmbActividad"] = this.props.location.state.idActividad;
    campo["cmbMatches"] = this.props.location.state.idTransaccion;
    
    // Se muestra y asigna el valor del match si es que tiene
    if (campo["cmbMatches"])
      document.querySelector("#capaMatches").className = "row pt-2";
    
    // A pesar de haber actualizado el estado de la descripción, se debe pintar en el textarea
    if (document.querySelector("#txtDescripcion"))
      document.querySelector("#txtDescripcion").value = campo["txtDescripcion"];
    
    this.setState({ campo });
  }

  guardarActividad(){

    if (this.esFormValido()){

      const data = {
        "id": this.state.campo["txtId"],
        "idHijo": utils.getTokenValue("idHijo", appConst.PERFIL_HIJO),
        "fecha": this.state.campo["txtFecha"],
        "idActividad": this.state.campo["cmbActividad"],
        "descripcion": this.state.campo["txtDescripcion"],
        "idTransaccion": this.state.campo["cmbMatches"],
        "idAutor": utils.getTokenValue("idUsuario", appConst.PERFIL_HIJO)
      }
      //console.log(data);

      if (this.props.operacion === appConst.OPERACION_NUEVO)
        this.nueva(data);

      else if (this.props.operacion === appConst.OPERACION_EDITAR)
        this.edicion(data);
    }
  }

  async nueva(data){

    try{
      const response = await axios.post(appConst.POST_ACTIVIDAD_HIJO, data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_HIJO)})
      if (response && response.data && response.status === appConst.HTTPSTATUS_CREATED){
        if (utils.getLog()) console.log("Status POST_ACTIVIDAD_HIJO: "+response.status+ ". Actividad guardada con éxito");
        this.volverListado();
      }
    }catch(error){
      if (utils.getLog()) console.log("Error POST_ACTIVIDAD_HIJO: "+error);
      this.setState({
        textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.nueva.bind(this), [data], appConst.PERFIL_HIJO),
        tipoAlert: utils.getTipoAlert(error),
        closeAlert: appConst.ALERT_OPEN
      });
    }
  }

  async edicion(data){

    try{
      const response = await axios.put(appConst.PUT_ACTIVIDAD_HIJO.replace("#idActividadHijo", data.id), data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_HIJO)})
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
        if (utils.getLog()) console.log("Status PUT_ACTIVIDAD_HIJO: "+response.status+ ". Actividad actualizada con éxito");
        this.volverListado();
      }
    }catch(error){
      if (utils.getLog()) console.log("Error PUT_ACTIVIDAD_HIJO: "+error);
      this.setState({
        textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.edicion.bind(this), [data], appConst.PERFIL_HIJO),
        tipoAlert: utils.getTipoAlert(error),
        closeAlert: appConst.ALERT_OPEN
      });
    }
  }

  volverListado(){

    this.props.history.push(process.env.REACT_APP_WEB_BASE_URL+"/h/lista-actividades");
  }

  esFormValido(){

    let campo = this.state.campo;
    let errorForm = {};
    let formularioValido = true;

    // Fecha de la actividad
    if (!campo["txtFecha"]){
        formularioValido = false;
        errorForm["txtFecha"] = <FM id='app.general.requerido'/>;
    }

    // Tipo de actividad
    if (!campo["cmbActividad"]){
      formularioValido = false;
      errorForm["cmbActividad"] = <FM id='app.general.requerido'/>;
    }

    // Mis matches
    if (!campo["cmbMatches"] && campo["cmbActividad"] == appConst.TIPO_ACTIVIDAD_MATCH){
      formularioValido = false;
      errorForm["cmbMatches"] = <FM id='app.general.requerido'/>;
    }

    // Seteo el estado de error 
    if (!formularioValido)
      this.setState({
        error: errorForm,
        textoAlert: <FM id="app.general.form.revisarCampos" />,
        tipoAlert: appConst.ALERT_TIPO_WARNING
      });
    
    // Se limpian las validaciones por cada campo
    else this.setState({ error: [] });
    
    return formularioValido;
  }

  detectarCambio(field, e) {
  
    let campo = this.state.campo;

    // Si el campo que llega es txtFecha, el evento e es el valor de la propia fecha, en caso contrario, e es el evento
    if (field === "txtFecha")
      campo[field] = (e) ? e : "";
    else
      campo[field] = (e) ? e.target.value : "";

    if (e && e.target){
      // Si el campo es el combo de tipos de actividad y su valor seleccionado es == MATCH: se muestra el combo de matches
      if (e.target.id.split("-")[0] == "cmbActividad" && e.target.value == appConst.TIPO_ACTIVIDAD_MATCH)
        document.querySelector("#capaMatches").className = "row pt-2";

      // Si el campo es el combo de tipos de actividad y su valor seleccionado es != MATCH: se oculta el combo de matches
      else if (e.target.id.split("-")[0] == "cmbActividad" && e.target.value != appConst.TIPO_ACTIVIDAD_MATCH){
        document.querySelector("#capaMatches").className = "hideElement";
        campo["cmbMatches"] = null;
      }
      
      // Como ayuda, en función del option seleccionado en cmbMatches se pinta la fecha del match en el DatePicker
      if (field == "cmbMatches") campo["txtFecha"] = this.getFechaMatch();
    }

    this.setState({
      campo,
      textoAlert: "",
      tipoAlert: ""
    });
  }

  getFechaMatch(){

    // Se setea la fecha del match (leído del option seleccionado de matches) en el campo fecha y se limpia la fecha si no hay match (index = 0)
    if(document.querySelector("#cmbMatches"))
      return (document.querySelector("#cmbMatches").selectedIndex === 0) ? "" : parseInt( utils.getAtributeCombo("cmbMatches", "textobd") );
  }
  
  render(){ 
    return (
      <div>
        <Header itemMenu={appConst.MENU_HIJO_MI_ACTIVIDAD}/>
        
        <div className="contenedorHijo">

          {/* MIGAS DE PAN */}
          <ol className="migaPan">
              <li className="enLinea icon-list"> <Link to={process.env.REACT_APP_WEB_BASE_URL+"/h/lista-actividades"} className="link"><FM id="app.hijo.lista-actividades.title" /></Link></li>
              {this.props.operacion === appConst.OPERACION_NUEVO && <li className="icon-new enLineaFin"><FM id="app.hijo.detalleActividad.crear-actividad.title" /></li>}
              {this.props.operacion === appConst.OPERACION_EDITAR && <li className="icon-edit enLineaFin"><FM id="app.hijo.detalleActividad.editar-actividad.title" /></li>}
          </ol>
          
          {/* INI CONTENEDOR */}
          <div className='contenedor'>
          
            <Alert texto={this.state.textoAlert} tipo={this.state.tipoAlert} close={this.state.closeAlert}/>

            <div className="row mx-auto" style={{width: "50%"}}>
              
              {/* INI FORM */}
              <form id="form">

                <div className="row">
                  
                  <div className="row pt-2">
                    <div className="col-md-6">
                      
                      <div><span className="label-form" data-bs-toggle="tooltip" title="Fecha en la que la empresa ha conseguido el hito"><FM id='app.hijo.lista-actividades.form.label.fecha'/> <span className="prosa icon-error"></span></span></div>
                        <DatePicker
                          dateFormat="dd 'de' MMMM 'de' yyyy"
                          selected={this.state.campo["txtFecha"]}
                          onChange={date => this.detectarCambio("txtFecha", date)}
                          locale="es"
                          id="txtFecha"
                          className="input-css full-width"
                        /><br/>
                        <span className="form-error">{this.state.error["txtFecha"]}</span>

                    </div>
                    <div className="col-md-6">
                      
                      <div><span className="label-form"><FM id='app.hijo.lista-actividades.form.label.actividad'/></span></div>
                      <select id="cmbActividad" name="cmbActividad" className="select-css full-width" onChange={this.detectarCambio.bind(this, "cmbActividad")}>
                        <option value="">{this.props.intl.formatMessage( {id: 'app.general.combos.selecciona'} )}</option>
                        <CargarMaestroDatos
                            tabla={appConst.MAESTRO_ACTIVIDADES}
                            preSelected={this.state.campo['cmbActividad']}
                            perfil={appConst.PERFIL_HIJO}
                        />
                      </select>
                      <span className="form-error">{this.state.error["cmbActividad"]}</span>

                    </div>
                  </div>

                  <div className="hideElement" id="capaMatches">
                    <div className="col-md-12">

                      <div><span className="label-form" title={this.props.intl.formatMessage( {id: 'app.hijo.lista-actividades.form.label.matches'} )}><FM id='app.hijo.lista-actividades.form.label.matches'/></span></div>
                      <select id="cmbMatches" name="cmbMatches" className="select-css full-width" onChange={this.detectarCambio.bind(this, "cmbMatches")}>
                          <option value="">{this.props.intl.formatMessage( {id: 'app.general.combos.selecciono'} )}</option>
                          {<CargarListaDatos
                              endpoint={appConst.GET_TRANSACCIONES}
                              valueField={appConst.TABLA_MATCHES_CAMPO_VALOR}
                              textField1={appConst.TABLA_MATCHES_CAMPO_TEXTO1}
                              textField2={appConst.TABLA_MATCHES_CAMPO_TEXTO2}
                              func2={utils.formatearFechaCastellano}
                              preSelected={this.state.campo['cmbMatches']}
                              textSize1={120}
                              paramsEndpoint={{
                                idEmpresaParticipante: utils.getTokenValue("idEmpresa", appConst.PERFIL_HIJO),
                                idTipoTransaccion: appConst.TIPO_TRAN_MATCH,
                                idEstadoTransaccion: appConst.ESTADO_TRAN_ENVIADO + "," + appConst.ESTADO_TRAN_MATCHEADO
                              }}
                              perfil={appConst.PERFIL_HIJO}
                              data={appConst.TABLA_MATCHES_CAMPO_TEXTO2}
                          />}
                      </select>
                      <span className="form-error">{this.state.error["cmbMatches"]}</span>

                    </div>
                  </div>
                  
                  <div className="row pt-2">
                    <div className="col-md-12">

                      <div><span className="label-form"><FM id='app.hijo.lista-actividades.form.label.descripcion'/></span></div>
                      <textarea type="text" className="textArea-css full-width" name="txtDescripcion" id="txtDescripcion" placeholder="Escribe una breve descripción del hito conseguido" rows="5" maxLength="4000" onChange={this.detectarCambio.bind(this, "txtDescripcion")}>{this.state.campo["txtDescripcion"]}</textarea>
                      <span className="form-error">{this.state.error["txtDescripcion"]}</span>  
                      
                    </div>
                  </div>

                  <div className="row pt-4">
                    <div className="col-md-12 text-center">
                      
                      <button type="button" className="btn btn-sm btn-primary" onClick={this.guardarActividad}><span className="label-button"><FM id='app.hijo.lista-actividades.form.button.guardarActividad'/></span></button>

                    </div>
                  </div>

                </div>
                </form>
                {/* FIN FORM */}
              
            </div>
          </div>
          {/* FIN CONTENEDOR */}

        </div>

        <Footer patrocinadores={appConst.MOSTRAR_PATROCINADORES} />
      </div>
    )
  }
}

export default injectIntl(DetalleActividad);