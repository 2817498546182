import React, { Component } from 'react';
import axios from 'axios';
import { injectIntl,  FormattedMessage as FM } from 'react-intl';
import { withRouter } from 'react-router-dom';
import * as utils from './../../common/utils/Funcs';
import * as appConst from './../../common/const/Const';
import Confirmacion from './../../common/utils/Confirmacion';

class FichaEmpresa extends Component{

    constructor(props){
        super(props);
        this.state = {
            usuariosEmpresaLogeada: []
        }
        this.getDescripcion = this.getDescripcion.bind(this);
        //this.confirmarSolicitudMatch = this.confirmarSolicitudMatch.bind(this);
    }

    componentDidMount(){
    
        // Inicialización de los tooltip de este componente
        utils.iniTooltip();

        // Solo en caso de que la empresa de la ficha actual sea la que está logeada, se permite visualizar los usuarios asociados a su empresa
        if (this.props.empresaLogeada)
            this.setUsuariosEmpresaLogeada();

        this.getDescripcion(this.props.descripcion);

        //this.cargarEventosModal();
    }

    /*componentWillReceiveProps(next_props){

        // Implementar el refresco de la página si la propiedad descrición ha cambiado (next_props) llamando a this.getDescripcion();
        // Pendiente confirmar este funcionamiento...

        //alert (this.props.descripcion + " - " + next_props.descripcion);
        if (this.props.descripcion != next_props.descripcion){
            // Para actualizar todas las props, no solo la descripción, pero quizá no haga falta
            //this.props = next_props;
            this.getDescripcion(next_props.descripcion);
        }
    }*/


    cargarEventosModal(){
        
        const obj = this;
        const modal = document.querySelector("#confirm_"+this.props.id);
    
        if (modal){
            // Acciones antes de cargarse el DOM de la modal
            modal.addEventListener('show.bs.modal', function (e) {
                alert("show.bs.modal");
                e.preventDefault();
            });
        
            // Acciones al ocultarse la modal
            modal.addEventListener('hide.bs.modal', function (e) {
                alert("hide.bs.modal");
            });
        }
    }

    async setUsuariosEmpresaLogeada(){

        try{
          const response = await axios.get(appConst.GET_USUARIOS_BY_EMPRESA.replace("#idEmpresa", utils.getTokenValue("idEmpresa", appConst.PERFIL_HIJO)), {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_HIJO)} );
          
          if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
                if (utils.getLog()) console.log("status GET_USUARIOS_BY_EMPRESA: "+response.status);
                this.setState ({ usuariosEmpresaLogeada: response.data});
          }
    
        }catch(error){
            if (utils.getLog()) console.log("Error GET_USUARIOS_BY_EMPRESA: "+error);
            this.props._showMensaje(utils.getTextError(error, this.props.intl, this.props.history, null, null, this.setUsuariosEmpresaLogeada, [], appConst.PERFIL_HIJO), utils.getTipoAlert(error), appConst.ALERT_OPEN);
        }
    }

    async getUsuarioEmpresaSolicitudMatch(idEmpresa){

        try{
          const response = await axios.get(appConst.GET_USUARIOS_BY_EMPRESA.replace("#idEmpresa", idEmpresa), {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_HIJO)} );
          
          if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
                if (utils.getLog()) console.log("status GET_USUARIOS_BY_EMPRESA: "+response.status);
                // Tratamiento para retornar solo el usuario que sea admin de la empresa (si hay más de un admin, se retorna el primero)
                return utils.getUsuarioAdmin(response.data);
          }
          return null;
    
        }catch(error){
            if (utils.getLog()) console.log("Error GET_USUARIOS_BY_EMPRESA: "+error);
            this.props._showMensaje(utils.getTextError(error, this.props.intl, this.props.history, null, null, this.getUsuarioEmpresaSolicitudMatch, [idEmpresa], appConst.PERFIL_HIJO), utils.getTipoAlert(error), appConst.ALERT_OPEN);
        }
    }

    collapsedDescripcion(desc){

        // Plegar
        if (desc && desc.length > appConst.LIMITE_DESC_EMPRESA)
            return desc.substr(0, appConst.LIMITE_DESC_EMPRESA) + "...";
        
        // Desplegar
        else 
            return desc;
    }

    getDescripcion(desc){

        const VER_MAS = this.props.intl.formatMessage( {id: 'app.hijo.mi-mundo.descripcion.ver-mas'} )
        const VER_MENOS = this.props.intl.formatMessage( {id: 'app.hijo.mi-mundo.descripcion.ver-menos'} )
        
        if (document.querySelector("#desc_"+this.props.idHijo)){
            
            //Para rollback descomentar la siguiente línea, quitar el parámetro de entrada a la func 'desc', en sus llamadas también y quitar la func componentWillReceiveProps
            //let desc = this.props.descripcion;

            // Con descripción
            if (desc)
                if (document.querySelector("#desc_ver_"+this.props.idHijo).innerHTML === VER_MENOS){
                    
                    document.querySelector("#desc_"+this.props.idHijo).innerHTML = this.collapsedDescripcion(desc);
                    document.querySelector("#desc_ver_"+this.props.idHijo).innerHTML = VER_MAS;
                }else{

                    document.querySelector("#desc_"+this.props.idHijo).innerHTML = desc;
                    document.querySelector("#desc_ver_"+this.props.idHijo).innerHTML = VER_MENOS;
                }

            // Sin descripción
            else{
                document.querySelector("#desc_"+this.props.idHijo).innerHTML = "<i>"+this.props.intl.formatMessage({id:"app.hijo.mi-mundo.sin-descripcion"})+"</i>";
                document.querySelector("#desc_ver_"+this.props.idHijo).innerHTML = "";
            }
        }
    }

    async solicitarMatch(idEmpresaSolicitada){

        // Antes de guardar el participante destino se extrae el usuario destinatario que será el primero de la lista de usuarios de la empresa destino
        const usuarioEmpresaDestino = await this.getUsuarioEmpresaSolicitudMatch(idEmpresaSolicitada);

        // Se guarda la transacción
        const idTransaccion = await this.guardarTransaccion(usuarioEmpresaDestino);
        if (idTransaccion){
            
            // Se guarda participante origen ya confirmado (estado participante) pues es él mismo quien solicita el match
            const idParticipanteOrigen = await this.guardarParticipante(idTransaccion, utils.getTokenValue("idEmpresa", appConst.PERFIL_HIJO), utils.getTokenValue("idUsuario", appConst.PERFIL_HIJO), appConst.TIPO_PART_ORIGEN);

            // Antes de guardar el participante destino se extrae el usuario destinatario que será el primero de la lista de usuarios de la empresa destino
            //const usuarioEmpresaDestino = await this.getUsuarioEmpresaSolicitudMatch(idEmpresaSolicitada);

            // Se guarda participante destino pendiente de confirmar (estado participante)
            const idParticipanteDestino = await this.guardarParticipante(idTransaccion, idEmpresaSolicitada, usuarioEmpresaDestino.id, appConst.TIPO_PART_DESTINO);

            // Se envía el email a todas las partes implicadas
            await this.lanzarMatch(idTransaccion);
        }
    }

    async guardarTransaccion(usuarioEmpresaDestino){

        const data = {
            "nombre": this.getNombreMatchDefault(),
            "asunto": appConst.ASUNTO_MATCH_DEFAULT_BY_HIJO,
            "cuerpo": appConst.CUERPO_MATCH_DEFAULT_BY_HIJO
                .replace("#0", usuarioEmpresaDestino.nombre)
                .replace("#1", utils.getTokenValue("nombreEmpresa", appConst.PERFIL_HIJO)),
            "fecha": new Date(),
            "idTipoTransaccion": appConst.TIPO_TRAN_MATCH,
            "idEstadoTransaccion": appConst.ESTADO_TRAN_ENVIADO,
            "idEmpresa": utils.getTokenValue("idEmpresa", appConst.PERFIL_HIJO),
            "idUsuarioEmpresa": utils.getTokenValue("idUsuario", appConst.PERFIL_HIJO),
            "idAutor": utils.getTokenValue("idUsuario", appConst.PERFIL_HIJO)
        }

        try{
            const response = await axios.post(appConst.POST_TRANSACCION, data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_HIJO)} )
            
            if (response && response.data && response.status === appConst.HTTPSTATUS_CREATED){
                if (utils.getLog()) console.log("status POST_TRANSACCION: "+response.status);
                
                const idTransaccion = response.data.id;
                return idTransaccion;
            }
            return null; 

        }catch(error){
            if (utils.getLog()) console.log("Error POST_TRANSACCION: "+error);
            this.props._showMensaje(utils.getTextError(error, this.props.intl, this.props.history, null, null, this.guardarTransaccion.bind(this), [usuarioEmpresaDestino], appConst.PERFIL_HIJO), utils.getTipoAlert(error), appConst.ALERT_OPEN);
        }
    }

    async guardarParticipante(idTransaccion, idEmpresa, idUsuarioEmpresa, idTipoParticipante){

        const data = {
            "idTransaccion": idTransaccion,
            "idEmpresa": idEmpresa,
            "idUsuarioEmpresa": idUsuarioEmpresa,
            "idTipoParticipante": idTipoParticipante,
            "idEstadoParticipante": (idTipoParticipante == appConst.TIPO_PART_ORIGEN) ? appConst.ESTADO_PART_CONFIRMADO : appConst.ESTADO_PART_PENDIENTE,
            "idAutor": utils.getTokenValue("idUsuario", appConst.PERFIL_HIJO)
        }

        try{
            const response = await axios.post(appConst.POST_PARTICIPANTE, data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_HIJO)} )
            
            if (response && response.data && response.status === appConst.HTTPSTATUS_CREATED){
                if (utils.getLog()) console.log("status POST_PARTICIPANTE: "+response.status);
                return response.data.id;
            }
            return null;

        }catch(error){
            if (utils.getLog()) console.log("Error POST_PARTICIPANTE: "+error);
            this.props._showMensaje(utils.getTextError(error, this.props.intl, this.props.history, null, null, this.guardarParticipante.bind(this), [idTransaccion, idEmpresa, idUsuarioEmpresa, idTipoParticipante], appConst.PERFIL_HIJO), utils.getTipoAlert(error), appConst.ALERT_OPEN);
        }
    }

    async lanzarMatch(idTransaccion){

        /* NO SERÍA NECESARIO HACER EL PUT en la entidad Transaccion porque los valores ya se han guardado en el proceso anterior
        de este mismo hilo con los valores adecuados finales */

        // Se envía el email al participante origen y al participante destino (éste último será el email del primer usuario de la empresa destino)
        // Se actualiza el estado y la fecha de lanzamiento del match (y el asunto y cuerpo traducidos con posibles variables)
        const data = {
            "idEstadoTransaccion": appConst.ESTADO_TRAN_ENVIADO,
            "idAutor": utils.getTokenValue("idUsuario", appConst.PERFIL_HIJO)
        }

        try{
            const response = await axios.put(appConst.PUT_TRANSACCION_ENVIAR.replace("#idTransaccion", idTransaccion), data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_HIJO)} )
            
            if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
                if (utils.getLog()) console.log("status PUT_TRANSACCION_ENVIAR: "+response.status);
                this.props._showMensaje("El match se ha solicitado correctamente", appConst.ALERT_TIPO_SUCCESS, appConst.ALERT_CLOSE);
            }

        }catch(error){
            if (utils.getLog()) console.log("Error PUT_TRANSACCION_ENVIAR: "+error);
            this.props._showMensaje(utils.getTextError(error, this.props.intl, this.props.history, null, null, this.lanzarMatch.bind(this), [idTransaccion], appConst.PERFIL_HIJO), utils.getTipoAlert(error), appConst.ALERT_OPEN);
        }
    }

    getNombreMatchDefault(){
        
        return utils.getTokenValue("nombreEmpresa", appConst.PERFIL_HIJO) + " + " + this.props.nombre
    }

    render(){ 
        return (

            <div className="mb-4">

                {/* CABECERA */}
                <div className="row headerCajaMatch tamTitle">
                    <div className="col-md-3">
                        <span className="hand">{this.props.nombre}</span>
                    </div>
                    <div className="col-md-7 text-end pe-4">
                        <span className="fst-italic">{(!this.props.direccion) && <FM id="app.hijo.mi-mundo.sin-direccion" />}</span>
                        <span>{(this.props.direccion) && ((this.props.empresaLogeada) ? this.props.direccion +" - " : "") + this.props.localidad +" - "+ this.props.provincia}</span>
                    </div>
                    <div className="col-md-2 text-center bordeIzqBlanco">
                        <div className="row">
                            {/* WEB */}
                            <div className="col-md-4 ">
                                <a href={this.props.urlWeb} target="_blank" className="linkFFF">
                                    <span className="icon-globe icono-muy-peke" data-bs-toggle="tooltip" title={((this.props.urlWeb) && "Web") || ((!this.props.urlWeb) && "No existe enlace a la Web")} />
                                </a>
                            </div>
                            {/* NOTICIA */}
                            <div className="col-md-4 ">
                                <a href={this.props.urlNoticia} target="_blank" className="linkFFF">
                                    <span className="icon-noticia icono-muy-peke" data-bs-toggle="tooltip" title={((this.props.urlNoticia) && "Noticia") || ((!this.props.urlNoticia) && "No existe enlace a la noticia")} />
                                </a>
                            </div>
                            {/* VíDEO */}
                            <div className="col-md-4 ">
                                <a href={this.props.urlVideo} target="_blank" className="linkFFF">
                                    <span className="icon-video icono-muy-peke" data-bs-toggle="tooltip" title={((this.props.urlVideo) && "Vídeo") || ((!this.props.urlVideo) && "No existe enlace al vídeo")} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* CUERPO */}
                <div className={"row " + ((this.props.empresaLogeada) ? "bodyCajaMatchLogged " : "bodyCajaMatch ")}>
                    
                    {/* LOGO Y NOMBRE EMPRESA */}
                    <div className="col-md-2 ">
                        
                        {/* LOGOS EMPRESA */}
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <p><img className="imgMatch" src={this.props.logo} /></p>
                            </div>
                        </div>

                        {/* USUARIOS EMPRESA */}
                        <div className="row">
                            <div className="col-md-12 text-center">
                                {(this.state.usuariosEmpresaLogeada[0] != undefined) && <label className="avatar" data-bs-toggle="tooltip" title={utils.getUserNombreCargo(this.state.usuariosEmpresaLogeada, 0)}>
                                    {utils.getLetrasAvatar(this.state.usuariosEmpresaLogeada, 0)}
                                </label>}
                                {(this.state.usuariosEmpresaLogeada[1] != undefined) && <label className="avatar" data-bs-toggle="tooltip" title={utils.getUserNombreCargo(this.state.usuariosEmpresaLogeada, 1)}>
                                    {utils.getLetrasAvatar(this.state.usuariosEmpresaLogeada, 1)}
                                </label>}
                                {(this.state.usuariosEmpresaLogeada[2] != undefined) && <label className="avatar" data-bs-toggle="tooltip" title={utils.getUserNombreCargo(this.state.usuariosEmpresaLogeada, 2)}>
                                    {utils.getLetrasAvatar(this.state.usuariosEmpresaLogeada, 2)}
                                </label>}
                            </div>
                        </div>
                        
                    </div>

                    {/* DESCRIPCION, AREAS INTERES, NECESIDADES, SECTOR */}
                    <div className="col-md-8 px-4 bordeIzqAzul">
                        <p>
                            <p className="text-justify">
                                <span className="prosa tamTitle" id={"desc_"+this.props.idHijo}></span>
                                <span className="hand" id={"desc_ver_"+this.props.idHijo} onClick={this.getDescripcion.bind(this, this.props.descripcion)}><FM id="app.hijo.mi-mundo.descripcion.ver-menos" /></span>
                            </p>
                            <div className="row text-center">
                                <div className="col-sm-4">
                                    <span className="icon-star-full icono-medio" data-bs-toggle="tooltip" title="Áreas de interés"></span><br/>
                                    <span className="prosa">{this.props.areasInteres.map(e => <span key={e.areaInteres.id}>{e.areaInteres.nombre}<br /></span>)}</span>
                                    {this.props.areasInteres.length === 0 && <span className="prosa fst-italic"><FM id="app.padre.mi-mundo.sin-area-interes" /></span>}
                                </div>
                                <div className="col-sm-4">
                                    <span className="icon-help icono-medio" data-bs-toggle="tooltip" title="Necesidades"></span><br/>
                                    <span className="prosa">{this.props.necesidades.map(e => <span key={e.necesidad.id}>{e.necesidad.nombre}<br /></span>)}</span>
                                    {this.props.necesidades.length === 0 && <span className="prosa fst-italic"><FM id="app.hijo.mi-mundo.sin-necesidades" /></span>}
                                </div>
                                <div className="col-sm-4">
                                    <span className="icon-empresa icono-medio" data-bs-toggle="tooltip" title="Sector"></span><br/>
                                    <span className="prosa">{this.props.sector}</span>
                                </div>
                            </div>
                        </p>
                    </div>

                    {/* ESTADO MATCH */}
                    <div className="col-md-2 pt-1 bordeIzqAzul text-center">
                        {this.props.empresaLogeada && <span className='prosa'><span className="icon-info icono-medio"></span><br/><span>Sí, eres tú</span></span>}
                        {!this.props.empresaLogeada && <button className="icon-heart-empty icono-medio red btn" type="button" data-bs-toggle="modal" data-bs-target={"#confirm_"+this.props.id}><p className='prosa text-center' data-bs-toggle="tooltip" title="Provoca un encuentro con esta empresa, quizá podáis ayudaros mútuamente">Solicitar un match!!</p></button>}
                    </div>
                </div>

                {/* Confirmación de solicitud de match */}
                <Confirmacion
                    id={this.props.id}
                    estiloHeader={"icon-heart-full red icono-peke"}
                    titulo={"Solicitar un match"}
                    texto={"<p>¿Deseas solicitar un match con la empresa: <strong>"+this.props.nombre+"</strong>?</p><p>Esta empresa recibirá un email y tendrá la opción de declinar o aceptar tu solicitud. En este último caso se te avisará de este hecho mediante email y notificación de Finder.</p>"}
                    imagen={this.props.logo}
                    accion={this.solicitarMatch.bind(this, this.props.idEmpresa)}
                    boton={"Solicitar"}
                />

            </div>
        )
    }
}

export default injectIntl(withRouter(FichaEmpresa));