import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from './../header/Header';
import Footer from './../footer/Footer';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import * as appConst from './../../common/const/Const';
import * as utils from './../../common/utils/Funcs';

class ManualUsuario extends Component {

  constructor(props){
    super(props);
    this.state = {
      nombrePadre: utils.getTokenValue("nombrePadre", appConst.PERFIL_HIJO)
    }
  }

  componentDidMount(){

    // Si hay una página con scroll hacia abajo, al cargar ésta del manual, se cargaría en la misma posición. Se fuerza a subir, y con un delay de 1 seg para que dé tiempo a la carga del DOM
    window.setTimeout(() => window.scrollTo(0, 0), 1000);
  }

  render(){ 
    return (
      <div>
        <Header/>
        
        <div className="contenedorHijo">

          {/* MIGAS DE PAN */}
          <ol className="migaPan">
              <li className="enLineaFin icon-info"> <FM id="app.manualUsuario.title" /></li>
          </ol>
          
          {/* INI CONTENEDOR 50% */}
          <div className="contenedor75 pt-3">
          
            <div className='row my-3'>
              <div className='col-sm-9'>
                <h6> <FM id="app.manualUsuario.que.subtitle" /></h6>
                <span className="prosa"> <FM id="app.manualUsuario.que.descripcion" /></span>
              </div>
              <div className='col-sm-3 text-end pe-5'>
                <img src={appConst.URL_LOGO_FINDER} className='imgEmpresa' />
              </div>
            </div>
            
            <div className='row my-3'>
              <div className='col-sm-12'>
                <h6> <FM id="app.manualUsuario.paraque.subtitle" /></h6>
                <span className="prosa"> <FM id="app.manualUsuario.paraque.descripcion" values={{intro: <br/>}} /></span>
              </div>
            </div>

            <div className='row my-5'>
              <div className='col-sm-12'>
                <h6> <FM id="app.manualUsuario.como.subtitle" /></h6>
                <span className='prosa'>
                  <li className='mb-3'>
                    <strong>Mi mundo</strong>: Podrás disponer de una relación de empresas que formen parte, como tú, del mundo de {this.state.nombrePadre}, clasificadas por sectores, pudiendo ayudar en sus necesidades, al igual que todos ellos te podrán ayudar con las tuyas. Eso te facilitará localizar a la posible empresa y solicitarle un match. Este es el objetivo, conectar y tener un encuentro virtual o presencial para conseguir esa colaboración que necesitas.
                  </li>
                  <li className='mb-3'>
                    <strong>Mi actividad</strong>: Podrás crear un bitácora con todos los hitos que tu empresa va consiguiendo, tanto dentro como fuera de Finder:  acuerdos de colaboración, un match, un cliente estratégico, un canal de venta, una fuente de financiación, ... También te puedes plantear aquí tu propio roadmap u objetivos en cuanto a estos hitos comentados. {this.state.nombrePadre} lo verá y podrá ayudarte sugiriendo nuevos contactos y nuevos match entre tu empresa y otra que satisfaga tu necesidad.
                  </li>
                  <li className='mb-3'>
                    <strong>Colaboradores</strong>: Gracias a pertenecer a Finder, tendrás a tu alcance un conjunto de empresas colaboradoras de nuestra confianza que podrán ayudarte en muchas de las áreas que seguro podrías necesitar en función a la etapa que vive tu empresa en este momento. Tendrás vía directa a sus servicios con las ventajas y acuerdos que Finder personalizará para ti.
                  </li>
                  <li className='mb-3'>
                    <strong>Necesidades</strong>: En Finder se ha clasificado una serie de necesidades (financiación bancaria o con inversión, compra-venta de empresas, desarrollo de software, ...) que tu empresa podría tener. Selecciona aquello que quieras que te ayudemos a conseguir, aquellas necesidades que desde Finder, y entre todos, podamos poner en marcha para buscar posibles soluciones. El resto de tu mundo también puede verlo y solicitarte un match a ti. Para ello puedes acceder al menú derecho desde tu usuario y pinchar en la opción "Mi perfil", después pincha en la pestaña "Necesidades" y ya podrás seleccionar las que hoy te preocupan. Todo listo para dar visibilidad a tus necesidades dentro de tu mundo {this.state.nombrePadre}.
                  </li>
                  <li className='mb-3'>
                    <strong>Áreas de interés</strong>: {this.state.nombrePadre} creará diferentes temáticas de actualidad, las áreas de interés. Elige tus áreas de interés, aquéllas que coincidan con las inquietudes que tenga tu empresa, y así de este modo, {this.state.nombrePadre} podrá invitaros a eventos, formaciones, charlas o mesas redondas de vuestro interés, exclusivas sobre esas temáticas, donde se os pone en contacto a todas aquellas empresas que realmente estéis interesadas, creando además un poquito de networking ;). Para ello puedes acceder al menú derecho desde tu usuario y pinchar en la opción "Mi perfil", después pincha en la pestaña "Áreas de interés", y ya podrás seleccionar las tuyas. Finder te avisará por email para que sepas cuándo y cómo hacerlo.
                  </li>
                  <li className='mb-3'>
                    <strong>El papel de {this.state.nombrePadre}</strong>: {this.state.nombrePadre} está ahí para ayudarte, ve tu mundo con la ilusión de ampliar tu Red propia y más fuerte. Te sugerirá un match entre tu empresa y otra que conoce, porque sabe que tiene un potencial beneficio para que os aliéis. Como decíamos, también creará las mencionadas áreas de interés para dinamizar y provocar el movimiento entre las empresas.
                  </li>
                  <li className='mb-3'>
                    <strong>Notificaciones</strong>: Cualquier acción que se genere en Finder o aquellas acciones que tengas pendientes (por ejemplo, aceptar un match de otra empresa), se te notificará por email y a través  de la propia plataforma , persiguiendo siempre el objetivo de Finder: conseguir un match! Cuantos más y de calidad, mejor! Porque seguro que se materializará en muchos acuerdos de colaboración dentro de nuestro mundo global de Finder.
                  </li>
                </span>

                <br/><br/>

                <div className="position-relative text-nowrap">
                  <div className='position-absolute top-50 start-50 translate-middle'>
                    <p className="bg-white border p-3 prosa text-center rounded">
                    <FM id="app.manualUsuario.slogan" /> <span className='icon-heart-full icono-muy-peke red'></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
          {/* FIN CONTENEDOR */}

        </div>

        <Footer patrocinadores={appConst.MOSTRAR_PATROCINADORES} />
      </div>
    )
  }
}

export default injectIntl(withRouter(ManualUsuario));