import React, { Component } from 'react';
import axios from 'axios';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import * as appConst from '../../common/const/Const';
import * as utils from '../../common/utils/Funcs';

class Invitacion extends Component {

    constructor(props){
        super(props);
        this.state = {
            usuario: null
        }
    }

    componentDidMount(){

        // Inicialización de los tooltip de este componente
        //utils.iniTooltip();
        
        this.getUsuarioFromEmail();
    }

    async getUsuarioFromEmail(){

        try{
            const response = await axios.get(appConst.GET_USUARIO_INVITACION_BY_EMAIL, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE), params:{
                emailInvitado: this.props.email
            }} );
            
            if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
                if (utils.getLog()) console.log("status GET_USUARIO_INVITACION_BY_EMAIL: "+response.status);
                this.setState({
                    usuario: response.data
                });
                utils.iniTooltip();
            }

        }catch(error){
            if (utils.getLog()) console.log("Error GET_USUARIO_INVITACION_BY_EMAIL: "+error);
            this.setState({
                textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.getUsuarioFromEmail, [], appConst.PERFIL_PADRE),
                tipoAlert: utils.getTipoAlert(error),
                closeAlert: appConst.ALERT_OPEN
            });
        }
    }

    render(){
        return (
            <div className='caja m-0 p-2 px-3 mb-2' id={this.props.id}>
                
                <div className='row prosa hand' data-bs-toggle="collapse" href={"#detalle"+this.props.id}>
                    <div className='col-sm-8'>
                        <div>
                            <span 
                                className={this.state.usuario?.activo === appConst.COD_ESTADO_INVITACION_REGISTRADO ? 
                                    'icon-check green'
                                    :
                                    (this.state.usuario?.activo === appConst.COD_ESTADO_INVITACION_PENDIENTE) ? 
                                        'icon-warning red' 
                                        : 
                                        'icon-cancel red'
                                    }
                                data-bs-toggle="tooltip" title={this.state.usuario?.activo === appConst.COD_ESTADO_INVITACION_REGISTRADO ? appConst.TEXTO_ESTADO_INVITACION_REGISTRADO : (this.state.usuario?.activo === appConst.COD_ESTADO_INVITACION_PENDIENTE) ? appConst.TEXTO_ESTADO_INVITACION_PENDIENTE2 : appConst.TEXTO_ESTADO_INVITACION_PENDIENTE1}>
                            </span> <label className='hand' data-bs-toggle="collapse" href={"#detalle"+this.props.id}>{this.props.nombre}</label>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='text-end'>{utils.formatearFechaCastellano(this.props.fecha)}</div>
                    </div>
                </div>
                <div className='row prosa collapse' id={"detalle"+this.props.id}>
                    <div className='col-sm-12'>
                        <hr/>
                        <div className='row prosa'>
                            <div className='col-sm-6'>
                                {this.props.email}
                            </div>
                            <div className='col-sm-6 text-end'>
                                {this.state.usuario?.activo === appConst.COD_ESTADO_INVITACION_REGISTRADO ? 
                                    <span className='green'>{appConst.TEXTO_ESTADO_INVITACION_REGISTRADO}</span> 
                                    :
                                    <span className='red'>{(this.state.usuario?.activo === appConst.COD_ESTADO_INVITACION_PENDIENTE) ? 
                                        appConst.TEXTO_ESTADO_INVITACION_PENDIENTE2 
                                        : 
                                        appConst.TEXTO_ESTADO_INVITACION_PENDIENTE1}
                                    </span>
                                }
                            </div>
                        </div>
                        <div className='row prosa pt-3'>
                            <div className='col-sm-12'>
                                {this.props.descripcion}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default injectIntl(Invitacion);