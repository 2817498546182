import React, { Component } from 'react';
import Header from './../header/Header';
import Footer from './../footer/Footer';
import * as appConst from './../../common/const/Const';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import Alert from './../../common/utils/Alert';

class Patrocinadores extends Component {

  constructor(props){
    super(props);
    this.state = {
      textoAlert: '',
      tipoAlert: ''
    };    
  }

  render(){ 
    return (
      <div>
        <Header itemMenu={appConst.MENU_HIJO_COLABORADORES}/>
        
        <div className="contenedorHijo">

          {/* MIGAS DE PAN */}
          <ol className="migaPan">
              <li className="enLineaFin icon-info"> <FM id="app.hijo.patrocinadores.title" /></li>
          </ol>
          
          {/* INI CONTENEDOR */}
          <div className='contenedor'>
            
            <Alert texto={this.state.textoAlert} tipo={this.state.tipoAlert} />

            <p className="prosa"><FM id="app.hijo.patrocinadores.vacio" /></p>

            {/* FILA 1 */}
            {/*<div className="d-flex justify-content-center prosa mb-5 pb-5">
              
              <div className="card mr-5" style={{width: "11rem"}}>
                <img src="https://www.seedcapitalbizkaia.eus/wp-content/uploads/2020/05/seedcapitalbizkaia.png" className="card-img-top p-2" alt="..." width="140px" height="90px"/>
                <div className="card-header">
                  Seed Capital Bizkaia
                </div>
                <div className="card-body">
                  <h5 className="card-title">Servicios</h5>
                  <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  <div className='text-center'><a href="#" className="btn btn-sm btn-primary">Ver servicios</a></div>
                </div>
              </div>

              <div className="card mr-5" style={{width: "11rem"}}>
                <img src="https://finbox.es/prototipo/finderProd/images/mimundohijo/u1635.png" className="card-img-top p-2" alt="..." width="140px" height="90px"/>
                <div className="card-header">
                  CEBEK
                </div>
                <div className="card-body">
                  <h5 className="card-title">Servicios</h5>
                  <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  <div className='text-center'><a href="#" className="btn btn-sm btn-primary">Ver servicios</a></div>
                </div>
              </div>

              <div className="card mr-5" style={{width: "11rem"}}>
                <img src="https://es.safecreative.net/wp-content/uploads/2016/09/logo-bilbao-ekintza-maker-faire.jpg" className="card-img-top p-2" alt="..." width="140px" height="90px"/>
                <div className="card-header">
                  Bilbao Ekintza
                </div>
                <div className="card-body">
                  <h5 className="card-title">Servicios</h5>
                  <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  <div className='text-center'><a href="#" className="btn btn-sm btn-primary">Ver servicios</a></div>
                </div>
              </div>

              <div className="card mr-5" style={{width: "11rem"}}>
                <img src="https://finbox.es/prototipo/finderProd/images/mimundohijo/u1673.png" className="card-img-top p-2" alt="..." width="140px" height="90px"/>
                <div className="card-header">
                  Gaztempresa
                </div>
                <div className="card-body">
                  <h5 className="card-title">Servicios</h5>
                  <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  <div className='text-center'><a href="#" className="btn btn-sm btn-primary">Ver servicios</a></div>
                </div>
              </div>

              <div className="card mr-5" style={{width: "11rem"}}>
                <img src="https://www.coralis-h2020.eu/wp-content/uploads/2020/11/16-sidenor.jpg" className="card-img-top p-2" alt="..." width="140px" height="90px"/>
                <div className="card-header">
                  Sidenor
                </div>
                <div className="card-body">
                  <h5 className="card-title">Servicios</h5>
                  <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  <div className='text-center'><a href="#" className="btn btn-sm btn-primary">Ver servicios</a></div>
                </div>
              </div>
              
            </div>*/}
            
            {/* FILA 2 */}
            {/*<div className="d-flex justify-content-center prosa">
              
              <div className="card mr-5" style={{width: "11rem"}}>
                <img src="https://finbox.es/prototipo/finderProd/images/mimundohijo/u1669.png" className="card-img-top p-2" alt="..." width="140px" height="90px"/>
                <div className="card-header">
                  Rural Kutxa
                </div>
                <div className="card-body">
                  <h5 className="card-title">Servicios</h5>
                  <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  <div className='text-center'><a href="#" className="btn btn-sm btn-primary">Ver servicios</a></div>
                </div>
              </div>

              <div className="card mr-5" style={{width: "11rem"}}>
                <img src="https://beaz.bizkaia.eus/images/beaz/logos/logo-beaz-g.png" className="card-img-top p-2" alt="..." width="140px" height="90px"/>
                <div className="card-header">
                  BEAZ
                </div>
                <div className="card-body">
                  <h5 className="card-title">Servicios</h5>
                  <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  <div className='text-center'><a href="#" className="btn btn-sm btn-primary">Ver servicios</a></div>
                </div>
              </div>

              <div className="card mr-5" style={{width: "11rem"}}>
                <img src="https://www.bilbaoport.eus/wp-content/uploads/2014/09/logo_bilbaoport.png" className="card-img-top p-2" alt="..." width="140px" height="90px"/>
                <div className="card-header">
                  Puerto de Bilbao
                </div>
                <div className="card-body">
                  <h5 className="card-title">Servicios</h5>
                  <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  <div className='text-center'><a href="#" className="btn btn-sm btn-primary">Ver servicios</a></div>
                </div>
              </div>

              <div className="card mr-5" style={{width: "11rem"}}>
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXO7YTo4q2bdEQjgu6RH5f7aEq2K8Yzoj87ZfClXXItK-nUuF0Oqh8lZDP4FvL9EqSQ60&usqp=CAU" className="card-img-top p-2" alt="..." width="140px" height="90px"/>
                <div className="card-header">
                  Banco Sabadell
                </div>
                <div className="card-body">
                  <h5 className="card-title">Servicios</h5>
                  <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  <div className='text-center'><a href="#" className="btn btn-sm btn-primary">Ver servicios</a></div>
                </div>
              </div>

              <div className="card mr-5" style={{width: "11rem"}}>
                <img src="https://finbox.es/prototipo/finderProd/images/mimundohijo/u1671.png" className="card-img-top p-2" alt="..." width="140px" height="90px"/>
                <div className="card-header">
                  Elkargi SGR
                </div>
                <div className="card-body">
                  <h5 className="card-title">Servicios</h5>
                  <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  <div className='text-center'><a href="#" className="btn btn-sm btn-primary">Ver servicios</a></div>
                </div>
              </div>

            </div>*/}
          </div>
          {/* FIN CONTENEDOR */}

        </div>

        <Footer patrocinadores={appConst.OCULTAR_PATROCINADORES} />
      </div>
    )
  }
}

export default injectIntl(Patrocinadores);