import React, { Component } from 'react';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import axios from 'axios';
import Alert from '../../common/utils/Alert';
import * as appConst from '../../common/const/Const'
import * as utils from '../../common/utils/Funcs';
import Header from './../header/Header';
import Footer from './../footer/Footer';
import CargarMaestroDatos from './../../common/datos/CargarMaestroDatos';
import Badge from './../../common/utils/Badge';

class MiPerfil extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      campo: {},
      error: {},
      textoAlert: '',
      tipoAlert: '',
      closeAlert: appConst.ALERT_OPEN
    }
    this.cargarUsuario = this.cargarUsuario.bind(this);
    this.cargarEmpresa = this.cargarEmpresa.bind(this);
    this.guardarUsuario = this.guardarUsuario.bind(this);
    this.guardarEmpresa = this.guardarEmpresa.bind(this);
    this.actualizarUsuario = this.actualizarUsuario.bind(this);
    this.actualizarPadre = this.actualizarPadre.bind(this);
    this.actualizarEmpresa = this.actualizarEmpresa.bind(this);
    this.uploadFileToS3 = this.uploadFileToS3.bind(this);
  }

  componentDidMount(){

    // Inicialización de los tooltip de este componente
    utils.iniTooltip();

    // Carga los datos de usuario y de su empresa (pero ésta última en solo lectura si el usuario logeado no es admin)
    this.precargarForms();
  }
  
  async precargarForms(){

    // Usuario (carga datos pero no muestra) --> Por defecto se carga esta pestaña (por estilo active a pelo en MENU TABS)
    this.cargarUsuario();
    
    // Empresa (carga datos pero no muestra)
    const clickPestana2 = await this.cargarEmpresa();

    const url = window.location.href;
    const clickNotificacionPestana = (url.indexOf("?") >= 0) ? url.split("?")[1] : "";
    
    // Si llega mi-perfil?2 se precarga la pestaña de Empresa
    if (clickPestana2 || clickNotificacionPestana === "2")
      this.showEmpresa();
  }

  async cargarUsuario(){
    
    try{
      const response = await axios.get(appConst.GET_USUARIO_BY_ID.replace("#idUsuario", utils.getTokenValue("idUsuario", appConst.PERFIL_PADRE)), {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)})
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
        if (utils.getLog()) console.log("Status GET_USUARIO_BY_ID: "+response.status+ ". Perfil usuario cargado con éxito");
          
        const data = response.data;
        let campo = this.state.campo;
        // Se carga form de usuario
        campo["txtNombre"] = data.nombre;
        campo["txtApellidos"] = data.apellidos;
        campo["txtEmail"] = data.email;
        campo["cmbCargo"] = data.cargo.id;
        campo["chkPublicidad"] = utils.getStringToBoolean(data.consentPubli);
        campo["fechaAlta"] = utils.formatearFechaCastellano(data.fechaAlta);

        // Se deshabilita todo el form de empresa y su botón de guardar si el usuario no es admin
        if (!utils.isAdmin(appConst.PERFIL_PADRE)){
          let form = document.querySelector("#formEmpresa")
          var elements = form.elements;
          for (var i = 0, len = elements.length; i < len; ++i) 
              elements[i].readOnly = true;

          document.querySelector("#btnGuardarEmpresa").className = "hideElement";
        }

        this.setState({
          campo
        });
      }
    }catch(error){
      if (utils.getLog()) console.log("Error GET_USUARIO_BY_ID: "+error);
      this.setState({
        textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.cargarUsuario, [], appConst.PERFIL_PADRE),
        tipoAlert: utils.getTipoAlert(error),
        closeAlert: appConst.ALERT_OPEN
      });
    }
  }

  async cargarEmpresa(){
    
    try{
      const response = await axios.get(appConst.GET_PADRE_BY_ID.replace("#idPadre", utils.getTokenValue("idPadre", appConst.PERFIL_PADRE)), {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)})
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
        if (utils.getLog()) console.log("Status GET_PADRE_BY_ID: "+response.status+ ". Perfil empresa cargado con éxito");
          
        // Se consulta la entidad Padre, en lugar de simplemente, la entidad Empresa, porque la padre tiene los datos tipoPadre y linkServicios
        const data = response.data;
        let campo = this.state.campo;
        // Se carga form de empresa
        campo["txtNif"] = data.empresa.nif;
        campo["txtRazonSocial"] = data.empresa.razonSocial;
        campo["txtNombreEmpresa"] = data.empresa.nombre;
        campo["txtEmailEmpresa"] = data.empresa.email;
        campo["txtTelefono"] = data.empresa.telefono;
        campo["txtLogo"] = data.empresa.logo;
        campo["txtCP"] = data.empresa.cp;
        campo["txtLocalidad"] = data.empresa.localidad;
        campo["txtProvincia"] = data.empresa.provincia;
        campo["txtDireccion"] = data.empresa.direccion;
        campo["txtUrlWeb"] = data.empresa.urlweb;
        campo["txtUrlVideo"] = data.empresa.urlvideo;
        campo["txtUrlNoticia"] = data.empresa.urlnoticia;
        campo["txtServicios"] = data.linkServicio;
        campo["cmbTipoPadre"] = data.tipoPadre.id;
        campo["txtDescripcion"] = data.empresa.descripcion;
        campo["txtTipoEmpresa"] = data.empresa.idTipoEmpresa;
        // Si los combos no se cargan con ningún valor porque está vacio, se asigna en el estado el primer valor del combo por defecto
        campo["cmbSector"] = (data.empresa.sector) ? data.empresa.sector.id : "";
        campo["validacion"] = data.empresa.validacion;
        
        // Los datos de la empresa son incompletos, y se actualiza el array campo además de mostrar el mensaje al usuario indicando este hecho
        if (data.empresa.validacion === appConst.EMPRESA_VALIDACION_KO && utils.isAdmin(appConst.PERFIL_PADRE)){
          this.setState({
            campo,
            textoAlert: <FM id="app.general.datosEmpresaIncompletos" />,
            tipoAlert: appConst.ALERT_TIPO_WARNING,
            closeAlert: appConst.ALERT_OPEN
          });
          this.showEmpresa();
        }
        
        // Los datos de la empresa son completos
        else this.setState({campo});
      }

      // True se fuerza la carga de esta pestaña, False se ignora la precarga de esta pestaña
      return false;

    }catch(error){
      if (utils.getLog()) console.log("Error GET_PADRE_BY_ID: "+error);
      this.setState({
        textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.cargarEmpresa, [], appConst.PERFIL_PADRE),
        tipoAlert: utils.getTipoAlert(error),
        closeAlert: appConst.ALERT_OPEN
      });
    }
  }

  guardarUsuario(){

    if ( this.validarFormUsuario() )
      this.actualizarUsuario();
  }

  guardarEmpresa(){
    
    if ( this.validarFormEmpresa() )
      this.actualizarPadre();
  }

  async actualizarUsuario(){

    const data = {
      "nombre": this.state.campo["txtNombre"],
      "apellidos": this.state.campo["txtApellidos"],
      "idCargo": this.state.campo["cmbCargo"],
      "consentPubli": utils.getBooleanNumber(this.state.campo["chkPublicidad"])
    }
    
    try{
      const response = await axios.put(appConst.PUT_USUARIO.replace("#idUsuario", utils.getTokenValue("idUsuario", appConst.PERFIL_PADRE)), data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)})
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
        if (utils.getLog()) console.log("Status PUT_USUARIO: "+response.status+ ". Perfil actualizado con éxito");
        this.setState({
          textoAlert: <FM id="app.padre.mi-perfil.form.aviso.guardar.ok" />,
          tipoAlert: appConst.ALERT_TIPO_SUCCESS,
          closeAlert: appConst.ALERT_CLOSE
        });
      }

      // Se refresca el token cuando se guarda la pestaña Usuario para actualizar los datos del token y de la cabecera
      utils.refrescarToken(this.props.intl, this.props.history, this.showMessageUpdateToken.bind(this), [], appConst.PERFIL_PADRE);

    }catch(error){
      if (utils.getLog()) console.log("Error PUT_USUARIO: "+error);
      this.setState({
        textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.actualizarUsuario, [], appConst.PERFIL_PADRE),
        tipoAlert: utils.getTipoAlert(error),
        closeAlert: appConst.ALERT_OPEN
      });
    }
  }

  async actualizarPadre(){

    const data = {
      "linkServicio": this.state.campo["txtServicios"],
      "idTipoPadre": this.state.campo["cmbTipoPadre"],
      "idAutor": utils.getTokenValue("idUsuario", appConst.PERFIL_PADRE)
    }

    try{
      const response = await axios.put(appConst.PUT_PADRE.replace("#idPadre", utils.getTokenValue("idPadre", appConst.PERFIL_PADRE)), data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)})
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
        if (utils.getLog()) console.log("Status PUT_PADRE: "+response.status+ ". Perfil actualizado con éxito");

        this.actualizarEmpresa();
      }
    }catch(error){
      if (utils.getLog()) console.log("Error PUT_PADRE: "+error);
      this.setState({
        textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.actualizarPadre, [], appConst.PERFIL_PADRE),
        tipoAlert: utils.getTipoAlert(error),
        closeAlert: appConst.ALERT_OPEN
      });
    }
  }

  async actualizarEmpresa(){

    const data = {
      "nif": this.state.campo["txtNif"],
      "razonSocial": this.state.campo["txtRazonSocial"],
      "nombre": this.state.campo["txtNombreEmpresa"],
      "idSector": this.state.campo["cmbSector"],
      "idTipoEmpresa": this.state.campo["txtTipoEmpresa"],
      "email": this.state.campo["txtEmailEmpresa"],
      "telefono": this.state.campo["txtTelefono"],
      "logo": this.state.campo["txtLogo"],
      "cp": this.state.campo["txtCP"],
      "localidad": this.state.campo["txtLocalidad"],
      "provincia": this.state.campo["txtProvincia"],
      "direccion": this.state.campo["txtDireccion"],
      "urlweb": this.state.campo["txtUrlWeb"],
      "urlvideo": this.state.campo["txtUrlVideo"],
      "urlnoticia": this.state.campo["txtUrlNoticia"],
      "descripcion": this.state.campo["txtDescripcion"],
      "validacion": appConst.EMPRESA_VALIDACION_OK,
      "idAutor": utils.getTokenValue("idUsuario", appConst.PERFIL_PADRE)
    }

    try{
      const response = await axios.put(appConst.PUT_EMPRESA.replace("#idEmpresa", utils.getTokenValue("idEmpresa", appConst.PERFIL_PADRE)), data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)})
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
        if (utils.getLog()) console.log("Status PUT_EMPRESA: "+response.status+ ". Perfil actualizado con éxito");
        this.setState({
          textoAlert: <FM id="app.padre.mi-perfil.form.aviso.guardar.ok" />,
          tipoAlert: appConst.ALERT_TIPO_SUCCESS,
          closeAlert: appConst.ALERT_CLOSE
        });
      }

      // Se refresca el token cuando se guarda la pestaña Empresa para actualizar los datos del token, la cabecera y el logo del menú
      utils.refrescarToken(this.props.intl, this.props.history, this.showMessageUpdateToken.bind(this), [], appConst.PERFIL_PADRE);

    }catch(error){
      if (utils.getLog()) console.log("Error PUT_EMPRESA: "+error);
      this.setState({
        textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.actualizarEmpresa, [], appConst.PERFIL_PADRE),
        tipoAlert: utils.getTipoAlert(error),
        closeAlert: appConst.ALERT_OPEN
      });
    }
  }
  
  validarFormUsuario() {
    let campo = this.state.campo;
    let error = {};
    let formularioValido = true;
  
    // Nombre
    if (!campo['txtNombre']) {
      formularioValido = false;
      error["txtNombre"] = <FM id='app.general.requerido'/>;
    }

    // Apellidos
    if (!campo['txtApellidos']) {
      formularioValido = false;
      error["txtApellidos"] = <FM id='app.general.requerido'/>;
    }

    // Email
    if (!campo['txtEmail']) {
      formularioValido = false;
      error["txtEmail"] = <FM id='app.general.requerido'/>;
    
    // Se valida si el formato del email de usuario es válido
    }else if (!utils.isValidEmail(campo["txtEmail"])) {
      formularioValido = false;
      error["txtEmail"] = <FM id='app.general.format.email'/>;
    }

    // Cargo
    if (!campo['cmbCargo']) {
      formularioValido = false;
      error["cmbCargo"] = <FM id='app.general.requerido'/>;
    }
    
    // Seteo el estado de error 
    if (!formularioValido)
      this.setState({
        error,
        textoAlert: <FM id="app.general.form.revisarCampos" />,
        tipoAlert: appConst.ALERT_TIPO_WARNING
      });
    
    // Se limpian las validaciones por cada campo
    else this.setState({ error: {} });
  
    return formularioValido;
  }

  validarFormEmpresa() {
    let campo = this.state.campo;
    let error = {};
    let formularioValido = true;
  
    // NIF
    if (!campo['txtNif']) {
      formularioValido = false;
      error["txtNif"] = <FM id='app.general.requerido'/>;

    }else if (!utils.validarNIF(campo['txtNif'])){
      formularioValido = false;
      error["txtNif"] = <FM id='app.padre.mi-perfil.form.val.nif'/>;
    }

    // Razón social
    if (!campo['txtRazonSocial']) {
      formularioValido = false;
      error["txtRazonSocial"] = <FM id='app.general.requerido'/>;
    }

    // Nombre empresa
    if (!campo['txtNombreEmpresa']) {
        formularioValido = false;
        error["txtNombreEmpresa"] = <FM id='app.general.requerido'/>;
    }

    // Email empresa
    if (!campo['txtEmailEmpresa']) {
      formularioValido = false;
      error["txtEmailEmpresa"] = <FM id='app.general.requerido'/>;
    
    }else if (typeof campo["txtEmailEmpresa"] !== "undefined" && !utils.isValidEmail(campo["txtEmailEmpresa"])) {

      // Se valida si el formato del email de empresa es válido
      formularioValido = false;
      error["txtEmailEmpresa"] = <FM id='app.general.format.email'/>;
    }

    // Dirección
    if (!campo['txtDireccion']) {
      formularioValido = false;
      error["txtDireccion"] = <FM id='app.general.requerido'/>;
    }

    // Localidad
    if (!campo['txtLocalidad']) {
      formularioValido = false;
      error["txtLocalidad"] = <FM id='app.general.requerido'/>;
    }

    // Provincia
    if (!campo['txtProvincia']) {
      formularioValido = false;
      error["txtProvincia"] = <FM id='app.general.requerido'/>;
    }

    // C.P.
    if (!campo['txtCP']) {
      formularioValido = false;
      error["txtCP"] = <FM id='app.general.requerido'/>;
    }else if (!utils.validarCodigoPostal(campo['txtCP'])){
      formularioValido = false;
      error["txtCP"] = <FM id='app.general.campo.CP.requerido'/>;
    }

    // Teléfono
    if (!campo['txtTelefono']) {
      formularioValido = false;
      error["txtTelefono"] = <FM id='app.general.requerido'/>;

    }else if (!utils.validarTelefono(campo['txtTelefono'])){
      formularioValido = false;
      error["txtTelefono"] = <FM id='app.general.format.telefono'/>;
    }

    // Logo (objeto a subir a AWS S3, es la propiedad "location" del response de uploadFile)
    if (!campo['txtLogo']) {
      formularioValido = false;
      error["txtLogo"] = <FM id='app.general.requerido'/>;
    
    // Si hay logo pero es el de por defecto (sin imagen) se muestra error
    }else if (utils.getStringURL(campo['txtLogo']) === utils.getStringURL(appConst.SIN_LOGO_EMPRESA)){
      formularioValido = false;
      error["txtLogo"] = <FM id='app.general.cambiar-imagen'/>;

    // Si hay web se valida su formato (empezar por https://)
    }else campo['txtLogo'] = utils.validarURL(campo['txtLogo'])

    // Web
    if (!campo['txtUrlWeb']) {
      formularioValido = false;
      error["txtUrlWeb"] = <FM id='app.general.requerido'/>;
    
    // Si hay web se valida su formato (empezar por https://)
    }else campo['txtUrlWeb'] = utils.validarURL(campo['txtUrlWeb'])

    // Servicios
    if (!campo['txtServicios']) {
      formularioValido = false;
      error["txtServicios"] = <FM id='app.general.requerido'/>;
    
    // Si hay web se valida su formato (empezar por https://)
    }else campo['txtServicios'] = utils.validarURL(campo['txtServicios'])

    // Noticia (opcional pero si llega validar formato)
    if (campo['txtUrlNoticia']) campo['txtUrlNoticia'] = utils.validarURL(campo['txtUrlNoticia'])

    // Vídeo (opcional pero si llega validar formato)
    if (campo['txtUrlVideo']) campo['txtUrlVideo'] = utils.validarURL(campo['txtUrlVideo'])

    // Logo
    if (!campo['txtLogo']) {
      formularioValido = false;
      error["txtLogo"] = <FM id='app.general.requerido'/>;
    
    // Si hay logo pero es el de por defecto (sin imagen) se muestra error
    }else if (campo['txtLogo'] === appConst.SIN_LOGO_EMPRESA){
      formularioValido = false;
      error["txtLogo"] = <FM id='app.general.cambiar-imagen'/>;

    // Si hay logo se valida su formato (empezar por https://)
    }else campo['txtLogo'] = utils.validarURL(campo['txtLogo'])

    // Sector
    if (!campo['cmbSector']) {
      formularioValido = false;
      error["cmbSector"] = <FM id='app.general.requerido'/>;
    }

    // Tipo de perfil (tipo de Padre)
    if (!campo['cmbTipoPadre']) {
      formularioValido = false;
      error["cmbTipoPadre"] = <FM id='app.general.requerido'/>;
    }

    // Descripción
    if (!campo['txtDescripcion']) {
      formularioValido = false;
      error["txtDescripcion"] = <FM id='app.general.requerido'/>;
    }else if(campo['txtDescripcion'].length > 4000) {
      formularioValido = false;
      error["txtDescripcion"] = <FM id='app.general.limiteCaracteres.desc'/>;
    }

    // Seteo el estado de error 
    if (!formularioValido)
      this.setState({
        error,
        textoAlert: <FM id="app.general.form.revisarCampos" />,
        tipoAlert: appConst.ALERT_TIPO_WARNING
      });
    
    // Se limpian las validaciones por cada campo
    else this.setState({ error: {} });
  
    return formularioValido;
  }

  // Se detecta cuando un campo del formulario es rellenado y por ende cambia de estado 
  detectarCambio(field, e) {
      
      let campo = this.state.campo;
      if (e.target.type === 'checkbox'){
        campo[field] = e.target.checked;
      }else{
        campo[field] = e.target.value;
      }

      // Cambio de estado de campo 
      this.setState({
        campo,
        textoAlert: '',
        tipoAlert: ''
      });
  }

  showUsuario(){

    document.querySelector("#capaUsuario").className = "row mx-4 mt-3";
    document.querySelector("#capaEmpresa").className = "hideElement";
    document.querySelector("#itemTabUsuario").className = "icon-user hand nav-link active";
    document.querySelector("#itemTabEmpresa").className = "icon-empresa hand nav-link";
  }

  showEmpresa(){

    document.querySelector("#capaEmpresa").className = "row mx-4 mt-3 link";
    document.querySelector("#capaUsuario").className = "hideElement";
    document.querySelector("#itemTabUsuario").className = "icon-user hand nav-link";
    document.querySelector("#itemTabEmpresa").className = "icon-empresa hand nav-link active";
  }

  showMessageUpdateToken(){

    this.setState({
      textoAlert: <FM id="app.hijo.general.refreshToken" />,
      tipoAlert: appConst.ALERT_TIPO_SUCCESS,
      closeAlert: appConst.ALERT_CLOSE
    });
  }

  async uploadFileToS3(){

    const locationS3 = await utils.uploadFileToS3("fileInput");
    if (locationS3 != -1){
      let campo = this.state.campo;
      campo["txtLogo"] = locationS3;
      this.setState({
        campo,
        textoAlert: ""
      });
    
    }else if (locationS3 === -1){
      this.setState({
        textoAlert: <FM id="app.general.mimetype.imagen.error" values={{mimetype: appConst.TYPE_IMAGES}}/>,
        tipoAlert: appConst.ALERT_TIPO_WARNING,
        closeAlert: appConst.ALERT_CLOSE
      });
    }
  }

  render(){ 
    return(
      <div>
        <Header/>
        
        <div className="contenedorPadre">
          
          {/* MIGAS DE PAN */}
          <ol className="migaPan">
              <li className="enLineaFin icon-user"> <FM id="app.padre.mi-perfil.title" /></li>
          </ol>

          {/* INI CONTENEDOR */}        
          <div className="contenedor">
  
            <Alert texto={this.state.textoAlert} tipo={this.state.tipoAlert} close={this.state.closeAlert}/>
          
            <div className="mx-auto cajaBlanca" style={{width: "80%"}}>

              <div className="row">
                <div className="col-md-12">
                  
                  <div className="row p-4 me-2">
                    <div className="col-md-6">
                      <h6 className="subtitle1"><FM id='app.padre.mi-perfil.cabecera'/></h6>
                      <span className="subtitle2"><FM id='app.padre.mi-perfil.sub-cabecera'/></span>
                    </div>
                    <div className="col-md-5 text-end">
                      <span className="">
                        {utils.getUsuarioLogeado(appConst.PERFIL_PADRE, true)}&nbsp;
                        {utils.isAdmin(appConst.PERFIL_PADRE) && <span className="prosa">[admin]</span>}<br/>
                        <span className="prosa text-end" data-bs-toggle="tooltip" title={this.props.intl.formatMessage( {id: 'app.padre.mi-perfil.form.label.fecha-alta'} )}>{this.state.campo["fechaAlta"]}</span>
                      </span>
                    </div>
                    <div className="col-md-1">
                      <span><label className="avatar">{utils.getAvatar(appConst.PERFIL_PADRE)}</label></span>
                    </div>
                  </div>

                  {/* INI MENU TABS */}
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <span className="icon-user hand nav-link active" id="itemTabUsuario" aria-current="page" onClick={this.showUsuario}> <FM id="app.padre.mi-perfil.form-group.usuario" /></span>
                    </li>
                    <li className="nav-item">
                        <span className="icon-empresa hand nav-link" id="itemTabEmpresa" onClick={this.showEmpresa}> <FM id="app.padre.mi-perfil.form-group.empresa" /></span>
                    </li>
                  </ul>
                  
                  {/* INI FORM USUARIO */}
                  <form id="formUsuario">

                    <div className="row mx-4 mt-3" id="capaUsuario">
                    
                      <div className="row pt-2 ps-4">
                        <div className="col-md-6">
                          
                          <div><span className="label-form"><FM id='app.padre.mi-perfil.form.label.nombre'/></span></div>
                          <input type="text" className="input-css full-width" name="txtNombre" id="txtNombre" maxLength="100" onChange={this.detectarCambio.bind(this, "txtNombre")} value={this.state.campo["txtNombre"]} />
                          <span className="form-error">{this.state.error["txtNombre"]}</span>
                          
                        </div>
                        <div className="col-md-6">
                          
                          <div><span className="label-form"><FM id='app.padre.mi-perfil.form.label.apellidos'/></span></div>
                          <input type="text" className="input-css full-width" name="txtApellidos" id="txtApellidos" maxLength="100" onChange={this.detectarCambio.bind(this, "txtApellidos")} value={this.state.campo["txtApellidos"]} />
                          <span className="form-error">{this.state.error["txtApellidos"]}</span>

                        </div>
                      </div>

                      <div className="row pt-2 ps-4">
                        <div className="col-md-6">
                          
                        <div><span className="label-form"><FM id='app.padre.mi-perfil.form.label.email'/></span></div>
                          <input type="text" className="input-css full-width campoRequerido" name="txtEmail" id="txtEmail" readOnly maxLength="100" value={this.state.campo["txtEmail"]} />
                          <span className="form-error">{this.state.error["txtEmail"]}</span>
                          
                        </div>
                        <div className="col-md-6">
                          
                          <div><span className="label-form"><FM id='app.padre.mi-perfil.form.label.cargo'/></span></div>
                          <select id="cmbCargo" name="cmbCargo" className="select-css full-width" onChange={this.detectarCambio.bind(this, "cmbCargo")}>
                            <option value="">{this.props.intl.formatMessage( {id: 'app.general.combos.selecciono'} )}</option>
                            <CargarMaestroDatos
                                tabla={appConst.MAESTRO_CARGOS}
                                preSelected={this.state.campo['cmbCargo']}
                                perfil={appConst.PERFIL_PADRE}
                            />
                          </select>
                          <span className="form-error">{this.state.error["cmbCargo"]}</span>

                        </div>
                      </div>

                      <div className="row py-3 ps-4">
                        <div className="col-md-12">
                          
                          <div><input type="checkbox" className="check" id="chkPublicidad" name="chkPublicidad" onChange={this.detectarCambio.bind(this, "chkPublicidad")} checked={this.state.campo["chkPublicidad"]}/><label htmlFor="chkPublicidad" className="label-check"> <FM id='app.padre.mi-perfil.form.label.publicidad'/></label></div>
                          <span className="form-error">{this.state.error["chkPublicidad"]}</span>          

                        </div>
                      </div>
                      
                      <div className="separador mb-3"></div>

                      <div className="row pt-2 ps-4">
                        <div className="col-md-12 mb-3">
                          
                          <button type="button" className="btn btn-md btn-primary" onClick={this.guardarUsuario}><span className="label-button"><FM id='app.padre.mi-perfil.form.button.guardarUsuario'/></span></button>

                        </div>
                      </div>

                    </div>
                  </form>
                  {/* FIN FORM USUARIO */}

                  {/* INI FORM EMPRESA */}
                  <form id="formEmpresa">
                    
                    <input type="hidden" name="txtTipoEmpresa" id="txtTipoEmpresa"  value={this.state.campo["txtTipoEmpresa"]} />

                    <div className="hideElement" id="capaEmpresa">
                    
                      {/* SUBIR LOGO */}
                      <div className="row pt-2 ps-4">
                        <div className="col-md-6 pt-2">

                          {<span className="position-relative hand">
                              <label className='' for="fileInput">
                                <img className="imgEmpresa hand" src={this.state.campo["txtLogo"]} width="100px" height="100px" data-bs-toggle="tooltip" title={"Logo de tu empresa"}/>
                              </label>
                              <Badge 
                                  id={"logo"}
                                  icono={"icon-plus"}
                                  fondo={"bg-success"}
                              />
                          </span>}

                          <input type="file" id="fileInput" onChange={() => this.uploadFileToS3()} className='hideElement'/>
                          <input type="hidden" className="input-css full-width" name="txtLogo" id="txtLogo" value={this.state.campo["txtLogo"]}/>
                          <br/><span className="form-error">{this.state.error["txtLogo"]}</span>
                          
                        </div>

                        <div className="col-md-6">

                          <div className="row">
                            <div className="col-md-6">
                              <div><span className="label-form"><FM id='app.padre.mi-perfil.form.label.nif'/></span></div>
                              <input type="text" className="input-css full-width" name="txtNif" id="txtNif" maxLength="50" onChange={this.detectarCambio.bind(this, "txtNif")} value={this.state.campo["txtNif"]} />
                              <span className="form-error">{this.state.error["txtNif"]}</span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div><span className="label-form"><FM id='app.padre.mi-perfil.form.label.razon-social'/></span></div>
                              <input type="text" className="input-css full-width" name="txtRazonSocial" id="txtRazonSocial" maxLength="50" onChange={this.detectarCambio.bind(this, "txtRazonSocial")} value={this.state.campo["txtRazonSocial"]} />
                              <span className="form-error">{this.state.error["txtRazonSocial"]}</span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div><span className="label-form"><FM id='app.padre.mi-perfil.form.label.nombre-empresa'/></span></div>
                              <input type="text" className="input-css full-width" name="txtNombreEmpresa" id="txtNombreEmpresa" maxLength="100" onChange={this.detectarCambio.bind(this, "txtNombreEmpresa")} value={this.state.campo["txtNombreEmpresa"]} />
                              <span className="form-error">{this.state.error["txtNombreEmpresa"]}</span>
                            </div>
                          </div>
                          
                        </div>

                      </div>

                      <div className="row pt-4 ps-4">
                        <div className="col-md-4">
                          
                          <div><span className="label-form"><FM id='app.padre.mi-perfil.form.label.direccion'/></span></div>
                          <input type="text" className="input-css full-width" name="txtDireccion" id="txtDireccion" maxLength="300" onChange={this.detectarCambio.bind(this, "txtDireccion")} value={this.state.campo["txtDireccion"]} />
                          <span className="form-error">{this.state.error["txtDireccion"]}</span>

                        </div>
                        <div className="col-md-2">
                          
                          <div><span className="label-form"><FM id='app.padre.mi-perfil.form.label.cp'/></span></div>
                          <input type="text" className="input-css full-width" name="txtCP" id="txtCP" maxLength="100" onChange={this.detectarCambio.bind(this, "txtCP")} value={this.state.campo["txtCP"]} />
                          <span className="form-error">{this.state.error["txtCP"]}</span>

                        </div>
                        <div className="col-md-3">
                          
                          <div><span className="label-form"><FM id='app.padre.mi-perfil.form.label.localidad'/></span></div>
                          <input type="text" className="input-css full-width" name="txtLocalidad" id="txtLocalidad" maxLength="100" onChange={this.detectarCambio.bind(this, "txtLocalidad")} value={this.state.campo["txtLocalidad"]} />
                          <span className="form-error">{this.state.error["txtLocalidad"]}</span>
                          
                        </div>
                        <div className="col-md-3">
                          
                          <div><span className="label-form"><FM id='app.padre.mi-perfil.form.label.provincia'/></span></div>
                          <input type="text" className="input-css full-width" name="txtProvincia" id="txtProvincia" maxLength="100" onChange={this.detectarCambio.bind(this, "txtProvincia")} value={this.state.campo["txtProvincia"]} />
                          <span className="form-error">{this.state.error["txtProvincia"]}</span>

                        </div>
                      </div>

                      <div className="row pt-2 ps-4">
                        <div className="col-md-4">
                          
                          <div><span className="label-form"><FM id='app.padre.mi-perfil.form.label.email-empresa'/></span></div>
                          <input type="text" className="input-css full-width" name="txtEmailEmpresa" id="txtEmailEmpresa" maxLength="100" onChange={this.detectarCambio.bind(this, "txtEmailEmpresa")} value={this.state.campo["txtEmailEmpresa"]} />
                          <span className="form-error">{this.state.error["txtEmailEmpresa"]}</span>
                          
                        </div>
                        <div className="col-md-2">
                          
                          <div><span className="label-form"><FM id='app.padre.mi-perfil.form.label.telefono-empresa'/></span></div>
                          <input type="text" className="input-css-sm full-width" name="txtTelefono" id="txtTelefono" maxLength="13" onChange={this.detectarCambio.bind(this, "txtTelefono")} value={this.state.campo["txtTelefono"]} />
                          <span className="form-error">{this.state.error["txtTelefono"]}</span>

                        </div>
                        <div className="col-md-6">
                          
                          <div><span className="label-form"><FM id='app.padre.mi-perfil.form.label.url-web'/></span></div>
                          <input type="text" className="input-css full-width" name="txtUrlWeb" id="txtUrlWeb" maxLength="2000" onChange={this.detectarCambio.bind(this, "txtUrlWeb")} value={this.state.campo["txtUrlWeb"]} />
                          <span className="form-error">{this.state.error["txtUrlWeb"]}</span>
                          
                        </div>
                      </div>

                      <div className="row pt-2 ps-4">
                        <div className="col-md-12">
                          
                          <div><span className="label-form"><FM id='app.padre.mi-perfil.form.label.servicios'/> <span className='icon-error prosa' data-bs-toggle="tooltip" title={"Este link será visible y accesible a todas las empresas de tu mundo"}></span></span></div>
                          <input type="text" className="input-css full-width" name="txtServicios" id="txtServicios" maxLength="2000" onChange={this.detectarCambio.bind(this, "txtServicios")} value={this.state.campo["txtServicios"]} />
                          <span className="form-error">{this.state.error["txtServicios"]}</span>
                        
                        </div>
                      </div>

                      <div className="row pt-2 ps-4">
                        <div className="col-md-6">
                          
                          <div><span className="float-start label-form"><FM id='app.padre.mi-perfil.form.label.url-video'/></span></div><div className="float-end prosa"><FM id="app.general.campo-opcional" /></div>
                          <input type="text" className="input-css full-width" name="txtUrlVideo" id="txtUrlVideo" maxLength="2000" onChange={this.detectarCambio.bind(this, "txtUrlVideo")} value={this.state.campo["txtUrlVideo"]} />
                          <span className="form-error">{this.state.error["txtUrlVideo"]}</span>
                        
                        </div>
                        <div className="col-md-6">
                          
                          <div><span className="float-start label-form"><FM id='app.padre.mi-perfil.form.label.url-noticia'/></span></div><div className="float-end prosa"><FM id="app.general.campo-opcional" /></div>
                          <input type="text" className="input-css full-width" name="txtUrlNoticia" id="txtUrlNoticia" maxLength="2000" onChange={this.detectarCambio.bind(this, "txtUrlNoticia")} value={this.state.campo["txtUrlNoticia"]} />
                          <span className="form-error">{this.state.error["txtUrlNoticia"]}</span>
                        
                        </div>
                      </div>

                      <div className="row pt-2 ps-4">
                        <div className="col-md-6">
                          
                          <div><span className="label-form"><FM id='app.padre.mi-perfil.form.label.sector'/></span></div>
                          <select id="cmbSector" name="cmbSector" className="select-css full-width" onChange={this.detectarCambio.bind(this, "cmbSector")}>
                            <option value="">{this.props.intl.formatMessage( {id: 'app.general.combos.selecciono'} )}</option>
                            <CargarMaestroDatos
                                tabla={appConst.MAESTRO_SECTORES}
                                preSelected={this.state.campo['cmbSector']}
                                perfil={appConst.PERFIL_PADRE}
                            />
                          </select>
                          <span className="form-error">{this.state.error["cmbSector"]}</span>

                        </div>
                        <div className="col-md-6">
                          
                          <div><span className="label-form"><FM id='app.padre.mi-perfil.form.label.tipo-perfil'/></span></div>
                          <select id="cmbTipoPadre" name="cmbTipoPadre" className="select-css full-width" onChange={this.detectarCambio.bind(this, "cmbTipoPadre")}>
                            <option value="">{this.props.intl.formatMessage( {id: 'app.general.combos.selecciono'} )}</option>
                            <CargarMaestroDatos
                                tabla={appConst.MAESTRO_TIPOS_PADRE}
                                preSelected={this.state.campo["cmbTipoPadre"]}
                                perfil={appConst.PERFIL_PADRE}
                            />
                          </select>
                          <span className="form-error">{this.state.error["cmbTipoPadre"]}</span>
                        
                        </div>
                      </div>

                      <div className="row py-3 ps-4">
                        <div className="col-md-12">
                          
                          <div><span className="label-form"><FM id='app.padre.mi-perfil.form.label.descripcion'/></span></div>
                          <textarea className="textArea-css full-width" name="txtDescripcion" id="txtDescripcion" rows="10" maxLength="4000" onChange={this.detectarCambio.bind(this, "txtDescripcion")}>{this.state.campo["txtDescripcion"]}</textarea>
                          <span className="form-error">{this.state.error["txtDescripcion"]}</span>
                        
                        </div>
                      </div>
                      
                      <div className="separador mb-3"></div>

                      <div className="row pt-2 ps-4">
                        <div className="col-md-12 mb-3">
                          
                          <button type="button" className="btn btn-md btn-primary" id="btnGuardarEmpresa" onClick={this.guardarEmpresa}><span className="label-button"><FM id='app.padre.mi-perfil.form.button.guardarEmpresa'/></span></button>

                        </div>
                      </div>

                    </div>
                  </form>
                  {/* FIN FORM EMPRESA */}

                </div>
              </div>
            </div>
              
            
          </div>
          {/* FIN CONTENEDOR */}

        </div>

        <Footer />
      </div>
    )
  }
}

export default injectIntl(MiPerfil);