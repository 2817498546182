import React, { Component } from 'react';
import axios from 'axios';
import {injectIntl, FormattedMessage as FM} from 'react-intl';
import * as appConst from './../../common/const/Const';
import * as utils from './../../common/utils/Funcs';
import CargarMaestroDatos from './../../common/datos/CargarMaestroDatos';
import CargarListaDatos from './../../common/datos/CargarListaDatos';
import Alert from '../../common/utils/Alert';
import EditorHTML from '../../common/editor/EditorHTML';
import Confirmacion from './../../common/utils/Confirmacion';

class DetalleTexto extends Component {

    constructor (props){
        super(props);
        this.state = {
            campo: {},
            initEditor: true,
            error: {},
            operacion: appConst.EDITAR_TEXTO,
            reloadCargarListaDatos: false,
            textoAlert: '',
            tipoAlert: '',
            closeAlert: appConst.ALERT_OPEN
        };
        
        this.cancelar = this.cancelar.bind(this);
        this.iniSetNuevoTexto = this.iniSetNuevoTexto.bind(this);
        this.guardar = this.guardar.bind(this);
        this.nuevoTexto = this.nuevoTexto.bind(this);
        this.editarTexto = this.editarTexto.bind(this);
        this.eliminar = this.eliminar.bind(this);
        this.onChangeMisTextos = this.onChangeMisTextos.bind(this);
    }

    componentDidMount(){

        // Inicialización de los tooltip y popover de este componente
        utils.iniTooltip();

        // Al estar oculto el combo de tipos de match (porque solo hay un tipo por hoy) se precarga su estado
        this.precargarTipoTextoMatch();

        // Precarga los eventos (confirmación para eliminar plantilla)
        this.precargarEventos();
    }

    precargarTipoTextoMatch(){

        let campo = this.state.campo;
        campo["cmbTiposTexto"] = appConst.TIPO_TEXTO_MATCH;
        this.setState( {campo} );
    }

    precargarEventos(){

        const obj = this;
        const modal = document.querySelector('#confirm_eliminar_plantilla');

        // Acciones antes de cargarse el DOM de la modal
        modal.addEventListener('show.bs.modal', function (e) {

            if (!obj.hayPlantillaSeleccionada()){
                e.preventDefault();
                obj.setState({
                    textoAlert: <FM id="app.padre.textos.validar.sin-seleccion" />,
                    tipoAlert: appConst.ALERT_TIPO_WARNING,
                    closeAlert: appConst.ALERT_CLOSE
                });
            }
        });
    }

    iniSetNuevoTexto(){

        // Se oculta el tooltip
        utils.hideTooltip("btnCrearTexto");

        // Se oculta la edición
        document.querySelector("#fieldsetMisTextos").className = "hideElement";

        // Se muestran los campos asunto y cuerpo
        document.querySelector("#fieldsetTexto").className = "mt-3 mb-5 px-4 pb-3";
        
        // Se vacía el estado de los campos asunto y cuerpo
        let campo = this.state.campo;
        campo["txtAsunto"] = "";
        campo["textAreaCuerpo"] = "";
        
        this.setState( {
            campo,
            operacion: appConst.NUEVO_TEXTO,
            textoAlert: "",
            tipoAlert: ""
        } );

        this.initEditor();
    }

    guardar(){

        const data = {
            "texto": this.state.campo["txtAsunto"] + appConst.SEPARADOR_TEXTO_MATCH + this.state.campo["textAreaCuerpo"],
            "idTipoTexto": this.state.campo["cmbTiposTexto"],
            "idPadre": utils.getTokenValue("idPadre", appConst.PERFIL_PADRE),
            "baja": appConst.NO_BAJA_LOGICA,
            "idAutor": utils.getTokenValue("idUsuario", appConst.PERFIL_PADRE)
        }

        if (this.state.operacion === appConst.NUEVO_TEXTO)
            this.nuevoTexto(data);

        else if (this.state.operacion === appConst.EDITAR_TEXTO)
            this.editarTexto(data);
    }

    async nuevoTexto(data){

        if (this.esFormValido()){

            try{
                const response = await axios.post(appConst.POST_TEXTO, data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)} )
                
                // Se actualiza la lista de mis plantillas con los cambios porque el combo se refresca al cambiar de valor de reloadCargarListaDatos a modo de toggle
                if (response && response.data && response.status === appConst.HTTPSTATUS_CREATED){
                    if (utils.getLog()) console.log("status POST_TEXTO: "+response.status);
                    
                    this.setState( prevState => ({
                        reloadCargarListaDatos: !prevState.reloadCargarListaDatos,
                        textoAlert: <FM id="app.padre.textos.mensajes.crear" />,
                        tipoAlert: appConst.ALERT_TIPO_SUCCESS,
                        closeAlert: appConst.ALERT_CLOSE
                    }) );
                }

            }catch(error){
                if (utils.getLog()) console.log("Error POST_TEXTO: "+error);
                this.setState({
                    textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.nuevoTexto, [data], appConst.PERFIL_PADRE),
                    tipoAlert: utils.getTipoAlert(error),
                    closeAlert: appConst.ALERT_OPEN
                });
            }
            
            // Se vuelve a dejar la pantalla en modo edición, como su estado inicial
            this.cancelar();
        }
    }

    async editarTexto(data){
        
        if (this.esFormValido()){

            try{
                const response = await axios.put(appConst.PUT_TEXTO.replace("#idTexto", this.state.campo["cmbMisTextos"]), data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)} )
                
                if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
                    if (utils.getLog()) console.log("status PUT_TEXTO: "+response.status);
                    
                    // Se actualiza la lista de mis plantillas con los cambios porque el combo se refresca al cambiar de valor de reloadCargarListaDatos a modo de toggle
                    this.setState( prevState => ({
                        reloadCargarListaDatos: !prevState.reloadCargarListaDatos,
                        textoAlert: <FM id="app.padre.textos.mensajes.editar" />,
                        tipoAlert: appConst.ALERT_TIPO_SUCCESS,
                        closeAlert: appConst.ALERT_CLOSE
                    }) );
                }

            }catch(error){
                if (utils.getLog()) console.log("Error PUT_TEXTO: "+error);
                this.setState({
                    textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.editarTexto, [data], appConst.PERFIL_PADRE),
                    tipoAlert: utils.getTipoAlert(error),
                    closeAlert: appConst.ALERT_OPEN
                });
            }
        }
    }

    hayPlantillaSeleccionada(){

        return (document.querySelector("#cmbMisTextos").selectedIndex > 0);
    }

    async eliminar(){
        
        // Se oculta el tooltip
        utils.hideTooltip("btnEliminarTexto");

        if (this.hayPlantillaSeleccionada()){

            try{
                const response = await axios.delete(appConst.DELETE_TEXTO.replace("#idTexto", this.state.campo["cmbMisTextos"]), {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)} )
                
                // Se actualiza la lista de mis plantillas con los cambios porque el combo se refresca al cambiar de valor de reloadCargarListaDatos a modo de toggle
                if (response && response.status === appConst.HTTPSTATUS_OK){
                    if (utils.getLog()) console.log("status DELETE_TEXTO: "+response.status);
                    
                    // Tras un eliminado con éxito, se pone la pantalla en modo inicial (edición y sin texto seleccionado de Mis textos)
                    this.cancelar();

                    this.setState( prevState => ({
                        reloadCargarListaDatos: !prevState.reloadCargarListaDatos,
                        textoAlert: <FM id="app.padre.textos.mensajes.eliminar" />,
                        tipoAlert: appConst.ALERT_TIPO_SUCCESS,
                        closeAlert: appConst.ALERT_CLOSE
                    }) );
                }

            }catch(error){
                if (utils.getLog()) console.log("Error DELETE_TEXTO: "+error);
                this.setState({
                    textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.eliminar, [], appConst.PERFIL_PADRE),
                    tipoAlert: utils.getTipoAlert(error),
                    closeAlert: appConst.ALERT_OPEN
                });
            }

        }else{
            this.setState({
                textoAlert: <FM id="app.padre.textos.validar.sin-seleccion" />,
                tipoAlert: appConst.ALERT_TIPO_WARNING,
                closeAlert: appConst.ALERT_CLOSE
            });
        }
    }
    
    detectarCambio(field, e) {

        let campo = this.state.campo;

        // Actualización de estado del editor. Param "e" = valor del editor
        if (field === "textAreaCuerpo"){
            campo[field] = e;

        // Actualización de estado del resto de campos. Param "e" = evento
        }else{
            if (e.target.type === 'checkbox')
                campo[field] = e.target.checked;
            else
                campo[field] = e.target.value;
        }

        this.setState({ 
            campo,
            initEditor: false,
            error: {},
            textoAlert: "",
            tipoAlert: ""
        });
    }

    esFormValido(){

        let campo = this.state.campo;
        let errorForm = {};
        let formularioValido = true;

        // Asunto
        if (!campo["txtAsunto"]){
            formularioValido = false;
            errorForm["txtAsunto"] = <FM id='app.general.requerido'/>;
        }

        // Cuerpo
        if (!campo['textAreaCuerpo']) {
            formularioValido = false;
            errorForm["textAreaCuerpo"] = <FM id='app.general.requerido'/>;
        }

        // Seteo el estado de error 
        if (!formularioValido)
            this.setState({
                error: errorForm,
                textoAlert: <FM id="app.general.form.revisarCampos" />,
                tipoAlert: appConst.ALERT_TIPO_WARNING,
                closeAlert: appConst.ALERT_CLOSE
            });
        
        // Se limpian las validaciones por cada campo
        else this.setState({ error: {} });
        
        return formularioValido;
    }

    getTexto(texto, indiceTipoCampo){
        
        return (texto.split(appConst.SEPARADOR_TEXTO_MATCH)[indiceTipoCampo] !== undefined) ? texto.split(appConst.SEPARADOR_TEXTO_MATCH)[indiceTipoCampo] : this.props.intl.formatMessage({id:"app.padre.textos.validar.sin-texto"});
    }

    setPreview(e){
        
        // Hay opción seleccionada
        if (document.querySelector("#cmbMisTextos").selectedIndex > 0){
            // Se hace visible el preview
            document.querySelector("#fieldsetTexto").className = "mt-3 mb-5 px-4 pb-3";

            // Se asignan los valores del combo en los campos Input y TextArea y se actualiza el estado del asunto y cuerpo
            //const textoMatch = document.querySelector("#cmbMisTextos").options[document.querySelector("#cmbMisTextos").selectedIndex].text
            const textoMatch = utils.getAtributeCombo("cmbMisTextos", "textobd");
            let campo = this.state.campo;
            campo["txtAsunto"] = this.getTexto(textoMatch, 0);
            campo["textAreaCuerpo"] = this.getTexto(textoMatch, 1);

            this.setState( {
                campo,
                operacion: appConst.EDITAR_TEXTO,
                textoAlert: "",
                tipoAlert: ""
            } );
        
        // No hay opción seleccionada
        }else this.cancelar();
    }

    initEditor(){
        this.setState({ initEditor: true });
    }

    cancelar(){

        /* Se vuelve al modo inicial (modo edición y ningún texto seleccionado en Mis Textos) */

        // Índice 0 ("Selecciona uno")
        document.querySelector("#cmbMisTextos").selectedIndex = 0;
        
        // Se muestra el combo de mis texto (en la edición ya estará visible, es para cuando es nuevo porque queda oculto esto al crear)
        document.querySelector("#fieldsetMisTextos").className = "mt-3 mb-5 px-4 pb-3";

        // Se ocultan el asunto y cuerpo
        document.querySelector("#fieldsetTexto").className = "hideElement";

        // Se quitan los posibles avisos de formulario incompleto
        this.setState({ error: {} });
    }
    
    onChangeMisTextos(e){

        this.setPreview(e);
        this.detectarCambio("cmbMisTextos", e);
        this.initEditor();
    }

    render(){ 
        return (
            <div className="px-3">

                <div className="row">
                    <div className="col-sm-12">
                        <Alert texto={this.state.textoAlert} tipo={this.state.tipoAlert} close={this.state.closeAlert}/>
                    </div>
                </div>

                {/* TIPOS DE TEXTO (no se muestra por pantalla porque solo hay un tipo, si se quiere mostrar cambiar "hideElement" por "row") */}
                <div className="hideElement">
                    <div className="col-sm-12 mb-3">
                        
                        <div><span className="label-form"><FM id='app.padre.textos.form.label.tiposTexto'/></span></div>
                        <select id="cmbTiposTexto" name="cmbTiposTexto" className="select-css full-width" onChange={this.detectarCambio.bind(this, "cmbTiposTexto")}>
                            <CargarMaestroDatos
                                tabla={appConst.MAESTRO_TIPOS_TEXTOS}
                                descripcion={true}
                                perfil={appConst.PERFIL_PADRE}
                            />
                        </select>

                    </div>
                </div>

                <fieldset id="fieldsetMisTextos" className="mt-3 mb-5 px-4 pb-3">
                    <leyend><FM id="app.padre.textos.grupo.mis.textos" /></leyend>
                    {/* MIS TEXTOS */}
                    <div className="row mb-3">
                        <div className="col-sm-12">
                        
                            <div><span className="label-form"><FM id='app.padre.textos.form.label.misTextos'/></span></div>
                            <select id="cmbMisTextos" name="cmbMisTextos" className="select-css full-width" onChange={this.onChangeMisTextos}>
                                <option value="">{this.props.intl.formatMessage( {id: 'app.general.combos.selecciona'} )}</option>
                                <CargarListaDatos
                                    endpoint={appConst.GET_TEXTOS}
                                    valueField={appConst.TABLA_TEXTOS_CAMPO_VALOR}
                                    textField1={appConst.TABLA_TEXTOS_CAMPO_TEXTO1}
                                    paramsEndpoint={{
                                        idPadre: utils.getTokenValue("idPadre", appConst.PERFIL_PADRE),
                                        idTipoTexto: appConst.TIPO_TEXTO_MATCH
                                    }}
                                    perfil={appConst.PERFIL_PADRE}
                                    reload={this.state.reloadCargarListaDatos}
                                    sinHTML={true}
                                />
                            </select>

                        </div>
                    </div>

                    <div className="row float-end">
                        <div className="col-sm-12">
                            <button className="icon-new-1 btn btn-sm btn-primary me-3" id="btnCrearTexto" data-bs-toggle="tooltip" title={this.props.intl.formatMessage({id:"app.padre.textos.botonera.tooltip.crear"})} onClick={this.iniSetNuevoTexto}></button>
                            <button className="btn btn-sm btn-primary" id="btnEliminarTexto" data-bs-toggle="modal" data-bs-target={"#confirm_eliminar_plantilla"}><span className='icon-cancel' data-bs-toggle="tooltip" title={this.props.intl.formatMessage({id:"app.padre.textos.botonera.tooltip.eliminar"})}></span></button>
                        </div>
                    </div>
                </fieldset>

                <fieldset id="fieldsetTexto" className="hideElement">
                    <leyend>{(this.state.operacion === appConst.NUEVO_TEXTO) ? <FM id="app.padre.textos.grupo.nuevo-texto" /> : <FM id="app.padre.textos.grupo.editar-texto" />}</leyend>
                    {/* CAMPO ASUNTO */}
                    <div className="row mt-3 mb-3">
                        <div className="col-sm-12">
                            <div><span className="label-form"><FM id='app.padre.textos.form.label.asunto'/></span></div>
                            <input className="input-css full-width" type="text" id="txtAsunto" onChange={this.detectarCambio.bind(this, "txtAsunto")} placeholder={this.props.intl.formatMessage({id:"app.padre.textos.form.placeholder.asunto"})} value={this.state.campo["txtAsunto"]} />
                            <span className="form-error">{this.state.error["txtAsunto"]}</span>
                        </div>
                    </div>

                    {/* CAMPO CUERPO */}
                    <div className="row mb-3">
                        <div className="col-sm-12 ">
                            <div><span className="label-form"><FM id='app.padre.textos.form.label.cuerpo'/></span></div>
                            <EditorHTML
                                id={"textAreaCuerpo"}
                                value={this.state.campo["textAreaCuerpo"]}
                                init={this.state.initEditor}
                                placeholder={this.props.intl.formatMessage({id:"app.padre.textos.form.placeholder.cuerpo"})}
                                readonly={false}
                                toolbar={true}
                                minHeight={150}
                                _onChange={this.detectarCambio.bind(this)}
                            />
                            <span className="form-error">{this.state.error["textAreaCuerpo"]}</span>
                        </div>
                    </div>
                    
                    {/* BOTONERA */}
                    <div className="text-center">
                        <span className="icon-check btn btn-sm btn-primary me-4" id="guardarTag" onClick={this.guardar}> <FM id="app.padre.textos.form.botonera.guardar" /></span>
                        <span className="icon-cancel btn btn-sm btn-primary" id="cancelarTag" onClick={this.cancelar}> <FM id="app.padre.textos.form.botonera.cancelar" /></span>
                    </div>
                </fieldset>

                {/* Confirmación de eliminación de una plantilla */}
                <Confirmacion
                    id={"eliminar_plantilla"}
                    estiloHeader={"icon-delete icono-peke"}
                    titulo={"Eliminar plantilla"}
                    texto={"<p>¿Deseas eliminar la plantilla?</p><p><ul><li><strong>- "+this.state.campo["txtAsunto"]+"</strong></li></ul></p>"}
                    accion={this.eliminar.bind(this)}
                    boton={"Eliminar"}
                />
            </div>
        )
    }
}

export default injectIntl(DetalleTexto);