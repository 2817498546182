import React, { Component } from 'react';
import axios from 'axios';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import Alert from '../../common/utils/Alert';
import * as appConst from '../../common/const/Const';
import * as utils from '../../common/utils/Funcs';
import Badge from '../../common/utils/Badge';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { Link } from 'react-router-dom';
import CargarListaDatos from './../../common/datos/CargarListaDatos';
import EditorHTML from '../../common/editor/EditorHTML';

/* eslint-disable no-use-before-define */
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const classesAutocomplete = {
    label: { style: {
        fontFamily: "Roboto",
        fontSize: "0.8em",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.5,
        letterSpacing: "normal",
        color: "#9ea0a5",
        textTransform: "uppercase"
    }},
    input: { style: {
        fontFamily: "Roboto",
        fontSize: "14px",
        color: 'dimgray'
    }}
};
  
class DetalleMatch extends Component {

    constructor(props){
        super(props);
        this.state = {
            mundosOrigen: [],
            usuariosOrigen:[],
            inputEmpresa1: "",
            inputUsuarioEmpresa1: "",
            mundosDestino: [],
            usuariosDestino:[],
            inputEmpresa2: "",
            inputUsuarioEmpresa2: "",
            campo: {},
            error: {},
            initEditor: true,
            textoAlert: '',
            tipoAlert: '',
            closeAlert: appConst.ALERT_OPEN
        }
        this.invitacion = this.invitacion.bind(this);
        this.clickSeleccionarPlantilla = this.clickSeleccionarPlantilla.bind(this);
        this.setPreview = this.setPreview.bind(this);
        this.goPlantillas = this.goPlantillas.bind(this);
    }

    async componentDidMount(){

        // Inicialización de los tooltip de este componente
        utils.iniTooltip();

        // Carga el combo de empresas del origen y del destino del mundo actual (el del usuario logeado)
        // Se indica async-await para que termine la ejecución de getMundos y en secuencia se lance precargarMatch, pues necesita el estado usuarios
        await this.getMundos();

        // Si la operación es EDICION o VER, es decir, llegan datos del match y el idTransaccion
        if (this.props.location.state && this.props.location.state.idTransaccion) 
            await this.precargarMatch();
        
        // Carga los eventos del popup de cambio de plantilla
        this.cargarEventosModal();
    }

    async cargarEventosModal(){

        const obj = this;
        const modal = document.querySelector('#modalCambiarTexto');
        
        if (modal){
            // Acciones antes de cargarse el DOM de la modal
            modal.addEventListener('show.bs.modal', function (e) {

                // Precarga la primera entrada en el TextArea del popup de cambiar texto
                if (document.querySelector("#cmbTextos").selectedIndex >= 0){
                    
                    //const textoMatch = document.querySelector("#cmbTextos").options[document.querySelector("#cmbTextos").selectedIndex].text;
                    const textoMatch = utils.getAtributeCombo("cmbTextos", "textobd");
                    document.querySelector("#textAreaAsuntoPreview").innerHTML = "<strong>"+obj.getTexto(textoMatch, 0)+"</strong>";
                    document.querySelector("#textAreaCuerpoPreview").innerHTML = "<ul>"+obj.getTexto(textoMatch, 1)+"</ul>";
                }
                // e.explicitOriginalTarget.id === "btnTextoAsunto"
            });
        
            // Acciones al ocultarse la modal
            modal.addEventListener('hide.bs.modal', function (e) {
                
                utils.hideTooltip("ttTextoMatch");
            });
        
        }else{
            this.setState({
                textoAlert: <FM id="app.general.recarga.componente" />,
                tipoAlert: appConst.ALERT_TIPO_WARNING,
                closeAlert: appConst.ALERT_CLOSE
            });
        }
    }

    async precargarMatch(){
    
        try{
            // Se carga la lista de usuarios/cargos dependiente de cada hijo
            await this.setComboCargosUsuarios(this.props.location.state.idEmpresaOrigen, appConst.TIPO_PART_ORIGEN)
            await this.setComboCargosUsuarios(this.props.location.state.idEmpresaDestino, appConst.TIPO_PART_DESTINO)
            
            // Se controla que no se modifique el form si es acción "ver": solo lectura y se ocultan todos los botones excepto la botonera que se trata en el propia botonera
            this.setModoLectura();

            let campo = this.state.campo;
            // Identificación del match
            campo["txtIdTransaccion"] = this.props.location.state.idTransaccion;
            campo["txtNombre"] = this.props.location.state.nombre;
            // Hijo origen
            campo['idParticipanteOrigen'] = this.props.location.state.idParticipanteOrigen;
            campo["imgEmpresa1"] = this.props.location.state.logoEmpresaOrigen;
            campo["txtEmpresaOrigen"] = this.props.location.state.idEmpresaOrigen;
            campo["txtUsuarioEmpresaOrigen"] = this.props.location.state.idUsuarioEmpresaOrigen;
            // Hijo destino
            campo['idParticipanteDestino'] = this.props.location.state.idParticipanteDestino;
            campo["imgEmpresa2"] = this.props.location.state.logoEmpresaDestino;
            campo["txtEmpresaDestino"] = this.props.location.state.idEmpresaDestino;
            campo["txtUsuarioEmpresaDestino"] = this.props.location.state.idUsuarioEmpresaDestino;
            // Contenido match
            campo["txtAsunto"] = this.props.location.state.asunto;
            campo["txtCuerpo"] = this.props.location.state.cuerpo;
            campo["txtIdEstadoTransaccion"] = this.props.location.state.idEstadoTransaccion;
            campo["txtNombreEstadoTransaccion"] = this.props.location.state.nombreEstadoTransaccion;

            this.setState({
                campo,
                inputEmpresa1: this.props.location.state.nombreEmpresaOrigen,
                inputUsuarioEmpresa1: this.props.location.state.nombreUsuarioEmpresaOrigen,
                inputEmpresa2: this.props.location.state.nombreEmpresaDestino,
                inputUsuarioEmpresa2: this.props.location.state.nombreUsuarioEmpresaDestino
            });

        }catch(error){
            if (utils.getLog()) console.log("Error PRECARGA MATCH: "+error);
            console.log(error);
            this.setState({
                textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.precargarMatch.bind(this), [], appConst.PERFIL_PADRE),
                tipoAlert: utils.getTipoAlert(error),
                closeAlert: appConst.ALERT_OPEN
            });
        }
    }

    setModoLectura(){

        if (this.props.operacion === appConst.VER_MATCH){
                
            document.querySelector("#txtNombre").disabled = true;
            document.querySelector("#txtAsunto").disabled = true;
            //document.querySelector("#txtCuerpo").disabled = true;
            document.querySelector("#txtEmpresaOrigen").disabled = true;
            document.querySelector("#txtUsuarioEmpresaOrigen").disabled = true;
            document.querySelector("#txtEmpresaDestino").disabled = true;
            document.querySelector("#txtUsuarioEmpresaDestino").disabled = true;

            document.querySelector("#btnTextoMatch").className = "hideElement";
            //document.querySelector("#btnInvEmp1").className = "hideElement";
            //document.querySelector("#btnInvUser1").className = "hideElement";
            //document.querySelector("#btnInvEmp2").className = "hideElement";
            //document.querySelector("#btnInvUser2").className = "hideElement";
        }
    }

    invitacion(){
        utils.hideTooltip("btnInvEmp1");
        utils.hideTooltip("btnInvUser1");
        utils.hideTooltip("btnInvEmp2");
        utils.hideTooltip("btnInvUser2");
        this.props.history.push(process.env.REACT_APP_WEB_BASE_URL+"/p/invitacion-usuario");
    }

    getNombreUsuarioEmpresaParticipante(usuario){

        return (usuario) ? usuario.nombre+" "+utils.getCleanValue(usuario.apellidos)+" ("+usuario.cargo.nombre+")" : "";
    }

    async getMundos(){

        try{
          const response = await axios.get(appConst.GET_MUNDOS, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE), params: {
            idPadre: utils.getTokenValue("idPadre", appConst.PERFIL_PADRE)
          }} )
          
          if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
                if (utils.getLog()) console.log("status GET_MUNDOS: "+response.status);
            
                this.setState({ 
                    mundosOrigen: response.data,
                    mundosDestino: response.data
                });
          }
    
        }catch(error){
            if (utils.getLog()) console.log("Error GET_MUNDOS: "+error);
            this.setState({
                textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.getMundos.bind(this), [], appConst.PERFIL_PADRE),
                tipoAlert: utils.getTipoAlert(error),
                closeAlert: appConst.ALERT_OPEN
            });
        }
    }

    // INPUT CHANGE EMPRESA
    onInputChangeEmpresaParticipante(event, value, reason, tipo){

        /*alert("onInputChange");
        console.log("onInputChangeEmpresaParticipante");
        console.log("reason: "+reason);
        console.log("value: "+value);*/
        // reason: "input", "reset" or "clear"
        
        // Se resetean los valores de la empresa participante, ya sea por un select o por un clear. Siempre se limpia primero, y si es un select, se cargan los nuevos datos después
        if (reason === "input" || reason === "clear")
            this.clearParticipante(event, tipo, 1);
        
        // Se pinta en el componente la nueva selección de la empresa, cuyo value que llega es el propio String del nombre de la empresa
        //if (value) this.setState({ "inputEmpresa"+tipo: value});
        if (value)
            if (tipo === appConst.TIPO_PART_ORIGEN)
                this.setState({ inputEmpresa1: value});
            else if (tipo === appConst.TIPO_PART_DESTINO)
                this.setState({inputEmpresa2: value});
    }

    // CHANGE EMPRESA
    onChangeEmpresaParticipante(event, value, reason, tipo){

        /*alert("onChange");
        console.log("onInputChangeEmpresaParticipante");
        console.log("reason: "+reason);
        console.log("value: "+value);*/
        // reason: "create-option", "select-option", "remove-option", "blur" or "clear"
            
        // Una nueva selección de un empresa, y debe ser con este evento onChange pq el value viene con el objeto completo que se necesita
        if (reason === "select-option"){

            // Se carga el logo de la empresa seleccionada
            document.querySelector("#imgEmpresa"+tipo).src = value.hijo.empresa.logo;

            // Se cargan los usuarios y cargos de la empresa seleccionada
            this.setComboCargosUsuarios(value.hijo.idEmpresa, tipo);

            // Se cargan los participantes (empresa) en el estado para poder enviarlos al guardar el match
            // event.target.id.split("-")[0] es el id del autocomplete cuyo valor de event.target.id = <id-html>-option-<index-selected> y se coge la primera parte: <id-html>
            this.detectarCambio(event.target.id.split("-")[0], event, true, value.hijo.idEmpresa);

            // Al ser una nueva selección de una empresa, se limpia el input del usuario actual de la empresa anterior
            if (tipo === appConst.TIPO_PART_ORIGEN)
                this.setState({ inputUsuarioEmpresa1: "" });
            else if (tipo === appConst.TIPO_PART_DESTINO)
                this.setState({ inputUsuarioEmpresa2: "" });
        }
    }

    // INPUT CHANGE USUARIO
    onInputChangeUsuarioParticipante(event, value, reason, tipo){
        
        /*alert("onInputChange");
        console.log("onInputChangeUsuarioParticipante");
        console.log("reason: "+reason);
        console.log("value: "+value);*/
        // reason: "input", "reset" or "clear"
        
        // Se resetean los valores de la empresa participante, ya sea por un select o por un clear. Siempre se limpia primero, y si es un select, se cargan los nuevos datos después
        if (reason === "input" || reason === "clear")
            this.clearParticipante(event, tipo, 2);
        
        // Se pinta en el componente la nueva selección de la empresa, cuyo value que llega es el propio String del nombre de la empresa
        if (value)
            if (tipo === appConst.TIPO_PART_ORIGEN)
                this.setState({inputUsuarioEmpresa1: value});
            else if (tipo === appConst.TIPO_PART_DESTINO)
                this.setState({inputUsuarioEmpresa2: value});
    }
    
    // CHANGE USUARIO
    onChangeUsuarioParticipante(event, value, reason, tipo){

        /*alert("onChange");
        console.log("onChangeUsuarioParticipante");
        console.log("reason: "+reason);
        console.log("value: "+value);*/
        // reason: "create-option", "select-option", "remove-option", "blur" or "clear"
        
        // Se cargan los participantes (usuarios de la empresa) en el estado para poder enviarlos al guardar el match
        // event.target.id.split("-")[0] es el id del autocomplete cuyo valor de event.target.id = <id-html>-option-<index-selected> y se coge la primera parte: <id-html>
        if (reason === "select-option")
            this.detectarCambio(event.target.id.split("-")[0], event, true, value.id);
    }

    clearParticipante(event, tipoParticipante, tipoComponente){

        // Se vacía el logo que pudiera haber antes solo si el clear es desde el componente 1 (empresa)
        if (tipoComponente === 1)
            document.querySelector("#imgEmpresa"+tipoParticipante).src = appConst.SIN_LOGO_EMPRESA;

        if (tipoParticipante === appConst.TIPO_PART_ORIGEN){
            
            // Se vacía el valor actual de la empresa (input) si se procede del evento del componente del origen 1, no del 2
            if (tipoComponente === 1)
                this.detectarCambio("txtEmpresaOrigen", event, true, null);
        
            // Se vacía el valor actual del usuario (input)
            this.detectarCambio("txtUsuarioEmpresaOrigen", event, true, null);
        
            // Se vacía la lista de opciones del combo de usuarios que pudiera haber antes
            this.setState({ 
                usuariosOrigen: (tipoComponente === 1) ? [] : this.state.usuariosOrigen,
                inputEmpresa1: (tipoComponente === 1) ? "" : this.state.inputEmpresa1,
                inputUsuarioEmpresa1: ""
            });

        }else if (tipoParticipante === appConst.TIPO_PART_DESTINO){
            
            // Se vacía el valor actual de la empresa (input) si se procede del evento del componente del origen 1, no del 2
            if (tipoComponente === 1)
                this.detectarCambio("txtEmpresaDestino", event, true, null);
        
            // Se vacía el valor actual del usuario (input)
            this.detectarCambio("txtUsuarioEmpresaDestino", event, true, null);
        
            // Se vacía la lista de opciones del combo de usuarios que pudiera haber antes
            this.setState({ 
                usuariosDestino: (tipoComponente === 1) ? [] : this.state.usuariosDestino,
                inputEmpresa2: (tipoComponente === 1) ? "" : this.state.inputEmpresa2,
                inputUsuarioEmpresa2: ""
            });
        }
    }

    async setComboCargosUsuarios(idEmpresa, tipo){

        try{
          const response = await axios.get(appConst.GET_USUARIOS_BY_EMPRESA.replace("#idEmpresa", idEmpresa), {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)} );
          
          if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
                if (utils.getLog()) console.log("status GET_USUARIOS_BY_EMPRESA: "+response.status);

                /* Si solo hay una persona en la empresa seleccionada, se precarga en el combo de usuarios por lo que se actualiza el estado de: 
                    campo,
                    inputUsuarioEmpresa1
                    inputUsuarioEmpresa2
                */
                if (response.data.length == 1){
                    let campo = this.state.campo;
                    if (tipo === appConst.TIPO_PART_ORIGEN) {
                        campo["txtUsuarioEmpresaOrigen"] = response.data[0].id;
                        this.setState({ 
                            campo,
                            inputUsuarioEmpresa1: (response.data.length > 0) ? this.getNombreUsuarioEmpresaParticipante(response.data[0]) : "",
                            usuariosOrigen: response.data
                        });
                    }else if (tipo === appConst.TIPO_PART_DESTINO) {
                        campo["txtUsuarioEmpresaDestino"] = response.data[0].id;
                        this.setState({
                            campo,
                            inputUsuarioEmpresa2: (response.data.length > 0) ? this.getNombreUsuarioEmpresaParticipante(response.data[0]) : "",
                            usuariosDestino: response.data
                        });
                    }
                
                /* Si hay más de una persona en la empresa seleccionada, se carga toda la lista de usuarios sin preseleccionar ninguno */
                }else if (response.data.length > 1){
                    if (tipo === appConst.TIPO_PART_ORIGEN)
                        this.setState({ usuariosOrigen: response.data });
                    else if (tipo === appConst.TIPO_PART_DESTINO)
                        this.setState({ usuariosDestino: response.data });
                }
          }
    
        }catch(error){
            if (utils.getLog()) console.log("Error GET_USUARIOS_BY_EMPRESA: "+error);
            this.setState({
                textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.setComboCargosUsuarios.bind(this), [idEmpresa, tipo], appConst.PERFIL_PADRE),
                tipoAlert: utils.getTipoAlert(error),
                closeAlert: appConst.ALERT_OPEN
            });
        }
    }

    async runTransaccion(idEstadoTransaccion){

        const idTransaccion = await this.guardarTransaccion();
        if (idTransaccion){
            const partOrigen = await this.guardarParticipante(idTransaccion, this.state.campo['idParticipanteOrigen'], this.state.campo['txtEmpresaOrigen'], this.state.campo['txtUsuarioEmpresaOrigen'], appConst.TIPO_PART_ORIGEN, idEstadoTransaccion);
            const partDestino = await this.guardarParticipante(idTransaccion, this.state.campo['idParticipanteDestino'], this.state.campo['txtEmpresaDestino'], this.state.campo['txtUsuarioEmpresaDestino'], appConst.TIPO_PART_DESTINO, idEstadoTransaccion);
            if (idEstadoTransaccion === appConst.ESTADO_TRAN_ENVIADO && idTransaccion && idTransaccion !== ""){
                await this.lanzarMatch(idTransaccion);
            }
            this.props.history.push(process.env.REACT_APP_WEB_BASE_URL + "/p/mis-matches");
        }
    }

    async guardarTransaccion(){

        // Debido a un bug en librería Bootstrap, no se ocultan los tooltips al hacer click en los botones, y se fuerza la ocultación
        if (document.querySelector("#btnBorrador")) utils.hideTooltip("btnBorrador");
        if (document.querySelector("#btnEnviar")) utils.hideTooltip("btnEnviar");

        // Se valida el form
        if (this.esFormMatchValido()){
            
            const data = {
                "nombre": this.getNombreMatchDefault(),
                "asunto": this.state.campo["txtAsunto"],
                "cuerpo": this.state.campo["txtCuerpo"],
                "idTipoTransaccion": appConst.TIPO_TRAN_MATCH,
                "idEstadoTransaccion": appConst.ESTADO_TRAN_BORRADOR,
                "idEmpresa": utils.getTokenValue("idEmpresa", appConst.PERFIL_PADRE),
                "idUsuarioEmpresa": utils.getTokenValue("idUsuario", appConst.PERFIL_PADRE),
                "idAutor": utils.getTokenValue("idUsuario", appConst.PERFIL_PADRE)
            }

            if(this.props.operacion === appConst.NUEVO_MATCH)
                return await this.guardarNuevoMatch(data);
            else if (this.props.operacion === appConst.EDITAR_MATCH)
                return await this.guardarEdicionMatch(data, this.state.campo["txtIdTransaccion"]);
        }
    }

    async guardarNuevoMatch(data){

        try{
            const response = await axios.post(appConst.POST_TRANSACCION, data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)} )
            
            if (response && response.data && response.status === appConst.HTTPSTATUS_CREATED){
                if (utils.getLog()) console.log("status POST_TRANSACCION: "+response.status);
                
                const idTransaccion = response.data.id;
                return idTransaccion;
            }
            return null; 

        }catch(error){
            if (utils.getLog()) console.log("Error POST_TRANSACCION: "+error);
            this.setState({
                textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.guardarNuevoMatch.bind(this), [data], appConst.PERFIL_PADRE),
                tipoAlert: utils.getTipoAlert(error),
                closeAlert: appConst.ALERT_OPEN
            });
        }
    }

    async guardarEdicionMatch(data, idTransaccion){

        try{
            const response = await axios.put(appConst.PUT_TRANSACCION.replace("#idTransaccion", idTransaccion), data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)} )
            
            if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
                if (utils.getLog()) console.log("status PUT_TRANSACCION: "+response.status);
                
                return idTransaccion;
            }
            return null; 

        }catch(error){
            if (utils.getLog()) console.log("Error PUT_TRANSACCION: "+error);
            this.setState({
                textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.guardarEdicionMatch.bind(this), [data, idTransaccion], appConst.PERFIL_PADRE),
                tipoAlert: utils.getTipoAlert(error),
                closeAlert: appConst.ALERT_OPEN
            });
        }
    }

    async guardarParticipante(idTransaccion, idParticipante, idEmpresa, idUsuarioEmpresa, idTipoParticipante, idEstadoTransaccion){

        const data = {
            "idTransaccion": idTransaccion,
            "idEmpresa": idEmpresa,
            "idUsuarioEmpresa": idUsuarioEmpresa,
            "idTipoParticipante": idTipoParticipante,
            "idEstadoParticipante": appConst.ESTADO_PART_PENDIENTE,
            "baja": appConst.NO_BAJA_LOGICA,
            "idAutor": utils.getTokenValue("idUsuario", appConst.PERFIL_PADRE)
        }

        if(this.props.operacion === appConst.NUEVO_MATCH)
            return await this.guardarNuevoParticipante(idEstadoTransaccion, data);
        else if (this.props.operacion === appConst.EDITAR_MATCH)
            return await this.guardarEdicionParticipante(idEstadoTransaccion, data, idParticipante);
    }

    async guardarNuevoParticipante(idEstadoTransaccion, data){

        try{
            const response = await axios.post(appConst.POST_PARTICIPANTE, data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)} )
            
            if (response && response.data && response.status === appConst.HTTPSTATUS_CREATED){
                if (utils.getLog()) console.log("status POST_PARTICIPANTE: "+response.status);
                if (idEstadoTransaccion === appConst.ESTADO_TRAN_BORRADOR){
                    this.setState({
                        textoAlert: "El match se ha guardado con éxito como borrador",
                        tipoAlert: appConst.ALERT_TIPO_SUCCESS,
                        closeAlert: appConst.ALERT_CLOSE
                    });
                }
                return response.data.id;
            }
            return null;

        }catch(error){
            if (utils.getLog()) console.log("Error POST_PARTICIPANTE: "+error);
            this.setState({
                textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.guardarNuevoParticipante.bind(this), [idEstadoTransaccion, data], appConst.PERFIL_PADRE),
                tipoAlert: utils.getTipoAlert(error),
                closeAlert: appConst.ALERT_OPEN
            });
        }
    }

    async guardarEdicionParticipante(idEstadoTransaccion, data, idParticipante){

        try{
            const response = await axios.put(appConst.PUT_PARTICIPANTE.replace("#idParticipante", idParticipante), data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)} )
            
            if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
                if (utils.getLog()) console.log("status PUT_PARTICIPANTE: "+response.status);
                if (idEstadoTransaccion === appConst.ESTADO_TRAN_BORRADOR){
                    this.setState({
                        textoAlert: "El match se ha guardado con éxito como borrador",
                        tipoAlert: appConst.ALERT_TIPO_SUCCESS,
                        closeAlert: appConst.ALERT_CLOSE
                    });
                }
                return response.data.id;
            }
            return null;

        }catch(error){
            if (utils.getLog()) console.log("Error PUT_PARTICIPANTE: "+error);
            this.setState({
                textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.guardarEdicionParticipante.bind(this), [idEstadoTransaccion, data, idParticipante], appConst.PERFIL_PADRE),
                tipoAlert: utils.getTipoAlert(error),
                closeAlert: appConst.ALERT_OPEN
            });
        }
    }

    async lanzarMatch(idTransaccion){

        // Se envía el email al participante origen, destino y al padre que lo lanza
        // Se actualiza el estado y la fecha de lanzamiento del match (y el asunto y cuerpo traducidos con posibles variables)
        const data = {
            "idEstadoTransaccion": appConst.ESTADO_TRAN_ENVIADO,
            "idAutor": utils.getTokenValue("idUsuario", appConst.PERFIL_PADRE)
        }

        try{
            const response = await axios.put(appConst.PUT_TRANSACCION_ENVIAR.replace("#idTransaccion", idTransaccion), data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)} )
            
            if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
                if (utils.getLog()) console.log("status PUT_TRANSACCION_ENVIAR: "+response.status);
                this.setState({
                    textoAlert: "El match se ha enviado con éxito a todos los participantes",
                    tipoAlert: appConst.ALERT_TIPO_SUCCESS,
                    closeAlert: appConst.ALERT_CLOSE
                });
            }

        }catch(error){
            if (utils.getLog()) console.log("Error PUT_TRANSACCION_ENVIAR: "+error);
            this.setState({
                textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.lanzarMatch.bind(this), [idTransaccion], appConst.PERFIL_PADRE),
                tipoAlert: utils.getTipoAlert(error),
                closeAlert: appConst.ALERT_OPEN
            });
        }
    }

    esFormMatchValido(){

        let campo = this.state.campo;
        let errorForm = {};
        let formularioValido = true;

        // Nombre del match --> Se quita de la validación pq si está vacío se añade un valor por defecto en el json a enviar a la API
        /*if (!campo["txtNombre"]){
            formularioValido = false;
            errorForm["txtNombre"] = <FM id='app.general.requerido'/>;
        }*/

        // Nombre empresa origen
        if (!campo['txtEmpresaOrigen']) {
            formularioValido = false;
            errorForm["txtEmpresaOrigen"] = <FM id='app.general.requerido'/>;
        }

        // Usuario empresa origen
        if (!campo['txtUsuarioEmpresaOrigen']) {
            formularioValido = false;
            errorForm["txtUsuarioEmpresaOrigen"] = <FM id='app.general.requerido'/>;
        }

        // Nombre empresa destino
        if (!campo['txtEmpresaDestino']) {
            formularioValido = false;
            errorForm["txtEmpresaDestino"] = <FM id='app.general.requerido'/>;
        }

        // Usuario empresa destino
        if (!campo['txtUsuarioEmpresaDestino']) {
            formularioValido = false;
            errorForm["txtUsuarioEmpresaDestino"] = <FM id='app.general.requerido'/>;
        }

        // La empresa destino no puede ser la misma que la origen
        if (campo['txtEmpresaOrigen'] && campo['txtEmpresaDestino'] && campo['txtEmpresaDestino'] === campo['txtEmpresaOrigen']) {
            formularioValido = false;
            errorForm["txtEmpresaDestino"] = <FM id='app.padre.nuevo-match.val.destinoIgualOrigen'/>;
        }

        // Asunto
        if (!campo['txtAsunto']) {
            formularioValido = false;
            errorForm["txtAsunto"] = <FM id='app.general.requerido'/>;
        }

        // Cuerpo
        if (!campo['txtCuerpo']) {
            formularioValido = false;
            errorForm["txtCuerpo"] = <FM id='app.general.requerido'/>;
        }

        // Seteo el estado de error 
        if (!formularioValido)
            this.setState({
                error: errorForm,
                textoAlert: <FM id="app.general.form.revisarCampos" />,
                tipoAlert: appConst.ALERT_TIPO_WARNING,
                closeAlert: appConst.ALERT_OPEN
            });
        
        // Se limpian las validaciones por cada campo
        else this.setState({ error: {} });
        
        return formularioValido;
    }

    // Se detecta cuando un campo del formulario es rellenado y por ende cambia de estado 
    detectarCambio(field, e, esAutocomplete, valor) {
        
        let campo = this.state.campo;

        // Si el valor pertenece a un autocomplete
        if (esAutocomplete) campo[field] = valor;
        
        // Actualización de estado del editor. Param "e" = valor del editor
        else if (field === "txtCuerpo") campo[field] = e;

        // Si pertenece a un campo input o select
        else campo[field] = (e && e.target) ? e.target.value : "";
        
        // Cambio de estado de campo 
        this.setState({
            campo,
            error: {},
            initEditor: false,
            textoAlert: '',
            tipoAlert: '',
            closeAlert: appConst.ALERT_OPEN
        });
    }

    clickSeleccionarPlantilla(){

        // Solo si existe alguna opción de un texto seleccionado se copia
        if (document.querySelector("#cmbTextos").selectedIndex !== -1){

            //const textoMatch = document.querySelector("#cmbTextos").options[document.querySelector("#cmbTextos").selectedIndex].text;
            const textoMatch = utils.getAtributeCombo("cmbTextos", "textobd");
            let campo = this.state.campo;
            campo["txtAsunto"] = this.getTexto(textoMatch, 0);
            campo["txtCuerpo"] = this.getTexto(textoMatch, 1);
            
            this.setState({
                initEditor: true,
                campo
            });
            
        }else{
            this.setState({
                textoAlert: "Aún no has creado ninguna plantilla. Accede a 'Mis plantillas' en el menú superior derecho",
                tipoAlert: appConst.ALERT_TIPO_WARNING,
                closeAlert: appConst.ALERT_CLOSE
            });
        }
    }

    getTexto(texto, indiceTipoCampo){
        
        return (texto.split(appConst.SEPARADOR_TEXTO_MATCH)[indiceTipoCampo] !== undefined) ? texto.split(appConst.SEPARADOR_TEXTO_MATCH)[indiceTipoCampo] : "No hay texto disponible";
    }

    setPreview(){
        
        //const textoMatch = document.querySelector("#cmbTextos").options[document.querySelector("#cmbTextos").selectedIndex].text
        const textoMatch = utils.getAtributeCombo("cmbTextos", "textobd");
        document.querySelector("#textAreaAsuntoPreview").innerHTML = "<strong>"+this.getTexto(textoMatch, 0)+"</strong>";
        document.querySelector("#textAreaCuerpoPreview").innerHTML = "<ul>"+this.getTexto(textoMatch, 1)+"</ul>";
    }

    getNombreMatchDefault(){
        // Si la operación es NUEVO y el usuario se olvida de informar el campo nombre sepone por defecto la suma de las empresas del match
        return (this.state.campo["txtNombre"]) ? this.state.campo["txtNombre"] : document.querySelector("#txtEmpresaOrigen").value+" + "+document.querySelector("#txtEmpresaDestino").value;
    }

    goPlantillas(){
        this.props.history.push(process.env.REACT_APP_WEB_BASE_URL+"/p/mis-textos");
      }

    render(){
        return (
            <div>
                <Header itemMenu={appConst.MENU_PADRE_MIS_MATCHES}/>
                
                {/* INI CONTENEDOR PADRE */}
                <div className="contenedorPadre">

                    {/* MIGAS DE PAN */}
                    <ol className="migaPan">
                        <li className="enLinea icon-heart-full"> <Link to={process.env.REACT_APP_WEB_BASE_URL+"/p/mis-matches"} className="link"><FM id="app.padre.mis-matches.title" /></Link></li>
                        {this.props.operacion === appConst.NUEVO_MATCH && <li className="icon-heart-empty enLineaFin"><FM id="app.padre.nuevo-match.title" /></li>}
                        {this.props.operacion === appConst.EDITAR_MATCH && <li className="icon-edit enLineaFin"><FM id="app.padre.editar-match.title" /></li>}
                        {this.props.operacion === appConst.VER_MATCH && <li className="icon-eye enLineaFin"><FM id="app.padre.ver-match.title" /></li>}
                    </ol>
                    
                    {/* INI CONTENEDOR */}
                    <div className="contenedor">
                        
                        <p><span className="prosa">Pon en contacto a 2 empresas para crear algún beneficio para ambas. Puedes crear el match ahora y guardarlo como borrador para enviarlo más tarde, o enviarlo ahora mismo.</span></p>

                        <div>
                            
                            <div className="row">
                                <div className="col-md-1"></div>
                                <div className="col-md-10">
                                    <Alert texto={this.state.textoAlert} tipo={this.state.tipoAlert} close={this.state.closeAlert}/>
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-1"></div>
                                <div className="col-md-10">
                                    <div><span className="label-form"><FM id='app.padre.nuevo-match.form.label.nombreMatch'/></span></div>
                                    <input type="hidden" id="txtIdTransaccion" value={this.state.campo["txtIdTransaccion"]} />
                                    <input className="input-css full-width" type="text" id="txtNombre" size="55" placeholder="Nombre del match" onChange={this.detectarCambio.bind(this, "txtNombre")} value={this.state.campo["txtNombre"]}/>
                                    <span className="form-error">{this.state.error["txtNombre"]}</span>
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-md-1"></div>

                                {/************** EMPRESA 1 **************/}
                                <div className="col-md-4">
                                    {/* LOGO 1 */}
                                    <div className="text-center">
                                        <span className="position-relative">
                                            <img className="imgEmpresaMedio" id="imgEmpresa1" src={ (!this.state.campo["imgEmpresa1"]) ? appConst.SIN_LOGO_EMPRESA : this.state.campo["imgEmpresa1"]} />
                                            {this.props.location.state && 
                                            <Badge 
                                                tooltip={this.props.location.state.estadoParticipanteOrigen}
                                                icono={(this.props.location.state.idEstadoParticipanteOrigen === appConst.ESTADO_PART_CONFIRMADO)?"icon-check":"icon-help"}
                                                fondo={(this.props.location.state.idEstadoParticipanteOrigen === appConst.ESTADO_PART_CONFIRMADO)?"bg-success":"bg-danger"}
                                            />}
                                        </span>
                                    </div>
                                    {/* NOMBRE 1 */}
                                    <div className="selectEmpresaMatch">
                                        <div className="anchoAutoComplete">
                                            <Autocomplete
                                                id="txtEmpresaOrigen"
                                                clearText={"Limpiar"}
                                                openText={"Abrir"}
                                                closeText={"Cerrar"}
                                                forcePopupIcon={(this.props.operacion === appConst.VER_MATCH) ? false : true}
                                                noOptionsText={"No se ha econtrado ninguna empresa"}
                                                options={ this.state.mundosOrigen }
                                                getOptionLabel={ (option) => {
                                                    if (option !== undefined && option.hijo !== undefined && option.hijo.empresa !== undefined){
                                                        return option.hijo.empresa.nombre;
                                                    }else return "";
                                                }}
                                                clearOnBlur={false}
                                                inputValue={this.state.inputEmpresa1}
                                                onInputChange={ (event, value, reason) => (this.onInputChangeEmpresaParticipante(event, value, reason, appConst.TIPO_PART_ORIGEN)) }
                                                value={this.state.campo["txtEmpresaOrigen"]}
                                                onChange={ (event, value, reason) => (this.onChangeEmpresaParticipante(event, value, reason, appConst.TIPO_PART_ORIGEN)) }
                                                renderInput={(params) => ( <TextField {...params}
                                                    InputLabelProps={{...params.InputProps, ...classesAutocomplete.label}}
                                                    InputProps={{...params.InputProps, ...classesAutocomplete.input}}
                                                    placeholder="Escribe o selecciona el nombre de la empresa"
                                                    label="Nombre de la empresa" 
                                                    margin="normal" />
                                                )}
                                            />
                                            <span className="form-error">{this.state.error["txtEmpresaOrigen"]}</span>
                                        </div>
                                        {/*<div className="mt-4">
                                            <button className="icon-empresa btn btn-sm btn-primary ms-2" id="btnInvEmp1" type="button" data-bs-toggle="tooltip" title="Ummm... ¿no encuentras la empresa?, créala aquí!" onClick={this.invitacion}/><br />
                                        </div>*/}
                                    </div>
                                    {/* CARGO 1 */}
                                    <div className="selectEmpresaMatch">
                                        <div className="anchoAutoComplete">
                                            <Autocomplete
                                                id="txtUsuarioEmpresaOrigen"
                                                clearText={"Limpiar"}
                                                openText={"Abrir"}
                                                closeText={"Cerrar"}
                                                forcePopupIcon={(this.props.operacion === appConst.VER_MATCH) ? false : true}
                                                noOptionsText={"No hay usuarios"}
                                                options={ this.state.usuariosOrigen }
                                                getOptionLabel={ (option) => option.nombre + " " + utils.getCleanValue(option.apellidos) + " (" + option.cargo.nombre + ")" }
                                                clearOnBlur={false}
                                                inputValue={this.state.inputUsuarioEmpresa1}
                                                onInputChange={ (event, value, reason) => (this.onInputChangeUsuarioParticipante(event, value, reason, appConst.TIPO_PART_ORIGEN)) }
                                                value={this.state.campo["txtUsuarioEmpresaOrigen"]}
                                                onChange={ (event, value, reason) => (this.onChangeUsuarioParticipante(event, value, reason, appConst.TIPO_PART_ORIGEN)) }
                                                renderInput={(params) => ( <TextField {...params} 
                                                    InputLabelProps={{...params.InputProps, ...classesAutocomplete.label}}
                                                    InputProps={{...params.InputProps, ...classesAutocomplete.input}}
                                                    placeholder="Escribe el nombre o cargo de la persona"
                                                    label="Destinatario de la empresa"
                                                    margin="normal" />
                                                )}
                                            />
                                            <span className="form-error">{this.state.error["txtUsuarioEmpresaOrigen"]}</span>
                                        </div>
                                        {/*<div className="mt-4">
                                            <button className="icon-new-user btn btn-sm btn-primary ms-2" id="btnInvUser1" type="button" data-bs-toggle="tooltip" title="Ummm... ¿no encuentras a la persona? lanza una invítación!" onClick={this.invitacion}/>
                                        </div>*/}
                                    </div>
                                </div>
                                
                                {/* NEXO ENTRE EMPRESAS */}
                                <div className="col-md-2 text-center prosa">
                                    {this.state.campo["txtIdEstadoTransaccion"] === appConst.ESTADO_TRAN_BORRADOR && <div><label className="prosa">Este match aún no se ha enviado</label><span className="icon-new icono-grande align-middle"></span></div>}
                                    {this.state.campo["txtIdEstadoTransaccion"] === appConst.ESTADO_TRAN_ENVIADO && <div><label className="prosa">Este match ha sido enviado el {this.props.location.state.fecha}</label><span className="icon-help icono-grande align-middle"></span></div>}
                                    {this.state.campo["txtIdEstadoTransaccion"] === appConst.ESTADO_TRAN_MATCHEADO && <div><label className="prosa">Match aceptado por las dos partes el {this.props.location.state.fecha}</label><span className="icon-heart-full icono-grande align-middle"></span></div>}
                                    <p className="texto-icono-grande">{this.state.campo["txtNombreEstadoTransaccion"]}</p>
                                </div>

                                {/************** EMPRESA 2 **************/}
                                <div className="col-md-4">
                                    {/* LOGO 2 */}
                                    <div className="text-center">
                                        <span className="position-relative">
                                            <img className="imgEmpresaMedio" id="imgEmpresa2" src={ (!this.state.campo["imgEmpresa2"]) ? appConst.SIN_LOGO_EMPRESA : this.state.campo["imgEmpresa2"]} />
                                            {this.props.location.state && 
                                            <Badge 
                                                tooltip={this.props.location.state.estadoParticipanteDestino}
                                                icono={(this.props.location.state.idEstadoParticipanteDestino === appConst.ESTADO_PART_CONFIRMADO)?"icon-check":"icon-help"}
                                                fondo={(this.props.location.state.idEstadoParticipanteDestino === appConst.ESTADO_PART_CONFIRMADO)?"bg-success":"bg-danger"}
                                            />}
                                        </span>
                                    </div>
                                    {/* NOMBRE 2 */}
                                    <div className="selectEmpresaMatch">
                                        <div className="anchoAutoComplete">
                                            <Autocomplete
                                                id="txtEmpresaDestino"
                                                clearText={"Limpiar"}
                                                openText={"Abrir"}
                                                closeText={"Cerrar"}
                                                forcePopupIcon={(this.props.operacion === appConst.VER_MATCH) ? false : true}
                                                noOptionsText={"No se ha econtrado ninguna empresa"}
                                                options={ this.state.mundosDestino }
                                                getOptionLabel={ (option) => {
                                                    if (option !== undefined && option.hijo !== undefined && option.hijo.empresa !== undefined){
                                                        return option.hijo.empresa.nombre;
                                                    }else return "";
                                                }}
                                                clearOnBlur={false}
                                                inputValue={this.state.inputEmpresa2}
                                                onInputChange={ (event, value, reason) => (this.onInputChangeEmpresaParticipante(event, value, reason, appConst.TIPO_PART_DESTINO)) }
                                                value={this.state.campo["txtEmpresaDestino"]}
                                                onChange={ (event, value, reason) => (this.onChangeEmpresaParticipante(event, value, reason, appConst.TIPO_PART_DESTINO)) }
                                                renderInput={(params) => ( <TextField {...params}
                                                    InputLabelProps={{...params.InputProps, ...classesAutocomplete.label}}
                                                    InputProps={{...params.InputProps, ...classesAutocomplete.input}}
                                                    placeholder="Escribe o selecciona el nombre de la empresa"
                                                    label="Nombre de la empresa" 
                                                    margin="normal" />
                                                )}
                                            />
                                            <span className="form-error">{this.state.error["txtEmpresaDestino"]}</span>
                                        </div>
                                        {/*<div className="mt-4">
                                            <button className="icon-empresa btn btn-sm btn-primary ms-2" id="btnInvEmp2" type="button" data-bs-toggle="tooltip" title="Ummm... ¿no encuentras la empresa? créala aquí!" onClick={this.invitacion}/><br />
                                        </div>*/}
                                    </div>
                                    {/* CARGO 2 */}
                                    <div className="selectEmpresaMatch">
                                        <div className="anchoAutoComplete">
                                            <Autocomplete
                                                id="txtUsuarioEmpresaDestino"
                                                clearText={"Limpiar"}
                                                openText={"Abrir"}
                                                closeText={"Cerrar"}
                                                forcePopupIcon={(this.props.operacion === appConst.VER_MATCH) ? false : true}
                                                noOptionsText={"No hay usuarios"}
                                                options={ this.state.usuariosDestino }
                                                getOptionLabel={ (option) => option.nombre + " " + utils.getCleanValue(option.apellidos) + " (" + option.cargo.nombre + ")" }
                                                clearOnBlur={false}
                                                inputValue={this.state.inputUsuarioEmpresa2}
                                                onInputChange={ (event, value, reason) => (this.onInputChangeUsuarioParticipante(event, value, reason, appConst.TIPO_PART_DESTINO)) }
                                                value={this.state.campo["txtUsuarioEmpresaDestino"]}
                                                onChange={ (event, value, reason) => (this.onChangeUsuarioParticipante(event, value, reason, appConst.TIPO_PART_DESTINO)) }
                                                renderInput={(params) => ( <TextField {...params}
                                                    InputLabelProps={{...params.InputProps, ...classesAutocomplete.label}}
                                                    InputProps={{...params.InputProps, ...classesAutocomplete.input}}
                                                    placeholder="Escribe el nombre o cargo de la persona"
                                                    label="Destinatario de la empresa" 
                                                    margin="normal" />
                                                )}
                                            />
                                            <span className="form-error">{this.state.error["txtUsuarioEmpresaDestino"]}</span>
                                        </div>
                                        {/*<div className="mt-4">
                                            <button className="icon-new-user btn btn-sm btn-primary ms-2" id="btnInvUser2" type="button" data-bs-toggle="tooltip" title="Ummm... ¿no encuentras a la persona? lanza una invítación!" onClick={this.invitacion}/>
                                        </div>*/}
                                    </div>
                                </div>
                                <div className="col-md-1"></div>
                            </div>

                            {/* ASUNTO */}
                            <div className="row mb-3">
                                <div className="col-sm-1"></div>
                                <div className="col-sm-10">
                                    <div className="row">
                                        <div className="col-sm-11 hTexto">
                                            <div><span className="label-form"><FM id='app.padre.nuevo-match.form.label.asunto'/></span></div>
                                            <input className="input-css full-width" type="text" id="txtAsunto" size="30" onChange={this.detectarCambio.bind(this, "txtAsunto")} value={this.state.campo["txtAsunto"]} placeholder="Si quieres puedes buscar una de tus plantillas guardadas"/>
                                            <span className="form-error">{this.state.error["txtAsunto"]}</span>
                                        </div>
                                        <div className="col-sm-1 hTexto">
                                            <button id="btnTextoMatch" className="btn btn-sm btn-primary ms-2" type="button" data-bs-toggle="modal" data-bs-target="#modalCambiarTexto">
                                                <span className="icon-text" id="ttTextoMatch" data-bs-toggle="tooltip" title={this.props.intl.formatMessage( {id:"app.padre.nuevo-match.plantilla.tooltip"} )}></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-1"></div>
                            </div>

                            {/* CUERPO */}
                            <div className="row mb-3">
                                <div className="col-sm-1"></div>
                                <div className="col-sm-10">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div><span className="label-form"><FM id='app.padre.nuevo-match.form.label.cuerpo'/></span></div>
                                            <EditorHTML
                                                id={"txtCuerpo"}
                                                value={this.state.campo["txtCuerpo"]}
                                                init={this.state.initEditor}
                                                placeholder={"Si quieres puedes buscar una de tus plantillas guardadas"}
                                                readonly={(this.props.operacion === appConst.VER_MATCH) ? true : false}
                                                toolbar={(this.props.operacion === appConst.VER_MATCH) ? false : true}
                                                minHeight={150}
                                                _onChange={this.detectarCambio.bind(this)}
                                            />
                                            <span className="form-error">{this.state.error["txtCuerpo"]}</span>
                                        </div>
                                        {/*<div className="col-sm-XxXxXxXxXxXxXx hTexto">
                                            <button id="btnTextoCuerpo" className="btn btn-sm btn-primary ms-2" type="button" data-bs-toggle="modal" data-bs-target="#modalCambiarTexto">
                                                <span className="icon-text" id="ttTextoCuerpo" data-bs-toggle="tooltip" title="Cambiar el 'cuerpo' a otro texto guardado"></span>
                                            </button>
                                        </div>*/}
                                    </div>
                                </div>
                                <div className="col-sm-1"></div>
                            </div>

                            {/* BOTONERA */}
                            <div className="row mb-5">
                                <div className="col-sm-1"></div>
                                <div className="col-sm-2 text-start">
                                    {/* Se pinta el botón Guardar si es NUEVO match o ya existe con estado BORRADOR*/}
                                    {(this.props.operacion === appConst.NUEVO_MATCH || this.state.campo["txtIdEstadoTransaccion"] === appConst.ESTADO_TRAN_BORRADOR) && 
                                        <button className="btn btn-sm btn-primary ms-2" type="button" id="btnBorrador" onClick={this.runTransaccion.bind(this, appConst.ESTADO_TRAN_BORRADOR)} data-bs-toggle="tooltip" title="Guarda el match como borrador sin enviarlo"> Guardar borrador</button>}
                                </div>
                                <div className="col-sm-8 text-end">
                                    {/*<button className="icon-eye btn btn-sm btn-primary ms-2" type="button" data-bs-toggle="tooltip" title="Te recomendamos revisar el match antes de enviarlo"> Preview</button>*/}
                                    {/* Se pinta el botón Enviar si es NUEVO match o ya existe con estado BORRADOR*/}
                                    {(this.props.operacion === appConst.NUEVO_MATCH || this.state.campo["txtIdEstadoTransaccion"] === appConst.ESTADO_TRAN_BORRADOR) && 
                                        <button className="btn btn-sm btn-primary ms-2" type="button" id="btnEnviar" onClick={this.runTransaccion.bind(this, appConst.ESTADO_TRAN_ENVIADO)} data-bs-toggle="tooltip" title="Envía el match a los participantes"> Enviar</button>}
                                </div>
                                <div className="col-sm-1"></div>
                            </div>
                            
                        </div>
                    </div>
                    {/* FIN CONTENEDOR */}
                </div>
                {/* FIN CONTENEDOR PADRE */}

                {/* INI: POPUP PLANTILLAS */}
                <div className="modal fade" id="modalCambiarTexto" tabindex="-1" aria-labelledby="modalCambiarTextoLabel" aria-hidden="true" data-bs-backdrop="true">
                    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                        <div className="modal-content px-3">
                            <div className="modal-header">
                                <h5 className="modal-title icon-text" id="modalCambiarTextoLabel"><FM id="app.padre.nuevo-match.popup.title" /></h5>
                                <button id="btnCerrarModal" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            
                            <div className="modal-body text-justify">
                                <div className="textError text-justify mb-2" id="msgError"></div>

                                <div className="mb-3">
                                    <span><FM id="app.padre.nuevo-match.popup.descripcion" /></span>
                                </div>
                                
                                <div id="valorTextBody">
                                    <select id="cmbTextos" name="cmbTextos" className="select-css full-width mb-3" onChange={this.setPreview}>
                                        <CargarListaDatos
                                            endpoint={appConst.GET_TEXTOS}
                                            valueField={appConst.TABLA_TEXTOS_CAMPO_VALOR}
                                            textField1={appConst.TABLA_TEXTOS_CAMPO_TEXTO1}
                                            paramsEndpoint={{
                                                idPadre: utils.getTokenValue("idPadre", appConst.PERFIL_PADRE),
                                                idTipoTexto: appConst.TIPO_TEXTO_MATCH
                                            }}
                                            //textSize1={60}
                                            perfil={appConst.PERFIL_PADRE}
                                            sinHTML={true}
                                        />
                                    </select>
                                    <div><span className="label-form"><FM id='app.padre.nuevo-match.popup.label.preview'/></span></div>
                                    <hr className="separador"/>
                                    <div id="textAreaAsuntoPreview" className="prosa full-width pb-2">Aún no has creado ninguna plantilla</div>
                                    <div id="textAreaCuerpoPreview" className="prosa full-width"></div>
                                </div>
                            </div>
                        
                            <hr className="text-muted"/>

                            {/* BOTONERA POPUP */}
                            <div className="mb-3">
                                <div className="float-start align-middle" >
                                    <span className="icon-text hand text-muted mx-2" data-bs-dismiss="modal" onClick={this.goPlantillas} data-bs-toggle="tooltip" title={this.props.intl.formatMessage( {id: 'app.padre.mis-matches.popup.botonera.nueva.tooltip'} )}>Plantillas</span>
                                </div>
                                <div className="float-end align-middle">
                                    <button type="button" className="btn btn-sm btn-secondary me-3" data-bs-dismiss="modal"><FM id="app.padre.nuevo-match.popup.botonera.cerrar" /></button>
                                    <button type="button" className="btn btn-sm btn-primary me-3" data-bs-dismiss="modal" onClick={this.clickSeleccionarPlantilla}><FM id="app.padre.nuevo-match.popup.botonera.seleccionar" /></button>
                                </div>
                                
                            </div>


                        </div>
                    </div>
                </div>
                {/* FIN: POPUP PLANTILLAS */}

                <Footer />
            </div>
        )
    }
}

export default injectIntl(DetalleMatch);