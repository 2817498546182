import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { FormattedMessage as FM } from 'react-intl';
import * as appConst from './../../common/const/Const';
import * as utils from './../../common/utils/Funcs';
import Confirmacion from './../../common/utils/Confirmacion';
import Badge from '../../common/utils/Badge';
import DOMPurify from 'dompurify';

class Match extends Component {

    constructor(props){
        super(props);
        this.getFecha = this.getFecha.bind(this);
    }

    componentDidMount(){

        // Inicialización de los tooltip de este componente
        utils.iniTooltip();
    }

    getFecha(){

        let fechaFormateada = <FM id="app.padre.mis-matches.match.sin-fecha" />;
        if (this.props.fecha) fechaFormateada = utils.formatearFechaCastellano(this.props.fecha, true);
        return fechaFormateada;
    }

    async lanzarMatch(idTransaccion){

        // Se envía el email al participante origen, destino y al padre que lo lanza
        // Se actualiza el estado y la fecha de lanzamiento del match (y el asunto y cuerpo traducidos con posibles variables)
        const data = {
            "idEstadoTransaccion": appConst.ESTADO_TRAN_ENVIADO,
            "idAutor": utils.getTokenValue("idUsuario", appConst.PERFIL_PADRE)
        }

        try{
            const response = await axios.put(appConst.PUT_TRANSACCION_ENVIAR.replace("#idTransaccion", idTransaccion), data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)} )
            
            if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
                if (utils.getLog()) console.log("status PUT_TRANSACCION_ENVIAR: "+response.status);
                // Recargar los matches del listado
                this.props._recargarLista();
                this.props._lanzarMensaje("El match se ha enviado con éxito a todos los participantes", appConst.ALERT_TIPO_SUCCESS, appConst.ALERT_CLOSE)
            }

        }catch(error){
            if (utils.getLog()) console.log("Error PUT_TRANSACCION_ENVIAR: "+error);
            this.props._lanzarMensaje(utils.getTextError(error, this.props.intl, this.props.history, null, null, this.lanzarMatch, [idTransaccion], appConst.PERFIL_PADRE), utils.getTipoAlert(error), appConst.ALERT_CLOSE);
        }
    }

    async forzarMatch(id){

        // Cambia el estado del Match a Matcheado y actualiza la fecha del match (e idAutor)
        // Cambia el estado de los 2 participantes del Match a aceptado (e idAutor)
        // Se envían los emails correspondientes a todas las partes implicadas
        const data = {
            "idAutor": utils.getTokenValue("idUsuario", appConst.PERFIL_PADRE)
        };

        try{
            const response = await axios.put(appConst.PUT_PARTICIPANTE_CONFIRMAR_TODOS.replace("#idTransaccion", id), data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE),
                params: {
                    "idEmpresaOrigen": utils.getIdEmpresaParticipante(this.props.participantes, appConst.TIPO_PART_ORIGEN),
                    "idEmpresaDestino": utils.getIdEmpresaParticipante(this.props.participantes, appConst.TIPO_PART_DESTINO)
                }
            })

            if (response && response.status === appConst.HTTPSTATUS_OK){
                if (utils.getLog()) console.log("status PUT_PARTICIPANTE_CONFIRMAR_TODOS: "+response.status);
                
                // Recargar los matches del listado
                this.props._recargarLista();
                this.props._lanzarMensaje("Ahora ya es un match!!", appConst.ALERT_TIPO_SUCCESS, appConst.ALERT_CLOSE)
            }

        }catch(error){
            if (utils.getLog()) console.log("Error PUT_PARTICIPANTE_CONFIRMAR_TODOS: "+error);
            this.props._lanzarMensaje(utils.getTextError(error, this.props.intl, this.props.history, null, null, this.forzarMatch.bind(this), [id], appConst.PERFIL_PADRE), utils.getTipoAlert(error), appConst.ALERT_OPEN);
        }
    }

    async eliminar(id){

        try{
            const response = await axios.delete(appConst.DELETE_TRANSACCION.replace("#idTransaccion", id), {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)} )

            if (response && response.status === appConst.HTTPSTATUS_OK){
                if (utils.getLog()) console.log("status DELETE_TRANSACCION: "+response.status);
                // Recargar los matches del listado
                this.props._recargarLista();
                this.props._lanzarMensaje("Match eliminado correctamente", appConst.ALERT_TIPO_SUCCESS, appConst.ALERT_CLOSE)
            }

        }catch(error){
            if (utils.getLog()) console.log("Error DELETE_TRANSACCION: "+error);
            this.props._lanzarMensaje(utils.getTextError(error, this.props.intl, this.props.history, null, null, this.eliminar.bind(this), [id], appConst.PERFIL_PADRE), utils.getTipoAlert(error), appConst.ALERT_OPEN);
        }
    }

    detalleMatch(operacion){
        
        if (document.querySelector("#btnEditar"+this.props.id)) utils.hideTooltip("btnEditar"+this.props.id);
        if (document.querySelector("#btnVer"+this.props.id)) utils.hideTooltip("btnVer"+this.props.id);

        let URLDestino = "";
        if (operacion === appConst.EDITAR_MATCH) URLDestino = "/p/editar-match";
        else if (operacion === appConst.VER_MATCH) URLDestino = "/p/ver-match";

        const datosMatch = {
            idTransaccion: this.props.id,
            nombre: this.props.nombre,
            
            idParticipanteOrigen: utils.getIdParticipante(this.props.participantes, appConst.TIPO_PART_ORIGEN),
            logoEmpresaOrigen: utils.getLogoParticipante(this.props.participantes, appConst.TIPO_PART_ORIGEN),
            idEmpresaOrigen: utils.getIdEmpresaParticipante(this.props.participantes, appConst.TIPO_PART_ORIGEN),
            idUsuarioEmpresaOrigen: utils.getIdUsuarioEmpresaParticipante(this.props.participantes, appConst.TIPO_PART_ORIGEN),
            nombreEmpresaOrigen: utils.getNombreEmpresaParticipante(this.props.participantes, appConst.TIPO_PART_ORIGEN),
            nombreUsuarioEmpresaOrigen: utils.getNombreUsuarioEmpresaParticipante(this.props.participantes, appConst.TIPO_PART_ORIGEN),
            idEstadoParticipanteOrigen: utils.getIdEstadoParticipante(this.props.participantes, appConst.TIPO_PART_ORIGEN),
            estadoParticipanteOrigen: utils.getEstadoParticipante(this.props.participantes, appConst.TIPO_PART_ORIGEN),
            
            idParticipanteDestino: utils.getIdParticipante(this.props.participantes, appConst.TIPO_PART_DESTINO),
            logoEmpresaDestino: utils.getLogoParticipante(this.props.participantes, appConst.TIPO_PART_DESTINO),
            idEmpresaDestino: utils.getIdEmpresaParticipante(this.props.participantes, appConst.TIPO_PART_DESTINO),
            idUsuarioEmpresaDestino: utils.getIdUsuarioEmpresaParticipante(this.props.participantes, appConst.TIPO_PART_DESTINO),
            nombreEmpresaDestino: utils.getNombreEmpresaParticipante(this.props.participantes, appConst.TIPO_PART_DESTINO),
            nombreUsuarioEmpresaDestino: utils.getNombreUsuarioEmpresaParticipante(this.props.participantes, appConst.TIPO_PART_DESTINO),
            idEstadoParticipanteDestino: utils.getIdEstadoParticipante(this.props.participantes, appConst.TIPO_PART_DESTINO),
            estadoParticipanteDestino: utils.getEstadoParticipante(this.props.participantes, appConst.TIPO_PART_DESTINO),

            asunto: this.props.asunto,
            cuerpo: this.props.cuerpo,
            idEstadoTransaccion: this.props.idEstadoTransaccion,
            nombreEstadoTransaccion: this.props.nombreEstadoTransaccion,
            fecha: this.getFecha()
        }

        this.props.history.push({
            pathname: process.env.REACT_APP_WEB_BASE_URL + URLDestino,
            state: datosMatch,
            //search: '?query=abc'
          });
    }

    render(){ 
        return (

            <div className="mb-4">

                {/* CABECERA */}
                <div className="row headerCajaMatch">
                    <div className="col-md-7 text-start">
                        <span className="tamTitle hand" onClick={this.detalleMatch.bind(this, (this.props.idEstadoTransaccion === appConst.ESTADO_TRAN_BORRADOR)?appConst.EDITAR_MATCH:appConst.VER_MATCH)}>{this.props.nombre}</span>
                    </div>
                    <div className="col-md-3 text-end pe-4">
                        {this.getFecha()}
                    </div>
                    <div className="col-md-2 text-center bordeIzqBlanco">
                        {this.props.idEstadoTransaccion === appConst.ESTADO_TRAN_BORRADOR && <span className="icon-edit icono-muy-peke enLineaIconos hand" data-bs-toggle="tooltip" data-bs-html="true" title="<strong>Editar</strong><br/>Aún estás a tiempo de cambiar los datos del match" onClick={this.detalleMatch.bind(this, appConst.EDITAR_MATCH)} id={"btnEditar"+this.props.id}></span>}
                        {this.props.idEstadoTransaccion === appConst.ESTADO_TRAN_BORRADOR && <span className="icon-send icono-muy-peke enLineaIconos hand" data-bs-toggle="tooltip" data-bs-html="true" title="<strong>Enviar</strong><br/>Envía el match a los participantes" onClick={this.lanzarMatch.bind(this, this.props.id)} id={"btnEnviar"+this.props.id}></span>}
                        {this.props.idEstadoTransaccion === appConst.ESTADO_TRAN_BORRADOR && <span className="enLineaFin hand" data-bs-toggle="tooltip" data-bs-html="true" title="<strong>Eliminar</strong><br/>Elimina el borrador aún no enviado a nadie" id={"btnEliminar"+this.props.id}><span className='icon-delete icono-peke' data-bs-toggle="modal" data-bs-target={"#confirm_eliminar_match_"+this.props.id}></span></span>}
                        {(this.props.idEstadoTransaccion === appConst.ESTADO_TRAN_ENVIADO || this.props.idEstadoTransaccion === appConst.ESTADO_TRAN_MATCHEADO) && <span className="icon-eye icono-muy-peke enLineaFin hand" data-bs-toggle="tooltip" data-bs-html="true" title="<strong>Ver</strong><br/>Ver el detalle del match" onClick={this.detalleMatch.bind(this, appConst.VER_MATCH)} id={"btnVer"+this.props.id}></span>}
                        {this.props.idEstadoTransaccion === appConst.ESTADO_TRAN_ENVIADO && <span className="enLineaFin hand" data-bs-toggle="tooltip" data-bs-html="true" title="<strong>Forzar match</strong><br/>Acción manual para cambiar el estado del match a aceptado" id={"btnMatchear"+this.props.id}><span className='icon-heart-full icono-peke' data-bs-toggle="modal" data-bs-target={"#confirm_forzar_match_"+this.props.id}></span></span>}
                        {/*this.props.idEstadoTransaccion === appConst.ESTADO_TRAN_ENVIADO && <span className="icon-heart-full icono-muy-peke enLineaFin hand" data-bs-toggle="tooltip" data-bs-html="true" title="<strong>Forzar match</strong><br/>Acción manual para cambiar el estado del match a aceptado" onClick={this.forzarMatch.bind(this, this.props.id)} id={"btnMatchear"+this.props.id}></span>*/}
                        {/*this.props.idEstadoTransaccion === appConst.ESTADO_TRAN_MATCHEADO && <span className="icon-heart-broken icono-muy-peke enLineaFin hand" data-bs-toggle="tooltip" data-bs-html="true" title="<strong>Desmatchear</strong><br/>Alguno de los participantes se ha echado atrás (este hecho no será visible a la otra parte)"></span>*/}
                    </div>
                </div>
                
                {/* CUERPO */}
                <div className="row bodyCajaMatch">
                    
                    {/* EMPRESAS */}
                    <div className="col-md-4 pe-4">
                        
                        {/* LOGOS EMPRESAS */}
                        <div className="row pb-2">

                            <div className="col-md-6 text-center">
                                <span className="position-relative">
                                    <img className="imgMatch" src={utils.getLogoParticipante(this.props.participantes, appConst.TIPO_PART_ORIGEN)} />
                                    <Badge 
                                        tooltip={utils.getEstadoParticipante(this.props.participantes, appConst.TIPO_PART_ORIGEN)}
                                        icono={(utils.getIdEstadoParticipante(this.props.participantes, appConst.TIPO_PART_ORIGEN) === appConst.ESTADO_PART_CONFIRMADO)?"icon-check":"icon-help"}
                                        fondo={(utils.getIdEstadoParticipante(this.props.participantes, appConst.TIPO_PART_ORIGEN) === appConst.ESTADO_PART_CONFIRMADO)?"bg-success":"bg-danger"}
                                    />
                                </span>
                            </div>
                            {/*<div className="col-md-2 text-center pt-4 mx-2">
                                <span className="icon-match icono-medio"></span>
                            </div>*/}
                            <div className="col-md-6 text-center">
                                <span className="position-relative">
                                    <img className="imgMatch" src={utils.getLogoParticipante(this.props.participantes, appConst.TIPO_PART_DESTINO)} />
                                    <Badge 
                                        tooltip={utils.getEstadoParticipante(this.props.participantes, appConst.TIPO_PART_DESTINO)}
                                        icono={(utils.getIdEstadoParticipante(this.props.participantes, appConst.TIPO_PART_DESTINO) === appConst.ESTADO_PART_CONFIRMADO)?"icon-check":"icon-help"}
                                        fondo={(utils.getIdEstadoParticipante(this.props.participantes, appConst.TIPO_PART_DESTINO) === appConst.ESTADO_PART_CONFIRMADO)?"bg-success":"bg-danger"}
                                    />
                                </span>
                            </div>

                        </div>

                        {/* NOMBRES EMPRESAS */}
                        <div className="row">
                            <div className="col-md-6 text-center">
                                <p className="textMatch">{utils.getNombreEmpresaParticipante(this.props.participantes, appConst.TIPO_PART_ORIGEN)}</p>
                            </div>
                            <div className="col-md-6 text-center">
                                <p className="textMatch">{utils.getNombreEmpresaParticipante(this.props.participantes, appConst.TIPO_PART_DESTINO)}</p>
                            </div>
                        </div>

                    </div>

                    {/* ASUNTO Y CUERPO */}
                    <div className="col-md-6 px-4 bordeIzqAzul">
                        <p className="text-justify">
                            <p><span className="prosa tamTitle fw-bold">{this.props.asunto}</span></p>
                            <p><span className="prosa" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.cuerpo)}}></span></p>
                        </p>
                    </div>

                    {/* ESTADOS */}
                    <div className="col-md-2 text-center bordeIzqAzul pt-3">
                        {this.props.idEstadoTransaccion === appConst.ESTADO_TRAN_BORRADOR && <span className="icon-new icono-grande"></span>}
                        {this.props.idEstadoTransaccion === appConst.ESTADO_TRAN_ENVIADO && <span className="icon-help icono-grande"></span>}
                        {this.props.idEstadoTransaccion === appConst.ESTADO_TRAN_MATCHEADO && <span className="icon-heart-full icono-grande rojo"></span>}
                        <p className="texto-icono-grande">{this.props.nombreEstadoTransaccion}</p>
                    </div>
                </div>

                {/* Confirmación de eliminación de un match */}
                <Confirmacion
                    id={"eliminar_match_"+this.props.id}
                    estiloHeader={"icon-delete icono-peke"}
                    titulo={"Eliminar match"}
                    texto={"<p>¿Deseas eliminar el siguiente match en estado borrador?</p><p><ul><li><strong>- "+this.props.nombre+"</strong></li></ul></p>"}
                    accion={this.eliminar.bind(this, this.props.id)}
                    boton={"Eliminar"}
                />

                {/* Confirmación para forzar un match */}
                <Confirmacion
                    id={"forzar_match_"+this.props.id}
                    titulo={"Forzar match"}
                    estiloHeader={"icon-heart-full red icono-peke"}
                    texto={"<p>Ambas partes recibirán un email con los datos de contacto de cada uno.</p><p>¿Estás seguro de forzar el siguiente match?</p><p><ul><li><strong>- "+this.props.nombre+"</strong></li></ul></p>"}
                    accion={this.forzarMatch.bind(this, this.props.id)}
                    boton={"Forzar match"}
                />

            </div>
        )
    }
}

export default withRouter(Match);