import React, { Component } from 'react';
import axios from 'axios';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import { Link } from 'react-router-dom';
import Alert from '../../common/utils/Alert';
import * as appConst from '../../common/const/Const';
import * as utils from '../../common/utils/Funcs';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import Invitacion from './Invitacion';

class InvitacionUsuario extends Component {

    constructor(props){
        super(props);
        this.state = {
            campo: {},
            error: {},
            invitaciones: [],
            textoAlert: '',
            tipoAlert: '',
            closeAlert: appConst.ALERT_OPEN
        }
        this.invitarUsuario = this.invitarUsuario.bind(this);
    }

    componentDidMount(){

        // Inicialización de los tooltip de este componente
        utils.iniTooltip();

        // Se listan las invitaciones ya enviadas
        this.listarInvitacionesEnviadas();
    }

    async invitarUsuario(){

        if (this.validarForm()){
            try{
                const response = await axios.get(appConst.INVITAR_USUARIO, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE),
                params: {
                    nombreEmpresaPadre: utils.getTokenValue("nombreEmpresa", appConst.PERFIL_PADRE),
                    nombreUsuarioPadre: utils.getTokenValue("nombre", appConst.PERFIL_PADRE),
                    desc: this.state.campo["txtDesc"],
                    linkRegistro: appConst.LINK_REGISTRO_HIJO + utils.getTokenValue("idPadre", appConst.PERFIL_PADRE),
                    nombreInvitado: this.state.campo["txtNombreInvitado"],
                    emailInvitado: this.state.campo["txtEmailInvitado"]
                }} );
                
                if (response && response.status === appConst.HTTPSTATUS_OK){
                    if (utils.getLog()) console.log("status INVITAR_USUARIO: "+response.status);

                    this.guardarInvitacion();
                    this.limpiarForm();
                }
            
            }catch(error){
                if (utils.getLog()) console.log("Error INVITAR_USUARIO: "+error);
                this.setState({
                    textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, "email", this.invitarUsuario, [], appConst.PERFIL_PADRE),
                    tipoAlert: utils.getTipoAlert(error),
                    closeAlert: appConst.ALERT_OPEN
                });
            }
        }
    }

    limpiarForm(){

        utils.hideTooltip("btnInvitar");

        let campo = this.state.campo;
        campo["txtNombreInvitado"] = "";
        campo["txtEmailInvitado"] = "";
        campo["txtDesc"] = "";
        document.querySelector("#txtDesc").value = "";
        this.setState({campo});
    }

    validarForm() {
        let campo = this.state.campo;
        let error = {};
        let formularioValido = true;
      
        // Nombre invitado
        if (!campo['txtNombreInvitado']) {
          formularioValido = false;
          error["txtNombreInvitado"] = <FM id='app.general.requerido'/>;
        }
    
        // Email invitado
        if (!campo['txtEmailInvitado']) {
          formularioValido = false;
          error["txtEmailInvitado"] = <FM id='app.general.requerido'/>;
        
        // Se valida si el formato del email es válido
        }else if (typeof campo["txtEmailInvitado"] !== "undefined" && !utils.isValidEmail(campo["txtEmailInvitado"])) {
          formularioValido = false;
          error["txtEmailInvitado"] = <FM id='app.general.format.email'/>;
        }
        
        // Seteo el estado de error 
        if (!formularioValido){
            utils.hideTooltip("btnInvitar");
            this.setState({
                error,
                textoAlert: <FM id="app.general.form.revisarCampos" />,
                tipoAlert: appConst.ALERT_TIPO_WARNING
            });
        
        // Se limpian las validaciones por cada campo
        }else this.setState({ error: {} });
        
        return formularioValido;
    }

    // Se detecta cuando un campo del formulario es rellenado y por ende cambia de estado 
    detectarCambio(field, e) {
      
        let campo = this.state.campo;
        campo[field] = e.target.value;
        
        // Cambio de estado de campo 
        this.setState({
            campo,
            textoAlert: '',
            tipoAlert: ''
        });
    }

    async guardarInvitacion(){

        const data = {
            idUsuPad: utils.getTokenValue("idUsuario", appConst.PERFIL_PADRE),
            nombre: this.state.campo["txtNombreInvitado"],
            email: this.state.campo["txtEmailInvitado"],
            descripcion: this.state.campo["txtDesc"],
            fecha: new Date(),
            idAutor: utils.getTokenValue("idUsuario", appConst.PERFIL_PADRE)
        }

        try{
            const response = await axios.post(appConst.POST_INVITACION, data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)} );
            
            if (response && response.data && response.status === appConst.HTTPSTATUS_CREATED){
                if (utils.getLog()) console.log("status POST_INVITACION: "+response.status);

                this.setState({
                    textoAlert: <FM id="app.padre.invitacion-usuario.envio.ok" />,
                    tipoAlert: appConst.ALERT_TIPO_SUCCESS,
                    closeAlert: appConst.ALERT_OPEN
                });

                // Actualizar listado invitaciones enviadas
                this.listarInvitacionesEnviadas();
            }
        
        }catch(error){
            if (utils.getLog()) console.log("Error POST_INVITACION: "+error);
            this.setState({
                textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.guardarInvitacion, [], appConst.PERFIL_PADRE),
                tipoAlert: utils.getTipoAlert(error),
                closeAlert: appConst.ALERT_OPEN
            });
        }
    }

    async listarInvitacionesEnviadas(){

        try{
            const response = await axios.get(appConst.GET_INVITACIONES_BY_PADRE.replace("#idPadre", utils.getTokenValue("idPadre", appConst.PERFIL_PADRE)), {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)} );
            
            if (response && response.status === appConst.HTTPSTATUS_OK){
                if (utils.getLog()) console.log("status GET_INVITACIONES_BY_PADRE: "+response.status);

                this.setState({
                    invitaciones: response.data
                });
            }

        }catch(error){
            if (utils.getLog()) console.log("Error GET_INVITACIONES_BY_PADRE: "+error);
            this.setState({
                textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.listarInvitacionesEnviadas, [], appConst.PERFIL_PADRE),
                tipoAlert: utils.getTipoAlert(error),
                closeAlert: appConst.ALERT_OPEN
            });
        }
    }

    render(){
        return (
            <div>
                <Header />
                
                <div className="contenedorPadre">

                    {/* MIGAS DE PAN */}
                    <ol className="migaPan">
                        <li className="enLineaFin icon-send"> <FM id="app.padre.invitacion-usuario.title" /></li>
                    </ol>
                    
                    <p className="prosa mx-5"> <FM id="app.padre.invitacion-usuario.subtitle" /></p>
                    <p className="prosa mx-5 mb-5">Este es el enlace que se incluirá automáticamente en el email de la invitación: <Link className='link' to={appConst.LINK_RELATIVO_REGISTRO_HIJO + utils.getTokenValue("idPadre", appConst.PERFIL_PADRE)} target="_blanc">{appConst.LINK_REGISTRO_HIJO + utils.getTokenValue("idPadre", appConst.PERFIL_PADRE)}</Link></p>

                    {/* INI CONTENEDOR 50% */}
                    <div className="contenedor75">

                        <Alert texto={this.state.textoAlert} tipo={this.state.tipoAlert} close={this.state.closeAlert}/>

                        <div className='row'>

                            {/* IZQUIERDA: ENVIAR INVITACIONES */}
                            <div className='col-sm-5 pe-5'>
                                <h5>Enviar invitación</h5>

                                {/* CAMPO NOMBRE */}
                                <div className="row mb-2">
                                    <div className="col-sm-12">
                                        <span className="label-form"><FM id='app.padre.invitacion-usuario.form.label.nombreInvitado'/></span>
                                        <input className="input-css full-width" id="txtNombreInvitado" type="text" size="40" placeholder={this.props.intl.formatMessage({id:"app.padre.invitacion-usuario.form.label.tooltip.nombreInvitado"})} onChange={this.detectarCambio.bind(this, "txtNombreInvitado")} value={this.state.campo["txtNombreInvitado"]}/>
                                        <span className="form-error">{this.state.error["txtNombreInvitado"]}</span>
                                    </div>
                                </div>

                                {/* CAMPO EMAIL */}
                                <div className="row mb-2">
                                    <div className="col-sm-12">
                                        <span className="label-form"><FM id='app.padre.invitacion-usuario.form.label.emailInvitado'/></span>
                                        <input className="input-css full-width" id="txtEmailInvitado" type="text" size="40" placeholder={this.props.intl.formatMessage({id:"app.padre.invitacion-usuario.form.label.tooltip.emailInvitado"})} onChange={this.detectarCambio.bind(this, "txtEmailInvitado")} value={this.state.campo["txtEmailInvitado"]}/>
                                        <span className="form-error">{this.state.error["txtEmailInvitado"]}</span>
                                    </div>
                                </div>

                                {/* CAMPO DESCRIPCION */}
                                <div className="row mb-2">
                                    <div className="col-sm-12">
                                        <div><span className="label-form"><FM id='app.padre.invitacion-usuario.form.label.descripcion'/></span></div>
                                        <textarea className="textArea-css full-width" id="txtDesc" rows="5" placeholder={this.props.intl.formatMessage({id:"app.padre.invitacion-usuario.form.label.tooltip.desc"})} onChange={this.detectarCambio.bind(this, "txtDesc")}>{this.state.campo["txtDesc"]}</textarea>
                                    </div>
                                </div>

                                {/* BOTONERA */}
                                <div className="row mb-5 text-center">
                                    <div className="col-sm-12">
                                        <button className="icon-send btn btn-sm btn-primary full-width" type="button" id="btnInvitar" data-bs-toggle="tooltip" title={this.props.intl.formatMessage({id:"app.padre.invitacion-usuario.form.botonera.tooltip.invitar"})} onClick={this.invitarUsuario}> <FM id="app.padre.invitacion-usuario.form.botonera.invitar" /></button>
                                    </div>
                                </div>
                            </div>

                            {/* DERECHA: INVITACIONES ENVIADAS */}
                            <div className='col-sm-7 ps-5 separadorV'>
                                <h5 className='mb-4'>Invitaciones enviadas</h5>
                                {this.state.invitaciones.length === 0 && <div className='pt-3'><span className='prosa'>No existen aún invitaciones enviadas</span></div>}

                                {this.state.invitaciones.map(i => 
                                    <Invitacion 
                                        key={i.id}
                                        id={i.id}
                                        nombre={i.nombre}
                                        email={i.email}
                                        fecha={i.fecha}
                                        descripcion={i.descripcion}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    {/* FIN CONTENEDOR */}

                </div>

                <Footer />
            </div>
        )
    }
}

export default injectIntl(InvitacionUsuario);