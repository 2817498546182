import { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import * as utils from './../../common/utils/Funcs';
import Badge from './../../common/utils/Badge';
import * as appConst from './../../common/const/Const';
import Notificaciones from './../../common/utils/Notificaciones';

class Header extends Component{

    constructor (props){
        super(props);
        this.miPerfil = this.miPerfil.bind(this);
        this.misEtiquetas = this.misEtiquetas.bind(this);
        this.misAreasInteres = this.misAreasInteres.bind(this);
        this.misTextos = this.misTextos.bind(this);
        this.logout = this.logout.bind(this);
        this.invitacionUsuario = this.invitacionUsuario.bind(this);
    }

    componentDidMount(){

        // Inicialización de los tooltip de este componente
        utils.iniTooltip();
        
        // Selección del menú cargado por defecto
        this.selMenu(this.props.itemMenu);
    }

    getUserLoged(){
        return utils.getUsuarioLogeado(appConst.PERFIL_PADRE);
    }
    
    getEmailLogged(){
        return utils.getTokenValue("sub", appConst.PERFIL_PADRE);
    }

    selMenu(item){

        const NAME_LINK = "m";
        const NAME_HR = "hr-item";
        if(item){
            document.querySelector("#"+NAME_LINK+item).className = "link menu-item-sel";
            document.querySelector("#"+NAME_HR+item).className = "item-hr-sel item-hr-sel-long";
        }
    }

    miPerfil(){
        
        utils.hideTooltip("btnPerfil");
        utils.goTarget(this.props.history, process.env.REACT_APP_WEB_BASE_URL+"/p/mi-perfil");
    }

    invitacionUsuario(){
        utils.hideTooltip("btnInvitacion");
        utils.goTarget(this.props.history, process.env.REACT_APP_WEB_BASE_URL+"/p/invitacion-usuario");
    }

    misEtiquetas(){

        utils.hideTooltip("btnEtiquetas");
        utils.goTarget(this.props.history, process.env.REACT_APP_WEB_BASE_URL+"/p/mis-etiquetas");
    }

    misAreasInteres(){

        utils.hideTooltip("btnAreas");
        utils.goTarget(this.props.history, process.env.REACT_APP_WEB_BASE_URL+"/p/mis-areas-interes");
    }

    misTextos(){

        utils.hideTooltip("btnTextos");
        utils.goTarget(this.props.history, process.env.REACT_APP_WEB_BASE_URL+"/p/mis-textos");
    }

    logout(){

        utils.hideTooltip("btnDesconectar");
        utils.doLogout(this.props.history, appConst.PERFIL_PADRE);
    }

    render(){
        return(
            <header className="container-fluid">
                <div className="row cabecera py-2">
                    {/* ZONA LOGO FINDER */}
                    <div className="col-sm-2 align-self-center ps-5">
                        <span><img src={utils.getTokenValue("logoEmpresa", appConst.PERFIL_PADRE)} width='35' height='35' alt='logo'/></span>
                    </div>
                    <div className="col-sm-1"></div>

                    {/* ZONA MENU */}
                    <div className="col-sm-5 align-self-center">
                        <div className="row px-4 mx-auto">
                            <div className="col-sm-4">
                                <Link id="m1" style={{ textDecoration: 'none' }} to={process.env.REACT_APP_WEB_BASE_URL+"/p/mi-mundo"} className="link menu-item"><span className="icon-globe" id="menu1"> <FM id='app.padre.cabecera.menu.miMundo' /></span></Link>
                                <div className="item-hr-sel item-hr-sel-long hideElement" id="hr-item1"></div>
                            </div>
                            <div className="col-sm-4">
                                <Link id="m2" style={{ textDecoration: 'none' }} to={process.env.REACT_APP_WEB_BASE_URL+"/p/mis-matches"} className="link menu-item"><span className="icon-heart-full" id="menu2"> <FM id='app.padre.cabecera.menu.misMatches' /></span></Link>
                                <div className="item-hr-sel item-hr-sel-long hideElement" id="hr-item2"></div>
                            </div>
                            <div className="col-sm-4">
                                <Link id="m3" style={{ textDecoration: 'none' }} to={process.env.REACT_APP_WEB_BASE_URL+"/p/mis-envios"} className="link menu-item"><span className="icon-send" id="menu3"> <FM id='app.padre.cabecera.menu.misEnvios' /></span></Link>
                                <div className="item-hr-sel item-hr-sel-long hideElement" id="hr-item3"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3 p-1 capa-perfil">
                    
                        <Notificaciones 
                            perfil={appConst.PERFIL_PADRE}
                            funcs={[
                                { n: "notificacionDatosEmpresa" }
                            ]}
                        />

                        <span className="ps-3"><label className="avatar">{utils.getAvatar(appConst.PERFIL_PADRE)}</label></span>
                        
                        {/* MENÚ (click) */}
                        <button className="btn py-0 px-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenu" aria-controls="offcanvasMenu">
                            <span className="hand" id="userLogged">{this.getUserLoged()}</span>
                        </button>
                        {/* MENÚ (despliegue) */}
                        <div className="offcanvas offcanvas-end ms-4 px-4" data-bs-scroll="true" tabindex="-1" id="offcanvasMenu" aria-labelledby="offcanvasMenuRightLabel">
                            <div className="offcanvas-header">
                                <h5 id="offcanvasMenuRightLabel">Menú</h5>
                                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div className="separador"></div>
                            <div className="offcanvas-body">
                                
                                {/* TRATAMIENTO DE LOGO DE EMPRESA */}
                                <div className="text-center pb-3">
                                    {/* CON LOGO */}
                                    {(utils.getStringURL(utils.getTokenValue("logoEmpresa", appConst.PERFIL_PADRE)) != utils.getStringURL(appConst.SIN_LOGO_EMPRESA)) && <img src={utils.getTokenValue("logoEmpresa", appConst.PERFIL_PADRE)} className="imgEmpresaMedio" />}
                                    {/* SIN LOGO (siendo admin) */}
                                    {(utils.getStringURL(utils.getTokenValue("logoEmpresa", appConst.PERFIL_PADRE)) === utils.getStringURL(appConst.SIN_LOGO_EMPRESA) && utils.isAdmin(appConst.PERFIL_PADRE)) && 
                                        <span className="position-relative hand" onClick={() => {utils.hideTooltip("logo"); utils.goTarget(this.props.history, process.env.REACT_APP_WEB_BASE_URL+"/p/mi-perfil?2")}}>
                                            <img src={utils.getTokenValue("logoEmpresa", appConst.PERFIL_PADRE)} className="imgEmpresaMedio"/>
                                            <Badge 
                                                id={"logo"}
                                                tooltip={"Añade un logo a tu empresa"}
                                                icono={"icon-plus"}
                                                fondo={"bg-success"}
                                            />
                                        </span>}
                                    {/* SIN LOGO (no siendo admin) */}
                                    {(utils.getStringURL(utils.getTokenValue("logoEmpresa", appConst.PERFIL_PADRE)) === utils.getStringURL(appConst.SIN_LOGO_EMPRESA) && !utils.isAdmin(appConst.PERFIL_PADRE)) && 
                                        <img src={utils.getTokenValue("logoEmpresa", appConst.PERFIL_PADRE)} className="imgEmpresaMedio"/>
                                    }
                                </div>
                                
                                {/* Opciones de menú */}
                                <div className="row">
                                    <div className="col-sm-12">
                                        <li id="btnPerfil" className="icon-user hand item-menu-user" onClick={this.miPerfil} data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="left" title={this.props.intl.formatMessage( {id:'app.padre.cabecera.menu.emergente.miPerfil.tooltip'} ) + " <br/><i><strong>" + this.getEmailLogged()+"</i></strong>"}> <FM id='app.padre.cabecera.menu.emergente.miPerfil' /></li>
                                        <li id="btnInvitacion" className="icon-send hand item-menu-user" onClick={this.invitacionUsuario} data-bs-toggle="tooltip" data-bs-placement="left" title={this.props.intl.formatMessage( {id:'app.padre.cabecera.menu.emergente.invitacionNuevoUsuario.tooltip'} )} > <FM id='app.padre.cabecera.menu.emergente.invitacionNuevoUsuario' /></li>
                                        <li id="btnEtiquetas" className="icon-tags hand item-menu-user" onClick={this.misEtiquetas} data-bs-toggle="tooltip" data-bs-placement="left" title={this.props.intl.formatMessage( {id:'app.padre.cabecera.menu.emergente.misEtiquetas.tooltip'} )}> <FM id='app.padre.cabecera.menu.emergente.misEtiquetas' /></li>
                                        <li id="btnAreas" className="icon-star-full hand item-menu-user" onClick={this.misAreasInteres} data-bs-toggle="tooltip" data-bs-placement="left" title={this.props.intl.formatMessage( {id:'app.padre.cabecera.menu.emergente.areasInteres.tooltip'} )}> <FM id='app.padre.cabecera.menu.emergente.areasInteres' /></li>
                                        <li id="btnTextos" className="icon-text hand item-menu-user" onClick={this.misTextos} data-bs-toggle="tooltip" data-bs-placement="left" title={this.props.intl.formatMessage( {id:'app.padre.cabecera.menu.emergente.misTextos.tooltip'} )}> <FM id='app.padre.cabecera.menu.emergente.misTextos' /></li>
                                        <li id="btnDesconectar" className="icon-logout hand item-menu-user" onClick={this.logout} data-bs-toggle="tooltip" data-bs-placement="left" title={this.props.intl.formatMessage( {id:'app.padre.cabecera.menu.emergente.desconectar.tooltip'} )}> <FM id='app.padre.cabecera.menu.emergente.desconectar' /></li>
                                    </div>
                                </div>
                                
                                {/* Logo Finder */}
                                <p class="text-center">
                                    <div className='row fijarAbajo text-end'>
                                        <div className='col-sm-12 pb-3'>
                                            <img src={appConst.URL_LOGO_FINDER} className="imgEmpresaMedio"/>
                                        </div>
                                    </div>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default withRouter(injectIntl(Header));