import React, { Component } from 'react';
import axios from 'axios';
import { FormattedMessage as FM } from 'react-intl';
import {withRouter} from 'react-router-dom';
import * as utils from './../utils/Funcs';
import * as appConst from './../const/Const';
import HeaderGuess from '../header/HeaderGuess';
import FooterPadre from './../../padre/footer/Footer';
import FooterHijo from './../../hijo/footer/Footer';
import Alert from './../../common/utils/Alert';

class AceptacionMatch extends Component {

    constructor(props){
        super(props);
        
        this.state = {
            ok: false,
            perfil: utils.getURLParam("fill"),
            idUsuario: utils.getURLParam("token"),
            textoAlert: "",
            tipoAlert: "",
            closeAlert: appConst.ALERT_OPEN
        };
        this.aceptacionParticipante = this.aceptacionParticipante.bind(this);
        this.verMatch = this.verMatch.bind(this);
    }

    componentDidMount(){
        
        // Se lanza la actualización de la confirmación del usuario participante, previo checqueo de los parámetros esperados
        this.checkURLParams();
    }

    checkURLParams(){

        if (!utils.getURLParam("idTransaccion") || !utils.getURLParam("idEmpresa") || !utils.getURLParam("fill") || !utils.getURLParam("token")){
            this.setState({
                textoAlert: <FM id="app.general.paramsRequired" />,
                tipoAlert: appConst.ALERT_TIPO_WARNING
            });
        
        }else this.aceptacionParticipante();
    }

    async aceptacionParticipante(){

        // Se actualiza el estado del paticipante que desea confirmar su participación
        // El usuario llega a esta página desde su email por lo que se pueden producir 2 situaciones:
        //      1) El usuario no está logeado (no hay token local para hacer la actualización)
        //      2) El usuario ya está logeado pero es otro usuario diferente (no hay token local correcto para hacer la actualización)
        //      3) Usuario logeado correctamente (hay token local correcto para hacer la actualización))
        try{
            // 1) NO HAY TOKEN: se le redirige al login con una callback para que pueda repetir automáticamente el proceso, una vez logeado
            if (!utils.getJwtToken(this.state.perfil)){
                
                this.props.history.push({
                    pathname: process.env.REACT_APP_WEB_BASE_URL + "/",
                    state: {
                        callback: utils.getCallback(),
                    }
                });
                
            // 2) SI HAY TOKEN: el token de localStorage (su idUsuario) no es del usuario que hace click desde su email, se ejecuta logout y callback
            }else if (utils.getTokenValue("idUsuario", this.state.perfil) !== this.state.idUsuario){
                
                // Se elimina el token del otro usuario logeado que no es el de la petición y se redirige al login para introducir el usuario correcto
                utils.doLogout(this.props.history, utils.getTokenValue("tipo", this.state.perfil), utils.getCallback());

            // 3) Hay token y el usuario es ok
            }else{

                // Cambia el estado del participante a aceptado (e idAutor), y si ambos participantes están aceptados:
                //      - Se cambia el estado del Match a Matcheado y actualiza la fecha del match (e idAutor)
                //      - Se envían los emails correspondientes a todas las partes implicadas
                const data = {
                    "idAutor": utils.getTokenValue("idUsuario", this.state.perfil)
                };
                
                const response = await axios.put(appConst.PUT_PARTICIPANTE_CONFIRMAR.replace("#idTransaccion", utils.getURLParam("idTransaccion")), data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, this.state.perfil),
                    params: { "idEmpresa": utils.getURLParam("idEmpresa") }
                } )
                
                if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
                    if (utils.getLog()) console.log("status PUT_PARTICIPANTE_CONFIRMAR: "+response.status);

                    // En función de si ha aceptado solo una empresa o las 2, se muestra un mensaje u otro
                    const mensaje = (response.data.length == 2) ? <FM id="app.participante.aceptacion.OkCompleta" />: <FM id="app.participante.aceptacion.OkNoCompleta" />
                    this.setState({
                        ok: true,
                        textoAlert: mensaje,
                        tipoAlert: appConst.ALERT_TIPO_SUCCESS,
                        closeAlert: appConst.ALERT_OPEN
                    });
                }
            }

        }catch(error){
            if (utils.getLog()) console.log("Error PUT_PARTICIPANTE_CONFIRMAR: "+error);
            this.setState({
                textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.aceptacionParticipante, [], this.state.perfil),
                tipoAlert: utils.getTipoAlert(error),
                closeAlert: appConst.ALERT_OPEN
            });
        }
    }

    verMatch(){
        
        this.props.history.push(process.env.REACT_APP_WEB_BASE_URL+"/"+utils.getPrefixURL(this.state.perfil)+"/lista-actividades");
    }

    render(){
        return (

            <div>
                <HeaderGuess/>
                
                <div className='contenedorHijo'>
                    
                    {/* MIGAS DE PAN */}
                    <ol className="migaPan">
                        <li className="enLineaFin icon-heart-full"> <FM id="app.participante.aceptacion.title" /></li>
                    </ol>

                    <div className='contenedor'>

                        <Alert texto={this.state.textoAlert} tipo={this.state.tipoAlert} close={this.state.closeAlert}/>
                    
                        <div className='icon-heart-full red icono-extragrande text-center pb-4'></div>

                        {this.state.ok &&
                            <div className="row text-center mx-0">
                                <div className="col-sm-12">
                                    <button className="btn btn-sm btn-primary" onClick={this.verMatch}><span className="icon-heart-full"> Ver el match</span></button>
                                </div>
                            </div>}
                    </div>

                </div>

                {this.state.perfil === appConst.PERFIL_PADRE && <FooterPadre />}
                {this.state.perfil === appConst.PERFIL_HIJO && <FooterHijo />}
            </div>
        )
    }
}

export default withRouter(AceptacionMatch);