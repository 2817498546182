import { Component } from 'react';
import axios from 'axios';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import * as appConst from './../common/const/Const';
import * as utils from './../common/utils/Funcs';
import Alert from './../common/utils/Alert';
import HeaderGuess from './../common/header/HeaderGuess';

class ChangePass extends Component {

    constructor(props){
        super(props);
        this.state = {
            email: '',
            error: '',
            textoAlert: '',
            tipoAlert: '',
            closeAlert: appConst.ALERT_OPEN
        }
        this.clickRecuperar = this.clickRecuperar.bind(this);
    }

    clickRecuperar(){
        
        if (this.validarFormulario())
            this.sendEmailToUser();
    }

    validarFormulario() {
        let error;
        let formularioValido = true;
        
        // Campo Email
        if (!this.state.email) {
            formularioValido = false;
            error = <FM id='app.general.requerido'/>;
        
        // Se valida si el formato del email es válido
        }else if (typeof this.state.email !== "undefined" && !utils.isValidEmail(this.state.email)) {
            formularioValido = false;
            error = <FM id='app.general.format.email'/>;
        }

        // Seteo el estado de error 
        if (!formularioValido)
            this.setState({
                error,
                textoAlert: <FM id="app.general.form.revisarCampos" />,
                tipoAlert: appConst.ALERT_TIPO_WARNING
            });
    
        // Se limpian las validaciones por cada campo
        else this.setState({ error: '' });
        
        return formularioValido;
    }

    async sendEmailToUser(){

        try{
            const response = await axios.get(appConst.GET_CAMBIO_PASS, {params: {emailUsuario: this.state.email}, headers: utils.getHeaders(appConst.HEADER_SIN_TOKEN)} )
            if (response && response.status === appConst.HTTPSTATUS_OK){
                if (utils.getLog()) console.log("Status GET_CAMBIO_PASS: "+response.status);
                this.setState({ 
                    textoAlert: <FM id="app.general.cambiarPass.aviso.envioEmail" />,
                    tipoAlert: appConst.ALERT_TIPO_SUCCESS
                });
            }
          }catch(error){
            if (utils.getLog()) console.log("Error GET_CAMBIO_PASS: "+error);
            this.setState({
                // "Email" es el campo que no existe con un status 404
                textoAlert: utils.getTextError(error, this.props.intl, this.props.history, "Email"),
                tipoAlert: utils.getTipoAlert(error),
                closeAlert: appConst.ALERT_OPEN
            });
          }
    }

    // Se detecta cuando un campo del formulario es rellenado y por ende cambia de estado 
    detectarCambio(field, e) {
        
        // Para evitar repintar los posibles mensajes del componente Alert se resetea su estado (textoAlert y tipoAlert)
        this.setState({
            email: e.target.value,
            textoAlert: '',
            tipoAlert: ''
        });
    }

    render(){
        return (
            <div>

                <HeaderGuess />
                
                {/* INI CONTENEDOR */}
                <div className="contenedorHijo">

                    <div className="row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10">
                            <Alert texto={this.state.textoAlert} tipo={this.state.tipoAlert} close={this.state.closeAlert}/>
                        </div>
                        <div className="col-sm-1"></div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 p5 text-center">
                            <h5><FM id="app.general.cambiarPass.olvido1" /></h5>
                            <h6><FM id="app.general.cambiarPass.olvido2" /></h6><br />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 text-center">
                            <input className='input-css' type="text" name="txtEmail" id="txtEmail" placeholder="Introduce tu email" size="30" maxLength="100" onChange={this.detectarCambio.bind(this, "txtEmail")} value={this.state.email} /><br />
                            <span className="form-error-pass">{this.state.error}</span><br /><br />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 text-center">
                            <button type="button" className="btn btn-primary" onClick={this.clickRecuperar}>Recuperar</button> <br/><br/>
                            <Link onClick={window.close} className="link">Volver</Link>
                        </div>
                    </div>
                </div>
                {/* FIN CONTENEDOR */}

            </div>
        )
    }
}

export default injectIntl(withRouter(ChangePass));