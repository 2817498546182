import React, { Component } from 'react';
import axios from 'axios';
import * as appConst from './../../common/const/Const'
import * as utils from './../../common/utils/Funcs';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import Confirmacion from './../../common/utils/Confirmacion';
import Match from './Match';

class Actividad extends Component {

  constructor(props){
    super(props);
    this.state = {
      usuario: {},
      idTipoParticipante: (this.props.perfil === appConst.PERFIL_HIJO && this.props.transaccion && this.props.transaccion.participantes) ? utils.getIdTipoParticipanteByIdEmpresa(this.props.transaccion.participantes, utils.getTokenValue("idEmpresa", appConst.PERFIL_HIJO)) : "",
      match: {}
    }
    this.detalleActividad = this.detalleActividad.bind(this);
    this.eliminarActividad = this.eliminarActividad.bind(this);
    this.aceptarMatch = this.aceptarMatch.bind(this);
  }

  componentDidMount(){

    utils.iniTooltip();
    this.getNombreAutor();
  }

  async getNombreAutor(){

    try{
      const response = await axios.get(appConst.GET_USUARIO_BY_ID.replace("#idUsuario", this.props.idAutor), {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, this.props.perfil)});
      
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
          if (utils.getLog()) console.log("status GET_USUARIO_BY_ID: "+response.status);
          
          this.setState({ usuario: response.data });
      }

    }catch(error){
        if (utils.getLog()) console.log("Error GET_USUARIO_BY_ID: "+error);
        this.props._showMensaje(utils.getTextError(error, this.props.intl, this.props.history, null, null, this.getNombreAutor, [], this.props.perfil), utils.getTipoAlert(error), appConst.ALERT_OPEN);
    }
  }

  detalleActividad(){

    utils.hideTooltip("btnModificar_"+this.props.id);
    
    const datosActividad = {
      id: this.props.id,
      fecha: this.props.fecha,
      idActividad: this.props.idActividad,
      descripcion: this.props.descripcion,
      idTransaccion: this.props.idTransaccion
    }

    this.props.history.push({
      pathname: process.env.REACT_APP_WEB_BASE_URL + "/h/editarActividad",
      state: datosActividad
    });
  }

  async eliminarActividad(){

    utils.hideTooltip("btnEliminar_"+this.props.id);

    try{
      const response = await axios.delete(appConst.DELETE_ACTIVIDAD_HIJO.replace("#idActividadHijo", this.props.id), {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, this.props.perfil)});
      
      if (response && response.status === appConst.HTTPSTATUS_OK){
          if (utils.getLog()) console.log("status DELETE_ACTIVIDAD_HIJO: "+response.status);

          this.props._showMensaje(this.props.intl.formatMessage({id:"app.hijo.lista-actividades.aviso.eliminar.ok"}), appConst.ALERT_TIPO_SUCCESS, appConst.ALERT_CLOSE);
          this.props._listarActividad();
      }

    }catch(error){
        if (utils.getLog()) console.log("Error DELETE_ACTIVIDAD_HIJO: "+error);
        this.props._showMensaje(utils.getTextError(error, this.props.intl, this.props.history, null, null, this.eliminarActividad, [], this.props.perfil), utils.getTipoAlert(error), appConst.ALERT_OPEN);
    }
  }
  
  async aceptarMatch(){

    // Cambia el estado del participante a aceptado (e idAutor), y si ambos participantes están aceptados:
    //      - Se cambia el estado del Match a Matcheado y actualiza la fecha del match (e idAutor)
    //      - Se envían los emails correspondientes a todas las partes implicadas
    const data = {
      "idAutor": utils.getTokenValue("idUsuario", this.props.perfil)
    };

    try{
      const response = await axios.put(appConst.PUT_PARTICIPANTE_CONFIRMAR.replace("#idTransaccion", this.props.idTransaccion), data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, this.props.perfil),
        params: { "idEmpresa": utils.getTokenValue("idEmpresa", this.props.perfil) }
      } )
      
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
          if (utils.getLog()) console.log("status PUT_PARTICIPANTE_CONFIRMAR: "+response.status);

          // Se refresca la lista de actividades, para surtir efecto este match
          await this.props._listarActividad();

          // En función de si ha aceptado solo una empresa o las 2, se muestra un mensaje u otro
          const mensaje = (response.data.length == 2) ? <FM id="app.participante.aceptacion.OkCompleta" />: <FM id="app.participante.aceptacion.OkNoCompleta" />
          this.props._showMensaje(mensaje, appConst.ALERT_TIPO_SUCCESS, appConst.ALERT_OPEN);
      }
    }catch(error){
      if (utils.getLog()) console.log("Error PUT_PARTICIPANTE_CONFIRMAR: "+error);
      this.props._showMensaje(utils.getTextError(error, this.props.intl, this.props.history, null, null, this.aceptarMatch, [], this.props.perfil), utils.getTipoAlert(error), appConst.ALERT_OPEN);
    }
  }

  render(){ 
    return (
      <div className="mt-4">
        
        <div className="row">
          
          <div className="col-sm-1 text-end">
            <span className="rounded-circle px-2" style={{background: this.props.color}}></span>
          </div>

          <div className="col-sm-5">
            <p>
              <span>{utils.formatearFechaCastellano(this.props.fecha)} - <strong>{this.props.nombre}</strong></span>
              {/* Botonera SOLO para perfil HIJO */}
              {this.props.perfil === appConst.PERFIL_HIJO &&
                <span className="icono-muy-peke">
                  
                  {/* Editar */}
                  <span className="icon-edit link ps-2 hand" id={"btnModificar_"+this.props.id} data-bs-toggle="tooltip" title="Modificar la actividad" onClick={this.detalleActividad}></span>
                  
                  {/* Eliminar */}
                  <span data-bs-toggle="modal" data-bs-target={"#confirm_eliminar_"+this.props.id}><span className="icon-delete link ps-2 hand" id={"btnEliminar_"+this.props.id} data-bs-toggle="tooltip" title="Eliminar la actividad"></span></span>
                  
                  {/* Aceptar match (solo si la actividad es de tipo match, y es la empresa logeada y aún está su estado por confirmar) */}
                  {this.props.idActividad === appConst.TIPO_ACTIVIDAD_MATCH &&
                    this.props.transaccion &&
                    this.props.transaccion.participantes &&
                    utils.getIdEstadoParticipante(this.props.transaccion.participantes, this.state.idTipoParticipante) !== appConst.ESTADO_PART_CONFIRMADO &&
                    <span data-bs-toggle="modal" data-bs-target={"#confirm_aceptar_match_"+this.props.id}><span className="icon-check link ps-2 hand" id={"btnAceptarMatch_"+this.props.id} data-bs-toggle="tooltip" title="Aceptar match"></span></span>
                  }
                </span>
              }
            </p>
          </div>

          <div className="col-sm-6 pb-2 text-end">
            <span className="prosa">Creado por: </span>
            <i className="prosaMarron">
              {(this.state.usuario && this.state.usuario.empresa) ? this.state.usuario.nombre + " " + utils.getCleanValue(this.state.usuario.apellidos) + " (" + this.state.usuario.empresa.nombre + ")" : "sin usuario"}
            </i>
            <span className="prosa"> el </span>
            <i className="prosaMarron">{utils.formatearFechaCastellano(this.props.fechaCreacionReg, true)}</i>
            
            {/* Si ya está aceptado, se indica */}
            {this.props.idActividad === appConst.TIPO_ACTIVIDAD_MATCH &&
              this.props.transaccion &&
              this.props.transaccion.participantes &&
              utils.getIdEstadoParticipante(this.props.transaccion.participantes, this.state.idTipoParticipante) === appConst.ESTADO_PART_CONFIRMADO &&
              <span className="prosa"><br/><span className="icon-heart-full red"></span> Has aceptado</span>
            }
          </div>

        </div>

        <div className="row">

          <div className="col-sm-1"></div>
          <div className="col-sm-11">
            <p className="prosa text-justify">{(this.props.descripcion) ? this.props.descripcion :  <i><FM id="app.hijo.lista-actividades.form.vacio.descripcion" /></i>}</p>
          </div>

        </div>

        {/* SI LA ACTIVIDAD ES DE TIPO MATCH */}
        {(this.props.idActividad == appConst.TIPO_ACTIVIDAD_MATCH) && 

          <div className="row">
            <div className="col-sm-1"></div>
            <div className="col-sm-11 ps-4 py-2">
              <Match
                key={this.props.id}
                transaccion={this.props.transaccion}
              />
            </div>
          </div>
        }

        {/* Confirmación de eliminación de actividad */}
        <Confirmacion
          id={"eliminar_"+this.props.id}
          titulo={"Eliminar actividad"}
          estiloHeader={"icon-delete icono-peke"}
          texto={"¿Estás seguro de eliminar la siguiente actividad?</p><p><ul><li><strong>- "+((this.props.transaccion) ? this.props.transaccion.asunto : this.props.nombre)+"</strong></li></ul></p>"}
          accion={this.eliminarActividad.bind(this)}
          boton={"Eliminar"}
        />

        {/* Confirmación de aceptación de match */}
        <Confirmacion
          id={"aceptar_match_"+this.props.id}
          titulo={"Aceptar match"}
          estiloHeader={"icon-heart-full red icono-peke"}
          texto={"<p>Cuando las dos partes del match sean aceptadas se os enviará a ambos un email con los datos de contacto de cada uno.</p><p>¿Estás seguro de aceptar el siguiente match?</p><p><ul><li><strong>- "+((this.props.transaccion)?this.props.transaccion.asunto:"")+"</strong></li></ul></p>"}
          accion={this.aceptarMatch.bind(this)}
          boton={"Aceptar match"}
        />

      </div>
    )
  }
}

export default withRouter(injectIntl(Actividad));