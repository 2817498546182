import React, { Component } from 'react';
import axios from 'axios';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Alert from './../../common/utils/Alert';
import AlertHeader from './../../common/utils/AlertHeader';
import * as appConst from './../../common/const/Const';
import * as utils from './../../common/utils/Funcs';
import Header from './../header/Header';
import Footer from './../footer/Footer';
import CargarListaDatos from './../../common/datos/CargarListaDatos';
import CargarMaestroDatos from './../../common/datos/CargarMaestroDatos';
import FichaEmpresa from './FichaEmpresa';
import NuevaEtiqueta from './../misEtiquetas/NuevaEtiqueta'
import EditorHTML from '../../common/editor/EditorHTML';

class MiMundoPadre extends Component {

  constructor(props){
    super(props);
    this.state = {
      sinMundo: false,
      datos: [],
      listaEtiquetas: [],
      esNuevaEtiqueta: false,
      initEditor: true,
      reloadCargarListaDatos: false,
      campo: {},
      error: {},
      textoAlert: '',
      tipoAlert: '',
      closeAlert: appConst.ALERT_OPEN,
      textoAlertModal: '',
      tipoAlertModal: '',
      closeAlertModal: appConst.ALERT_OPEN
    };
    this.clickSeleccionarTodasEmpresas = this.clickSeleccionarTodasEmpresas.bind(this);
    this.listarMundo = this.listarMundo.bind(this);
    this.clickGuardarTags = this.clickGuardarTags.bind(this);
    this.listarEtiquetas = this.listarEtiquetas.bind(this);
    this.goEtiquetas =this.goEtiquetas.bind(this);
    this.abrirNuevaEtiqueta = this.abrirNuevaEtiqueta.bind(this);
    this.cerrarNuevaEtiqueta = this.cerrarNuevaEtiqueta.bind(this);
    this.clickEnviarEmailMasivo = this.clickEnviarEmailMasivo.bind(this);
    this.goMisEnvios =this.goMisEnvios.bind(this);
  }

  componentDidMount(){
    
    // Inicialización de los tooltip y popover de este componente
    utils.iniTooltip();
    utils.iniPopover();

    // Carga las empresas del mundo actual
    this.listarMundo();

    // Carga las etiquetas del popup
    this.listarEtiquetas();

    // Carga los eventos del popup Etiquetar
    this.cargarEventosModal();

    // Carga el evento click del botón copiar URL de registro
    utils.copy('btnCopiar');
  }

  cargarEventosModal(){

    /***** POPUP ETIQUETAR *****/
    const obj = this;
    const modalEtiquetar = document.querySelector('#modalEtiquetar');

    // Acciones antes de cargarse el DOM de la modal
    modalEtiquetar.addEventListener('show.bs.modal', function (e) {

      if (!obj.validarModal())
        e.preventDefault();

      //var button = e.relatedTarget
      //alert(button.getAttribute('data-bs-totalempresas'));
      //document.querySelector(e.currentTarget).find("#valorTextBody").html(textBody);
      //alert("defaultPrevented 1: "+e.defaultPrevented);
    });

    // Acciones al ocultarse la modal
    modalEtiquetar.addEventListener('hide.bs.modal', function (e) {
      obj.resetChecks(e);
    });

    // Acciones ejecutadas después del evento show.bs.popover cuando la plantilla popover se ha agregado al DOM (DOM listo para manipular #miContenidoEtiquetar para cargar las lista de empresas)
    document.querySelector("#btnEmpresasSeleccionadasEtiquetar").addEventListener('inserted.bs.popover', function (e) {
      let listaEmpresas = obj.state.datos.map(m => (document.querySelector("#chkIdHijo_"+m.hijo.id+":checked")) ? "<li>- "+m.hijo.empresa.nombre+"</li>" : "");
      document.querySelector("#miContenidoEtiquetar").innerHTML = "";
      for (let i = 0; i < listaEmpresas.length ; i++)
        document.querySelector("#miContenidoEtiquetar").innerHTML = document.querySelector("#miContenidoEtiquetar").innerHTML + listaEmpresas[i];
    });

    // Acciones ejecutadas al hacer click sobre #btnEmpresasSeleccionadasEtiquetar
    document.querySelector("#btnEmpresasSeleccionadasEtiquetar").addEventListener('click', function (e) {
      const textoBotonMostrar = obj.props.intl.formatMessage( {id: 'app.padre.mi-mundo.popup.botonera.mostrar'} );
      const textoBotonOcultar = obj.props.intl.formatMessage( {id: 'app.padre.mi-mundo.popup.botonera.ocultar'} );
      this.innerText = (this.innerText === textoBotonMostrar) ? textoBotonOcultar : textoBotonMostrar;
    });
    
    /***** POPUP ENVIAR EMAIL MASIVO  *****/
    const modalEnviarEmailMasivo = document.querySelector('#modalEnviarEmailMasivo');

    // Acciones antes de cargarse el DOM de la modal
    modalEnviarEmailMasivo.addEventListener('show.bs.modal', function (e) {

      if (!obj.validarModal())
        e.preventDefault();
      
      // Se indica el núm. total de destinatarios
      document.querySelector("#totalDest").innerText = obj.getTotalDestinatarios();

      // Se vacía de contenido la modal
      let campo = obj.state.campo;
      campo["cmbTipo"] = "-1";
      campo["txtAsunto"] = "";
      document.querySelector("#txtAsunto").value = "";
      obj.setState({
        campo,
        initEditor: true,
        textoAlert: ""
      });
    });

    // Acciones al ocultarse la modal
    modalEnviarEmailMasivo.addEventListener('hide.bs.modal', function (e) {
      obj.resetChecks(e);
    });

    // Acciones ejecutadas después del evento show.bs.popover cuando la plantilla popover se ha agregado al DOM (DOM listo para manipular #miContenidoEnviarEmailMasivo para cargar las lista de empresas)
    document.querySelector("#btnEmpresasSeleccionadasEnviarEmailMasivo").addEventListener('inserted.bs.popover', function (e) {
      let listaEmpresasEnviar = obj.state.datos.map(m => (document.querySelector("#chkIdHijo_"+m.hijo.id+":checked")) ? "<li>- "+m.hijo.empresa.nombre+"</li>" : "");
      document.querySelector("#miContenidoEnviarEmailMasivo").innerHTML = "";
      for (let i = 0; i < listaEmpresasEnviar.length ; i++)
        document.querySelector("#miContenidoEnviarEmailMasivo").innerHTML = document.querySelector("#miContenidoEnviarEmailMasivo").innerHTML + listaEmpresasEnviar[i];
    });

    // Acciones ejecutadas al hacer click sobre #btnEmpresasSeleccionadasEnviarEmailMasivo
    document.querySelector("#btnEmpresasSeleccionadasEnviarEmailMasivo").addEventListener('click', function (e) {
      const textoBotonMostrar = obj.props.intl.formatMessage( {id: 'app.padre.mi-mundo.popup.botonera.mostrar'} );
      const textoBotonOcultar = obj.props.intl.formatMessage( {id: 'app.padre.mi-mundo.popup.botonera.ocultar'} );
      this.innerText = (this.innerText === textoBotonMostrar) ? textoBotonOcultar : textoBotonMostrar;
    });
  }

  cerrarModal(){
    utils.hideModal("modalEtiquetar");
    utils.hideModal("modalEnviarEmailMasivo");
  }

  resetChecks(event){
    // Check todos
    document.querySelector("#chkTodos").checked = false;
    // Checks empresas
    this.seleccionarTodasEmpresas(false);
    // Checks input de usuarios de las empresas. All check input (incluido chkTodos)
    for (const item of document.querySelectorAll("[data-bs-tipoCheck='input']"))
      item.checked = false;
    // Check img de usuarios de las empresas
    for (const item of document.querySelectorAll("[data-bs-tipoCheck='img']"))
      item.className = "hideElement";
    // Checks etiquetas
    this.state.listaEtiquetas.map(e => document.querySelector("#chkIdTagPopup_"+e.id).checked = false);
    // Volver a mostrar el botón con 'Mostrar empresas'
    document.querySelector("#btnEmpresasSeleccionadasEnviarEmailMasivo").innerText = this.props.intl.formatMessage( {id: 'app.padre.mi-mundo.popup.botonera.mostrar'} );
    document.querySelector("#btnEmpresasSeleccionadasEtiquetar").innerText = this.props.intl.formatMessage( {id: 'app.padre.mi-mundo.popup.botonera.mostrar'} );
  }

  validarEtiquetasModal(){

    let validacion = false;
    this.state.listaEtiquetas.map(e => {
      if (document.querySelector("#chkIdTagPopup_"+e.id+":checked"))
        validacion = true;
    });
    return validacion;
  }

  getTotalDestinatarios(){

    return document.querySelectorAll("[data-bs-tipoCheck=input]:checked").length
  }

  validarModal(){
    
    let validacion = true;

    // Comprobar que al menos hay una empresa en el resultado de la búsqueda
    if (this.state.datos.length === 0){
      this.setState({
        textoAlert: <FM id='app.padre.mi-mundo.popup.mensaje.sin-empresas' />,
        tipoAlert: appConst.ALERT_TIPO_WARNING,
        closeAlert: appConst.ALERT_CLOSE
      })
      validacion = false;

    // Comprobar que al menos, de esa búsqueda, hay una empresa seleccionada
    }else{

      let encontrado = false;
      this.state.datos.map(e => {
        if (document.querySelector("#chkIdHijo_"+e.hijo.id+":checked"))
          encontrado = true
      });

      if (!encontrado){
        this.setState({
          textoAlert: <FM id='app.padre.mi-mundo.popup.mensaje.sin-empresas-seleccionadas' />,
          tipoAlert: appConst.ALERT_TIPO_WARNING,
          closeAlert: appConst.ALERT_CLOSE
        })
        validacion = false;
      }
    }
    return validacion;
  }

  clickSeleccionarTodasEmpresas(){

    if (this.state.datos && this.state.datos.length === 0){

      document.querySelector("#chkTodos").checked = false;
      this.setState({ 
        textoAlert: <FM id='app.padre.mi-mundo.popup.mensaje.sin-empresas-check' />,
        tipoAlert: appConst.ALERT_TIPO_WARNING,
        closeAlert: appConst.ALERT_CLOSE
      });

    }else
      this.seleccionarTodasEmpresas(document.querySelector("#chkTodos:checked"));
  }

  seleccionarTodasEmpresas(val){
    
    this.state.datos.map(m => {
      
      document.querySelector("#chkIdHijo_"+m.hijo.id).checked = val;
      this.checkedAdminUser(m.idHijo, m.hijo.idEmpresa);
    });
  }

  async clickGuardarTags(){
    
    if (this.validarEtiquetasModal()){

      // Array con las empresas seleccionadas (sus mundos correspondientes)
      let valoresCheckMundos = [];
      // Array con las etiquetas seleccionadas
      let valoresCheckTags = [];
      
      // Se cargan en el nuevo array los valores de los hijos seleccionados (sus mundos correspondientes)
      this.state.datos.map(m => {
        if (document.querySelector("#chkIdHijo_"+m.hijo.id+":checked"))
          valoresCheckMundos.push(m.id);
      });

      this.state.listaEtiquetas.map(e => {
        if (document.querySelector("#chkIdTagPopup_"+e.id+":checked"))
          valoresCheckTags.push(e.id);
      });

      // Se guarda por cada hijo (su mundo correspondiente) la asignación de cada etiqueta seleccionada, 
      // este proceso de varios POST debe realizarse con async para que al llamar a listarMundo() los datos ya contengan las nuevas etiquetas
      const tagsGuardados = await this.asociarTodosTags(valoresCheckMundos, valoresCheckTags);
      
      // Se actualiza el listado del mundo con las nuevas etiquetas en los hijos afectados,
      // pero no serán visibles por pantalla hasta que el componente FichaEmpresa lo detecte en su método componentWillReceiveProps
      // Solo cuando termine de ejecutarse por completo el método asociarTodosTags se podrá llamar a listarMundo
      if (tagsGuardados)
        setTimeout(this.listarMundo.bind(this), 2000);
      
      this.cerrarModal();
      
      // Se muestra el texto del OK en la operación de Etiquetar
      this.setState({
        textoAlert: <FM id="app.padre.mi-mundo.popup.mensaje.etiquetar-ok" />,
        tipoAlert: appConst.ALERT_TIPO_SUCCESS,
        closeAlert: appConst.ALERT_CLOSE
      });
      
    }else{
      this.setState({
        textoAlertModal: <FM id="app.padre.mi-mundo.popup.mensaje.sin-etiquetas" />,
        tipoAlertModal: appConst.ALERT_TIPO_WARNING,
        closeAlertModal: appConst.ALERT_CLOSE
      });
    }
  }

  async asociarTodosTags(checkedMundos, checkedTags){
    
    checkedMundos.map(mundo => checkedTags.map(etiqueta => this.asignarEtiquetaMundo(mundo, etiqueta) ) );
    return true;
  }

  async listarMundo(esBusquedaUsuario){

    try{
      const response = await axios.get(appConst.GET_MUNDOS, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE), params: {
        idPadre: utils.getTokenValue("idPadre", appConst.PERFIL_PADRE),
        idEtiqueta: document.querySelector("#cmbEtiquetas").value,
        idAreaInteres: document.querySelector("#cmbAreasInteres").value,
        idNecesidad: document.querySelector("#cmbNecesidades").value,
        idSector: document.querySelector("#cmbSectores").value,
        razonSocial: document.querySelector("#txtRazonSocial").value.trim(),
        descripcion: document.querySelector("#txtDescripcion").value.trim()
      }} )
      
      // Se resetea el check de todos
      document.querySelector("#chkTodos").checked = false;
      
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
          if (utils.getLog()) console.log("status GET_MUNDOS: "+response.status);
          this.setState({ 
            sinMundo: false,
            datos: response.data,
            textoAlert: "",
            tipoAlert: ""
          });
          
      }else if (response && response.status === appConst.HTTPSTATUS_NO_CONTENT){
          
          // SIN MUNDO: EL padre aún no tiene ninguna empresa hija registrada
          // CON MUNDO: EL padre tiene empresas hijas, pero no hay resultados por la combinación de los criterios de búsqueda del usuario
          this.setState({ 
            sinMundo: !esBusquedaUsuario,
            datos: [],
            textoAlert: <FM id="app.padre.mi-mundo.listado.sin-datos" />,
            tipoAlert: appConst.ALERT_TIPO_WARNING,
            closeAlert: appConst.ALERT_CLOSE
          });
      }

    }catch(error){
        if (utils.getLog()) console.log("Error GET_MUNDOS: "+error);
        this.setState({
            textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.listarMundo, [], appConst.PERFIL_PADRE),
            tipoAlert: utils.getTipoAlert(error),
            closeAlert: appConst.ALERT_OPEN
        });
    }
  }

  async listarEtiquetas(){

    try{
      const response = await axios.get(appConst.GET_ETIQUETAS, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE), 
        params: { idPadre: utils.getTokenValue("idPadre", appConst.PERFIL_PADRE) }} )

      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
          if (utils.getLog()) console.log("status GET_ETIQUETAS: "+response.status);
          this.setState({ 
            listaEtiquetas: response.data,
            textoAlert: '',
            tipoAlert: ''
          });
          
      }else if (response && response.status === appConst.HTTPSTATUS_NO_CONTENT){

          this.setState({  listaEtiquetas: [] });
      }

    }catch(error){
        if (utils.getLog()) console.log("Error GET_ETIQUETAS: "+error);
        this.setState({
            textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.listarEtiquetas, [], appConst.PERFIL_PADRE),
            tipoAlert: utils.getTipoAlert(error),
            closeAlert: appConst.ALERT_OPEN
        });
    }
  }

  async asignarEtiquetaMundo(idMundo, idEtiqueta){

    const data = {
      "idMundo": idMundo,
      "idEtiqueta": idEtiqueta,
      "idAutor": utils.getTokenValue("idUsuario", appConst.PERFIL_PADRE)
    }

    try{
      const response = await axios.post(appConst.POST_ETIQUETAS_HIJOS, data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)} );

      if (response && response.data && response.status === appConst.HTTPSTATUS_CREATED){
          if (utils.getLog()) console.log("status POST_ETIQUETAS_HIJOS: "+response.status);
      }

    }catch(error){
        if (utils.getLog()) console.log("Error POST_ETIQUETAS_HIJOS: "+error);
        this.setState({
            textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, "se ha evitado duplicar una etiqueta", this.asignarEtiquetaMundo, [idMundo, idEtiqueta], appConst.PERFIL_PADRE),
            tipoAlert: utils.getTipoAlert(error),
            closeAlert: appConst.ALERT_OPEN
        });
    }
  }
  
  abrirNuevaEtiqueta(){

    utils.hideTooltip("btnNuevaEtiqueta");
    this.setState({
      esNuevaEtiqueta: true,
      textoAlert: "",
      textoAlertModal: ""
    })
  }

  cerrarNuevaEtiqueta(){
    this.setState({esNuevaEtiqueta: false})
  }

  goEtiquetas(){
    this.props.history.push(process.env.REACT_APP_WEB_BASE_URL+"/p/mis-etiquetas");
  }

  showMensaje(texto, tipo, close){
    this.setState ({
      textoAlert: texto,
      tipoAlert: tipo,
      closeAlert: close
    });
  }

  // Se extraen los ids de usuario seleccionados de la empresa recibida como parámetro para posteriormente montar la entidad Participante
  getCheckedUsersFromIdEmpresa(idEmp){

    // Se monta el name del grupo de checkboxes de usuarios de una misma empresa
    const name = "inputCheck_"+idEmp;

    // El formato de id del checkbox es: inputCheck_<idEmp>_<idUsuario>, por ello se hace un split, para coger la último de los 3 trozos, index = 2 (idUsuario)
    let idUsuarios = [];
    for (const item of document.querySelectorAll("input[name='"+name+"']:checked"))
      idUsuarios.push(item.id.split("_")[2]);
    
    return idUsuarios;
  }

  async clickEnviarEmailMasivo(){
    
    if (this.validarModalFormEnviarEmailMasivo())
      this.guardarTransaccion();
  }

  async guardarTransaccion(){

    const transaccion = {
      "nombre": this.state.campo["txtAsunto"],
      //"nombre": document.querySelector("#txtAsunto").value,
      "asunto": this.state.campo["txtAsunto"],
      //"asunto": document.querySelector("#txtAsunto").value,
      "cuerpo": this.state.campo["txtCuerpo"],
      "fecha": new Date(),
      "idTipoTransaccion": parseInt(this.state.campo["cmbTipo"]),
      "idEstadoTransaccion": appConst.ESTADO_TRAN_MATCHEADO,
      "idEmpresa": utils.getTokenValue("idEmpresa", appConst.PERFIL_PADRE),
      "idUsuarioEmpresa": utils.getTokenValue("idUsuario", appConst.PERFIL_PADRE),
      "idAutor": utils.getTokenValue("idUsuario", appConst.PERFIL_PADRE)
    }
    
    try{
      const response = await axios.post(appConst.POST_TRANSACCION, transaccion, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)} );
      
      if (response && response.data && response.status === appConst.HTTPSTATUS_CREATED){
          if (utils.getLog()) console.log("status POST_TRANSACCION: "+response.status);
          
          this.guardarParticipantes(response.data);
      }

    }catch(error){
        if (utils.getLog()) console.log("Error POST_TRANSACCION: "+error);
        this.setState({
            textoAlertModal: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.guardarTransaccion, [], appConst.PERFIL_PADRE),
            tipoAlertModal: utils.getTipoAlert(error),
            closeAlertModal: appConst.ALERT_OPEN
        });
    }
  }

  async guardarParticipantes(transaccion){

    // Se monta las entidades 'Participante' que haya por cada empresa seleccionada (1 empresa --> n participantes)
    // Si no hay ningún usuario seleccionado, por defecto y automáticamente, la aplicación marcará el usuario administrador de cada empresa
    let participantes = [];
    this.state.datos.map(m => {
      if ( document.querySelector("#chkIdHijo_"+m.hijo.id+":checked")){
        
        const listCheckedIdUsers = this.getCheckedUsersFromIdEmpresa(m.hijo.idEmpresa);
        listCheckedIdUsers?.map(idUserChecked => {

          const participante = {
            "idTransaccion": transaccion.id,
            "idEmpresa": m.hijo.idEmpresa,
            "idUsuarioEmpresa": idUserChecked,
            "idTipoParticipante": appConst.TIPO_PART_DESTINO,
            "idEstadoParticipante": appConst.ESTADO_PART_PENDIENTE,
            "idAutor": utils.getTokenValue("idUsuario", appConst.PERFIL_PADRE),
          }
          participantes.push(participante);
        });
      }
    });
    
    try{
      const response = await axios.post(appConst.POST_LISTA_PARTICIPANTES, participantes, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)} );
      
      if (response && response.data && response.status === appConst.HTTPSTATUS_CREATED){
          if (utils.getLog()) console.log("status POST_LISTA_PARTICIPANTES: "+response.status);
          
          this.enviarMailing(transaccion, response.data);
      }

    }catch(error){
        if (utils.getLog()) console.log("Error POST_LISTA_PARTICIPANTES: "+error);
        this.setState({
            textoAlertModal: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.guardarParticipantes, [transaccion], appConst.PERFIL_PADRE),
            tipoAlertModal: utils.getTipoAlert(error),
            closeAlertModal: appConst.ALERT_OPEN
        });
        // Si se ha producido un error se ha hace rollback de la transacción recién creada (eliminarla)
        this.rollbackTransaccion(transaccion.id);
    }
  }

  async enviarMailing(transaccion, participantes){

    let emails = "";
    participantes.map(p => {
      if(document.querySelector("#inputCheck_"+p.idEmpresa +"_"+p.idUsuarioEmpresa+":checked"))
        emails = emails + document.querySelector("#inputEmail_"+p.idEmpresa +"_"+p.idUsuarioEmpresa)?.value + ",";
    });

    // Se crea un array de Strings para enviar al endpoint: los emails, el asunto, el cuerpo
    const data = [
      utils.quitarUltimoCaracter(emails),
      transaccion.asunto,
      transaccion.cuerpo
    ];
    
    try{
      const response = await axios.post(appConst.POST_TRANSACCION_MAILING, data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)} )
      
      if (response && response.status === appConst.HTTPSTATUS_CREATED){
          if (utils.getLog()) console.log("status POST_TRANSACCION_MAILING: "+response.status);
          
          this.setState({
            textoAlert: <FM id="app.padre.mi-mundo.popup.envioEmailMasivo.mensaje.ok" />,
            tipoAlert: appConst.ALERT_TIPO_SUCCESS,
            closeAlert: appConst.ALERT_CLOSE
          });
      }
      this.cerrarModal();

    }catch(error){
        if (utils.getLog()) console.log("Error POST_TRANSACCION_MAILING: "+error);
        this.setState({
            textoAlertModal: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.enviarMailing, [transaccion, participantes], appConst.PERFIL_PADRE),
            tipoAlertModal: utils.getTipoAlert(error),
            closeAlertModal: appConst.ALERT_OPEN
        });
        // Si se ha producido un error se ha hace rollback de la transacción recién creada (eliminarla)
        this.rollbackTransaccion(transaccion.id);
    }
  }

  async rollbackTransaccion(idTransaccion){

    try{
      const response = await axios.delete(appConst.DELETE_TRANSACCION.replace("#idTransaccion", idTransaccion), {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE), params:{ tipo: appConst.BAJA_FISICA }} )
      
      if (response && response.status === appConst.HTTPSTATUS_OK)
          if (utils.getLog()) console.log("status DELETE_TRANSACCION: "+response.status);
      
    }catch(error){
        if (utils.getLog()) console.log("Error DELETE_TRANSACCION: "+error);
        this.setState({
            textoAlertModal: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.rollbackTransaccion, [idTransaccion], appConst.PERFIL_PADRE),
            tipoAlertModal: utils.getTipoAlert(error),
            closeAlertModal: appConst.ALERT_OPEN
        });
    }
  }

  validarModalFormEnviarEmailMasivo(){

    let campo = this.state.campo;
    let errorForm = {};
    let formularioValido = true;

    // Tipo
    if (!campo["cmbTipo"] || campo["cmbTipo"] == "-1"){
      formularioValido = false;
      errorForm["cmbTipo"] = <FM id='app.general.requerido'/>;
    }

    // Asunto
    if (!campo["txtAsunto"]){
        formularioValido = false;
        errorForm["txtAsunto"] = <FM id='app.general.requerido'/>;
    }
    /*if (!document.querySelector("#txtAsunto").value){
      formularioValido = false;
      errorForm["txtAsunto"] = <FM id='app.general.requerido'/>;
    }*/

    // Cuerpo
    if (!campo['txtCuerpo']) {
        formularioValido = false;
        errorForm["txtCuerpo"] = <FM id='app.general.requerido'/>;
    }

    // Seteo el estado de error 
    if (!formularioValido)
      this.setState({
        error: errorForm,
        textoAlertModal: <FM id="app.general.form.revisarCampos" />,
        tipoAlertModal: appConst.ALERT_TIPO_WARNING,
        closeAlertModal: appConst.ALERT_CLOSE
      });
    
    // Se limpian las validaciones por cada campo
    else this.setState({ error: {} });
    
    return formularioValido;
  }

  detectarCambio(field, e) {
    
    let campo = this.state.campo;
    
    // Actualización de estado del editor. Param "e" = valor del editor
    if (field === "txtCuerpo"){
        campo[field] = e;

    // Actualización de estado del resto de campos. Param "e" = evento
    }else campo[field] = e.target.value;
    
    this.setState({ 
        campo,
        initEditor: false,
        error: {},
        textoAlert: "",
        tipoAlert: "",
        textoAlertModal: "",
        tipoAlertModal: ""
    });
  }

  checkedAdminUser(idHijo, idEmpresa){

    // Desmarcar todos los checks (img e input) de la empresa
    if (!document.querySelector("#chkIdHijo_"+idHijo).checked){
        
        // Desmarcar checks input de usuarios de las empresas
        for (const item of document.querySelectorAll("[name='inputCheck_"+idEmpresa+"']"))
            item.checked = false;
        // Ocultar check img de usuarios de las empresas
        for (const item of document.querySelectorAll("[name='imgCheck_"+idEmpresa+"']"))
            item.className = "hideElement";

    // Buscar y marcar el user admin
    }else{
        
        // Se marca el check de usuario admin (img e input)
        const objInputAdmin = document.querySelector("#inputAdmin_"+idEmpresa);
        // Si no se encuentra el usuario admin se sale directamente de la función
        if (!objInputAdmin) return;

        const sufijoIdObj = idEmpresa + "_" + objInputAdmin.value;
        
        // Se muestra el check (img)
        document.querySelector("#imgCheck_"+sufijoIdObj).className = "top-50 start-50 translate-middle icon-check green icono-muy-peke";
        // Se marca el check (input)
        document.querySelector("#inputCheck_"+sufijoIdObj).checked = true;
    }
  }

  goMisEnvios(){
    this.cerrarModal();
    this.props.history.push(process.env.REACT_APP_WEB_BASE_URL+"/p/mis-envios");
  }

  render(){ 
    return (
      <div>
        <Header itemMenu={appConst.MENU_PADRE_MI_MUNDO}/>
        
        <div className="contenedorPadre">

          {/* MIGAS DE PAN */}
          <ol className="migaPan">
              <li className="enLineaFin icon-globe"> <FM id="app.padre.mi-mundo.title" /></li>
          </ol>
          
          {/* INI CONTENEDOR */}
          <div className="contenedor">

            <div>
              {/* SIN EMPRESAS EN TU MUNDO */}
              {this.state.sinMundo && <AlertHeader 
                header={this.props.intl.formatMessage({id: 'app.padre.mi-mundo.listado.sin-mundo.header'})}
                texto={this.props.intl.formatMessage({id: 'app.padre.mi-mundo.listado.sin-mundo.body'},
                  {
                    opcion1: "<br/><p><ul><li>" + this.props.intl.formatMessage({id: 'app.padre.mi-mundo.listado.sin-mundo.body.opcion1'},
                      {
                        linkRegistro: "<br/><br/><ul><li class='link'><span> "+appConst.LINK_REGISTRO_HIJO + utils.getTokenValue("idPadre", appConst.PERFIL_PADRE)+" <span class='icon-copy icono-muy-peke hand' id='btnCopiar' title='Copiar'></span></span></li></ul>"
                      }) + "</li></ul></p>",
                    
                    opcion2: "<p><ul><li>"+this.props.intl.formatMessage({id: 'app.padre.mi-mundo.listado.sin-mundo.body.opcion2'}, 
                      {
                        linkInvitarUsuario: "<a href='" + process.env.REACT_APP_WEB_BASE_URL + '/#/p/invitacion-usuario' + "' class='link'>" + this.props.intl.formatMessage({id: 'app.padre.mi-mundo.listado.sin-mundo.body.opcion2.nombreLink'})  + "</a>"
                      }) + "</li></ul></p>"
                  }
                )}
                tipo={appConst.ALERT_TIPO_SUCCESS}
                close={appConst.ALERT_OPEN}
              />

              }{/* FIN SIN EMPRESAS EN TU MUNDO */}
                
              {/* ALERT */}
              {!this.state.sinMundo && <Alert texto={this.state.textoAlert} tipo={this.state.tipoAlert} close={this.state.closeAlert} />}
              {/* FIN ALERT */}
            </div>

            {/* HAY EMPRESAS EN TU MUNDO */}
            {!this.state.sinMundo &&
              <div>
              
                <div className="text-end mx-0 mb-2">
                  <a href="#criterios" className="icon-arrow-combo link" data-bs-toggle="collapse" aria-expanded="false" aria-controls="criterios">
                    <span data-bs-toggle="tooltip" title={this.props.intl.formatMessage( {id:"app.padre.mi-mundo.buscar.criterios.tooltip"} )}><FM id="app.padre.mi-mundo.buscar.criterios" /></span>
                  </a>
                </div>

                {/* FILTROS */}
                <div className="row cajaBlanca mx-0 collapse" id="criterios">
                  <div className="col-sm-10">
                    <div className="row">
                      <div className="col-sm-12">

                        <div className="block-field">
                          <div>
                            <div><span className="label-form" title={this.props.intl.formatMessage( {id: 'app.padre.mi-mundo.criterio.misEtiquetas'} )}><FM id='app.padre.mi-mundo.criterio.misEtiquetas'/></span></div>
                            <select id="cmbEtiquetas" name="cmbEtiquetas" className="select-css selectSizeCriteria">
                              <option value="">{this.props.intl.formatMessage( {id: 'app.general.combos.todas'} )}</option>
                              <CargarListaDatos
                                  endpoint={appConst.GET_ETIQUETAS}
                                  valueField={appConst.TABLA_ETIQUETAS_CAMPO_VALOR}
                                  textField1={appConst.TABLA_ETIQUETAS_CAMPO_TEXTO1}
                                  paramsEndpoint={{idPadre: utils.getTokenValue("idPadre", appConst.PERFIL_PADRE)}}
                                  perfil={appConst.PERFIL_PADRE}
                                  //_showMensaje={this.showMensaje.bind(this)}
                              />
                            </select>
                          </div>
                        </div>
                      
                        <div className="block-field">
                          <div>
                            <div><span className="label-form" title={this.props.intl.formatMessage( {id: 'app.padre.mi-mundo.criterio.areasInteres'} )}><FM id='app.padre.mi-mundo.criterio.areasInteres'/></span></div>
                            <select id="cmbAreasInteres" name="cmbAreasInteres" className="select-css selectSizeCriteria">
                                <option value="">{this.props.intl.formatMessage( {id: 'app.general.combos.todas'} )}</option>
                                <CargarListaDatos
                                    endpoint={appConst.GET_AREAS_INTERES}
                                    valueField={appConst.TABLA_AREAS_INTERES_CAMPO_VALOR}
                                    textField1={appConst.TABLA_AREAS_INTERES_CAMPO_TEXTO1}
                                    paramsEndpoint={{idPadre: utils.getTokenValue("idPadre", appConst.PERFIL_PADRE)}}
                                    perfil={appConst.PERFIL_PADRE}
                                    //_showMensaje={this.showMensaje.bind(this)}
                                />
                            </select>
                          </div>
                        </div>
                        
                        <div className="block-field">
                          <div>
                            <div><span className="label-form" title={this.props.intl.formatMessage( {id: 'app.padre.mi-mundo.criterio.necesidades'} )}><FM id='app.padre.mi-mundo.criterio.necesidades'/></span></div>
                            <select id="cmbNecesidades" name="cmbNecesidades" className="select-css selectSizeCriteria">
                              <option value="">{this.props.intl.formatMessage( {id: 'app.general.combos.todas'} )}</option>
                              <CargarMaestroDatos
                                  tabla={appConst.MAESTRO_NECESIDADES}
                                  preSelected={""}
                                  perfil={appConst.PERFIL_PADRE}
                                  //_showMensaje={this.showMensaje.bind(this)}
                              />
                            </select>
                          </div>
                        </div>
                      
                        <div className="block-field">
                          <div>
                            <div><span className="label-form" title={this.props.intl.formatMessage( {id: 'app.padre.mi-mundo.criterio.sector'} )}><FM id='app.padre.mi-mundo.criterio.sector'/></span></div>
                            <select id="cmbSectores" name="cmbSectores" className="select-css selectSizeCriteria">
                              <option value="">{this.props.intl.formatMessage( {id: 'app.general.combos.todas'} )}</option>
                              <CargarMaestroDatos
                                  tabla={appConst.MAESTRO_SECTORES}
                                  preSelected={""}
                                  perfil={appConst.PERFIL_PADRE}
                                  //_showMensaje={this.showMensaje.bind(this)}
                              />
                            </select>
                          </div>
                        </div>

                        {/*<div className="block-field">
                          <div>
                            <div><span className="label-form" title={this.props.intl.formatMessage( {id: 'app.padre.mi-mundo.criterio.provincia'} )}><FM id='app.padre.mi-mundo.criterio.provincia'/></span></div>
                            <select id="cmbSectores" name="cmbSectores" className="select-css selectSizeCriteria">
                              <option value="">{this.props.intl.formatMessage( {id: 'app.general.combos.todas'} )}</option>
                            </select>
                          </div>
                        </div>*/}

                      </div>
                    </div>

                    <div className="row pb-3">
                      <div className="col-sm-12">

                        <div className="block-field">
                          <div>
                            <div><span className="label-form" title={this.props.intl.formatMessage( {id: 'app.padre.mi-mundo.criterio.razonSocial'} )}><FM id='app.padre.mi-mundo.criterio.razonSocial'/></span></div>
                            <input className="input-css" id="txtRazonSocial" name="txtRazonSocial" style={{width: "150px"}} placeholder={this.props.intl.formatMessage( {id: 'app.padre.mi-mundo.criterio.razonSocial'} ).toLowerCase()}/>
                          </div>
                        </div>

                        <div className="block-field">
                          <div>
                            <div><span className="label-form" title={this.props.intl.formatMessage( {id: 'app.padre.mi-mundo.criterio.descripcion'} )}><FM id='app.padre.mi-mundo.criterio.descripcion'/></span></div>
                            <input className="input-css" id="txtDescripcion" name="txtDescripcion" style={{width: "490px"}} placeholder="buscar por texto libre"/>
                            {/*<input className="input-css" id="txtDescripcion" name="txtDescripcion" style={{width: "660px"}} placeholder="buscar por texto libre"/>*/}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="col-sm-2 pt-3 text-center">
                    <button className="btn btn-sm btn-primary icon-search" onClick={this.listarMundo.bind(this, appConst.BUSQUEDA_USUARIO)}><FM id="app.padre.mi-mundo.botonera.buscar" /></button>
                  </div>
                </div>
                {/* FIN FILTROS */}
                                  
                {/* DETALLE */}
                <div className="mx-0 mb-2">
                  <div className="row">
                    
                    {/* TODOS */}
                    <div className="col-sm-4">
                      <input className="label-check" type="checkbox" id="chkTodos" name="chkTodos" onClick={this.clickSeleccionarTodasEmpresas}/>
                      <label className="hand" for="chkTodos">&nbsp;&nbsp;<FM id="app.padre.mi-mundo.seleccionar-todos" /></label>
                    </div>

                    {/* TOTALES */}
                    <div className="col-sm-3 text-center">
                      <span className="m-5"><FM id="app.padre.mi-mundo.totales" /> <strong id="contador">{this.state.datos.length}</strong> <FM id="app.padre.mi-mundo.totales-empresas" /></span>
                    </div>

                    {/* BOTONERA: ENVIAR EMAIL MASIVO / ETIQUETAR */}
                    <div className="col-sm-5 text-end mb-2">
                      <button className="btn btn-sm btn-primary icon-send label-button me-3" data-bs-toggle="modal" data-bs-target="#modalEnviarEmailMasivo">
                        {/*<span id="btnEnviarEmailMasivo" data-bs-toggle="tooltip" title={this.props.intl.formatMessage( {id:"app.padre.mi-mundo.botonera.enviarEmailMasivo.tooltip"} )}> <FM id="app.padre.mi-mundo.botonera.enviarEmailMasivo" /></span>*/}
                        <FM id="app.padre.mi-mundo.botonera.enviarEmailMasivo" />
                      </button>

                      <button className="btn btn-sm btn-primary icon-tag label-button" data-bs-toggle="modal" data-bs-target="#modalEtiquetar">
                        {/*<span id="btnEtiquetar" data-bs-toggle="tooltip" title={this.props.intl.formatMessage( {id:"app.padre.mi-mundo.botonera.etiquetar.tooltip"} )}> <FM id="app.padre.mi-mundo.botonera.etiquetar" /></span>*/}
                        <FM id="app.padre.mi-mundo.botonera.etiquetar" />
                      </button>
                    </div>
                  </div>
                  
                  <div className="row pb-4">
                    <div className="col-sm-12">
                      { this.state.datos.map(m => 
                        <FichaEmpresa 
                          key={m.id}
                          idHijo={m.hijo.id}
                          idEmpresa={m.hijo.empresa.id}
                          razonSocial={m.hijo.empresa.razonSocial}
                          nombre={m.hijo.empresa.nombre}
                          descripcion={m.hijo.empresa.descripcion}
                          logo={m.hijo.empresa.logo}
                          email={m.hijo.empresa.email}
                          telefono={m.hijo.empresa.telefono}
                          direccion={m.hijo.empresa.direccion}
                          cp={m.hijo.empresa.cp}
                          localidad={m.hijo.empresa.localidad}
                          provincia={m.hijo.empresa.provincia}
                          urlWeb={m.hijo.empresa.urlweb}
                          urlVideo={m.hijo.empresa.urlvideo}
                          urlNoticia={m.hijo.empresa.urlnoticia}
                          sector={(m.hijo.empresa.sector) ? m.hijo.empresa.sector.nombre : <i><FM id="app.padre.mi-mundo.sin-sector" /></i>}
                          etiquetas={m.etiquetasHijo}
                          areasInteres={m.areasInteresHijo}
                          necesidades={m.hijo.necesidadesHijo}
                          _showMensaje={this.showMensaje.bind(this)}
                          _listarMundo={this.listarMundo.bind(this)}
                          _checkedAdminUser={this.checkedAdminUser.bind(this)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* FIN DETALLE */}

              </div>
            }
            {/* FIN HAY EMPRESAS EN TU MUNDO */}

            {/* POPUP ENVIAR EMAIL MASIVO */}
            <div className="modal fade" id="modalEnviarEmailMasivo" tabindex="-1" aria-labelledby="modalEnviarEmailMasivoLabel" aria-hidden="true" data-bs-backdrop="true">
              <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                <div className="modal-content px-3">
                  <div className="modal-header">
                    <h5 className="modal-title icon-send" id="modalEnviarEmailMasivoLabel"> <FM id="app.padre.mi-mundo.popup.envioEmailMasivo.title" /> (<span id="totalDest">0</span>)</h5>
                    <button id="btnCerrarModal" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  
                  <div className="modal-body">

                    {/*<div className="textError mb-2" id="msgError"></div>*/}

                    {/* CUERPO */}
                    <div className="mb-3 prosa">
                      <p className="justify"><FM id="app.padre.mi-mundo.popup.envioEmailMasivo.descripcion" /></p>
                    </div>
                    
                    <Alert texto={this.state.textoAlertModal} tipo={this.state.tipoAlertModal} close={this.state.closeAlertModal} />

                    <div className='row pb-3'>
                      
                      {/* Tipo de transacción */}
                      <div className='col-sm-6'>
                        <div><span className="label-form"><FM id='app.padre.mi-mundo.popup.envioEmailMasivo.tipoTransaccion'/></span></div>
                        <select id="cmbTipo" name="cmbTipo" className="select-css selectSizeCriteria" onChange={this.detectarCambio.bind(this, "cmbTipo")} value={this.state.campo["cmbTipo"]}>
                          <option value="-1">{this.props.intl.formatMessage( {id: 'app.general.combos.selecciono'} )}</option>
                          <CargarMaestroDatos
                              tabla={appConst.MAESTRO_TIPOS_TRANSACCION}
                              preSelected={""}
                              perfil={appConst.PERFIL_PADRE}
                              excluirId={1}
                          />
                        </select>
                        <span className="form-error">{this.state.error["cmbTipo"]}</span>
                      </div>
                      
                      {/* Empresas seleccionadas en un popover */}
                      <div className='col-sm-6'>
                        <div className="text-end">
                          <span className="prosa fw-bold hand" style={{overflow: true}} data-bs-toggle="popover" data-bs-html="true" id="btnEmpresasSeleccionadasEnviarEmailMasivo" data-bs-original-title={this.props.intl.formatMessage( {id:"app.padre.mi-mundo.popup.popover.title"} )} data-bs-content="<div id='miContenidoEnviarEmailMasivo'></div>"> <FM id="app.padre.mi-mundo.popup.botonera.mostrar" /></span>
                        </div>    
                      </div>

                    </div>

                    {/* Asunto email (también será el nombre de la transacción) */}
                    <div className='row pb-3'>
                      <div className='col-sm-12'>
                        <div><span className="label-form"><FM id='app.padre.mi-mundo.popup.envioEmailMasivo.asunto'/></span></div>
                        <input className="input-css full-width" type='text' id="txtAsunto" placeholder={ this.props.intl.formatMessage({id: "app.padre.mi-mundo.popup.envioEmailMasivo.asunto.placeholder"}) } onBlur={this.detectarCambio.bind(this, "txtAsunto")} />
                        {/* onChange={this.detectarCambio.bind(this, "txtAsunto")} value={this.state.campo["txtAsunto"]} */}
                        <span className="form-error">{this.state.error["txtAsunto"]}</span>
                      </div>
                    </div>

                    {/* Cuerpo email */}
                    <div className='row'>
                      <div className='col-sm-12'>
                        <div><span className="label-form"><FM id='app.padre.mi-mundo.popup.envioEmailMasivo.cuerpo'/></span></div>
                        <EditorHTML
                          id={"txtCuerpo"}
                          init={this.state.initEditor}
                          placeholder={this.props.intl.formatMessage({id:"app.padre.mi-mundo.popup.envioEmailMasivo.cuerpo.placeholder"})}
                          readonly={false}
                          toolbar={true}
                          minHeight={150}
                          //_onChange={this.detectarCambio.bind(this)}
                          _onBlur={this.detectarCambio.bind(this)}
                        />
                        <span className="form-error">{this.state.error["txtCuerpo"]}</span>
                      </div>
                    </div>

                  </div>
                  
                  <hr className="text-muted"/>
                  <div className="mb-3">
                    
                    <div className="float-start align-middle" >
                      <span className="icon-tag hand text-muted mx-2" id="btnMisMailings" onClick={this.goMisEnvios} data-bs-toggle="tooltip" title={this.props.intl.formatMessage( {id: 'app.padre.mi-mundo.popup.botonera.mis-mailings.tooltip'} )}> Mis mailings</span>
                    </div>

                    <div className="float-end align-middle">
                      <button type="button" className="btn btn-sm btn-secondary me-3" data-bs-dismiss="modal"><FM id="app.padre.mi-mundo.popup.botonera.cerrar" /></button>
                      <button type="button" className="btn btn-sm btn-primary me-3" onClick={this.clickEnviarEmailMasivo}><FM id="app.padre.mi-mundo.popup.envioEmailMasivo.botonera.enviar" /></button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            {/* FIN POPUP ENVIAR EMAIL MASIVO */}

            {/* POPUP ETIQUETAR */}
            <div className="modal fade" id="modalEtiquetar" tabindex="-1" aria-labelledby="modalEtiquetarLabel" aria-hidden="true" data-bs-backdrop="true">
              <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                <div className="modal-content px-3">
                  <div className="modal-header">
                    <h5 className="modal-title icon-tags" id="modalEtiquetarLabel"> <FM id="app.padre.mi-mundo.popup.title" /></h5>
                    <button id="btnCerrarModal" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  
                  <div className="modal-body text-justify">

                    {/*<div className="textError text-justify mb-2" id="msgError"></div>*/}
                    <Alert texto={this.state.textoAlertModal} tipo={this.state.tipoAlertModal} close={this.state.closeAlertModal} />

                    {/* COMPONENTE 'NUEVA ETIQUETA' INCRUSTADO */}
                    {this.state.esNuevaEtiqueta && <div className="mb-3">
                      <NuevaEtiqueta 
                        _cerrarComponente={this.cerrarNuevaEtiqueta}
                        _recargarDatos={this.listarEtiquetas}
                        fondoComponente="fondoNuevaEtiqueta"
                        focus={true}
                      />
                    </div>}

                    {/* CUERPO */}
                    
                    {/* No hay etiquetas */}
                    {this.state.listaEtiquetas.length == 0 && 
                      <div className="row mb-3">
                        <div className="col-sm-12">
                          <span className='prosa'>
                            <li>No existen etiquetas. Puedes crearlas a través de la botonera:<br/><span className='icon-tag'> Nueva</span> | <span className='icon-tags'> Etiquetas</span></li>
                          </span>
                        </div>
                      </div>}
                    
                    {/* Sí hay etiquetas */}
                    {this.state.listaEtiquetas.length > 0 &&
                      <div className="mb-3">
                        <p className='justify'><FM id="app.padre.mi-mundo.popup.descripcion" /></p>
                      </div>}
                      
                    {/* Empresas seleccionadas en un popover */}
                    <div className="text-end">
                      <span className="prosa fw-bold hand" style={{overflow: true}} data-bs-toggle="popover" data-bs-html="true" id="btnEmpresasSeleccionadasEtiquetar" data-bs-original-title={this.props.intl.formatMessage( {id:"app.padre.mi-mundo.popup.popover.title"} )} data-bs-content="<div id='miContenidoEtiquetar'></div>"> <FM id="app.padre.mi-mundo.popup.botonera.mostrar" /></span>
                    </div>

                    {/* Listado etiquetas */}
                    <div id="valorTextBody">
                      <ul>{this.state.listaEtiquetas.map( e => <li><span><input type="checkbox" id={"chkIdTagPopup_"+e.id} name={"chkIdTagPopup_"+e.id} /> <label className="hand" for={"chkIdTagPopup_"+e.id}>{e.nombre}</label></span></li> )}</ul>
                    </div>
                    
                  </div>
                  
                  {/*<div className="modal-footer">*/}
                  {/*<div className="modal-footer d-block">*/}
                  <hr className="text-muted"/>
                  <div className="mb-3">
                    
                    <div className="float-start align-middle" >
                      <span className="icon-tag hand text-muted mx-2" id="btnNuevaEtiqueta" onClick={this.abrirNuevaEtiqueta} data-bs-toggle="tooltip" title={this.props.intl.formatMessage( {id: 'app.padre.mi-mundo.popup.botonera.nuevaEtiqueta.tooltip'} )}> Nueva</span>
                      <span className="text-muted">&nbsp;|&nbsp;</span>
                      <span className="icon-tags hand text-muted mx-2" data-bs-dismiss="modal" onClick={this.goEtiquetas} data-bs-toggle="tooltip" title={this.props.intl.formatMessage( {id: 'app.padre.mi-mundo.popup.botonera.etiquetas.tooltip'} )}>Etiquetas</span>
                    </div>

                    <div className="float-end align-middle">
                      <button type="button" className="btn btn-sm btn-secondary me-3" data-bs-dismiss="modal"><FM id="app.padre.mi-mundo.popup.botonera.cerrar" /></button>
                      <button type="button" className="btn btn-sm btn-primary me-3" onClick={this.clickGuardarTags}><FM id="app.padre.mi-mundo.popup.botonera.etiquetar" /></button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            {/* FIN POPUP ETIQUETAR */}
          
          </div>
          {/* FIN CONTENEDOR */}

        </div>
        <Footer />
      </div>
    )
  }
}

export default injectIntl(withRouter(MiMundoPadre));