import React, { Component } from 'react';
import axios from 'axios';
import { injectIntl,  FormattedMessage as FM } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import * as utils from './../../common/utils/Funcs';
import * as appConst from './../../common/const/Const';

class FichaEmpresa extends Component{

    constructor(props){
        super(props);
        this.state = {
            usuarios: [],
            etiquetas: props.etiquetas
        }
        this.getUsuariosEmpresa = this.getUsuariosEmpresa.bind(this);
        this.getDescripcion = this.getDescripcion.bind(this);
        this.goActividades = this.goActividades.bind(this);
    }

    componentDidMount(){
    
        // Inicialización de los tooltip de este componente
        //utils.iniTooltip();

        // Se cargan los usuarios asociados a cada empresa debajo de su logo
        this.getUsuariosEmpresa();

        // Tratamiento para pintar la descripción en función al tamaño del texto (ver más... / ver menos...)
        this.getDescripcion();
    }

    componentDidUpdate(){

        // Inicialización de los tooltip de este componente
        utils.iniTooltip();
    }

    componentWillReceiveProps(next_props){

        // A veces se queda pillado el tooltip al quitarse una etiqueta. Se quitan todas las etiquetas del hijo en curso por si acaso
        next_props.etiquetas.map(e => {if (document.querySelector("#idEtiquetas_"+e.id)) utils.hideTooltip("idEtiquetas_"+e.id)});
        
        // Ya que este componente 'FichaEmpresa' no se actualiza automáticamente cuando hay cambios en los datos,
        // estos cambios los detecta a través de este método para actualizar las etiquetas por si se ha añadido o quitado alguna        
        this.setState({etiquetas: next_props.etiquetas});
    }

    collapsedDescripcion(desc){

        // Plegar
        if (desc && desc.length > appConst.LIMITE_DESC_EMPRESA)
            return desc.substr(0, appConst.LIMITE_DESC_EMPRESA) + "...";
        
        // Desplegar
        else return desc;
    }

    getDescripcion(){

        const VER_MAS = this.props.intl.formatMessage( {id: 'app.padre.mi-mundo.descripcion.ver-mas'} )
        const VER_MENOS = this.props.intl.formatMessage( {id: 'app.padre.mi-mundo.descripcion.ver-menos'} )
        
        if (document.querySelector("#desc_"+this.props.idHijo)){
            
            let desc = this.props.descripcion;

            // Con descripción
            if (desc)
                if (document.querySelector("#desc_ver_"+this.props.idHijo).innerHTML === VER_MENOS){
                    
                    document.querySelector("#desc_"+this.props.idHijo).innerHTML = this.collapsedDescripcion(desc);
                    document.querySelector("#desc_ver_"+this.props.idHijo).innerHTML = VER_MAS;
                }else{

                    document.querySelector("#desc_"+this.props.idHijo).innerHTML = desc;
                    document.querySelector("#desc_ver_"+this.props.idHijo).innerHTML = VER_MENOS;
                }

            // Sin descripción
            else{
                document.querySelector("#desc_"+this.props.idHijo).innerHTML = "<i>"+this.props.intl.formatMessage({id:"app.padre.mi-mundo.sin-descripcion"})+"</i>";
                document.querySelector("#desc_ver_"+this.props.idHijo).innerHTML = "";
            }
        }
    }

    async getUsuariosEmpresa(){

        try{
          const response = await axios.get(appConst.GET_USUARIOS_BY_EMPRESA.replace("#idEmpresa", this.props.idEmpresa), {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)} );
          
          if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
              if (utils.getLog()) console.log("status GET_USUARIOS_BY_EMPRESA: "+response.status);
              this.setState ({
                usuarios: response.data
              });
          }
    
        }catch(error){
            if (utils.getLog()) console.log("Error GET_USUARIOS_BY_EMPRESA: "+error);
            this.props._showMensaje(utils.getTextError(error, this.props.intl, this.props.history, null, null, this.getUsuariosEmpresa, [], appConst.PERFIL_PADRE), utils.getTipoAlert(error), appConst.ALERT_OPEN);
        }
    }

    async deleteTag(idEtiqueta){
        
        try{
            const response = await axios.delete(appConst.DELETE_ETIQUETAS_HIJO.replace("#idEtiquetasHijo", idEtiqueta), {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE)} );
            
            if (response && response.status === appConst.HTTPSTATUS_OK){
                if (utils.getLog()) console.log("status DELETE_ETIQUETAS_HIJO: "+response.status);
                this.props._showMensaje("La etiqueta se ha quitado correctamente", appConst.ALERT_TIPO_SUCCESS, appConst.ALERT_CLOSE);
                this.props._listarMundo();
            }
      
          }catch(error){
              if (utils.getLog()) console.log("Error DELETE_ETIQUETAS_HIJO: "+error);
              this.props._showMensaje(utils.getTextError(error, this.props.intl, this.props.history, null, null, this.deleteTag.bind(this), [idEtiqueta], appConst.PERFIL_PADRE), utils.getTipoAlert(error), appConst.ALERT_OPEN);
          }
    }
    
    goActividades(){

        utils.hideTooltip("btnIconoActividad_"+this.props.idHijo);

        this.props.history.push({
            pathname: process.env.REACT_APP_WEB_BASE_URL+"/p/lista-actividades",
            state: {
                nombreEmpresa: this.props.nombre,
                idHijo: this.props.idHijo,
                perfil: appConst.PERFIL_PADRE
            }
        });
    }
    
    toggleUserSel(sufijoIdObj){

        // Param sufijoIdObj: El sufijo es idEmpresa + "_" + idUsuario

        // Toggle: se muestra todo (input e img)
        if (document.querySelector("#imgCheck_"+sufijoIdObj).className.indexOf("hideElement") >= 0){
            
            // Comprobar antes que la empresa esté ya seleccionada, en caso contrario, forzar su selección
            if (!document.querySelector("#chkIdHijo_"+this.props.idHijo).checked)
                document.querySelector("#chkIdHijo_"+this.props.idHijo).checked = true;
            
            // La empresa ya está marcada y se procede a mostrar el check (img)
            document.querySelector("#imgCheck_"+sufijoIdObj).className = "top-50 start-50 translate-middle icon-check green icono-muy-peke";
            // La empresa ya está marcada y se procede a marcar el check oculto (input) 
            document.querySelector("#inputCheck_"+sufijoIdObj).checked = true;
            
        // Toggle: se oculta todo (input e img)
        }else{
            // Se oculta el check (img)
            document.querySelector("#imgCheck_"+sufijoIdObj).className = "hideElement";
            // Se desmarca el check (input)
            document.querySelector("#inputCheck_"+sufijoIdObj).checked = false;

            // Comprobar si ya no quedan más usuarios marcados, en ese caso, se desmarca la empresa también
            let encontradoMarcado = false;
            const idEmpresa = sufijoIdObj.split("_")[0];
            for (const item of document.querySelectorAll("input[name='inputCheck_"+idEmpresa+"']"))
                if (item.checked)
                    encontradoMarcado = true;

            if (!encontradoMarcado)
                document.querySelector("#chkIdHijo_"+this.props.idHijo).checked = false;
        }
    }

    /*checkedAdminUser(idEmpresa){

        // Desmarcar todos los checks (img e input) de la empresa
        if (!document.querySelector("#chkIdHijo_"+this.props.idHijo).checked){
            
            // Desmarcar checks input de usuarios de las empresas
            for (const item of document.querySelectorAll("[name='inputCheck_"+idEmpresa+"']"))
                item.checked = false;
            // Ocultar check img de usuarios de las empresas
            for (const item of document.querySelectorAll("[name='imgCheck_"+idEmpresa+"']"))
                item.className = "hideElement";

        // Buscar y marcar el user admin
        }else{
            
            // Se marca el check de usuario admin (img e input)
            const objInputAdmin = document.querySelector("#inputAdmin_"+idEmpresa);
            // Si no se encuentra el usuario admin se sale directamente de la función
            if (!objInputAdmin) return;

            const sufijoIdObj = idEmpresa + "_" + objInputAdmin.value;
            
            // Se muestra el check (img)
            document.querySelector("#imgCheck_"+sufijoIdObj).className = "top-50 start-50 translate-middle icon-check green icono-muy-peke";
            // Se marca el check (input)
            document.querySelector("#inputCheck_"+sufijoIdObj).checked = true;
        }
    }*/

    getUserTooltip = u => "<strong>"+u.nombre+" "+utils.getCleanValue(u.apellidos)+"</strong><br/>"+u.email+"<br/>"+u.cargo.nombre+"<br/>"+((u.admin == appConst.ES_USER_ADMIN) ? "[Administrador]" : "");
    
    render(){ 
        return (

            <div className="mb-4">

                {/* CABECERA */}
                <div className="row headerCajaMatch tamTitle">
                    <div className="col-md-3">
                        <input type="checkbox" id={"chkIdHijo_"+this.props.idHijo} name={"chkIdHijo_"+this.props.idHijo} onClick={this.props._checkedAdminUser.bind(this, this.props.idHijo, this.props.idEmpresa)}/>&nbsp;&nbsp;
                        <label for={"chkIdHijo_"+this.props.idHijo} className="hand">{this.props.nombre}</label>
                    </div>
                    <div className="col-md-7 text-end pe-4">
                        <span className="fst-italic">{(!this.props.direccion) && <FM id="app.padre.mi-mundo.sin-direccion" />}</span>
                        <span>{(this.props.direccion) && this.props.direccion +" - "+ this.props.localidad +" - "+ this.props.provincia}</span>
                    </div>
                    <div className="col-md-2 text-center bordeIzqBlanco">
                        <div className="row">
                            {/* ACTIVIDAD */}
                            <div className="col-md-3">
                                <span id={"btnIconoActividad_"+this.props.idHijo} className="icon-list icono-muy-peke linkFFF hand" onClick={this.goActividades} data-bs-toggle="tooltip" title="Actividad"></span>
                            </div>
                            {/* WEB */}
                            <div className="col-md-3">
                                <a href={this.props.urlWeb} target="_blank" className="linkFFF">
                                    <span className="icon-globe icono-muy-peke" data-bs-toggle="tooltip" title={((this.props.urlWeb) && "Web") || ((!this.props.urlWeb) && "No existe enlace a su Web")} />
                                </a>
                            </div>
                            {/* NOTICIA */}
                            <div className="col-md-3">
                                <a href={this.props.urlNoticia} target="_blank" className="linkFFF">
                                    <span className="icon-noticia icono-muy-peke" data-bs-toggle="tooltip" title={((this.props.urlNoticia) && "Noticia") || ((!this.props.urlNoticia) && "No existe enlace a su noticia")} />
                                </a>
                            </div>
                            {/* VíDEO */}
                            <div className="col-md-3">
                                <a href={this.props.urlVideo} target="_blank" className="linkFFF">
                                    <span className="icon-video icono-muy-peke" data-bs-toggle="tooltip" title={((this.props.urlVideo) && "Vídeo") || ((!this.props.urlVideo) && "No existe enlace a su vídeo")} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* CUERPO */}
                <div className="row bodyCajaMatch">
                    
                    {/* LOGO Y NOMBRE EMPRESA */}
                    <div className="col-md-2 pe-4">
                        
                        {/* LOGOS EMPRESA */}
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <p><img className="imgMatch" src={this.props.logo} /></p>
                            </div>
                        </div>

                        {/* USUARIOS EMPRESA */}
                        <div className="row">
                            <div className="col-md-12 text-center">
                                
                                {this.state.usuarios.map(u => 
                                    <div className='box'>
                                        <input type="checkbox" id={"inputCheck_"+u.idEmpresa +"_"+ u.id} name={"inputCheck_"+u.idEmpresa} className="hideElement" data-bs-tipoCheck="input"/>
                                        <input type="hidden" id={"inputEmail_"+u.idEmpresa +"_"+ u.id} value={u.email}/>
                                        {/* Si es admin (solo puede haber uno) se guarda su id de usuario en el value del input para después seleccionarlo cuando se marque la empresa */}
                                        {(u.admin == appConst.ES_USER_ADMIN) && <input type="hidden" id={"inputAdmin_"+u.idEmpresa} value={u.id}/>}
                                        <div data-bs-toggle="tooltip" data-bs-html="true" title={this.getUserTooltip(u)} name={"FichaEmpresa"}>
                                            <span className="hideElement" id={"imgCheck_"+u.idEmpresa +"_"+ u.id} name={"imgCheck_"+u.idEmpresa} data-bs-tipoCheck="img"></span><br/>
                                            <label 
                                                className={((u.admin == appConst.ES_USER_ADMIN) ? "avatarAdmin" : "avatar") + " hand"}
                                                onClick={this.toggleUserSel.bind(this, u.idEmpresa + "_" + u.id)}
                                            >
                                                {utils.getLetrasAvatarFromUser(u)}
                                            </label>
                                        </div>
                                    </div>
                                )}
                                
                                {this.state.usuarios.length === 0 && <span className="prosa fst-italic">(sin usuarios)</span>}
                            </div>
                        </div>
                        
                    </div>

                    {/* DESCRIPCION, AREAS INTERES, NECESIDADES, SECTOR */}
                    <div className="col-md-8 px-4 bordeIzqAzul">
                        <p>
                            <p className="text-justify">
                                <span className="prosa tamTitle" id={"desc_"+this.props.idHijo}></span>
                                <span className="hand" id={"desc_ver_"+this.props.idHijo} onClick={this.getDescripcion}><FM id="app.padre.mi-mundo.descripcion.ver-menos" /></span>
                            </p>
                            <div className="row text-center">
                                <div className="col-sm-4">
                                    <span className="icon-star-full icono-medio" data-bs-toggle="tooltip" title="Áreas de interés"></span><br/>
                                    <span className="prosa">{this.props.areasInteres.map(e => <span>{e.areaInteres.nombre}<br /></span>)}</span>
                                    {this.props.areasInteres.length === 0 && <span className="prosa fst-italic"><FM id="app.padre.mi-mundo.sin-area-interes" /></span>}
                                </div>
                                <div className="col-sm-4">
                                    <span className="icon-help icono-medio" data-bs-toggle="tooltip" title="Necesidades"></span><br/>
                                    <span className="prosa">{this.props.necesidades.map(e => <span>{e.necesidad.nombre}<br /></span>)}</span>
                                    {this.props.necesidades.length === 0 && <span className="prosa fst-italic"><FM id="app.padre.mi-mundo.sin-necesidades" /></span>}
                                </div>
                                <div className="col-sm-4">
                                    <span className="icon-empresa icono-medio" data-bs-toggle="tooltip" title="Sector"></span><br/>
                                    <span className="prosa">{this.props.sector}</span>
                                </div>
                            </div>
                        </p>
                    </div>

                    {/* ETIQUETAS */}
                    <div className="col-md-2 bordeIzqAzul">
                        <div className="icon-tags text-center icono-medio mb-2" data-bs-toggle="tooltip" title={"Etiquetas de "+this.props.nombre} ></div>
                        {this.state.etiquetas.length === 0 && <div className='text-center'><span className="prosa fst-italic ps-2"><FM id="app.padre.mi-mundo.sin-etiquetas" /></span></div>}
                        
                        {this.state.etiquetas.length > 0 && this.state.etiquetas.map(e =>
                            <div className="row capaTag p-1 mx-1 mb-2" id={"idEtiquetas_"+e.id}>
                            {/*<div className="row capaTag mx-1 mb-2" id={"idEtiquetas_"+e.id} title={(e.etiqueta.nombre.length > appConst.LIMITE_TAG_EMPRESA) ? e.etiqueta.nombre : ""}>*/}
                                <div className='col-sm-9 text-start'>
                                    {/*<span>{(e.etiqueta.nombre.length <= appConst.LIMITE_TAG_EMPRESA) ? e.etiqueta.nombre : e.etiqueta.nombre.substr(0, appConst.LIMITE_TAG_EMPRESA) + "..."}</span>*/}
                                    <span>{e.etiqueta.nombre}</span>
                                </div>
                                <div className='col-sm-3'>
                                    <span className="icon-delete icono-muy-peke hand" onClick={this.deleteTag.bind(this, e.id)}></span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(withRouter(FichaEmpresa));