import React, { Component, useRef } from 'react';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import axios from 'axios';
import Alert from '../../common/utils/Alert';
import * as appConst from '../../common/const/Const'
import * as utils from '../../common/utils/Funcs';
import Header from './../header/Header';
import Footer from './../footer/Footer';
import CargarMaestroDatos from './../../common/datos/CargarMaestroDatos';
import Badge from './../../common/utils/Badge';
// DatePicker
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
registerLocale("es", es);

class MiPerfil extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      campo: {},
      necesidades: [],
      necesidadesHijo: [],
      areasInteres: [],
      areasInteresHijo: [],
      error: {},
      textoAlert: '',
      tipoAlert: '',
      closeAlert: appConst.ALERT_OPEN
    }
    this.cargarUsuario = this.cargarUsuario.bind(this);
    this.cargarEmpresa = this.cargarEmpresa.bind(this);
    this.guardarUsuario = this.guardarUsuario.bind(this);
    this.guardarEmpresa = this.guardarEmpresa.bind(this);
    this.guardarNecesidades = this.guardarNecesidades.bind(this);
    this.guardarAreasInteres = this.guardarAreasInteres.bind(this);
    this.actualizarUsuario = this.actualizarUsuario.bind(this);
    this.actualizarMundo = this.actualizarMundo.bind(this);
    this.actualizarNecesidades = this.actualizarNecesidades.bind(this);
    this.actualizarAreasInteres = this.actualizarAreasInteres.bind(this);
    this.actualizarHijo = this.actualizarHijo.bind(this);
    this.actualizarEmpresa = this.actualizarEmpresa.bind(this);
    this.onChangeFechaAntiguedad = this.onChangeFechaAntiguedad.bind(this);
    this.uploadFileToS3 = this.uploadFileToS3.bind(this);
  }

  componentDidMount(){

    // Inicialización de los tooltip de este componente
    utils.iniTooltip();

    this.precargarForms();
  }

  async precargarForms(){

    // Usuario (carga datos pero no muestra) --> Por defecto se carga esta pestaña (por estilo active a pelo en MENU TABS)
    this.cargarUsuario();
    
    // Empresa (carga datos pero no muestra)
    const clickPestana2 = await this.cargarEmpresa();
    
    // Necesidades (carga datos pero no muestra)
    const clickPestana3 = await this.cargarNecesidades();

    // Áreas de interés (carga datos pero no muestra)
    const clickPestana4 = await this.cargarAreasInteres();
    
    // Posibilidades de procedencia para forzar la precarga del form solicitado:
    //    - PRIMERO: al pinchar en el menú perfil 
    //    - SEGUNDO: al llegar procedente de una notificación en la url (header): .../h/mi-perfil?<num_pestaña>
    const url = window.location.href;
    const clickNotificacionPestana = (url.indexOf("?") >= 0) ? url.split("?")[1] : "";
    
    if (clickPestana2 || clickNotificacionPestana === "2"){
      this.showEmpresa();
      this.showMessage(<FM id="app.general.datosEmpresaIncompletos" />);
    
    }else if ((clickPestana3 && !clickNotificacionPestana) || clickNotificacionPestana === "3"){
      this.showNecesidades();
      this.showMessage(<FM id="app.general.necesidadesEmpresaIncompletas" />);
    
    }else if ((clickPestana4 && !clickNotificacionPestana) || clickNotificacionPestana === "4"){
      this.showAreasInteres();
      this.showMessage(<FM id="app.general.areasInteresEmpresaIncompletas" />);
    }
  }

  showMessage(texto){
    this.setState({
      textoAlert: texto,
      tipoAlert: appConst.ALERT_TIPO_WARNING,
      closeAlert: appConst.ALERT_OPEN
    });
  }
  
  async cargarUsuario(){
    
    try{
      const response = await axios.get(appConst.GET_USUARIO_BY_ID.replace("#idUsuario", utils.getTokenValue("idUsuario", appConst.PERFIL_HIJO)), {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_HIJO)})
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
        if (utils.getLog()) console.log("Status GET_USUARIO_BY_ID: "+response.status+ ". Perfil usuario cargado con éxito");
          
        const data = response.data;
        let campo = this.state.campo;
        // Se carga form de 'Mi usuario'
        campo["txtNombre"] = data.nombre;
        campo["txtApellidos"] = data.apellidos;
        campo["txtEmail"] = data.email;
        campo["cmbCargo"] = data.cargo.id;
        campo["chkPublicidad"] = utils.getStringToBoolean(data.consentPubli);
        campo["fechaAlta"] = utils.formatearFechaCastellano(data.fechaAlta);

        this.setState({ campo });
      }

    }catch(error){
      if (utils.getLog()) console.log("Error GET_USUARIO_BY_ID: "+error);
      this.setState({
        textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.cargarUsuario, [], appConst.PERFIL_HIJO),
        tipoAlert: utils.getTipoAlert(error),
        closeAlert: appConst.ALERT_OPEN
      });
    }
  }

  async cargarEmpresa(){
    
    try{
      const response = await axios.get(appConst.GET_MUNDO_BY_ID.replace("#idMundo", utils.getTokenValue("idMundo", appConst.PERFIL_HIJO)), {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_HIJO)})
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
        if (utils.getLog()) console.log("Status GET_MUNDO_BY_ID: "+response.status+ ". Perfil empresa cargado con éxito");
          
        // Se consulta la entidad Mundo para obtener los datos desde arriba y cargar datos del mundo, del hijo y de la empresa
        const data = response.data;
        let campo = this.state.campo;
        // Se carga form de 'Mi empresa'
        // Datos de empresa
        campo["txtNif"] = data.hijo.empresa.nif;
        campo["txtRazonSocial"] = data.hijo.empresa.razonSocial;
        campo["txtNombreEmpresa"] = data.hijo.empresa.nombre;
        campo["txtEmailEmpresa"] = data.hijo.empresa.email;
        campo["txtTelefono"] = data.hijo.empresa.telefono;
        campo["txtLogo"] = data.hijo.empresa.logo;
        campo["txtDireccion"] = data.hijo.empresa.direccion;
        campo["txtProvincia"] = data.hijo.empresa.provincia;
        campo["txtLocalidad"] = data.hijo.empresa.localidad;
        campo["txtCP"] = data.hijo.empresa.cp;
        campo["txtUrlWeb"] = data.hijo.empresa.urlweb;
        campo["txtUrlVideo"] = data.hijo.empresa.urlvideo;
        campo["txtUrlNoticia"] = data.hijo.empresa.urlnoticia;
        campo["txtDescripcion"] = data.hijo.empresa.descripcion;
        campo["txtTipoEmpresa"] = data.hijo.empresa.idTipoEmpresa;
        // Si los combos no se cargan con ningún valor porque está vacio, se asigna en el estado el primer valor del combo por defecto
        campo["cmbSector"] = (data.hijo.empresa.sector) ? data.hijo.empresa.sector.id : "";
        campo["validacion"] = data.hijo.empresa.validacion;
        // Datos de Hijo
        campo["cmbTipoHijo"] = data.hijo.idTipoHijo;
        // Datos de Mundo
        campo["txtFechaAntiguedad"] = data.fechaAntiguedad;
        
        this.setState({ campo });

        // Se fuerza la precarga de este form retornando true. Los datos de la empresa son incompletos y el usuario es administador de su empresa para poder editarlos
        if (data.hijo.empresa.validacion === appConst.EMPRESA_VALIDACION_KO && utils.isAdmin(appConst.PERFIL_HIJO)){
          return true;
        
        // En caso contrario (que no sea un usuario administador), se deshabilita siempre, sin condición, todo el form de empresa y su botón de guardar
        }else if (!utils.isAdmin(appConst.PERFIL_HIJO)){

          let form = document.querySelector("#formEmpresa")
          var elements = form.elements;
          for (var i = 0, len = elements.length; i < len; ++i) 
              elements[i].readOnly = true;

          document.querySelector("#btnGuardarEmpresa").className = "hideElement";
        }

        return false;
      }
      
    }catch(error){
      if (utils.getLog()) console.log("Error GET_MUNDO_BY_ID: "+error);
      this.setState({
        textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.cargarEmpresa, [], appConst.PERFIL_HIJO),
        tipoAlert: utils.getTipoAlert(error),
        closeAlert: appConst.ALERT_OPEN
      });
    }
  }

  async cargarNecesidades(){

    try{
      const response = await axios.get(appConst.GET_MAESTRO.replace("#tabla", appConst.MAESTRO_NECESIDADES), {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_HIJO)})
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
        if (utils.getLog()) console.log("Status GET_MAESTRO: "+response.status+ ". Perfil necesidades cargado con éxito");
        
        this.setState({ necesidades: response.data });

        // Se chequean las necesidades que este hijo ha seleccionado y se devuelve si procede de notificación (necesidades a rellenar)
        return this.marcarNecesidadesHijo();
      }
    }catch(error){
      if (utils.getLog()) console.log("Error GET_MAESTRO: "+error);
      this.setState({
        textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.cargarNecesidades, [], appConst.PERFIL_HIJO),
        tipoAlert: utils.getTipoAlert(error),
        closeAlert: appConst.ALERT_OPEN
      });
    }
  }

  async marcarNecesidadesHijo(){

    try{
      const response = await axios.get(appConst.GET_NECESIDADES_HIJO.replace("#idHijo", utils.getTokenValue("idHijo", appConst.PERFIL_HIJO)), {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_HIJO)})
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
        if (utils.getLog()) console.log("Status GET_NECESIDADES_HIJO: "+response.status+ ". Necesidades del hijo chequeadas con éxito");
        
        // Se recorre todas las necesidades y se van chequeando las que coincidan con este hijo
        const necesidadesHijo = response.data;
        if (necesidadesHijo && necesidadesHijo.length > 0)
          this.state.necesidades.map(n => necesidadesHijo.map(h => {
              if (n.id === h.necesidad.id) document.querySelector("#chkNecesidad_"+n.id).checked = true;
            })
          );
      
      }else if (response && response.status === appConst.HTTPSTATUS_NO_CONTENT){

        // Se fuerza la precarga de este form retornando true. Las necesidades de la empresa están vacías y el usuario es administador de su empresa para poder editarlas
        if (utils.isAdmin(appConst.PERFIL_HIJO))
          return true;
      }
      
      // Si el usuario no es administador se deshabilita siempre, sin condición, todo el form de necesidades y su botón de guardar
      if (!utils.isAdmin(appConst.PERFIL_HIJO)){
        this.state.necesidades.map(n => document.querySelector("#chkNecesidad_"+n.id).disabled = true);
        document.querySelector("#btnGuardarNecesidades").className = "hideElement";
      }

      return false;

    }catch(error){
      if (utils.getLog()) console.log("Error GET_NECESIDADES_HIJO: "+error);
      this.setState({
        textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.marcarNecesidadesHijo, [], appConst.PERFIL_HIJO),
        tipoAlert: utils.getTipoAlert(error),
        closeAlert: appConst.ALERT_OPEN
      });
    }
  }

  async cargarAreasInteres(){

    try{
      const response = await axios.get(appConst.GET_AREAS_INTERES, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_HIJO), params: {
        idPadre: utils.getTokenValue("idPadre", appConst.PERFIL_HIJO)
      }})
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
        if (utils.getLog()) console.log("Status GET_AREAS_INTERES: "+response.status+ ". Perfil áreas de interés cargado con éxito");
        
        this.setState({ areasInteres: response.data });

        // Se chequean las áreas de interés que este hijo ha seleccionado y se devuelve si procede de notificación (áreas de interés a rellenar)
        return this.marcarAreasInteresHijo();
      }
    }catch(error){
      if (utils.getLog()) console.log("Error GET_AREAS_INTERES: "+error);
      this.setState({
        textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.cargarAreasInteres, [], appConst.PERFIL_HIJO),
        tipoAlert: utils.getTipoAlert(error),
        closeAlert: appConst.ALERT_OPEN
      });
    }
  }

  async marcarAreasInteresHijo(){

    try{
      const response = await axios.get(appConst.GET_AREAS_INTERES_HIJO.replace("#idMundo", utils.getTokenValue("idMundo", appConst.PERFIL_HIJO)), {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_HIJO)})
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
        if (utils.getLog()) console.log("Status GET_AREAS_INTERES_HIJO: "+response.status+ ". Áreas de interés del hijo chequeadas con éxito");
        
        // Se recorre todas las áreas de interés y se van chequeando las que coincidan con este hijo
        const areasInteresHijo = response.data;
        if (areasInteresHijo && areasInteresHijo.length > 0)
          this.state.areasInteres.map(a => areasInteresHijo.map(ah => {
              if (a.id === ah.idAreaInteres) document.querySelector("#chkAreasInteres_"+a.id).checked = true;
            })
          );
      
      }else if (response && response.status === appConst.HTTPSTATUS_NO_CONTENT){

        // Se fuerza la precarga de este form retornando true. Las áreas de interés de la empresa están vacías y el usuario es administador de su empresa para poder editarlas
        if (utils.isAdmin(appConst.PERFIL_HIJO))
          return true;
      }
      
      // Si el usuario no es administador se deshabilita siempre, sin condición, todo el form de áreas de interés y su botón de guardar
      if (!utils.isAdmin(appConst.PERFIL_HIJO)){
        this.state.areasInteres.map(a => document.querySelector("#chkAreasInteres_"+a.id).disabled = true);
        if (document.querySelector("#btnGuardarAreasInteres"))
          document.querySelector("#btnGuardarAreasInteres").className = "hideElement";
      }

      return false;

    }catch(error){
      if (utils.getLog()) console.log("Error GET_AREAS_INTERES_HIJO: "+error);
      this.setState({
        textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.marcarAreasInteresHijo, [], appConst.PERFIL_HIJO),
        tipoAlert: utils.getTipoAlert(error),
        closeAlert: appConst.ALERT_OPEN
      });
    }
  }

  guardarUsuario(){

    if ( this.validarFormUsuario() )
      this.actualizarUsuario();
  }

  guardarEmpresa(){
    
    if ( this.validarFormEmpresa() )
      this.actualizarMundo();
  }

  guardarNecesidades(){

    this.actualizarNecesidades();
  }

  guardarAreasInteres(){

    this.actualizarAreasInteres();
  }

  async actualizarUsuario(){

    const data = {
      "nombre": this.state.campo["txtNombre"],
      "apellidos": this.state.campo["txtApellidos"],
      "idCargo": this.state.campo["cmbCargo"],
      "consentPubli": utils.getBooleanNumber(this.state.campo["chkPublicidad"])
    }
    
    try{
      const response = await axios.put(appConst.PUT_USUARIO.replace("#idUsuario", utils.getTokenValue("idUsuario", appConst.PERFIL_HIJO)), data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_HIJO)})
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
        if (utils.getLog()) console.log("Status PUT_USUARIO: "+response.status+ ". Perfil usuario actualizado con éxito");
        this.setState({
          textoAlert: <FM id="app.hijo.mi-perfil.form.aviso.guardar.ok" />,
          tipoAlert: appConst.ALERT_TIPO_SUCCESS,
          closeAlert: appConst.ALERT_CLOSE
        });
      }

      // Se refresca el token cuando se guarda la pestaña Usuario para actualizar los datos del token y de la cabecera
      utils.refrescarToken(this.props.intl, this.props.history, this.showMessageUpdateToken.bind(this), [], appConst.PERFIL_HIJO);

    }catch(error){
      if (utils.getLog()) console.log("Error PUT_USUARIO: "+error);
      this.setState({
        textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.actualizarUsuario, [], appConst.PERFIL_HIJO),
        tipoAlert: utils.getTipoAlert(error),
        closeAlert: appConst.ALERT_OPEN
      });
    }
  }

  async actualizarMundo(){

    const data = {
      "fechaAntiguedad": this.state.campo["txtFechaAntiguedad"],
      "idAutor": utils.getTokenValue("idUsuario", appConst.PERFIL_HIJO)
    }

    try{
      const response = await axios.put(appConst.PUT_MUNDO.replace("#idMundo", utils.getTokenValue("idMundo", appConst.PERFIL_HIJO)), data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_HIJO)})
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
        if (utils.getLog()) console.log("Status PUT_MUNDO: "+response.status+ ". Perfil (mundo) actualizado con éxito");

        this.actualizarHijo();
      }
    }catch(error){
      if (utils.getLog()) console.log("Error PUT_MUNDO: "+error);
      this.setState({
        textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.actualizarMundo, [], appConst.PERFIL_HIJO),
        tipoAlert: utils.getTipoAlert(error),
        closeAlert: appConst.ALERT_OPEN
      });
    }
  }

  async actualizarHijo(){

    const data = {
      "idTipoHijo": this.state.campo["cmbTipoHijo"],
      "idAutor": utils.getTokenValue("idUsuario", appConst.PERFIL_HIJO)
    }

    try{
      const response = await axios.put(appConst.PUT_HIJO.replace("#idHijo", utils.getTokenValue("idHijo", appConst.PERFIL_HIJO)), data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_HIJO)})
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
        if (utils.getLog()) console.log("Status PUT_HIJO: "+response.status+ ". Perfil (hijo) actualizado con éxito");

        this.actualizarEmpresa();
      }
    }catch(error){
      if (utils.getLog()) console.log("Error PUT_HIJO: "+error);
      this.setState({
        textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.actualizarHijo, [], appConst.PERFIL_HIJO),
        tipoAlert: utils.getTipoAlert(error),
        closeAlert: appConst.ALERT_OPEN
      });
    }
  }

  async actualizarEmpresa(){

    const data = {
      "nif": this.state.campo["txtNif"],
      "razonSocial": this.state.campo["txtRazonSocial"],
      "nombre": this.state.campo["txtNombreEmpresa"],
      "idSector": this.state.campo["cmbSector"],
      "idTipoEmpresa": this.state.campo["txtTipoEmpresa"],
      "email": this.state.campo["txtEmailEmpresa"],
      "telefono": this.state.campo["txtTelefono"],
      "logo": this.state.campo["txtLogo"],
      "cp": this.state.campo["txtCP"],
      "localidad": this.state.campo["txtLocalidad"],
      "provincia": this.state.campo["txtProvincia"],
      "direccion": this.state.campo["txtDireccion"],
      "urlweb": this.state.campo["txtUrlWeb"],
      "urlvideo": this.state.campo["txtUrlVideo"],
      "urlnoticia": this.state.campo["txtUrlNoticia"],
      "descripcion": this.state.campo["txtDescripcion"],
      "validacion": appConst.EMPRESA_VALIDACION_OK,
      "idAutor": utils.getTokenValue("idUsuario", appConst.PERFIL_HIJO)
    }

    try{
      const response = await axios.put(appConst.PUT_EMPRESA.replace("#idEmpresa", utils.getTokenValue("idEmpresa", appConst.PERFIL_HIJO)), data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_HIJO)})
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
        if (utils.getLog()) console.log("Status PUT_EMPRESA: "+response.status+ ". Perfil (empresa) actualizado con éxito");
        this.setState({
          textoAlert: <FM id="app.hijo.mi-perfil.form.aviso.guardar.ok" />,
          tipoAlert: appConst.ALERT_TIPO_SUCCESS,
          closeAlert: appConst.ALERT_CLOSE
        });
      }

      // Se refresca el token cuando se guarda la pestaña Empresa para actualizar los datos del token, la cabecera y el logo del menú
      utils.refrescarToken(this.props.intl, this.props.history, this.showMessageUpdateToken.bind(this), [], appConst.PERFIL_HIJO);

    }catch(error){
      if (utils.getLog()) console.log("Error PUT_EMPRESA: "+error);
      this.setState({
        textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.actualizarEmpresa, [], appConst.PERFIL_HIJO),
        tipoAlert: utils.getTipoAlert(error),
        closeAlert: appConst.ALERT_OPEN
      });
    }
  }
  
  async actualizarNecesidades(){
    
      let data = [];
      const res = this.state.necesidades.map(n => {
        if (document.querySelector("#chkNecesidad_"+n.id+":checked")){
          return data.push({
            idHijo: utils.getTokenValue("idHijo", appConst.PERFIL_HIJO),
            idNecesidad: n.id,
            idAutor: utils.getTokenValue("idUsuario", appConst.PERFIL_HIJO),
          });
        }
      });
      
      try{
        const response = await axios.put(appConst.PUT_NECESIDADES_HIJO_COMPLETAS.replace("#idHijo", utils.getTokenValue("idHijo", appConst.PERFIL_HIJO)), data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_HIJO)})
        if (response && response.status === appConst.HTTPSTATUS_OK){
          if (utils.getLog()) console.log("Status PUT_NECESIDADES_HIJO_COMPLETAS: "+response.status+ ". Necesidades del hijo actualizadas con éxito");
          this.setState({
            textoAlert: <FM id="app.hijo.mi-perfil.form.aviso.guardar.ok" />,
            tipoAlert: appConst.ALERT_TIPO_SUCCESS,
            closeAlert: appConst.ALERT_CLOSE
          });
        }
      }catch(error){
        if (utils.getLog()) console.log("Error PUT_NECESIDADES_HIJO_COMPLETAS: "+error);
        this.setState({
          textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.actualizarNecesidades, [], appConst.PERFIL_HIJO),
          tipoAlert: utils.getTipoAlert(error),
          closeAlert: appConst.ALERT_OPEN
        });
      }
  }

  async actualizarAreasInteres(){
    
    let data = [];
    const res = this.state.areasInteres.map(a => {
      if (document.querySelector("#chkAreasInteres_"+a.id+":checked")){
        return data.push({
          idMundo: utils.getTokenValue("idMundo", appConst.PERFIL_HIJO),
          idAreaInteres: a.id,
          idAutor: utils.getTokenValue("idUsuario", appConst.PERFIL_HIJO),
        });
      }
    });
    
    try{
      const response = await axios.put(appConst.PUT_AREAS_INTERES_HIJO_COMPLETAS.replace("#idMundo", utils.getTokenValue("idMundo", appConst.PERFIL_HIJO)), data, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_HIJO)})
      if (response && response.status === appConst.HTTPSTATUS_OK){
        if (utils.getLog()) console.log("Status PUT_AREAS_INTERES_HIJO_COMPLETAS: "+response.status+ ". Áreas de interés del hijo actualizadas con éxito");
        this.setState({
          textoAlert: <FM id="app.hijo.mi-perfil.form.aviso.guardar.ok" />,
          tipoAlert: appConst.ALERT_TIPO_SUCCESS,
          closeAlert: appConst.ALERT_CLOSE
        });
      }
    }catch(error){
      if (utils.getLog()) console.log("Error PUT_AREAS_INTERES_HIJO_COMPLETAS: "+error);
      this.setState({
        textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.actualizarAreasInteres, [], appConst.PERFIL_HIJO),
        tipoAlert: utils.getTipoAlert(error),
        closeAlert: appConst.ALERT_OPEN
      });
    }
  }

  validarFormUsuario() {
    let campo = this.state.campo;
    let error = {};
    let formularioValido = true;
  
    // Nombre
    if (!campo['txtNombre']) {
      formularioValido = false;
      error["txtNombre"] = <FM id='app.general.requerido'/>;
    }

    // Apellidos
    if (!campo['txtApellidos']) {
      formularioValido = false;
      error["txtApellidos"] = <FM id='app.general.requerido'/>;
    }

    // Email
    if (!campo['txtEmail']) {
      formularioValido = false;
      error["txtEmail"] = <FM id='app.general.requerido'/>;
    
    // Se valida si el formato del email de usuario es válido
    }else if (!utils.isValidEmail(campo["txtEmail"])) {
      formularioValido = false;
      error["txtEmail"] = <FM id='app.general.format.email'/>;
    }

    // Cargo
    if (!campo['cmbCargo']) {
      formularioValido = false;
      error["cmbCargo"] = <FM id='app.general.requerido'/>;
    }
    
    // Seteo el estado de error 
    if (!formularioValido)
      this.setState({
        error: error,
        textoAlert: <FM id='app.general.form.revisarCampos'/>,
        tipoAlert: appConst.ALERT_TIPO_WARNING,
        closeAlert: appConst.ALERT_OPEN
      });
    
    // Se limpian las validaciones por cada campo
    else this.setState({ error: {} });
  
    return formularioValido;
  }

  validarFormEmpresa() {
    let campo = this.state.campo;
    let error = {};
    let formularioValido = true;
  
    // NIF
    if (!campo['txtNif']) {
      formularioValido = false;
      error["txtNif"] = <FM id='app.general.requerido'/>;

    }else if (!utils.validarNIF(campo['txtNif'])){
      formularioValido = false;
      error["txtNif"] = <FM id='app.hijo.mi-perfil.form.val.nif'/>;
    }

    // Razón social
    if (!campo['txtRazonSocial']) {
      formularioValido = false;
      error["txtRazonSocial"] = <FM id='app.general.requerido'/>;
    }

    // Nombre empresa
    if (!campo['txtNombreEmpresa']) {
        formularioValido = false;
        error["txtNombreEmpresa"] = <FM id='app.general.requerido'/>;
    }

    // Dirección
    if (!campo['txtDireccion']) {
      formularioValido = false;
      error["txtDireccion"] = <FM id='app.general.requerido'/>;
    }

    // Localidad
    if (!campo['txtLocalidad']) {
      formularioValido = false;
      error["txtLocalidad"] = <FM id='app.general.requerido'/>;
    }

    // Provincia
    if (!campo['txtProvincia']) {
      formularioValido = false;
      error["txtProvincia"] = <FM id='app.general.requerido'/>;
    }

    // C.P.
    if (!campo['txtCP']) {
      formularioValido = false;
      error["txtCP"] = <FM id='app.general.requerido'/>;
    }else if (!utils.validarCodigoPostal(campo['txtCP'])){
      formularioValido = false;
      error["txtCP"] = <FM id='app.general.campo.CP.requerido'/>;
    }

    // Email empresa
    if (!campo['txtEmailEmpresa']) {
      formularioValido = false;
      error["txtEmailEmpresa"] = <FM id='app.general.requerido'/>;

    }else if (typeof campo["txtEmailEmpresa"] !== "undefined" && !utils.isValidEmail(campo["txtEmailEmpresa"])) {

      // Se valida si el formato del email de empresa es válido
      formularioValido = false;
      error["txtEmailEmpresa"] = <FM id='app.general.format.email'/>;
    }

    // Teléfono
    if (!campo['txtTelefono']) {
      formularioValido = false;
      error["txtTelefono"] = <FM id='app.general.requerido'/>;

    }else if (!utils.validarTelefono(campo['txtTelefono'])){
      formularioValido = false;
      error["txtTelefono"] = <FM id='app.general.format.telefono'/>;
    }

    // Web
    if (!campo['txtUrlWeb']) {
      formularioValido = false;
      error["txtUrlWeb"] = <FM id='app.general.requerido'/>;
    
    // Si hay web se valida su formato (empezar por https://)
    }else campo['txtUrlWeb'] = utils.validarURL(campo['txtUrlWeb'])
    
    // Logo (objeto a subir a AWS S3, es la propiedad "location" del response de uploadFile)
    if (!campo['txtLogo']) {
      formularioValido = false;
      error["txtLogo"] = <FM id='app.general.requerido'/>;
      
    // Si hay logo pero es el de por defecto (sin imagen) se muestra error
    }else if (utils.getStringURL(campo['txtLogo']) === utils.getStringURL(appConst.SIN_LOGO_EMPRESA)){
      formularioValido = false;
      error["txtLogo"] = <FM id='app.general.cambiar-imagen'/>;

    // Si hay logo se valida su formato (empezar por https://)
    }else campo['txtLogo'] = utils.validarURL(campo['txtLogo'])

    // Noticia (opcional pero si llega validar formato)
    if (campo['txtUrlNoticia']) campo['txtUrlNoticia'] = utils.validarURL(campo['txtUrlNoticia'])

    // Vídeo (opcional pero si llega validar formato)
    if (campo['txtUrlVideo']) campo['txtUrlVideo'] = utils.validarURL(campo['txtUrlVideo'])

    // Sector
    if (!campo['cmbSector']) {
      formularioValido = false;
      error["cmbSector"] = <FM id='app.general.requerido'/>;
    }

    // Tipo de perfil (tipo de hijo)
    if (!campo['cmbTipoHijo']) {
      formularioValido = false;
      error["cmbTipoHijo"] = <FM id='app.general.requerido'/>;
    }

    // Descripción
    if (!campo['txtDescripcion']) {
      formularioValido = false;
      error["txtDescripcion"] = <FM id='app.general.requerido'/>;
    }else if(campo['txtDescripcion'].length > 4000) {
      formularioValido = false;
      error["txtDescripcion"] = <FM id='app.general.limiteCaracteres.desc'/>;
    }

    // Seteo el estado de error 
    if (!formularioValido)
      this.setState({
        campo,
        error,
        textoAlert: <FM id='app.general.form.revisarCampos'/>,
        tipoAlert: appConst.ALERT_TIPO_WARNING,
        closeAlert: appConst.ALERT_OPEN
      });
    
    // Se limpian las validaciones por cada campo
    else this.setState({ error: {} });
  
    return formularioValido;
  }

  // Se detecta cuando un campo del formulario es rellenado y por ende cambia de estado 
  detectarCambio(field, e) {
      
      let campo = this.state.campo;
      if (e.target.type === 'checkbox'){
        campo[field] = e.target.checked;
      }else{
        campo[field] = e.target.value;
      }

      // Cambio de estado de campo 
      this.setState({
        campo,
        textoAlert: '',
        tipoAlert: '',
        closeAlert: appConst.ALERT_CLOSE
      });
  }

  onChangeFechaAntiguedad(fecha){
    let campo = this.state.campo;
    campo["txtFechaAntiguedad"] = fecha;
    this.setState({ campo });
  }

  showUsuario(){

    document.querySelector("#capaUsuario").className = "row mx-4 mt-3";
    document.querySelector("#capaEmpresa").className = "hideElement";
    document.querySelector("#capaNecesidades").className = "hideElement";
    document.querySelector("#capaAreasInteres").className = "hideElement";

    document.querySelector("#itemTabUsuario").className = "icon-user hand nav-link active";
    document.querySelector("#itemTabEmpresa").className = "icon-empresa hand nav-link";
    document.querySelector("#itemTabNecesidades").className = "icon-help hand nav-link";
    document.querySelector("#itemTabAreasInteres").className = "icon-star-full hand nav-link";
  }

  showEmpresa(){

    document.querySelector("#capaUsuario").className = "hideElement";
    document.querySelector("#capaEmpresa").className = "row mx-4 mt-3 link";
    document.querySelector("#capaNecesidades").className = "hideElement";
    document.querySelector("#capaAreasInteres").className = "hideElement";

    document.querySelector("#itemTabUsuario").className = "icon-user hand nav-link";
    document.querySelector("#itemTabEmpresa").className = "icon-empresa hand nav-link active";
    document.querySelector("#itemTabNecesidades").className = "icon-help hand nav-link";
    document.querySelector("#itemTabAreasInteres").className = "icon-star-full hand nav-link";
  }

  showNecesidades(){

    document.querySelector("#capaUsuario").className = "hideElement";
    document.querySelector("#capaEmpresa").className = "hideElement";
    document.querySelector("#capaNecesidades").className = "row mx-4 mt-3 link";
    document.querySelector("#capaAreasInteres").className = "hideElement";

    document.querySelector("#itemTabUsuario").className = "icon-user hand nav-link";
    document.querySelector("#itemTabEmpresa").className = "icon-empresa hand nav-link";
    document.querySelector("#itemTabNecesidades").className = "icon-help hand nav-link active";
    document.querySelector("#itemTabAreasInteres").className = "icon-star-full hand nav-link";
  }

  showAreasInteres(){

    document.querySelector("#capaUsuario").className = "hideElement";
    document.querySelector("#capaEmpresa").className = "hideElement";
    document.querySelector("#capaNecesidades").className = "hideElement";
    document.querySelector("#capaAreasInteres").className = "row mx-4 mt-3 link";

    document.querySelector("#itemTabUsuario").className = "icon-user hand nav-link";
    document.querySelector("#itemTabEmpresa").className = "icon-empresa hand nav-link";
    document.querySelector("#itemTabNecesidades").className = "icon-help hand nav-link";
    document.querySelector("#itemTabAreasInteres").className = "icon-star-full hand nav-link active";
  }

  showMessageUpdateToken(){

    this.setState({
      textoAlert: <FM id="app.hijo.general.refreshToken" />,
      tipoAlert: appConst.ALERT_TIPO_SUCCESS,
      closeAlert: appConst.ALERT_CLOSE
    });
  }

  async uploadFileToS3(){

    const locationS3 = await utils.uploadFileToS3("fileInput");
    if (locationS3 != -1){
      let campo = this.state.campo;
      campo["txtLogo"] = locationS3;
      this.setState({ 
        campo,
        textoAlert: ""
      });
    
    }else if (locationS3 === -1){
      this.setState({
        textoAlert: <FM id="app.general.mimetype.imagen.error" values={{mimetype: appConst.TYPE_IMAGES}}/>,
        tipoAlert: appConst.ALERT_TIPO_WARNING,
        closeAlert: appConst.ALERT_CLOSE
      });
    }
  }

  render(){ 
    return(
      <div>
        <Header/>
        
        <div className="contenedorHijo">
          
          {/* MIGAS DE PAN */}
          <ol className="migaPan">
              <li className="enLineaFin icon-user"> <FM id="app.hijo.mi-perfil.title" /></li>
          </ol>
          
          {/* INI CONTENEDOR */}
          <div className="contenedor">

            <Alert texto={this.state.textoAlert} tipo={this.state.tipoAlert} close={this.state.closeAlert}/>
      
            <div className="mx-auto cajaBlanca" style={{width: "80%"}}>

              <div className="row">
                <div className="col-md-12">
                  
                  <div className="row p-4 me-2">
                    <div className="col-md-6">
                      <h6 className="subtitle1"><FM id='app.hijo.mi-perfil.cabecera'/></h6>
                      <span className="subtitle2"><FM id='app.hijo.mi-perfil.sub-cabecera'/></span>
                    </div>
                    <div className="col-md-5 text-end">
                      <span className="">
                        {utils.getUsuarioLogeado(appConst.PERFIL_HIJO, true)}&nbsp;
                        {utils.isAdmin(appConst.PERFIL_HIJO) && <span className="prosa">[admin]</span>}<br/>
                        <span className="prosa text-end" data-bs-toggle="tooltip" title={this.props.intl.formatMessage( {id: 'app.hijo.mi-perfil.form.label.fecha-alta'} )}>{this.state.campo["fechaAlta"]}</span>
                      </span>
                    </div>
                    <div className="col-md-1">
                      <span><label className="avatar">{utils.getAvatar(appConst.PERFIL_HIJO)}</label></span>
                    </div>
                  </div>

                  {/* INI MENU TABS */}
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <span className="icon-user hand nav-link active" id="itemTabUsuario" aria-current="page" onClick={this.showUsuario}> <FM id="app.hijo.mi-perfil.form-group.usuario" /></span>
                    </li>
                    <li className="nav-item">
                        <span className="icon-empresa hand nav-link" id="itemTabEmpresa" onClick={this.showEmpresa} data-bs-toggle="tooltip" title={this.props.intl.formatMessage({id: "app.hijo.mi-perfil.form-group.empresa.tooltip"})}> <FM id="app.hijo.mi-perfil.form-group.empresa" /></span>
                    </li>
                    <li className="nav-item">
                        <span className="icon-help hand nav-link" id="itemTabNecesidades" onClick={this.showNecesidades} data-bs-toggle="tooltip" title={this.props.intl.formatMessage({id: "app.hijo.mi-perfil.form-group.necesidades.tooltip"})}> <FM id="app.hijo.mi-perfil.form-group.necesidades" /></span>
                    </li>
                    <li className="nav-item">
                        <span className="icon-star-full hand nav-link" id="itemTabAreasInteres" onClick={this.showAreasInteres} data-bs-toggle="tooltip" title={this.props.intl.formatMessage({id: "app.hijo.mi-perfil.form-group.areasInteres.tooltip"})}> <FM id="app.hijo.mi-perfil.form-group.areasInteres" /></span>
                    </li>
                  </ul>

                  {/* INI FORM USUARIO */}
                  <form id="formUsuario">

                    <div className="row mx-4 mt-3" id="capaUsuario">
                    
                      <div className="row pt-2 ps-4">
                        <div className="col-md-6">
                          
                          <div><span className="label-form"><FM id='app.hijo.mi-perfil.form.label.nombre'/></span></div>
                          <input type="text" className="input-css full-width" name="txtNombre" id="txtNombre" maxLength="100" onChange={this.detectarCambio.bind(this, "txtNombre")} value={this.state.campo["txtNombre"]} />
                          <span className="form-error">{this.state.error["txtNombre"]}</span>
                          
                        </div>
                        <div className="col-md-6">
                          
                          <div><span className="label-form"><FM id='app.hijo.mi-perfil.form.label.apellidos'/></span></div>
                          <input type="text" className="input-css full-width" name="txtApellidos" id="txtApellidos" maxLength="100" onChange={this.detectarCambio.bind(this, "txtApellidos")} value={this.state.campo["txtApellidos"]} />
                          <span className="form-error">{this.state.error["txtApellidos"]}</span>

                        </div>
                      </div>

                      <div className="row pt-2 ps-4">
                        <div className="col-md-6">
                          
                        <div><span className="label-form"><FM id='app.hijo.mi-perfil.form.label.email'/></span></div>
                          <input type="text" className="input-css full-width campoRequerido" name="txtEmail" id="txtEmail" readOnly maxLength="100" value={this.state.campo["txtEmail"]} />
                          <span className="form-error">{this.state.error["txtEmail"]}</span>
                          
                        </div>
                        <div className="col-md-6">
                          
                          <div><span className="label-form"><FM id='app.hijo.mi-perfil.form.label.cargo'/></span></div>
                          <select id="cmbCargo" name="cmbCargo" className="select-css full-width" onChange={this.detectarCambio.bind(this, "cmbCargo")}>
                            <option value="">{this.props.intl.formatMessage( {id: 'app.general.combos.selecciono'} )}</option>
                            <CargarMaestroDatos
                                tabla={appConst.MAESTRO_CARGOS}
                                preSelected={this.state.campo['cmbCargo']}
                                perfil={appConst.PERFIL_HIJO}
                            />
                          </select>
                          <span className="form-error">{this.state.error["cmbCargo"]}</span>

                        </div>
                      </div>

                      <div className="row py-3 ps-4">
                        <div className="col-md-12">
                          
                          <div><input type="checkbox" className="check" id="chkPublicidad" name="chkPublicidad" onChange={this.detectarCambio.bind(this, "chkPublicidad")} checked={this.state.campo["chkPublicidad"]}/><label htmlFor="chkPublicidad" className="label-check"> <FM id='app.hijo.mi-perfil.form.label.publicidad'/></label></div>
                          <span className="form-error">{this.state.error["chkPublicidad"]}</span>          

                        </div>
                      </div>
                      
                      <div className="separador mb-3"></div>

                      <div className="row pt-2 ps-4">
                        <div className="col-md-12 mb-3">
                          
                          <button type="button" className="btn btn-md btn-primary" onClick={this.guardarUsuario}><span className="label-button"><FM id='app.hijo.mi-perfil.form.button.guardarUsuario'/></span></button>

                        </div>
                      </div>

                    </div>
                  </form>
                  {/* FIN FORM USUARIO */}

                  {/* INI FORM EMPRESA */}
                  <form id="formEmpresa">
                    
                    <div className="hideElement" id="capaEmpresa">
                    
                      <input type="hidden" name="txtTipoEmpresa" id="txtTipoEmpresa"  value={this.state.campo["txtTipoEmpresa"]} />

                      {/* SUBIR LOGO */}
                      <div className="row pt-2 ps-4">
                        <div className="col-md-6 pt-2">

                          {<span className="position-relative hand">
                              <label className='' for="fileInput">
                                <img className="imgEmpresa hand" src={this.state.campo["txtLogo"]} width="100px" height="100px" data-bs-toggle="tooltip" title={"Logo de tu empresa"}/>
                              </label>
                              <Badge 
                                  id={"logo"}
                                  icono={"icon-plus"}
                                  fondo={"bg-success"}
                              />
                          </span>}

                          <input type="file" id="fileInput" onChange={() => this.uploadFileToS3()} className='hideElement'/>
                          <input type="hidden" className="input-css full-width" name="txtLogo" id="txtLogo" value={this.state.campo["txtLogo"]}/>
                          <br/><span className="form-error">{this.state.error["txtLogo"]}</span>
                          
                        </div>

                        <div className="col-md-6">

                          <div className="row">
                            <div className="col-md-6">
                              <div><span className="label-form"><FM id='app.hijo.mi-perfil.form.label.nif'/></span></div>
                              <input type="text" className="input-css full-width" name="txtNif" id="txtNif" maxLength="50" onChange={this.detectarCambio.bind(this, "txtNif")} value={this.state.campo["txtNif"]} />
                              <span className="form-error">{this.state.error["txtNif"]}</span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div><span className="label-form"><FM id='app.hijo.mi-perfil.form.label.razon-social'/></span></div>
                              <input type="text" className="input-css full-width" name="txtRazonSocial" id="txtRazonSocial" maxLength="50" onChange={this.detectarCambio.bind(this, "txtRazonSocial")} value={this.state.campo["txtRazonSocial"]} />
                              <span className="form-error">{this.state.error["txtRazonSocial"]}</span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div><span className="label-form"><FM id='app.hijo.mi-perfil.form.label.nombre-empresa'/></span></div>
                              <input type="text" className="input-css full-width" name="txtNombreEmpresa" id="txtNombreEmpresa" maxLength="100" onChange={this.detectarCambio.bind(this, "txtNombreEmpresa")} value={this.state.campo["txtNombreEmpresa"]} />
                              <span className="form-error">{this.state.error["txtNombreEmpresa"]}</span>
                            </div>
                          </div>
                          
                        </div>

                      </div>

                      <div className="row pt-4 ps-4">
                        <div className="col-md-4">
                          
                          <div><span className="label-form"><FM id='app.hijo.mi-perfil.form.label.direccion'/></span></div>
                          <input type="text" className="input-css full-width" name="txtDireccion" id="txtDireccion" maxLength="300" onChange={this.detectarCambio.bind(this, "txtDireccion")} value={this.state.campo["txtDireccion"]} />
                          <span className="form-error">{this.state.error["txtDireccion"]}</span>

                        </div>
                        <div className="col-md-2">
                          
                          <div><span className="label-form"><FM id='app.hijo.mi-perfil.form.label.cp'/></span></div>
                          <input type="text" className="input-css full-width" name="txtCP" id="txtCP" maxLength="100" onChange={this.detectarCambio.bind(this, "txtCP")} value={this.state.campo["txtCP"]} />
                          <span className="form-error">{this.state.error["txtCP"]}</span>

                        </div>
                        <div className="col-md-3">
                          
                          <div><span className="label-form"><FM id='app.hijo.mi-perfil.form.label.localidad'/></span></div>
                          <input type="text" className="input-css full-width" name="txtLocalidad" id="txtLocalidad" maxLength="100" onChange={this.detectarCambio.bind(this, "txtLocalidad")} value={this.state.campo["txtLocalidad"]} />
                          <span className="form-error">{this.state.error["txtLocalidad"]}</span>
                          
                        </div>
                        <div className="col-md-3">
                          
                          <div><span className="label-form"><FM id='app.hijo.mi-perfil.form.label.provincia'/></span></div>
                          <input type="text" className="input-css full-width" name="txtProvincia" id="txtProvincia" maxLength="100" onChange={this.detectarCambio.bind(this, "txtProvincia")} value={this.state.campo["txtProvincia"]} />
                          <span className="form-error">{this.state.error["txtProvincia"]}</span>
                          
                        </div>
                      </div>

                      <div className="row pt-2 ps-4">
                        <div className="col-md-4">
                          
                          <div><span className="label-form"><FM id='app.hijo.mi-perfil.form.label.email-empresa'/></span></div>
                          <input type="text" className="input-css full-width" name="txtEmailEmpresa" id="txtEmailEmpresa" maxLength="100" onChange={this.detectarCambio.bind(this, "txtEmailEmpresa")} value={this.state.campo["txtEmailEmpresa"]} />
                          <span className="form-error">{this.state.error["txtEmailEmpresa"]}</span>
                          
                        </div>
                        <div className="col-md-2">
                          
                          <div><span className="label-form"><FM id='app.hijo.mi-perfil.form.label.telefono-empresa'/></span></div>
                          <input type="text" className="input-css-sm full-width" name="txtTelefono" id="txtTelefono" maxLength="13" onChange={this.detectarCambio.bind(this, "txtTelefono")} value={this.state.campo["txtTelefono"]} />
                          <span className="form-error">{this.state.error["txtTelefono"]}</span>

                        </div>
                        <div className="col-md-6">
                          
                          <div><span className="label-form"><FM id='app.hijo.mi-perfil.form.label.fechaAntiguedad'/></span> <span className="icon-error" data-bs-toggle="tooltip" data-bs-html="true" title={"Fecha desde la cual tu empresa está asociada a <strong>" + utils.getTokenValue("nombrePadre", appConst.PERFIL_HIJO) + "</strong>"}></span> <span className='float-end prosa'><FM id="app.general.campo-opcional" /></span></div>
                          <div>
                            <DatePicker
                              dateFormat="dd 'de' MMMM 'de' yyyy"
                              selected={this.state.campo["txtFechaAntiguedad"]}
                              onChange={dateAntig => this.onChangeFechaAntiguedad(dateAntig)}
                              locale="es"
                              id="txtFechaAntiguedad"
                              className="input-css full-width"
                            />
                          </div>
                          <span className="form-error">{this.state.error["txtFechaAntiguedad"]}</span>

                        </div>
                      </div>

                      <div className="row pt-2 ps-4">
                        <div className="col-md-12">

                          <div><span className="label-form"><FM id='app.hijo.mi-perfil.form.label.url-web'/></span></div>
                          <input type="text" className="input-css full-width" name="txtUrlWeb" id="txtUrlWeb" placeholder="https://" maxLength="2000" onChange={this.detectarCambio.bind(this, "txtUrlWeb")} value={this.state.campo["txtUrlWeb"]} />
                          <span className="form-error">{this.state.error["txtUrlWeb"]}</span>

                        </div>
                      </div>

                      <div className="row pt-3 ps-4">
                        <div className="col-md-6">
                          
                          <div><span className="float-start label-form"><FM id='app.hijo.mi-perfil.form.label.url-noticia'/></span></div><div className="float-end prosa"><FM id="app.general.campo-opcional" /></div>
                          <input type="text" className="input-css full-width" name="txtUrlNoticia" id="txtUrlNoticia" maxLength="2000" onChange={this.detectarCambio.bind(this, "txtUrlNoticia")} value={this.state.campo["txtUrlNoticia"]} />
                          <span className="form-error">{this.state.error["txtUrlNoticia"]}</span>
                        
                        </div>
                        <div className="col-md-6">
                          
                          <div><span className="float-start label-form"><FM id='app.hijo.mi-perfil.form.label.url-video'/></span></div><div className="float-end prosa"><FM id="app.general.campo-opcional" /></div>
                          <input type="text" className="input-css full-width" name="txtUrlVideo" id="txtUrlVideo" maxLength="2000" onChange={this.detectarCambio.bind(this, "txtUrlVideo")} value={this.state.campo["txtUrlVideo"]} />
                          <span className="form-error">{this.state.error["txtUrlVideo"]}</span>
                          
                        </div>
                      </div>

                      <div className="row pt-2 ps-4">
                        <div className="col-md-6">
                          
                          <div><span className="label-form"><FM id='app.hijo.mi-perfil.form.label.sector'/></span></div>
                          <select id="cmbSector" name="cmbSector" className="select-css full-width" onChange={this.detectarCambio.bind(this, "cmbSector")}>
                            <option value="">{this.props.intl.formatMessage( {id: 'app.general.combos.selecciono'} )}</option>
                            <CargarMaestroDatos
                                tabla={appConst.MAESTRO_SECTORES}
                                preSelected={this.state.campo['cmbSector']}
                                perfil={appConst.PERFIL_HIJO}
                            />
                          </select>
                          <span className="form-error">{this.state.error["cmbSector"]}</span>

                        </div>
                        <div className="col-md-6">
                          
                          <div><span className="label-form"><FM id='app.hijo.mi-perfil.form.label.tipo-perfil'/></span></div>
                          <select id="cmbTipoHijo" name="cmbTipoHijo" className="select-css full-width" onChange={this.detectarCambio.bind(this, "cmbTipoHijo")}>
                            <option value="">{this.props.intl.formatMessage( {id: 'app.general.combos.selecciono'} )}</option>
                            <CargarMaestroDatos
                                tabla={appConst.MAESTRO_TIPOS_HIJOS}
                                preSelected={this.state.campo["cmbTipoHijo"]}
                                perfil={appConst.PERFIL_HIJO}
                            />
                          </select>
                          <span className="form-error">{this.state.error["cmbTipoHijo"]}</span>
                        
                        </div>
                      </div>

                      <div className="row py-3 ps-4">
                        <div className="col-md-12">
                          
                          <div><span className="float-start label-form"><FM id='app.hijo.mi-perfil.form.label.descripcion'/> </span></div><div className="float-end prosa">(máx. 4.000 caracteres)</div>
                          <textarea className="textArea-css full-width" name="txtDescripcion" id="txtDescripcion" rows="10" maxLength="4000" placeholder="Describe brevemente tu empresa, actividad, equipo, logros, roadmap, ..." onChange={this.detectarCambio.bind(this, "txtDescripcion")}>{this.state.campo["txtDescripcion"]}</textarea>
                          <span className="form-error">{this.state.error["txtDescripcion"]}</span>
                        
                        </div>
                      </div>
                      
                      <div className="separador mb-3"></div>

                      <div className="row pt-2 ps-4">
                        <div className="col-md-12 mb-3">
                          
                          <button type="button" className="btn btn-md btn-primary" id="btnGuardarEmpresa" onClick={this.guardarEmpresa}><span className="label-button"><FM id='app.hijo.mi-perfil.form.button.guardarEmpresa'/></span></button>

                        </div>
                      </div>

                    </div>
                  </form>
                  {/* FIN FORM EMPRESA */}

                  {/* INI FORM NECESIDADES */}
                  <form id="formNecesidades">

                    <div className="hideElement" id="capaNecesidades">
                    
                      <p className="prosa"><FM id="app.hijo.mi-perfil.form.necesidades.titulo" /></p>

                      <div className="row pt-2 ps-4">
                        <div className="col-md-12">
                          
                          {this.state.necesidades.map(n => 
                            <div key={"chkNecesidad_"+n.id} className="full-width mb-2">
                              <div>
                                <input type="checkbox" className="check"
                                  id={"chkNecesidad_"+n.id} 
                                  name={"chkNecesidad_"+n.id}
                                  onChange={this.detectarCambio.bind(this, "chkNecesidad_"+n.id)}
                                />
                                <label htmlFor={"chkNecesidad_"+n.id} className="label-check prosa"> {n.nombre}</label>
                              </div>
                              <span className="form-error">{this.state.error["chkNecesidad_"+n.id]}</span>  
                            </div>
                          )}

                        </div>
                      </div>
                      
                      <div className="separador mb-3"></div>

                      <div className="row pt-2 ps-4">
                        <div className="col-md-12 mb-3">
                          
                          <button type="button" className="btn btn-md btn-primary" id="btnGuardarNecesidades" onClick={this.guardarNecesidades}><span className="label-button"><FM id='app.hijo.mi-perfil.form.button.guardarNecesidades'/></span></button>

                        </div>
                      </div>

                    </div>
                  </form>
                  {/* FIN FORM NECESIDADES */}

                  {/* INI FORM ÁREAS INTERÉS */}
                  <form id="formAreasInteres">

                    <div className="hideElement" id="capaAreasInteres">
                    
                      <p className="prosa"><FM id="app.hijo.mi-perfil.form.areasInteres.titulo" values={{valor: utils.getTokenValue("nombrePadre", appConst.PERFIL_HIJO)}} /></p>

                      <div className="row pt-2 ps-4">
                        <div className="col-md-12">
                          
                          {/* No hay Areas de interés */}
                          {this.state.areasInteres.length == 0 && <div className="full-width mb-2">
                              <p className='prosa'>*** La empresa <strong>{utils.getTokenValue("nombrePadre", appConst.PERFIL_HIJO)}</strong> aún no ha creado ningún área de interés ***</p>
                            </div>}
                          
                          {/* Hay Areas de interés */}
                          {this.state.areasInteres.map(a => 
                            <div key={"chkAreasInteres_"+a.id} className="full-width mb-2">
                              <div>
                                <input type="checkbox" className="check"
                                  id={"chkAreasInteres_"+a.id} 
                                  name={"chkAreasInteres_"+a.id}
                                  onChange={this.detectarCambio.bind(this, "chkAreasInteres_"+a.id)}
                                />
                                <label htmlFor={"chkAreasInteres_"+a.id} className="label-check prosa"> {a.nombre}</label>
                              </div>
                              <span className="form-error">{this.state.error["chkAreasInteres_"+a.id]}</span>  
                            </div>
                          )}

                        </div>
                      </div>
                      
                      <div className="separador mb-3"></div>

                      <div className="row pt-2 ps-4">
                        <div className="col-md-12 mb-3">
                          
                          {this.state.areasInteres.length > 0 && <button type="button" className="btn btn-md btn-primary" id="btnGuardarAreasInteres" onClick={this.guardarAreasInteres}><span className="label-button"><FM id='app.hijo.mi-perfil.form.button.guardarAreasInteres'/></span></button>}

                        </div>
                      </div>

                    </div>
                  </form>
                  {/* FIN FORM ÁREAS INTERÉS */}

                </div>
              </div>
            </div>
              
          </div>
          {/* FIN CONTENEDOR */}

        </div>

        <Footer patrocinadores={appConst.MOSTRAR_PATROCINADORES} />
      </div>
    )
  }
}

export default injectIntl(MiPerfil);