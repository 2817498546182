//Add Link Component
import React from "react";
import * as utils from './../../common/utils/Funcs';
import { CompositeDecorator, EditorState, Modifier } from "draft-js";

const miLink = ({ entityKey, contentState, children }) => {

    let { url, target, rel } = contentState.getEntity(entityKey).getData();
    const miTarget = target != null ? {target} : {};
    const miRel = rel != null ? {rel} : {};
    return (
        <a style={{ color: "blue", fontStyle: "bold" }} href={url} target={miTarget} rel={miRel}>
            {children}
        </a>
    );
};

const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === "LINK"
        );
    }, callback);
};

export const createLinkDecorator = () =>
    new CompositeDecorator([
    {
        strategy: findLinkEntities,
        component: miLink,
    },
]);

// call all together
export const onAddLink = (editorState, setEditorState, getTextSelection) => {

    const valorSelEditorState = getTextSelection();
    const PREFIJO_LINK = "https://";
    let linkUrl = window.prompt("Añadir enlace", PREFIJO_LINK);
    
    if (linkUrl) {
        linkUrl = utils.validarURL(linkUrl);
        let displayLink = (valorSelEditorState) ? valorSelEditorState : window.prompt("Texto del enlace");

        if (displayLink) {
            const currentContent = editorState.getCurrentContent();
            const createEntity = currentContent.createEntity("LINK", "MUTABLE", {url: linkUrl, target: '_blank', rel: 'noopener noreferrer'});
            let entityKey = currentContent.getLastCreatedEntityKey();
            const selection = editorState.getSelection();
            
            let textWithEntity = "";
            if (valorSelEditorState){
                textWithEntity = Modifier.replaceText(
                    currentContent,
                    selection,
                    displayLink,
                    null,
                    entityKey
                );
            }else{
                textWithEntity = Modifier.insertText(
                    currentContent,
                    selection,
                    displayLink,
                    null,
                    entityKey
                );
            }
            let newState = EditorState.createWithContent(textWithEntity, createLinkDecorator());
            // Se actualiza el contenido del editor en el estado del editor del componente que lo llamó
            setEditorState(newState);
            
        }else if (displayLink != null) alert("El texto del enlace no puede estar vacío. Por favor, vuelve a crear el enlace");
    
    }else if (linkUrl != null) alert("El enlace no puede estar vacío. Por favor, vuelve a crear el enlace");
};