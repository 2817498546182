import React, { Component } from 'react';
import axios from 'axios';
import Alert from './../../common/utils/Alert';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import {withRouter} from 'react-router-dom';
import * as utils from './../utils/Funcs';
import * as appConst from './../const/Const';
import HeaderGuess from './../header/HeaderGuess';
import FooterPadre from './../../padre/footer/Footer';
import FooterHijo from './../../hijo/footer/Footer';

class ConfirmacionCuenta extends Component {

    constructor(props){
        super(props);
        this.state = {
            ok: false,
            textoAlert: "",
            tipoAlert: "",
            closeAlert: appConst.ALERT_OPEN
        };
        this.login = this.login.bind(this);
    }

    componentDidMount(){

        this.enviarEmailValidacion();
    }

    async enviarEmailValidacion(){
        
        const loc = window.location.href;
        if (loc.indexOf('?') > 0){
            const cadena = utils.getURLParam("tokenval");
            
            try{
                const response = await axios.put(appConst.PUT_EMAIL_VALIDACION.replace("#cadenaValidacion", cadena), null, { headers: utils.getHeaders(appConst.HEADER_SIN_TOKEN) });
                
                if (response && response.status === appConst.HTTPSTATUS_OK){
                    if (utils.getLog()) console.log("status PUT_EMAIL_VALIDACION: "+response.status);

                    this.setState({
                        ok: true,
                        textoAlert: <FM id="app.cuenta.validacion.ok" />,
                        tipoAlert: appConst.ALERT_TIPO_SUCCESS,
                        closeAlert: appConst.ALERT_OPEN
                    });
                }
            }catch(error){
                if (utils.getLog()) console.log("Error PUT_EMAIL_VALIDACION: "+error);
                this.setState({
                    textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.enviarEmailValidacion, []),
                    tipoAlert: utils.getTipoAlert(error),
                    closeAlert: appConst.ALERT_OPEN
                });
            }
        }else{
            this.setState({
                textoAlert: "La URL no es correcta, faltan parámetros para poder confirmar la validación de tu cuenta",
                tipoAlert: appConst.ALERT_TIPO_WARNING,
                closeAlert: appConst.ALERT_OPEN
            });
        }
    }

    login(){
        
        const prefijo = utils.getURLParam("prefix");
        if (prefijo)
            // En caso de no estar logeado, se redirige al login y se ejecuta la callback para ir acto seguido a .../home
            this.props.history.push(process.env.REACT_APP_WEB_BASE_URL + "/" + prefijo + "/home");
        else
            this.setState({
                textoAlert: "No ha sido posible redirigir al login. Por favor, acceda manualmente.",
                tipoAlert: appConst.ALERT_TIPO_WARNING,
                closeAlert: appConst.ALERT_OPEN
            });
    }

    render(){
        return (
            <div>
                <HeaderGuess/>
                
                <div className='contenedorHijo'>
                    
                    {/* MIGAS DE PAN */}
                    <ol className="migaPan">
                        <li className="enLineaFin icon-user"> Validación de cuenta de Finder</li>
                    </ol>

                    <div className='contenedor'>
                    
                        <Alert texto={this.state.textoAlert} tipo={this.state.tipoAlert} close={this.state.closeAlert}/>
                    
                        {this.state.ok &&
                            <div className="row text-center mx-0">
                                <div className="col-md-12">
                                    <button className="btn btn-sm btn-primary" onClick={this.login}><span className="icon-check"> Acceder a Finder</span></button>
                                </div>
                            </div>}
                    </div>

                </div>

                {utils.getURLParam("prefix") === appConst.PREFIX_URL_PADRE && <FooterPadre />}
                {utils.getURLParam("prefix") === appConst.PREFIX_URL_HIJO && <FooterHijo />}
            </div>
        )
    }
}

export default injectIntl(withRouter(ConfirmacionCuenta));