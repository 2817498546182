/**********************************************************************/
/*********      CONSTANTES GLOBALES A TODA LA APLICACION      *********/
/**********************************************************************/

// Mensajes al usuario por pantalla
export const ALERT_TIPO_SUCCESS = 1;
export const ALERT_TIPO_WARNING = 2;
export const ALERT_TIPO_DANGER = 3;

export const ALERT_ESTILO_SUCCESS = "alert-success";
export const ALERT_ESTILO_WARNING = "alert-warning";
export const ALERT_ESTILO_DANGER = "alert-danger";

export const ALERT_ICONO_SUCCESS = "#check-circle-fill";
export const ALERT_ICONO_WARNING = "#info-fill";
export const ALERT_ICONO_DANGER = "#exclamation-triangle-fill";

export const ALERT_ARIALABEL_SUCCESS = "Success:";
export const ALERT_ARIALABEL_WARNING = "Warning:";
export const ALERT_ARIALABEL_DANGER = "Danger:";

export const ALERT_CLOSE = 3000;
export const ALERT_OPEN = 0;

/*export const AVISO_SUCCESS = 1;
export const AVISO_ALERT = 2;
export const AVISO_ERROR = 3;
export const AVISO_CLOSE_DELAY = 6000;
export const AVISO_NOT_CLOSE = 0;
export const AVISO_CLOSE = 1;*/

// Prefijos para las urls de la aplicación en función al perfil
export const PREFIX_URL_ADMIN = "a";
export const PREFIX_URL_PADRE = "p";
export const PREFIX_URL_HIJO = "h";

// Baja lógica
export const NO_BAJA_LOGICA = "0";
export const BAJA_LOGICA = "1";
export const BAJA_FISICA = "1";

// Idiomas
export const IDIOMA_ES = "es-ES";
export const IDIOMA_EN = "en-EN";
export const LOCALE_ES = "es";
export const LOCALE_EN = "en";

// Tipos de headers para los endpoints
export const HEADER_LOGIN = 0;
export const HEADER_CON_TOKEN = 1;
export const HEADER_SIN_TOKEN = 2;
export const HEADER_CON_REFRESH_TOKEN = 3;

// Direcciones de correo
export const EMAIL_CONTACTO_FINDER = "finder@finbox.es";

// Estados de una cuenta de usuario
export const CUENTA_NO_ACTIVA = 0;
export const CUENTA_ACTIVA = 1;

// Token de seguridad
export const TOKEN_KEY = "jwtToken_Finder" + process.env.REACT_APP_TOKEN_ENV;
export const REFRESH_TOKEN_KEY = "jwtRefreshToken_Finder" + process.env.REACT_APP_TOKEN_ENV;

// Perfiles de usuarios en toda la plataforma
export const PERFIL_ALL = "-1";
export const PERFIL_ADMIN = "0";
export const PERFIL_PADRE = "1";
export const PERFIL_HIJO = "2";

// Tipo de padre
export const TIPO_EMPRESA_PADRE = 1;
export const TIPO_EMPRESA_HIJO = 2;

// Tamaño contraseña del usuario
export const PASSWORD_SIZE = 6;
// Tamaño máximo para mostrar la descripcion de una empresa
export const LIMITE_DESC_EMPRESA = 180;

// Tamaño máximo de la cadena del usuario logeado (por ejemplo, arriba en la cabecera)
export const SIZE_HEADER_USER_LOGGED = 25;

// Tamaño máximo para mostrar la etiqueta de una empresa
export const LIMITE_TAG_EMPRESA = 15;

// Mostrar o no los patrocinadores en el pie de página de la aplicación para hijos
export const OCULTAR_PATROCINADORES = 0
export const MOSTRAR_PATROCINADORES = 1

// Maestros
export const MAESTRO_NECESIDADES = "necesidades";
export const MAESTRO_SECTORES = "sectores";
export const MAESTRO_TIPOS_PADRE = "tipos_padre";
export const MAESTRO_CARGOS = "cargos";
export const MAESTRO_TIPOS_TEXTOS = "tipos_texto";
export const MAESTRO_TIPOS_HIJOS = "tipos_hijo";
export const MAESTRO_ESTADOS_TRANSACCION = "estados_transaccion";
export const MAESTRO_ACTIVIDADES = "actividades";
export const MAESTRO_TIPOS_TRANSACCION = "tipos_transaccion";
/*export const MAESTRO_ESTADOS_MATCH = "estados_match";
export const MAESTRO_UNIDADES = "unidades";
export const PRIMERA_PK_MAESTRO_ESTADOS_SOLICITUD = 1;
export const PRIMERA_PK_MAESTRO_UNIDAD = 1;*/

// Campos de entidades para carga de listas desplegables (clave-valor)
export const TABLA_ETIQUETAS_CAMPO_VALOR = "id";
export const TABLA_ETIQUETAS_CAMPO_TEXTO1 = "nombre";

export const TABLA_AREAS_INTERES_CAMPO_VALOR = "id";
export const TABLA_AREAS_INTERES_CAMPO_TEXTO1 = "nombre";

export const TABLA_TEXTOS_CAMPO_VALOR = "id";
export const TABLA_TEXTOS_CAMPO_TEXTO1 = "texto";

export const TABLA_MATCHES_CAMPO_VALOR = "id";
export const TABLA_MATCHES_CAMPO_TEXTO1 = "asunto";
export const TABLA_MATCHES_CAMPO_TEXTO2 = "fecha";

// Campos de literales para listas desplegables
export const LITERAL_CLAVE_SI = "1";
export const LITERAL_VALOR_SI = "Sí";
export const LITERAL_CLAVE_NO = "0";
export const LITERAL_VALOR_NO = "No";

// Componente Tabulator
export const TABLA_PAGINACION=5;

// Operaciones con recursos
export const OPERACION_NUEVO=0;
export const OPERACION_EDITAR=1;

// Código de menú principal para padre e hijo
export const MENU_PADRE_MI_MUNDO = "1";
export const MENU_PADRE_MIS_MATCHES = "2";
export const MENU_PADRE_MIS_ENVIOS = "3";

export const MENU_HIJO_MI_MUNDO  = "1";
export const MENU_HIJO_MI_ACTIVIDAD  = "2";
export const MENU_HIJO_COLABORADORES  = "3";

// Tipos de transacción
export const TIPO_TRAN_MATCH = 1;
export const TIPO_TRAN_FORMACION = 2;
export const TIPO_TRAN_WEBINAR = 3;
export const TIPO_TRAN_REUNION = 4;
export const TIPO_TRAN_NOTICIA = 5;
export const TIPO_TRAN_EL_DIA_DE = 6;
export const TIPO_TRAN_MESA_REDONDA = 7;

// Tipos de participantes
export const TIPO_PART_ORIGEN = 1;
export const TIPO_PART_DESTINO = 2;

// Estados de un participante
export const ESTADO_PART_PENDIENTE = 1;
export const ESTADO_PART_CONFIRMADO = 2;

// Estados e iconos de una transacción
export const ESTADO_TRAN_BORRADOR = 1;
export const ESTADO_TRAN_ENVIADO = 2;
export const ESTADO_TRAN_MATCHEADO = 3;
export const ICONO_TRAN_BORRADOR = "icon-new";
export const ICONO_TRAN_ENVIADO = "icon-help";
export const ICONO_TRAN_MATCH = "icon-heart-full";

// Estados de una invitación
export const COD_ESTADO_INVITACION_PENDIENTE = "0";
export const COD_ESTADO_INVITACION_REGISTRADO = "1";
export const TEXTO_ESTADO_INVITACION_PENDIENTE1 = "Pendiente de registro";
export const TEXTO_ESTADO_INVITACION_PENDIENTE2 = "Pendiente validar cuenta";
export const TEXTO_ESTADO_INVITACION_REGISTRADO = "Registrado";

// Tipos de una actividad
export const TIPO_ACTIVIDAD_MATCH = 1;

// OPERACIONES CON MATCHES
export const NUEVO_MATCH = 1;
export const EDITAR_MATCH = 2;
export const VER_MATCH = 3;

// OPERACIONES CON TEXTOS
export const NUEVO_TEXTO = 1;
export const EDITAR_TEXTO = 2;

// Usuario administrador de su propia empresa
export const ES_USER_ADMIN = "1";

// Tipos de texto
export const TIPO_TEXTO_MATCH = 1;

// Separador de asunto y cuerpo de un match
export const SEPARADOR_TEXTO_MATCH = "&";

// Estados de validación de una empresa al registrarse en el sistema
export const EMPRESA_SIN_VALIDACION = "-1";
export const EMPRESA_VALIDACION_KO = "0";
export const EMPRESA_VALIDACION_OK = "1";

// Link al registro de usuario hijo con referencia al padre
export const LINK_REGISTRO_HIJO = process.env.REACT_APP_WEB_SERVER + process.env.REACT_APP_WEB_BASE_URL+'#/' + PREFIX_URL_HIJO + '/registro?';
export const LINK_RELATIVO_REGISTRO_HIJO = process.env.REACT_APP_WEB_BASE_URL + '/' + PREFIX_URL_HIJO + '/registro?';

// Link a la ayuda (manual de usuario)
export const LINK_RELATIVO_AYUDA_HIJO = process.env.REACT_APP_WEB_BASE_URL + '/' + PREFIX_URL_HIJO + '/manual-usuario';
export const LINK_RELATIVO_AYUDA_PADRE = process.env.REACT_APP_WEB_BASE_URL + '/' + PREFIX_URL_PADRE + '/manual-usuario';

// Link que se monta en el email masivo a un mundo con las áreas de interés
export const LINK_ENVIO_AREAS_INTERES = process.env.REACT_APP_WEB_SERVER + process.env.REACT_APP_WEB_BASE_URL+'#/h/mi-perfil?4';

// Primera carga de datos (listarMundos, ...) que indica si es provocada por una búsqueda de usuario
export const BUSQUEDA_USUARIO = true;

// Valor del asunto y cuerpo del email enviado a un participante de un match desde otro participante (no desde un padre) 
export const ASUNTO_MATCH_DEFAULT_BY_HIJO = "Finder: Propuesta de match!!";
export const CUERPO_MATCH_DEFAULT_BY_HIJO = "<p>Hola #0!</p><p>La empresa <strong>#1</strong> ha solicitado un encuentro con vosotros desde la plataforma Finder.";

// Primera carga de datos (listarMundos, ...) que indica si es provocada por una búsqueda de usuario
export const NOTIF_EMPRESA_INCOMPLETO = 1;
export const NOTIF_NECESIDADES_INCOMPLETO = 2;
export const NOTIF_AREAS_INTERES_INCOMPLETO = 3;
export const NOTIF_TIENES_UN_MATCH = 4;

// Clasificación de las notificaciones
export const TIPO_NOTIF_PERFIL = "Mi PERFIL";
export const TIPO_NOTIF_MATCH = "MATCH";

// Para tipos de búsqueda de usuario al crear un match desde un hijo
//export const USUARIOS_EMPRESA_LOGEADA = 1;
//export const USUARIO_EMPRESA_SOLICITAR_MATCH = 2;

// URL de aviso legal y privacidad
export const AVISO_LEGAL = "https://finbox.es/aviso-legal/";
export const POLITICA_PRIVACIDAD = "https://finbox.es/politica-privacidad/";

// Url del logo de FinBox
//export const URL_LOGO_FINBOX = "https://finbox.es/wp-content/uploads/2016/12/cropped-Logo-e1607250151470.png";
export const URL_LOGO_FINBOX = "https://"+process.env.REACT_APP_S3_BUCKET+".s3.eu-west-3.amazonaws.com/logos/logo-finbox.jpg";
export const URL_LOGO_FINDER = "https://"+process.env.REACT_APP_S3_BUCKET+".s3.eu-west-3.amazonaws.com/logos/logo-finder.jpeg";
export const URL_LOGO_FINDER_SOLO_IMG = "https://"+process.env.REACT_APP_S3_BUCKET+".s3.eu-west-3.amazonaws.com/logos/logo-finder-solo-img.jpeg";

// Url del logo vacío por defecto 
export const SIN_LOGO_EMPRESA = "https://"+process.env.REACT_APP_S3_BUCKET+".s3.eu-west-3.amazonaws.com/logos/sin-imagen.png";
//export const SIN_LOGO_EMPRESA = "https://finbox.es/img/sin-imagen.png";

// Url de imagen de bienvenida
export const IMG_BIENVENIDA = "https://"+process.env.REACT_APP_S3_BUCKET+".s3.eu-west-3.amazonaws.com/img/imagen_bienvenida.jpg";

// Extensiones válidas para las imágenes (logos, ...)
export const TYPE_IMAGES = ".jpg, .jpeg, .png";
export const MIMETYPE_IMAGES = "image/jpg,image/jpeg,image/png";

// API REST httpStatus
export const HTTPSTATUS_OK = 200;
export const HTTPSTATUS_CREATED = 201;
export const HTTPSTATUS_NO_CONTENT = 204;
export const HTTPSTATUS_BAD_REQUEST = 400;
export const HTTPSTATUS_UNAUTHORIZED = 401;
export const HTTPSTATUS_FORBIDDEN = 403;
export const HTTPSTATUS_NOT_FOUND = 404;
export const HTTPSTATUS_CONFLICT = 409;
export const HTTPSTATUS_INTERNAL_SERVER_ERROR = 500;

// ERRORES API REST: Algunos son errorCode de respuesta de endpoints y otros son genéricos
export const ERROR_CODE_DATABASE = 2;
export const ERROR_CODE_EXPIRED_ACCESS_TOKEN = 11;
export const ERROR_CODE_EXPIRED_REFRESH_TOKEN = 13;
export const SIN_CONEXION = "Network Error";
// En algunos servidores es 'max_questions' y en otros 'max_queries', así que se queda en: [has exceeded the 'max_que]
export const ERROR_MAX_QUESTIONS = "JDBC Connection";
//export const ACCESS_TOKEN_EXPIRED = 11;
//export const REFRESH_TOKEN_EXPIRED = 13;

// API REST Endpoints
export const POST_LOGIN = process.env.REACT_APP_API_BASE_URL + 'api/auth/login';
export const GET_ME = process.env.REACT_APP_API_BASE_URL + 'api/auth/me';
export const GET_TOKEN_WITH_REFRESH_TOKEN = process.env.REACT_APP_API_BASE_URL + 'api/auth/token';
export const GET_CAMBIO_PASS = process.env.REACT_APP_API_BASE_URL + 'api/auth/usuarios/solicitudcambiopass';
export const GET_MAESTRO = process.env.REACT_APP_API_BASE_URL + 'api/maestros/#tabla';
export const GET_USUARIO_BY_ID = process.env.REACT_APP_API_BASE_URL + 'api/usuarios/#idUsuario';
export const GET_USUARIOS_BY_PADRE = process.env.REACT_APP_API_BASE_URL + 'api/usuarios/padres/#idPadre';
export const POST_USUARIO = process.env.REACT_APP_API_BASE_URL + 'api/auth/usuarios';
export const POST_USUARIO_MUNDO = process.env.REACT_APP_API_BASE_URL + 'api/auth/usuarios/crearUsuarioMundo';
export const POST_USUARIO_PADRE = process.env.REACT_APP_API_BASE_URL + 'api/auth/usuarios/crearUsuarioPadre';
export const DELETE_USER = process.env.REACT_APP_API_BASE_URL + 'api/auth/usuarios/#idUsuario';
export const PUT_USUARIO = process.env.REACT_APP_API_BASE_URL + 'api/usuarios/#idUsuario';
export const PUT_PASS_USUARIO = process.env.REACT_APP_API_BASE_URL + 'api/auth/usuarios/#idUsuario/updatepass';
export const GET_EMAIL_VALIDACION = process.env.REACT_APP_API_BASE_URL + 'api/auth/email_validacion';
export const PUT_EMAIL_VALIDACION = process.env.REACT_APP_API_BASE_URL + 'api/auth/validacion/#cadenaValidacion';

export const INVITAR_USUARIO = process.env.REACT_APP_API_BASE_URL + 'api/usuarios/invitarUsuario';
export const GET_INVITACIONES_BY_PADRE = process.env.REACT_APP_API_BASE_URL + 'api/invitacionesPadres/padres/#idPadre';
export const POST_INVITACION = process.env.REACT_APP_API_BASE_URL + 'api/invitacionesPadres';
export const GET_USUARIO_INVITACION_BY_EMAIL = process.env.REACT_APP_API_BASE_URL + 'api/invitacionesPadres/usuarios';

export const GET_CHECK_PADRE = process.env.REACT_APP_API_BASE_URL + 'api/auth/padres/#idPadre/check';
export const GET_PADRE_BY_ID = process.env.REACT_APP_API_BASE_URL + 'api/padres/#idPadre';
export const PUT_PADRE = process.env.REACT_APP_API_BASE_URL + 'api/padres/#idPadre';

export const GET_EMPRESA_BY_ID = process.env.REACT_APP_API_BASE_URL + 'api/empresas/#idEmpresa';
export const PUT_EMPRESA = process.env.REACT_APP_API_BASE_URL + 'api/empresas/#idEmpresa';

export const GET_ETIQUETAS = process.env.REACT_APP_API_BASE_URL + 'api/etiquetas';
export const POST_ETIQUETA = process.env.REACT_APP_API_BASE_URL + 'api/etiquetas';
export const PUT_ETIQUETA = process.env.REACT_APP_API_BASE_URL + 'api/etiquetas/#idEtiqueta';
export const DELETE_ETIQUETA = process.env.REACT_APP_API_BASE_URL + 'api/etiquetas/#idEtiqueta';

export const GET_MUNDO_BY_ID = process.env.REACT_APP_API_BASE_URL + 'api/mundos/#idMundo';
export const GET_MUNDOS = process.env.REACT_APP_API_BASE_URL + 'api/mundos';
export const PUT_MUNDO = process.env.REACT_APP_API_BASE_URL + 'api/mundos/#idMundo';

export const POST_ETIQUETAS_HIJOS = process.env.REACT_APP_API_BASE_URL + 'api/etiquetasHijos';
export const DELETE_ETIQUETAS_HIJO = process.env.REACT_APP_API_BASE_URL + 'api/etiquetasHijos/#idEtiquetasHijo';

export const GET_NECESIDADES_HIJO = process.env.REACT_APP_API_BASE_URL + 'api/necesidadesHijos/hijos/#idHijo';
export const PUT_NECESIDADES_HIJO_COMPLETAS = process.env.REACT_APP_API_BASE_URL + 'api/necesidadesHijos/hijos/#idHijo';

export const GET_AREAS_INTERES = process.env.REACT_APP_API_BASE_URL + 'api/areasInteres';
export const POST_AREA_INTERES = process.env.REACT_APP_API_BASE_URL + 'api/areasInteres';
export const PUT_AREA_INTERES = process.env.REACT_APP_API_BASE_URL + 'api/areasInteres/#idAreaInteres';
export const DELETE_AREA_INTERES = process.env.REACT_APP_API_BASE_URL + 'api/areasInteres/#idAreaInteres';
export const GET_AREA_INTERES_ENVIAR_EMAIL = process.env.REACT_APP_API_BASE_URL + 'api/areasInteres/padres/#idPadre/enviarEmail';

export const GET_AREAS_INTERES_HIJO = process.env.REACT_APP_API_BASE_URL + 'api/areasInteresHijos/mundos/#idMundo';
export const PUT_AREAS_INTERES_HIJO_COMPLETAS = process.env.REACT_APP_API_BASE_URL + 'api/areasInteresHijos/mundos/#idMundo';

export const GET_TRANSACCIONES = process.env.REACT_APP_API_BASE_URL + 'api/transacciones';
export const POST_TRANSACCION = process.env.REACT_APP_API_BASE_URL + 'api/transacciones';
export const POST_TRANSACCION_MAILING = process.env.REACT_APP_API_BASE_URL + 'api/transacciones/mailing';
export const PUT_TRANSACCION_ENVIAR = process.env.REACT_APP_API_BASE_URL + 'api/transacciones/#idTransaccion/enviar';
export const PUT_TRANSACCION = process.env.REACT_APP_API_BASE_URL + 'api/transacciones/#idTransaccion';
export const DELETE_TRANSACCION = process.env.REACT_APP_API_BASE_URL + 'api/transacciones/#idTransaccion';

export const POST_PARTICIPANTE = process.env.REACT_APP_API_BASE_URL + 'api/participantes';
export const POST_LISTA_PARTICIPANTES = process.env.REACT_APP_API_BASE_URL + 'api/participantes/lista';
export const PUT_PARTICIPANTE = process.env.REACT_APP_API_BASE_URL + 'api/participantes/#idParticipante';
export const PUT_PARTICIPANTE_CONFIRMAR = process.env.REACT_APP_API_BASE_URL + 'api/transacciones/#idTransaccion/participante/confirmar';
export const PUT_PARTICIPANTE_CONFIRMAR_TODOS = process.env.REACT_APP_API_BASE_URL + 'api/transacciones/#idTransaccion/participantes/confirmar_todos';

export const GET_USUARIOS_BY_EMPRESA = process.env.REACT_APP_API_BASE_URL + 'api/usuarios/empresa/#idEmpresa';

export const GET_TEXTOS = process.env.REACT_APP_API_BASE_URL + 'api/textos';
export const POST_TEXTO = process.env.REACT_APP_API_BASE_URL + 'api/textos';
export const PUT_TEXTO = process.env.REACT_APP_API_BASE_URL + 'api/textos/#idTexto';
export const DELETE_TEXTO = process.env.REACT_APP_API_BASE_URL + 'api/textos/#idTexto';

export const GET_HIJO_BY_ID = process.env.REACT_APP_API_BASE_URL + 'api/hijos/#idHijo';
export const PUT_HIJO = process.env.REACT_APP_API_BASE_URL + 'api/hijos/#idHijo';

export const GET_ACTIVIDADES_HIJO = process.env.REACT_APP_API_BASE_URL + 'api/actividadesHijos/hijos/#idHijo';
export const POST_ACTIVIDAD_HIJO = process.env.REACT_APP_API_BASE_URL + 'api/actividadesHijos';
export const PUT_ACTIVIDAD_HIJO = process.env.REACT_APP_API_BASE_URL + 'api/actividadesHijos/#idActividadHijo';
export const DELETE_ACTIVIDAD_HIJO = process.env.REACT_APP_API_BASE_URL + 'api/actividadesHijos/#idActividadHijo';
