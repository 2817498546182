import React, { Component } from 'react';
import axios from 'axios';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import Header from './../header/Header';
import Footer from './../footer/Footer';
import Match from './Match';
import Alert from './../../common/utils/Alert';
import * as appConst from './../../common/const/Const';
import * as utils from './../../common/utils/Funcs';

class MiMatchesPadre extends Component {

  constructor(props){
    super(props);
    this.state = {
      datos: [],
      textoAlert: '',
      tipoAlert: '',
      closeAlert: appConst.ALERT_OPEN
    }
    this.crearNuevoMatch = this.crearNuevoMatch.bind(this);
  }

  componentDidMount(){

    // Inicialización de los tooltip y popover de este componente
    utils.iniTooltip();
    utils.iniPopover();
    
    // Carga los matches que ha generado la empresa logeada
    this.listarMatches();
  }

  async listarMatches(){

    try{
      const response = await axios.get(appConst.GET_TRANSACCIONES, {headers: utils.getHeaders(appConst.HEADER_CON_TOKEN, appConst.PERFIL_PADRE), params: {
        idEmpresa: utils.getTokenValue("idEmpresa", appConst.PERFIL_PADRE),
        idTipoTransaccion: appConst.TIPO_TRAN_MATCH
      }} )
      
      if (response && response.data && response.status === appConst.HTTPSTATUS_OK){
          if (utils.getLog()) console.log("status GET_TRANSACCIONES: "+response.status);
          
          this.setState({ 
            datos: response.data,
            textoAlert: "",
            tipoAlert: ""
          });
          
      }else if (response && response.status === appConst.HTTPSTATUS_NO_CONTENT){
          this.setState({ 
            datos: [],
            textoAlert: <FM id="app.padre.mis-matches.listado.sin-datos" />,
            tipoAlert: appConst.ALERT_TIPO_WARNING,
            closeAlert: appConst.ALERT_CLOSE
          });
      }

    }catch(error){
        if (utils.getLog()) console.log("Error GET_TRANSACCIONES: "+error);
        this.setState({
            textoAlert: utils.getTextError(error, this.props.intl, this.props.history, null, null, this.listarMatches.bind(this), [], appConst.PERFIL_PADRE),
            tipoAlert: utils.getTipoAlert(error),
            closeAlert: appConst.ALERT_OPEN
        });
    }
  }

  crearNuevoMatch(){

    this.props.history.push(process.env.REACT_APP_WEB_BASE_URL+"/p/nuevo-match");
  }

  lanzarMensaje (texto, tipo, close){
    this.setState({
        textoAlert: texto,
        tipoAlert: tipo,
        closeAlert: close
    });
  }

  recargarLista(){
      this.listarMatches();
  }

  render(){ 
    return (
      <div>
        <Header itemMenu={appConst.MENU_PADRE_MIS_MATCHES}/>
        
        <div className="contenedorPadre">
          
          {/* MIGAS DE PAN */}
          <ol className="migaPan">
              <li className="enLineaFin icon-heart-full"> <FM id="app.padre.mis-matches.title" /></li>
          </ol>
          
          <div className="contenedor">

            <Alert texto={this.state.textoAlert} tipo={this.state.tipoAlert} close={this.state.closeAlert}/>
            
            <div>

              {/* PANTALLA PPAL */}
              <div className="mx-0 mb-5">
                
                {/* BOTON CREAR MATCH */}
                <div className="row mb-4">
                  <div className="col-md-12 text-end">
                    <button className="btn btn-sm btn-primary icon-heart-full" onClick={this.crearNuevoMatch}> <FM id="app.padre.mis-matches.botonera.crear-match" /></button>
                  </div>
                </div>

                {this.state.datos.map(t => 
                  <Match 
                    key={t.id}
                    id={t.id}
                    nombre={t.nombre}
                    asunto={t.asunto}
                    cuerpo={t.cuerpo}
                    fecha={t.fecha}
                    participantes={t.participantes}
                    nombreEstadoTransaccion={t.estadoTransaccion.nombre}
                    idEstadoTransaccion={t.idEstadoTransaccion}
                    _recargarLista={this.recargarLista.bind(this)}
                    _lanzarMensaje={this.lanzarMensaje.bind(this)}
                  />
                )}
              </div>
              
            </div>
          </div>
        </div>
        
        <Footer />
      </div>
    )
  }
}

export default injectIntl(MiMatchesPadre);